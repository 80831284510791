import { EStripeSubStatus } from '../../../models/consts';
import { IGithubUser, ITrialDataAPI } from '../../../models/models';
import { EGithubPlanNames } from '../../../models/paymentPlans/github-payments-plan';
import { ETrelloPlanNames, trelloPlans } from '../../../models/paymentPlans/trello-payments-plan';
import { trialDaysFinishDate } from '../../../utils/trialDaysLeft';
import { trelloBoardAdapterFunc, trelloUserAdapterFunction } from '../../functions';

export const fetchTrelloUserAction = (state, { payload }) => {
  if (!payload) {
    state.trello.usersLoading = false;
    state.trello.users = [];
    return;
  }

  state.trello.usersLoading = false;
  state.trello.users = trelloUserAdapterFunction([payload]);
}

export const addTrelloUserAction = (state, { payload }) => {
  if (payload) {
    state.trello.users = trelloUserAdapterFunction([payload] as Array<IGithubUser>);
  }
}

export const fetchItemsForTrelloUserAction = (state, { payload }) => {
  if (payload) {
    state.trello.itemsLoading = false;
    state.trello.items = trelloBoardAdapterFunc(payload.boards || []);
    state.trello.canUserBackup = payload.canUserBackup
    state.trello.totalItems = payload.totalItemsNumber || 0
  }
}

export const addTrelloItemsAction = (state, { payload }) => {
  state.trello.items?.push(...trelloBoardAdapterFunc(payload.boards_to_add || []));
}

export const deleteTrelloItemAction = (state, { meta }) => {
  if (state?.trello.items && meta.arg) {
    state.trello.items = [...state.trello.items]?.filter(x => x.id !== meta.arg);
  }
}

export const checkTrelloTrialStatusAction = (state, { payload }) => {
  if (payload) {
    state.trello.isConnectionActive = !!payload.isConnectionActive

    const trialData = payload as ITrialDataAPI;
    state.trello.trial = {
      trialStatus: trialData.trialStatus,
      finishDate: trialDaysFinishDate(trialData.trialHoursLeft),
      trialHoursLeft: trialData.trialHoursLeft,
      availableItemsQuantity: trialData.availableItemsQuantity,
      addedItemsQuantity: trialData.addedItemsQuantity,
      planQuantity: trialData.trialDefaultQuantity,
      isInvoiceRequested: trialData.stripe_trello_subscription_bank_transfer_requested || false,
      isCancel: false
    };

    const {s3_region, cloud_storages, stripe_trello_next_invoice, subscription_type, subscription_period, stripe_trello_subscription_cancel_at_period_end, stripe_trello_boards_quantity, stripe_trello_subscription_status, availableItemsQuantity, addedItemsQuantity, stripe_trello_subscription_bank_transfer_requested, stripe_trello_subscription_current_period_end } = trialData;

    state.trello.storage = cloud_storages || []
    if (s3_region) {
      state.trello.regionS3 = s3_region
    }
    if (stripe_trello_boards_quantity && stripe_trello_subscription_status) {
      const planData = trelloPlans.find(({ name }) => name === ETrelloPlanNames.ESSENTIAL);
      const isAnnual = subscription_period && subscription_period === 'year' || planData?.stripeIds.yearly === trialData.stripe_trello_price_id;

      const cost = stripe_trello_next_invoice ? stripe_trello_next_invoice / 100 : 0;

      state.trello.subscription = {
        status: stripe_trello_subscription_status.toLowerCase() as EStripeSubStatus,
        planName: subscription_type === 'pro_plan' ? EGithubPlanNames.PRO : EGithubPlanNames.ESSENTIAL,
        isAnnual,
        finishDate: stripe_trello_subscription_current_period_end ? new Date(Number(stripe_trello_subscription_current_period_end + '000')).toLocaleDateString() : null,
        finishDateInMillisecond: Number(stripe_trello_subscription_current_period_end +'000' ),
        cost: cost as number,
        quantity: availableItemsQuantity,
        addedItemsQuantity: addedItemsQuantity,
        planQuantity: stripe_trello_boards_quantity,
        isInvoiceRequested: stripe_trello_subscription_bank_transfer_requested || false,
        isCancel: stripe_trello_subscription_cancel_at_period_end || false
      };
    } else if (stripe_trello_subscription_status) {
      state.trello.subscription = {
        status: stripe_trello_subscription_status.toLowerCase() as EStripeSubStatus,
        // planName: EGitlabPlanNames.ESSENTIAL,
        // isAnnual:
        // finishDate: stripe_gitlab_subscription_current_period_end ? new Date(Number(stripe_gitlab_subscription_current_period_end + '000')).toLocaleDateString() : null,
        addedItemsQuantity: addedItemsQuantity,
        cost: 0,
        // quantity: availableItemsQuantity,
        // planQuantity: stripe_gitlab_projects_quantity,
        // isInvoiceRequested: stripe_gitlab_subscription_bank_transfer_requested || false,
        // isCancel: stripe_gitlab_subscription_cancel_at_period_end || false
      };
    }
  }
}
