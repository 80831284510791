import { CssBaseline } from '@mui/material';
import React from 'react';
import {Outlet} from 'react-router-dom';
import logo from '../assets/icons/backuplabs-logo-white.png';
import {  blArrow2Grey } from '../assets/images/other';
import Footer from '../components/Footer/Footer';
import { MyRoot } from '../components/StyledComponents';
import { GlobalAuthStyle } from '../GlobalStyle';
import useWindowDimensions from '../utils/windowDimensions';
// import OnWork from "../views/OnWork/OnWork";

const Auth: React.FC<{isSignUp?: boolean}> = ({ children, isSignUp }) => {
  const { isMobileOnly } = useWindowDimensions();


  return (
    <MyRoot className='auth-wrapper'>
      <CssBaseline />
      <GlobalAuthStyle ismobile={isMobileOnly}/>
      {/*<OnWork/>*/}
      {!isSignUp && <div/>}
      <div className="first-arrow">
        <img src={blArrow2Grey} loading="lazy"/>
      </div>
      <div className="second-arrow">
        <img src={blArrow2Grey} loading="lazy"/>
      </div>
      <div className="third-arrow">
        <img src={blArrow2Grey} loading="lazy"/>
      </div>
      <div className="fourth-arrow">
        <img src={blArrow2Grey} loading="lazy"/>
      </div>
      <div className='auth-content'>
        {!isSignUp && (
          <div className="brand-block" onClick={() => {
            window.location.href = 'https://www.backuplabs.io';
          }}>
            <img className="logo" src={logo} loading="lazy"/>
          </div>
        )}
        <Outlet />
      </div>
      {children}
      {!isSignUp && (
        <Footer />
      )}
    </MyRoot>
  );
};

export default Auth;
