import { createAction, createSlice } from '@reduxjs/toolkit';
import { EActionStatus } from '../../models/consts';
import { IAction, IActionAPI } from '../../models/models';
import { humanReadableFileSize } from '../../utils/fileSizeConverter';
import {getGithubActions, getGitlabActions, getJiraActions, getNotionActions, getTrelloActions} from '../actions';
import {EBackups} from '../constants';
import {EWebsocketMethods} from "../../constants";
import {socketUpdatePlatformActionsStates} from "./platformCases/socketCases";

interface PlatformBackupsState {
  loading: boolean;
  repo: string | null;
  actions: Array<IAction> | [];
  totalActions: number;
  canRestore:boolean
}

export interface BackupState {
  github: PlatformBackupsState;
  gitlab: PlatformBackupsState;
  trello: PlatformBackupsState;
  jira: PlatformBackupsState;
  notion: PlatformBackupsState;
}

const initialState: BackupState = {
  github: {
    loading: true,
    repo: null,
    actions: [],
    totalActions: 0,
    canRestore:true
  },
  trello: {
    loading: true,
    repo: null,
    actions: [],
    totalActions: 0,
    canRestore:true
  },
  gitlab: {
    loading: true,
    repo: null,
    actions: [],
    totalActions: 0,
    canRestore:true
  },
  jira: {
    loading: true,
    repo: null,
    actions: [],
    totalActions: 0,
    canRestore:true
  },
  notion: {
    loading: true,
    repo: null,
    actions: [],
    totalActions: 0,
    canRestore:true
  },
};

export interface ActionUpdatePayload extends IAction{
  method: EWebsocketMethods;
  actionProcess: string;
  data_size: number;
  id: string;
  _id: string;
  platform: string;
  error_message: string,
  page:number
}

export interface ActionsUpdatePayload {
  method: EWebsocketMethods;
  actionProcess: string;
  actions: ActionUpdatePayload[]
  platform: string,
  page:number
}

const clearBackupsState = createAction(EBackups.resetBackup);
const updateActionsState = createAction(EBackups.updateActions);

const { reducer } = createSlice({
  name: 'backups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGithubActions.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          state.github.repo = meta.arg.itemId;
          state.github.loading = false;

          if (!payload.actions?.length) {
            state.github.actions = [];
            state.github.totalActions = 0;
            return;
          }

          state.github.canRestore = payload.userCanRestore;
          state.github.actions = (payload.actions as Array<IActionAPI>).map((action) => ({
            size: action.data_size ? humanReadableFileSize(action.data_size) : '-',
            date: action?.date as unknown as string || '',
            status: action.status as EActionStatus,
            error_message: action.error_message || action.message,
            versionid: action?.version || '',
            key: action?.id || '',
            type: action.type || '',
            action_process: action?.actionProcess
          }));
          state.github.totalActions = payload.totalItemsNumber
        }
      })
      .addCase(getGithubActions.rejected, (state) => {
        state.github.repo = null;
        state.github.loading = false;
        state.github.actions = [];
        state.github.totalActions = 0;
      })

      .addCase(getGitlabActions.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          state.gitlab.repo = meta.arg.itemId;
          state.gitlab.loading = false;

          if (!payload.actions?.length) {
            state.gitlab.actions = [];
            state.gitlab.totalActions = 0;
            return;
          }

          state.gitlab.canRestore = payload.userCanRestore;
          state.gitlab.actions = (payload.actions as Array<IActionAPI>).map((action) => ({
            size: action.data_size ? humanReadableFileSize(action.data_size) : '-',
            date: action?.date as unknown as string || '',
            status: action.status as EActionStatus,
            error_message: action.error_message || action.message,
            versionid: action?.version || '',
            key: action?.id || '',
            type: action.type || '',
            action_process: action?.actionProcess
          }));
          state.gitlab.totalActions = payload.totalItemsNumber
        }
      })
      .addCase(getGitlabActions.rejected, (state) => {
        state.gitlab.repo = null;
        state.gitlab.loading = false;
        state.gitlab.actions = [];
        state.gitlab.totalActions = 0;
      })

      .addCase(getTrelloActions.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          state.trello.repo = meta.arg.itemId;
          state.trello.loading = false;

          if (!payload.actions?.length) {
            state.trello.actions = [];
            state.trello.totalActions = 0;
            return;
          }

          state.trello.canRestore = payload.userCanRestore;
          state.trello.actions = (payload.actions as Array<IActionAPI>).map((action) => ({
            size: (action?.extra_fields?.fullBackupSize || action.data_size) ? humanReadableFileSize(action?.extra_fields?.fullBackupSize || action.data_size) : '-',
            sizeSub: action.data_size ? humanReadableFileSize(action.data_size) : '-',
            date: action?.date as unknown as string || '',
            status: action.status as EActionStatus,
            error_message: action.error_message || action.message,
            versionid: action?.id || '',
            key: action?.id || '',
            type: action.type || '',
            versions: action.version,
            download_zip_url: action?.extra_fields?.get_zip?.download_zip_url,
            zip_status: action?.extra_fields?.get_zip?.zip_status,
            expire_date_zip_url: action?.extra_fields?.get_zip?.expire_date_zip_url,
            action_process: action?.actionProcess
          }));
          state.trello.totalActions = payload.totalItemsNumber

        }
      })
      .addCase(getTrelloActions.rejected, (state) => {
        state.trello.repo = null;
        state.trello.loading = false;
        state.trello.actions = [];
        state.trello.totalActions = 0;
      })

      .addCase(getNotionActions.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          state.notion.repo = meta.arg.itemId;
          state.notion.loading = false;

          if (!payload.actions?.length) {
            state.notion.actions = [];
            state.notion.totalActions = 0;
            return;
          }

          state.notion.canRestore = payload.userCanRestore;
          state.notion.actions = (payload.actions as Array<IActionAPI>).map((action) => ({
            size: (action?.extra_fields?.fullBackupSize || action.data_size) ? humanReadableFileSize(action?.extra_fields?.fullBackupSize || action.data_size) : '-',
            sizeSub: action.data_size ? humanReadableFileSize(action.data_size) : '-',
            date: action?.date as unknown as string || '',
            status: action.status as EActionStatus,
            error_message: action.error_message || action.message,
            versionid: action?.id || '',
            key: action?.id || '',
            type: action.type || '',
            versions: action.version,
            scopeVersionId: action.extra_fields?.scopeVersionId || '',
            download_zip_url: action?.extra_fields?.get_zip?.download_zip_url,
            zip_status: action?.extra_fields?.get_zip?.zip_status,
            expire_date_zip_url: action?.extra_fields?.get_zip?.expire_date_zip_url,
            action_process: action?.actionProcess
          }));
          state.notion.totalActions = payload.totalItemsNumber

        }
      })
      .addCase(getNotionActions.rejected, (state) => {
        state.notion.repo = null;
        state.notion.loading = false;
        state.notion.actions = [];
        state.notion.totalActions = 0;
      })
      .addCase(getJiraActions.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          state.jira.repo = meta.arg.itemId;
          state.jira.loading = false;

          if (!payload.actions?.length) {
            state.jira.actions = [];
            state.jira.totalActions = 0;
            return;
          }

          state.jira.canRestore = payload.userCanRestore;
          state.jira.actions = (payload.actions as Array<IActionAPI>).map((action) => ({
            size: (action?.extra_fields?.fullBackupSize || action.data_size) ? humanReadableFileSize(action?.extra_fields?.fullBackupSize || action.data_size) : '-',
            sizeSub: action.data_size ? humanReadableFileSize(action.data_size) : '-',
            date: action?.date as unknown as string || '',
            status: action.status as EActionStatus,
            error_message: action.error_message || action.message,
            versionid: action?.id || '',
            key: action?.id || '',
            type: action.type || '',
            versions: action.version,
            download_zip_url: action?.extra_fields?.get_zip?.download_zip_url,
            zip_status: action?.extra_fields?.get_zip?.zip_status,
            expire_date_zip_url: action?.extra_fields?.get_zip?.expire_date_zip_url,
            action_process: action?.actionProcess
          }));
          state.jira.totalActions = payload.totalItemsNumber

        }
      })
      .addCase(getJiraActions.rejected, (state) => {
        state.jira.repo = null;
        state.jira.loading = false;
        state.jira.actions = [];
        state.jira.totalActions = 0;
      })

      .addCase(updateActionsState, socketUpdatePlatformActionsStates)

      .addCase(clearBackupsState, () => initialState);
  },
});

export { clearBackupsState,updateActionsState };
export default reducer;
