enum EBackups {
  transferRepoData = 'backups/fetch',
  matchRepoData = 'backups/match',
  restoreBackup = 'backups/restore',
  restoreBackupGist = 'backups/restore-gist',
  downloadZip = 'backups/download',
  resetBackup = 'backups/reset',
  updateActions = 'backups/update-actions',
  restoreGistBackup = 'backups/restore-gist',
  downloadGistZip = 'backups/download-gist',
  updateUserWorkspace = 'update-user-workspace',
}

export default EBackups;
