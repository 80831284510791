import React, { FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';


const TrelloConnectSecret: FunctionComponent = () => {
  useEffect(() => {
    const body = document.getElementsByTagName('body');
    body[0].insertAdjacentHTML('afterbegin', `
<button type=button>Click to authorize</button>
<script src="https://p.trellocdn.com/power-up.min.js"></script>
<script>
  var t = window.TrelloPowerUp.iframe({
    appKey: '7d4742f3e24572c14de0ed71ef9cee74',
    appName: 'BackupLABS'
  });
  t.render(function() {
    document.querySelector('button').addEventListener('click', function() {
      t.getRestApi()
              .authorize({ scope: 'read,write' })
              .then(function(t) {
                alert('Success!');
              });
    }, false);
  });
</script>
`);
  });

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Authorization</title>
    </Helmet>
  );
};

export default (TrelloConnectSecret);
