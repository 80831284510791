export enum ENotionPlanNames {
  ESSENTIAL = 'Essential',
  TRIAL = 'Trial',
  PRO = 'Pro'
}

export const notionPlans = [
  {
    name: ENotionPlanNames.ESSENTIAL,
    stripeIds: {
      product: process.env.REACT_APP_STRIPE_NOTION_PRODUCT_KEY,
      monthly: process.env.REACT_APP_STRIPE_NOTION_MONTHLY_ID,
      yearly: process.env.REACT_APP_STRIPE_NOTION_YEARLY_ID,
    },
    defaultPlan: {
      from: 1,
      to: 1,
      cost: 10,
    },
    monthlyCost: [],
    annualyCost: [],
  },
];
