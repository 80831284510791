import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  Drawer as MuiDrawer,
  FormControl as MuiFormControl,
  IconButton as MuiIconButton,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from '@mui/material';
import { styled as muiStyled } from '@mui/styles';
import { spacing } from '@mui/system';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import styled, { css } from 'styled-components';

export const IconButton = muiStyled(MuiIconButton)(spacing);
export const Button = muiStyled(MuiButton)(spacing);
export const Divider = muiStyled(MuiDivider)(spacing);
export const Typography = muiStyled(MuiTypography)(spacing);
export const Breadcrumbs = muiStyled(MuiBreadcrumbs)(spacing);
export const Card = muiStyled(MuiCard)(spacing);
export const Paper = muiStyled(MuiPaper)(spacing);
export const FormControl = muiStyled(MuiFormControl)<{ my?: number }>(spacing);
export const TextField = muiStyled(MuiTextField)<{ my?: number }>(spacing);

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const drawerWidth = 258;
export const smallDrawerWidth = 58;

export const DashboardDrawer = styled.div<{bigmenu: string, onClose: () => void}>`
  ${(props) => props.theme.breakpoints.up(901)} {
    width: ${(props) => props.bigmenu ? `${drawerWidth}px` : `${smallDrawerWidth}px`};
    flex-shrink: 0;
    margin-top: 100px;
    transition: all .2s linear;
    + #platform-content {
      width: ${(props) => props.bigmenu ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${smallDrawerWidth}px)`};
    } 
  }
`;

export const SidebarDrawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    transition: all .2s linear;
    border-right: 0;
  }
`;

const baseScrollbar = css`
  background: linear-gradient(180deg, #5C3ED0 0%, #67DEE6 82.32%);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

export const Scrollbar = styled.div`
  ${baseScrollbar}
`;

export const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

export const Items = styled.div`
  padding: ${(props) => props.theme.spacing(2.5)} 8px;
`;

export const AuthWrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(3)};
  min-width: 400px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  z-index: 99;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  
  @media(max-width: 900px) {
    min-width: 300px;
  }
  
  @media(max-width: 767px) {
    width: 90%;
    min-width: 0 !important;
  }
`;

export const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

export const MyRoot = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
