import {Box, CssBaseline} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Header from '../components/Header/Header';
import dashboardItems from '../components/Sidebar/dashboardItems';
import Sidebar from '../components/Sidebar/Sidebar';
import { DashboardDrawer as Drawer, drawerWidth, Root, smallDrawerWidth } from '../components/StyledComponents';
import useWindowDimensions from '../utils/windowDimensions';
import {useAppSelector} from "../store/hooks";
import {IUser} from "../models/models";
import {getUserSelector, isHasProPlan, isSubUser, underWork, userRoles} from "../store/selectors";
import { Helmet } from 'react-helmet';
import OnWork from "../views/OnWork/OnWork";

const Dashboard: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user: IUser | null = useAppSelector(getUserSelector);
  const isUnderWork = useAppSelector(underWork);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [bigMenu, setBigMenu] = useState(true);
  const isUserSub = useAppSelector(isSubUser);
  const roles = useAppSelector(userRoles);
  const hasProPlan = useAppSelector(isHasProPlan);

  const { isSmallScreenPortrait, isSmallScreen } = useWindowDimensions();

  const handleDrawerToggle = () => {
    isSmallScreenPortrait ?
      setMobileOpen(!mobileOpen) :
      setBigMenu(!bigMenu);
  };

  useEffect(()=>{
    if (!isSmallScreen && !bigMenu) {
      setBigMenu(true)
    }
  }, [isSmallScreen])

  useEffect(() => {
    if (isUserSub && (
      (!hasProPlan && (location.pathname.includes('platform') || location.pathname.includes('audit'))) ||
      location.pathname.includes('subscriptions') ||
      location.pathname.includes('app-connector') ||
      location.pathname.includes('checkout')
    )) {
      navigate('/', { replace: true })
    }
  }, [isUserSub, location]);

  return (
    <Root>
      <Helmet>
        <title>BackupLABS</title>
        <meta name="title" content="BackupLABS" />
        <meta name="description" content="BackupLABS offer an automatic and reliable SaaS backup solution for your business. Protect GitHub, Trello, GitLab, Jira and Notion apps." />
      </Helmet>
      <CssBaseline />
      {isUnderWork && <OnWork/>}
      <Header menuToggle={handleDrawerToggle} secondLogo={user?.hadEverAnySubscriptions || false}/>
      {user?.hadEverAnySubscriptions && (
        <Drawer bigmenu={bigMenu ? 'ok' : ''} onClose={handleDrawerToggle}>
          <Box sx={{ display: isSmallScreenPortrait ? 'block' : 'none', backgroundColor: 'red' }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
            />
          </Box>
          <Box sx={{ display: !isSmallScreenPortrait ? 'block' : 'none' }}>
            <Sidebar
              bigMenu={bigMenu}
              PaperProps={{ style: { width: bigMenu ? drawerWidth : smallDrawerWidth } }}
              items={dashboardItems}
            />
          </Box>
        </Drawer>
      )}
      {children}
      <Outlet />
    </Root>
  );
};

export default Dashboard;
