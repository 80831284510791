const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        fontWeight: '500',
        fontSize: '14px',
        padding: '0 12px',
        minHeight: '36px',
        minWidth: '36px',
        height: '38px',
      }
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&.MuiButton-root:disabled': {
          backgroundColor: '#D9CFFF!important',
          color: '#ffffff!important'
        },
        '&.MuiButton-outlined' : {
          border: '1px solid #D1D5DB',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          backgroundColor: '#fff',
          color: '#374151'
        },
        '&.MuiButton-outlinedWarning': {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          backgroundColor: '#fff',
          border: '1px solid #EF4444',
          color: '#EF4444'
        },
        '&.MuiButton-containedSecondary': {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          background: '#EDE9FF',
          color: '#5C3ED0',
          '&:hover': {
            background: '#D9CFFF'
          }
        }
      },
    }
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
        border: '1px solid #F3F4F6',
        '& .MuiDataGrid-main': {
          borderBottom: '1px solid #E5E7EB!important'
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },

        '&  .MuiDataGrid-columnHeaders': {
          backgroundColor: '#F3F4F6',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        },
        '& .MuiDataGrid-cell': {
          paddingLeft: '16px',
          boxSizing: 'border-box'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none'
        },
        '.MuiDataGrid-overlay': {
          zIndex: 99,
          height:'100%!important'
        }
      }
    }
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        '& .MuiPaginationItem-root': {
          borderRadius: 0,
          height: '64px',
          boxSizing: 'border-box',
          width: '32px',
          fontWeight: 500,
          '&:hover:not(.Mui-selected)': {
            color: '#7D5EF8',
            borderTop: '0',
            backgroundColor: 'unset!important'
          },
        },
        '& .Mui-selected': {
          backgroundColor: 'unset!important',
          color: '#7D5EF8',
          borderTop: '2px solid #7D5EF8'
        },
        '& .MuiPaginationItem-ellipsis': {
          lineHeight: '64px'
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& .MuiInputLabel-formControl[data-shrink|="false"]': {
          transform: 'translate(14px, 10px) scale(1)'
        }
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: '42px',
        lineHeight: '40px',
        background: '#FFFFFF',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: '6px',
        '&.MuiInputBase-multiline': {
          height: 'auto'
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {

      root: {
        borderRadius: '10px',
        border: '1px solid #F3F4F6',
        '& .MuiAutocomplete-root': {
          height: '40px',
          borderRadius: '10px',
          border: '1px solid #F3F4F6',
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingTop: '2px!important',
          height: '38px'
        },
        '& .MuiAutocomplete-endAdornment': {
          top: '-2px!important'
        }
      }
    }
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
};

export default components;
