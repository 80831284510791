import {CircularProgress, Dialog, DialogContent, DialogTitle} from '@mui/material';
import React, {FunctionComponent, memo, useEffect, useState} from 'react';
import {closeModalIcon, errorBigIcon, toastSuccess} from '../../../assets/icons/icons';
import './style.scss';
import {useTranslation} from "react-i18next";
import {Button, TextField} from "../../StyledComponents";
import {IItem} from "../../../models/models";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {IDailyStatusBody, IError} from "../../../models/inner-models";
import {setStatusDailyBackup} from "../../../store/actions";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import {useAppDispatch} from "../../../store/hooks";
import {EPaymentRequiredType} from "../../../models/consts";

interface IDeleteDialogProps {
  onConfirm: () => void;
  name: string;
  isOpen: boolean;
  isBulk: boolean;
  platformName: string;
  bulkData: string[];
  closeNotification?: (approved?: boolean) => void;
  updItems: () => void;
  items: Array<IItem>;
  singleData?: { id?: string, name?: string, type?: string };
  paymentState: EPaymentRequiredType;
}

const DeleteItemDialog: FunctionComponent<IDeleteDialogProps> = ({
                                                                   isOpen,
                                                                   isBulk,
                                                                   bulkData,
                                                                   singleData,
                                                                   platformName,
                                                                   items,
                                                                   paymentState,
                                                                   closeNotification,
                                                                   onConfirm,
                                                                   name,
                                                                   updItems
                                                                 }: IDeleteDialogProps) => {
  const dispatch = useAppDispatch();
  const {t: translate} = useTranslation();
  const [inputField, setInputField] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [processingDeact, setProcessingDeact] = useState<boolean>(false);
  const singleName = bulkData?.length === 1 || !isBulk ? (isBulk ? items?.filter(i=>i.id === bulkData[0])?.map(({board, repo, project,workspace}) => board?.split('::')[0] || repo?.split('::')[0] || workspace?.split('::')[0]  || project?.split('::')[0] || '-')[0] : name) : ''
  const hasBulkDataNoProcessItem = bulkData?.some(item =>!items.find(rowItem => (rowItem.id === item))?.actionProcess)

  useEffect(()=>{
    if (isOpen && processing) {
      setProcessing(false)
    }
    if (isOpen && processingDeact) {
      setProcessingDeact(false)
    }
  }, [isOpen])

  const setDailyStatus = async () => {
    try {
      const payload: IDailyStatusBody = {is_daily_backed_up: false}
      const gists: string[] = [];
      const repos: string[] = [];
      setProcessingDeact(true)

      switch (platformName) {
        case 'trello':
          payload.boardsIds = !isBulk ? [singleData?.id || ''] : bulkData
          break;
        case 'gitlab':
        case 'jira':
          payload.projectsIds = !isBulk ? [singleData?.id || ''] : bulkData
          break;
        case 'github':
          if (!isBulk && singleData?.id) {
            if (!!singleData.type) {
              repos.push(singleData?.id)
            } else {
              gists.push(singleData?.id)
            }
          } else {
            bulkData.forEach(item => {
              const result = items.find(({id}) => id === item)
              if (result && !!result.isRepo) {
                repos.push(item)
              } else {
                gists.push(item)
              }
            })
          }
          payload.reposIds = repos
          payload.gistsIds = gists
          break;
        case 'notion':
          payload.workspacesIds = !isBulk ? [singleData?.id || ''] : bulkData
          break;
        default:
          break;
      }

      await dispatch(setStatusDailyBackup({payload: payload, platform: platformName})).unwrap();
      await updItems();

      closeNotification && closeNotification()
      setProcessingDeact(false)

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: translate('notifications.all.inactiveStatus'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError;

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          closeNotification && closeNotification();
          setInputField('')
          setProcessing(false)
          setProcessingDeact(false)
        }}
        aria-labelledby="delete-item-title"
        aria-describedby="delete-item-description"
        classes={{
          paper: 'delete-item-container',
        }}
      >
        <DialogTitle id="delete-item-title">
          {translate('notifications.delete_item.title')}
        </DialogTitle>
        <div className="close-btn" onClick={() => closeNotification && closeNotification()}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        <DialogContent>
          <div className='delete-item-content'>
            <p>
              {translate('notifications.delete_item.p11')}
              <b>{translate('notifications.delete_item.p12')}</b>
              {translate(
                'notifications.delete_item.p13',
                {itemType: platformName === 'github' && Number(localStorage['githubUser']) === 0 ? isBulk ? 'gists' : 'gist' : translate(`views.bulk_restore.itemName.${platformName}.${isBulk ? 'many' : 'one'}`)}
              )}
            </p>
            <p>
              {translate('notifications.delete_item.p2')}
            </p>
            {isBulk && (
              <p>
                {translate('notifications.delete_item.p21')}
                <b>{translate('notifications.delete_item.p22')}</b>
                {bulkData?.length === 1 ? translate('notifications.delete_item.p24') : translate('notifications.delete_item.p23')}
              </p>
            )}
            {isBulk && (
              <div className='delete-bulk-table'>
                <Table>
                  <col width="420px" />
                  <col width="120px" />
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Backup size</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items?.map(({id, board, repo, project, workspace, size}, index) => {
                      if (bulkData?.indexOf(id) !== -1) {
                        return (
                          <TableRow key={index + '_key'}>
                            <TableCell>{board?.split('::')[0] || repo?.split('::')[0] || project?.split('::')[0] || workspace?.split('::')[0] || '-'}</TableCell>
                            <TableCell>{size}</TableCell>
                          </TableRow>
                        )
                      }
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
            <p className='delete-modal-remove-name'>
              {translate('notifications.delete_item.p31')}
              <b>{isBulk && bulkData?.length > 1 ? 'I understand' : singleName?.split('::')[0]}</b>
              {translate('notifications.delete_item.p32')}
            </p>
            <TextField
              required
              fullWidth
              placeholder={isBulk ?
                'Type here' :
                `Enter ${platformName === 'github' && Number(localStorage['githubUser']) === 0 ? 'gist' : translate(`views.bulk_restore.itemName.${platformName}.one`)} name here`
            }
              id="delete-item-input"
              name="item-name"
              defaultValue={''}
              onChange={(e) => setInputField(e.target.value as string)}
            />
          </div>
          <div className="action-buttons">
            {paymentState === EPaymentRequiredType.NON && (isBulk || (singleData && items.find(({id}) => id === singleData.id)?.isDailyActive)) && (
              <Button
                onClick={() => setDailyStatus()}
                variant="outlined"
                color="primary"
                disabled={processingDeact || processing || !hasBulkDataNoProcessItem}
              >
                {processingDeact &&
                  <div className="small-spinner-wrapper">
                    <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                  </div>
                }
                {translate('notifications.choices.deactivateInst')}
              </Button>
            )}
            <Button
              onClick={() => {
                setInputField('')
                setProcessing(true)
                onConfirm()
              }}
              variant="contained"
              color="error"
              disabled={isBulk && bulkData?.length > 1 ? inputField !== 'I understand' : inputField !== singleName?.split('::')[0] || processing || processingDeact}
            >
              {processing &&
                <div className="small-spinner-wrapper">
                  <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                </div>
              }
              {(!isBulk || bulkData?.length === 1) ?
                translate('notifications.delete_item.btn') :
                translate('notifications.delete_item.btnBulk', {num: bulkData?.length})}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(DeleteItemDialog);
