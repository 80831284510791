import {Checkbox, CircularProgress, debounce, Dialog, DialogContent, DialogTitle, InputAdornment} from '@mui/material';
import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  CheckboxInputChecked,
  CheckboxInputEmpty,
  closeModalIcon,
  errorBigIcon,
  iconNotifyWarningInfo,
  searchIcon,
  toastSuccess,
  warningBigIcon,
} from '../../../../../assets/icons/icons';
import PaymentRequiredPoppup from '../../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup';
import {Button} from '../../../../../components/StyledComponents';
import {EApiStatuses} from '../../../../../constants';
import {EPaymentRequiredType, EPlatformsNames} from '../../../../../models/consts';
import {IError, IGithubAddProps, IPaymentRequiredPoppup} from '../../../../../models/inner-models';
import {ISetterUserWithItems} from '../../../../../models/models';
import {addJiraItems, getAllSubscriptions, getAvailableItemsForJiraUser} from '../../../../../store/actions';
import {useAppDispatch} from '../../../../../store/hooks';
import {SearchInput} from '../../../../../styles/components/MainInputElements';
import {decoratePopUpMessage} from '../../../../../utils/popUpTextDecorator';
import './style.scss';
import {BadgesTrial} from "../../../../../components/Badges/Badges";
let abortController: AbortController | null

const JiraBoardAdd: FunctionComponent<IGithubAddProps> = ({
                                                            isOpen,
                                                            closeNotification,
                                                            user,
                                                            platformName,
                                                            paymentType,
                                                            // availableItemsLeft,
                                                            subPlan,
                                                            updItems
                                                          }: IGithubAddProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t: translate} = useTranslation();
  const {handleSubmit, setValue, trigger, reset} = useForm<ISetterUserWithItems>({
    mode: 'onChange',
  });

  const [availableProjects, setAvailableProjects] = useState<Array<{ id: string, name: string }>>([]);
  const [storeItems, setStoreItems] = useState<Array<{ id: string, name: string, projectType?: string }>>([]);
  const [checkedProjects, setCheckedProjects] = useState<Array<string>>([]);
  const [filteredProjects, setFilteredProjects] = useState<Array<{ id: string, name: string, projectType?: string }>>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [availableProjectsLoading, setAvailableProjectsLoading] = useState<boolean>(true);
  const [addingProjectsProcessing, setAddingProjectsProcessing] = useState<boolean>(false);
  const [paymentPopup, setPaymentPopup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName || EPlatformsNames.JIRA,
    type: paymentType || EPaymentRequiredType.NON,
  });
  const [scroll, setScroll] = useState<boolean>(false);
  const [netSearch, setNetSearch] = useState<boolean>(false);
  const step = 20;

  async function getAvailableProjects(name?: string) {
    try {
      const page = !storeItems.length ? 0 : Math.trunc(storeItems.length / step)
      abortController = new AbortController();
      const {signal} = abortController;
      const projects = await dispatch(getAvailableItemsForJiraUser({page: page, perPage: step, name, signal})).unwrap();

      if (signal.aborted) {
        return
      }

      if (!projects || !projects.length) {
        setAvailableProjects([]);
        // setStoreItems([])
        // setFilteredProjects([]);
        setAvailableProjectsLoading(false);
        setScroll(false);
        setNetSearch(false);
        return;
      }

      setAvailableProjects(projects);
      setStoreItems(prevState => prevState.concat(projects));
      setFilteredProjects(prevState => prevState.concat(projects));
      setAvailableProjectsLoading(false);
      setScroll(false);
      setNetSearch(false);
    } catch (err) {
      console.log(err);
      setAvailableProjectsLoading(false);
      setScroll(false);
      setNetSearch(false);
    }
  }

  const handleScroll = (e) => {
    if (!scroll && availableProjects.length === step) {
      const element = e.target
      if (!element) return;

      const viewHeight = element.offsetHeight;
      const fullHeight = element.scrollHeight;
      const scrollingLength = element.scrollTop;
      if (fullHeight <= viewHeight + scrollingLength + 150) {
        setScroll(true);
        getAvailableProjects()
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((event) => {
      setAvailableProjectsLoading(true)
      setAvailableProjects([]);
      setStoreItems([]);
      setFilteredProjects([]);
      getAvailableProjects(event)
    }, 1000),
    [],
  );

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value || ''
    setSearch(val);
    setNetSearch(true)
    debouncedSearch(val)
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setAvailableProjectsLoading(true);
    setSearch('');
    if (isOpen) {
      dispatch(getAllSubscriptions()).unwrap();
      getAvailableProjects();
    } else if (!isOpen) {
      setAvailableProjects([]);
      setStoreItems([]);
      setFilteredProjects([]);
    }
  }, [isOpen]);

  const openPayModal = () => {
    Swal.fire({
      title: translate('notifications.subscription.buy_plan_title'),
      text: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.subscription.buy_plan_jira') :
        translate('notifications.subscription.upgrade_plan_jira'),
      imageUrl: iconNotifyWarningInfo,
      cancelButtonText: translate('notifications.choices.nevermind'),
      confirmButtonText: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.choices.buy_subscription') :
        translate('notifications.choices.upgradeSub'),
      showCancelButton: true,
    })
      .then((res) => {
        if (res.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        }
      })
  }

  const handleChange = (projectId) => {
    if (checkedProjects.includes(projectId)) {
      setCheckedProjects(checkedProjects.filter((ell) => ell !== projectId));
    } else { //if (availableItemsLeft >= 0) { //(availableItemsLeft - checkedProjects.length) > -1 &&
      setCheckedProjects([...checkedProjects, projectId]);
    }
    // else if (availableItemsLeft < 0) { //(availableItemsLeft - checkedProjects.length) <= -1 ||
    //   openPayModal()
    // }
  };

  const handleSelectAllChange = (checked: boolean) => {
    // if (availableItemsLeft < 0) {
    //   openPayModal()
    // } else {
      setCheckedProjects((prevState) => {
        if (checked) {
          const newArr = filteredProjects?.filter(project => project.projectType !== 'bug_tracking' && !prevState.includes(project.id)).map(i => i.id) || []
          return [...prevState, ...newArr]
        } else {
          return []
        }
      })
    // }
  };

  useEffect(() => {
    setValue('items', checkedProjects);
    trigger('items');
  }, [checkedProjects]);

  const onSubmit: SubmitHandler<ISetterUserWithItems> = async (data) => {
    const adaptedData = {
      user: user?.username,
      projects: data.items.map(item => ({id: item})),
    };
    // if (availableItemsLeft < 0) {
    //   openPayModal()
    //   return
    // }
    setAddingProjectsProcessing(true);
    try {
      await dispatch(addJiraItems(adaptedData)).unwrap();
      await updItems();
      // if ((availableItemsLeft - checkedProjects.length) === 0) {
      //   dispatch(getAllSubscriptions()).unwrap()
      // }
      setAddingProjectsProcessing(false);
      handleClose();

      Swal.fire({
        title: translate('notifications.titles.success'),
        text: checkedProjects.length > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError;

      handleClose();
      setAddingProjectsProcessing(false);
      console.log(err);

      if (error.status === EApiStatuses.WARNING) {
        const userChoice = await Swal.fire({
          title: translate('notifications.titles.warning'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: warningBigIcon,
          confirmButtonText: translate('subscriptions.edit_sub'),
        });

        if (userChoice.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        } else {
          await updItems();
        }

        return;
      }

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const openTrialModal = useCallback(() => {
    setPaymentPopup((prev) => ({
      ...prev,
      isOpen: true,
    }));
  }, [paymentType]);

  const handleClose = useCallback(() => {
    setCheckedProjects([]);
    reset();
    closeNotification && closeNotification();
    abortController && abortController.abort();
    abortController = null
  }, []);

  const closeTrialPopup = useCallback(() => {
    setPaymentPopup({...paymentPopup, isOpen: false});
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="jira-repo-edit-title"
        aria-describedby="jira-repo-edit-description"
        classes={{
          paper: 'add-edit-jira-modal',
        }}
      >
        <DialogTitle id="jira-repo-edit-title">
          {translate('forms.jira_add_edit_repo.add_new_boards')}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        <DialogContent>
          {/*{(subPlan && paymentType === EPaymentRequiredType.NON ) && ( //&& availableItemsLeft < 0*/}
          {/*  <BadgesTrial platformName={'jira'} color='warning' isSub={subPlan.name !== "Trial"} isModal={true}/>*/}
          {/*)}*/}
          <div id="jira-repo-edit-description">
            <div className="username-block">
              <div className='github-add-select-all'>
                <div className='title-line'>
                  {translate('views.bulk_restore.itemName.jira.many')}
                </div>
                <div className='desc-line'>
                  {user?.username &&
                      <>
                        {`A list of all the projects for user “${user?.username}”.`}<br/>
                      </>
                  }
                  {/*{availableItemsLeft - checkedProjects.length > 0*/}
                  {/*  ? translate('forms.jira_add_edit_repo.boards_left', {left: availableItemsLeft - checkedProjects.length})*/}
                  {/*  : translate('forms.jira_add_edit_repo.no_backups_left')*/}
                  {/*}*/}
                </div>
              </div>
              {(!!storeItems.length && !availableProjectsLoading || !!searchTerm || netSearch) && (
                <div className="search-block">
                  <SearchInput
                    className="search-field"
                    type="text"
                    placeholder="Search projects..."
                    value={searchTerm}
                    onChange={onSearch}
                    endAdornment={
                      <InputAdornment position="end" className="search-icon">
                        <img src={searchIcon} loading="lazy"/>
                      </InputAdornment>
                    }
                  />
                </div>
              )}
            </div>

            {(availableProjectsLoading || netSearch && !searchTerm) &&
                <div className="spinner-wrapper">
                    <CircularProgress/>
                </div>
            }
            {!availableProjectsLoading && (!netSearch || netSearch && !!searchTerm) &&
                <div className="scrollable-div" onScroll={handleScroll}>
                  {!!filteredProjects.length && (
                    <div className="form-row head-row">
                      <Checkbox
                        className={'checkbox'}
                        checked={!searchTerm && (storeItems.filter(i=>!i.projectType || i.projectType !== 'bug_tracking').length === checkedProjects.length )} // || (!!checkedProjects.length && checkedProjects.length === availableItemsLeft)
                        // disabled={(availableItemsLeft - checkedProjects.length) <= 0}
                        name="repoName"
                        onChange={(e) => {
                          handleSelectAllChange(e.target.checked);
                        }}
                        icon={<CheckboxInputEmpty/>}
                        checkedIcon={<CheckboxInputChecked/>}
                      />
                      Name
                    </div>
                  )}
                  {!!filteredProjects.length && !availableProjectsLoading ?
                    filteredProjects.map(
                      (project, index) => (
                          <div
                            className={project.projectType && project.projectType === 'bug_tracking' ? 'form-row check-inactive' : 'form-row'} // || (availableItemsLeft - checkedProjects.length) <= 0 && !checkedProjects.includes(project.id)
                            key={`${project.name}-${index}`}>
                            <label key={project.name} className="input-label-checkbox">
                              <Checkbox
                                className={'checkbox'}
                                value={project.id}
                                checked={checkedProjects.includes(project.id)}
                                name="repoName"
                                disabled={!!project.projectType && project.projectType === 'bug_tracking'}
                                onChange={() => {
                                  handleChange(project.id);
                                }}
                                icon={<CheckboxInputEmpty/>}
                                checkedIcon={<CheckboxInputChecked/>}
                              />
                              {project.name.split('::')[0]} {project.projectType && project.projectType === 'bug_tracking'&& ' - (Board type not applicable)'}
                            </label>
                          </div>
                       )
                    ) :
                    <div className="form-row with-overflow">
                      {!!searchTerm?.length ? translate('notifications.jira_boards.no_search') : subPlan?.planQuantity === subPlan?.quantity ?
                        translate('notifications.trello_boards.nothing_to_add_empty') :
                        translate('notifications.trello_boards.nothing_to_add')}
                    </div>
                  }
                  {scroll && (
                    <div className="spinner-wrapper">
                      <CircularProgress/>
                    </div>
                  )}
                </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.cancel')}
            </Button>
            {!!storeItems.length && (
              <Button
                onClick={
                  paymentType === EPaymentRequiredType.NON
                    ? handleSubmit(onSubmit)
                    : openTrialModal
                }
                variant="contained"
                color="primary"
                disabled={!checkedProjects.length || addingProjectsProcessing}
              >
                {addingProjectsProcessing &&
                    <div className="small-spinner-wrapper">
                      <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                    </div>
                }
                {addingProjectsProcessing
                  ? translate('notifications.choices.processing')
                  : checkedProjects.length > 1 ?
                    translate('forms.jira_add_edit_repo.add_board_num', {num: checkedProjects.length}) :
                    translate('forms.jira_add_edit_repo.add_board')}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <PaymentRequiredPoppup
        isOpen={paymentPopup.isOpen}
        closeNotification={closeTrialPopup}
        type={paymentPopup.type}
        platformName={paymentPopup.platformName}
      />
    </>
  );
};

export default memo(JiraBoardAdd);
