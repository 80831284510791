import { Dialog, DialogContent } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import {closeModalIcon, errorBigIcon, toastSuccess} from '../../../../assets/icons/icons';
import Notification from '../../../../components/Dialogs/Notification/Notification';
import { Button } from '../../../../components/StyledComponents';
import { ENotificationTypes } from '../../../../models/consts';
import { IError, IImageCropperProps, INotificationProps } from '../../../../models/inner-models';
import { updateUserAvatar } from '../../../../store/actions';
import { useAppDispatch } from '../../../../store/hooks';
import { decoratePopUpMessage } from '../../../../utils/popUpTextDecorator';
import './style.scss';

const ImageCropper: FunctionComponent<IImageCropperProps> = ({isOpen, image, mimeType, close}: IImageCropperProps) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [isSending, setSendingStatus] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotificationProps>({
    isOpen: false,
    type: ENotificationTypes.NONE,
    message: ''
  });
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const handleClose = useCallback(() => {
    close && close();
  }, []);

  const saveImage = useCallback(() => {
    setSendingStatus(true);
    const cropper = (cropperRef?.current as ReactCropperElement)?.cropper;
    const croppedImg = cropper.getCroppedCanvas().toDataURL(mimeType);

    dispatch(updateUserAvatar(croppedImg))
    .unwrap()
    .then(() => {

      Swal.fire({
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
        title: translate('notifications.titles.success'),
        text: decoratePopUpMessage('Your changes were successfully saved'),
      });

      close && close(croppedImg);
    })
    .catch((err) => {
      const error = err as IError

      console.log(error);
      Swal.fire({
        imageUrl: errorBigIcon,
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
      });
    })
    .finally(() => {
      setSendingStatus(false);
    })

  }, [image, mimeType]);

  const closeNotification = useCallback(() => {
    setNotification({...notification, isOpen: false})
  }, []);

  return (
  <>
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: 'image-cropper-modal'
      }}
    >
      <div className="close-btn" onClick={handleClose}>
        <img src={closeModalIcon} loading="lazy"/>
      </div>
      <DialogContent>
        <div className="cropper-dialog-description">
          <Cropper
            src={image}
            aspectRatio={1}
            viewMode={1}
            zoomable={false}
            initialAspectRatio={1}
            ref={cropperRef}
          />
        </div>
        <div className="action-buttons">
          <Button onClick={saveImage} variant='contained' color='primary' disabled={isSending}>
            Save
          </Button>
          <Button onClick={handleClose} variant='outlined' color='primary'>
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
    <Notification
      isOpen={notification.isOpen}
      type={notification.type}
      title={notification.title}
      message={notification.message}
      closeNotification={closeNotification}
    >
    </Notification>
  </>
  )
}

export default ImageCropper;
