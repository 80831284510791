import { Theme, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

export const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#282A3F',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #E5E7EB',
    borderRadius: 8,
    boxShadow: '0px -6px 24px rgba(0, 0, 0, 0.04), 0px 20px 34px rgba(31, 33, 63, 0.1)',
    padding: 10,
    pointerEvents: 'none'
  },
}))(Tooltip);
