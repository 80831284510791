export const decoratePopUpMessage = (text: string) => {
  if (!text) {
    return ''
  }

  let decoratedText = text;

  if (text[text.length - 1] === '.') {
    decoratedText = decoratedText.substr(0, decoratedText.length - 1);
  }

  if (decoratedText === 'User email verification check failed') {
    decoratedText = 'If this email has an account, you will now be sent a recovery link to your inbox'
  }

  if (decoratedText === 'SignUp verification failed') {
    decoratedText = 'This verification link has already been used'
  }

  // if (decoratedText.indexOf('username') !== -1) {
  //   decoratedText = decoratedText.replace('username', 'email')
  // }

  return decoratedText;
};
