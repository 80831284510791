import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import React, {FunctionComponent, memo, ReactChild} from 'react';
import {closeModalIcon} from '../../../assets/icons/icons';
import './style.scss';

interface IModalProps {
  child: ReactChild;
  title?: string;
  isOpen: boolean;
  closeNotification?: (approved?: boolean) => void;
  className?: string;
}

const Modal: FunctionComponent<IModalProps> = ({
                                                          isOpen,
                                                          closeNotification,
                                                          title,
                                                          child,
                                                          className
                                                        }: IModalProps) => {

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => closeNotification && closeNotification()}
        aria-labelledby="univ-modal-title"
        aria-describedby="univ-modal-description"
        classes={{
          root: className ? className : '',
          paper: 'univ-modal-container',
        }}
      >
        {title && (
          <DialogTitle id="univ-modal-title">
            {title}
          </DialogTitle>
        )}
        {closeNotification && (
          <div className="close-btn" onClick={() => closeNotification && closeNotification()}>
            <img src={closeModalIcon} loading="lazy"/>
          </div>
        )}
        <DialogContent>
          {child}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(Modal);
