import React from 'react';
import { useLocation } from 'react-router-dom';
import reduceChildRoutes from './reduceChildRoutes';
import { SidebarNavListProps } from './types';
import {PlatformsState} from "../../store/reducers/platform";
import {useAppSelector} from "../../store/hooks";
import {getRawSubscriptions, isHasProPlan} from "../../store/selectors";

const SidebarNavList: React.FC<SidebarNavListProps> = ({ pages, depth, bigMenu = true, onClose }:SidebarNavListProps) => {
  const router = useLocation();
  const currentRoute = router.pathname;
  const platform: PlatformsState | null = useAppSelector(getRawSubscriptions);
  const hasProPlan = useAppSelector(isHasProPlan);

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth, bigMenu, onClose, platform , hasProPlan}),
    [] as JSX.Element[]
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
