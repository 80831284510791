import { InputBase, Theme, OutlinedInput  } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const BackupLabsInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: '#F5F6FC',
      border: 'none',
      fontSize: 14,
      fontWeight: 600,
      padding: '10px 50px 10px 16px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
      }
    },
    error: {
      '& > input, & > textarea': {
        border: `1px solid ${theme.palette.error.main}`,
        borderRadius: 10,
        '&:focus': {
          border: `1px solid ${theme.palette.error.main}`,
        }
      }
    }
  }),
)(InputBase);

const AuthInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: '#7994F9',
      color: '#FFF',
      border: 'none',
      fontSize: 14,
      fontWeight: 500,
      padding: '10px 50px 10px 16px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        border: `1px solid ${theme.palette.primary.contrastText}`,
      }
    }
  }),
)(InputBase);

const SearchInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      position: 'relative',
      border: 'none',
      fontSize: 14,
      fontWeight: 600,
      padding: '0 16px',
      minWidth: '220px',
      height: '38px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    }
  }),
)(OutlinedInput );

export {BackupLabsInput, AuthInput, SearchInput};
