import React from 'react';
import { SidebarDrawer as Drawer } from '../StyledComponents';
import SidebarNav from './SidebarNav';
import { SidebarProps } from './types';

const Sidebar: React.FC<SidebarProps> = ({
  bigMenu,
  items,
  onClose,
  ...rest
}) => {
  return (
    <Drawer variant="permanent" {...rest} onClose={onClose}>
      <SidebarNav bigMenu={bigMenu} items={items} onClose={onClose}/>
    </Drawer>
  );
};

export default (Sidebar);
