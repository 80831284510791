import { EStripeSubStatus } from '../../../models/consts';
import { IGithubUser, ITrialDataAPI } from '../../../models/models';
import { EGithubPlanNames } from '../../../models/paymentPlans/github-payments-plan';
import { ENotionPlanNames, notionPlans } from '../../../models/paymentPlans/notion-payments-plan';
import { trialDaysFinishDate } from '../../../utils/trialDaysLeft';
import {notionUserAdapterFunction, notionWorkspaceAdapterFunc} from '../../functions';

export const fetchNotionUserAction = (state, { payload }) => {
  if (!payload) {
    state.notion.usersLoading = false;
    state.notion.users = [];
    return;
  }
  state.notion.usersLoading = false;
  state.notion.users = notionUserAdapterFunction(payload);
}

export const addNotionUserAction = (state, { payload }) => {
  if (payload) {
    state.notion.users = notionUserAdapterFunction([payload] as Array<IGithubUser>);
  }
}

export const fetchWorkspaceForNotionUserAction = (state, { payload }) => {
  state.notion.itemsLoading = false;
  state.notion.items = notionWorkspaceAdapterFunc(payload.workspace ? payload.workspace : []);
  state.notion.canUserBackup = payload.canUserBackup
  state.notion.totalItems = payload.totalItemsNumber || 0
}

export const checkNotionTrialStatusAction = (state, { payload }) => {
  if (payload) {
    state.notion.isConnectionActive = !!payload.isConnectionActive

    const trialData = payload as ITrialDataAPI;
    state.notion.trial = {
      trialStatus: trialData.trialStatus,
      finishDate: trialDaysFinishDate(trialData.trialHoursLeft),
      trialHoursLeft: trialData.trialHoursLeft,
      availableItemsQuantity: trialData.availableItemsQuantity,
      addedItemsQuantity: trialData.addedItemsQuantity,
      planQuantity: trialData.trialDefaultQuantity,
      isInvoiceRequested: trialData.stripe_notion_subscription_bank_transfer_requested || false,
      isCancel: false
    };

    const {s3_region, cloud_storages, stripe_notion_next_invoice, subscription_type, subscription_period, stripe_notion_subscription_cancel_at_period_end, stripe_notion_workspaces_quantity, stripe_notion_subscription_status, availableItemsQuantity, addedItemsQuantity, stripe_notion_subscription_bank_transfer_requested, stripe_notion_subscription_current_period_end } = trialData;

    state.notion.storage = cloud_storages || []
    if (s3_region) {
      state.notion.regionS3 = s3_region
    }

    if (stripe_notion_workspaces_quantity && stripe_notion_subscription_status) {
      const planData = notionPlans.find(({ name }) => name === ENotionPlanNames.ESSENTIAL);
      const isAnnual = subscription_period && subscription_period === 'year' || planData?.stripeIds.yearly === trialData.stripe_notion_price_id;

      const cost = stripe_notion_next_invoice ? stripe_notion_next_invoice / 100 : 0;

      state.notion.subscription = {
        status: stripe_notion_subscription_status.toLowerCase() as EStripeSubStatus,
        planName: subscription_type === 'pro_plan' ? EGithubPlanNames.PRO : EGithubPlanNames.ESSENTIAL,
        isAnnual,
        finishDate: stripe_notion_subscription_current_period_end ? new Date(Number(stripe_notion_subscription_current_period_end + '000')).toLocaleDateString() : null,
        finishDateInMillisecond:Number(stripe_notion_subscription_current_period_end + '000'),
        cost: cost as number,
        quantity: availableItemsQuantity,
        addedItemsQuantity: addedItemsQuantity,
        planQuantity: stripe_notion_workspaces_quantity,
        isInvoiceRequested: stripe_notion_subscription_bank_transfer_requested || false,
        isCancel: stripe_notion_subscription_cancel_at_period_end || false
      };
    } else if (stripe_notion_subscription_status) {
      state.notion.subscription = {
        status: stripe_notion_subscription_status.toLowerCase() as EStripeSubStatus,
        addedItemsQuantity: addedItemsQuantity,
        cost: 0,
      };
    }
  }
}
