export enum EGithubPlanNames {
  ESSENTIAL = 'Essential',
  TRIAL = 'Trial',
  PRO = 'Pro'
}

export const githubPlans = [
  {
    name: EGithubPlanNames.ESSENTIAL,
    stripeIds: {
      product: process.env.REACT_APP_STRIPE_GITHUB_PRODUCT_KEY,
      monthly: process.env.REACT_APP_STRIPE_GITHUB_MONTHLY_ID,
      yearly: process.env.REACT_APP_STRIPE_GITHUB_YEARLY_ID,
    },
    defaultPlan: {
      from: 1,
      to: 10,
      cost: 12,
    },
    monthlyCost: [
      {
        from: 1,
        to: 10,
        cost: 12,
      },
      {
        from: 11,
        to: 50,
        cost: 24,
      },
      {
        from: 51,
        to: 100,
        cost: 50,
      },
      {
        from: 101,
        to: 250,
        cost: 100,
      },
      {
        from: 251,
        to: 500,
        cost: 150,
      },
      {
        from: 501,
        to: 1000,
        cost: 250,
      },
    ],
    annualyCost: [
      {
        from: 1,
        to: 10,
        cost: 115.2,
        monthlyCost: 9.6,
      },
      {
        from: 11,
        to: 50,
        cost: 230.4,
        monthlyCost: 19.2,
      },
      {
        from: 51,
        to: 100,
        cost: 480,
        monthlyCost: 40,
      },
      {
        from: 101,
        to: 250,
        cost: 960,
        monthlyCost: 80,
      },
      {
        from: 251,
        to: 500,
        cost: 1440,
        monthlyCost: 120,
      },
      {
        from: 501,
        to: 1000,
        cost: 2400,
        monthlyCost: 200,
      },
    ],
  },
];
