import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EPlatformsNames, EStripeSubStatus, ETrialStatus } from '../../../models/consts';
import { EGitlabPlanNames } from '../../../models/paymentPlans/gitlab-payments-plan';
import { EJiraPlanNames } from '../../../models/paymentPlans/jira-payments-plan';
import { IPlatformSubscriptionRow } from '../../../views/UserPages/Subscription/Subscription';
import { PlatformsState } from '../../reducers/platform';

export const getJiraBoardsSelector = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.items);
export const getJiraTotal = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.totalItems);

export const getJiraCanUserBackup = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.canUserBackup);

export const isJiraBoardsLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.itemsLoading);

export const isJiraUsersLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.usersLoading);

export const getJiraUsersSelector = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.users);
export const isJiraConnect = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.isConnectionActive);

export const jiraTrialStatus = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.trial?.trialStatus);

export const jiraTrialHoursLeft = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => {
  return jira.trial?.trialHoursLeft || null;
});

export const jiraTrialQuantityLeft = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => {
  return jira.trial?.availableItemsQuantity || null;
});

export const jiraTrialPlanQuantity = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => {
  return jira.trial?.planQuantity || 0;
});

export const jiraSubInfo = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { subscription } = jira;
  if (subscription) {
    return {
      id: 3,
      platformName: EPlatformsNames.JIRA,
      plan: subscription?.planName || '',
      price: `$${subscription?.isAnnual 
        ? (subscription.cost * 12).toFixed(2) + ' / yr.' 
        : subscription.cost.toFixed(2) + ' / mo.'}`,
      status: subscription?.status,
      quantity: subscription.quantity,
      planQuantity: subscription.planQuantity,
      addedItemsQuantity: subscription.addedItemsQuantity,
      isCancel: subscription.isCancel,
      isConnect: false
    };
  }
  return null;
});

export const jiraTrialInfo = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { trial } = jira;
  if (trial) {
    return {
      id: 6,
      platformName: EPlatformsNames.JIRA,
      plan: EJiraPlanNames.TRIAL,
      price: `-`,
      status: trial.trialStatus,
      quantity: 0,
      planQuantity: 0,
      addedItemsQuantity: 0,
      isCancel: false,
      isConnect: false
    };
  }
  return null;
});

export const jiraSubscription = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => jira.subscription);

export const jiraTrialData = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => {
  return jira.trial
});

export const jiraActualPlan = createSelector((state: DefaultRootState) => state.platform, ({ jira }: PlatformsState) => {
  const { subscription: jiraSub, trial: jiraTrial, isConnectionActive } = jira;

  const jiraItem: IPlatformSubscriptionRow = {
    id: 5,
    platformName: EPlatformsNames.JIRA,
    plan: '-',
    price: '-',
    quantity: 0,
    planQuantity: 0,
    addedItemsQuantity: 0,
    status: EStripeSubStatus.INACTIVE,
    isCancel: false,
    finishDateInMillisecond: null,
    isConnect: isConnectionActive
  };

  if (jiraTrial && jiraTrial.trialStatus === ETrialStatus.NOT_STARTED) {
    jiraItem.plan = EGitlabPlanNames.TRIAL;
    jiraItem.status = jiraTrial.trialStatus;
    jiraItem.quantity = jiraTrial.availableItemsQuantity || 0;
    jiraItem.addedItemsQuantity = jiraTrial.addedItemsQuantity || 0;
    jiraItem.planQuantity = jiraTrial.planQuantity || 0;
    jiraItem.finishDate = jiraTrial.finishDate || null;
    jiraItem.isConnect = isConnectionActive
  }

  if (jiraTrial && jiraTrial.trialStatus === ETrialStatus.STARTED) {
    jiraItem.plan = EGitlabPlanNames.TRIAL;
    jiraItem.status = jiraTrial.trialStatus;
    jiraItem.quantity = jiraTrial.availableItemsQuantity || 0;
    jiraItem.addedItemsQuantity = jiraTrial.addedItemsQuantity || 0;
    jiraItem.planQuantity = jiraTrial.planQuantity || 0;
    jiraItem.finishDate = jiraTrial.finishDate || null;
    jiraItem.isConnect = isConnectionActive
  }

  if (jiraSub && (!jiraTrial || jiraTrial?.trialStatus !== ETrialStatus.STARTED || jiraSub?.status === EStripeSubStatus.ACTIVE)) {
    jiraItem.plan = jiraSub.planName || '';
    jiraItem.status = jiraSub.status;
    jiraItem.quantity = jiraSub.quantity || 0;
    jiraItem.addedItemsQuantity = jiraSub.addedItemsQuantity || 0;
    jiraItem.planQuantity = jiraSub.planQuantity || 0;
    jiraItem.finishDate = jiraSub.finishDate || null;
    jiraItem.price = `$${jiraSub.cost.toFixed(2)} / ${jiraSub.isAnnual ? 'yr.' : 'mo.'}`
    jiraItem.isCancel = jiraSub.isCancel;
    jiraItem.finishDateInMillisecond = jiraSub.finishDateInMillisecond;
    jiraItem.isConnect = isConnectionActive
  }

  return jiraItem
});
