import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getUserActions} from '../../../../store/actions';
import {useAppDispatch} from '../../../../store/hooks';
import '../style.scss';
import {DataGrid, GridCellParams} from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import {Chip as MuiChip, CircularProgress, Pagination, Stack, Typography} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import styled from 'styled-components';
import Platform from "../../../../models/platforms-data";
import {IError} from "../../../../models/inner-models";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import {errorBigIcon, visibleIcon} from "../../../../assets/icons/icons";
import {updateSubscriptionPlanModal} from "../../../../utils/functions/updateSubscriptionPlanModal";
import {useNavigate} from "react-router-dom";
import {CustomTooltip} from "../../../../styles/components/CustomTooltip";
import {HelpCircle} from "react-feather";

const Chip = styled(MuiChip)<{ color: string }>`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;

  span {
    height: 20px;
  }

  &.success {
    background-color: #D1FAE5;
    color: #065F46;
  }

  &.error {
    background-color: #FEE2E2;
    color: #991B1B;
  }
`;

type ActionRow = {
  id: number;
  action: string;
  idAction: string;
  date: number;
  status: string;
  ip: string;
  name: string
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '14px',
      lineHeight: '20px',
      border: 'none',
      '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-main': {
        maxHeight: 'calc(100vh - 212px)',
        '@media(min-height: 660px)': {
          maxHeight: 'calc(100vh - 230px)'
        }
      },
      padding: 0
    },
  }),
);

const UserActions: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const {t: translate} = useTranslation();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const navigate = useNavigate();
  const [actions, setActions] = useState<ActionRow[]>([]);
  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const classes = useStyles();

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).replaceAll('_', ' ');
  }

  const getDashData = async (newPage?: number) => {
    try {
      setIsLoading(true)
      const resp = await dispatch(getUserActions({page: newPage || 0, perPage: pageSize}));
      let arr: ActionRow[] = [];
      if (!!resp?.payload?.actions?.length) {
        arr = resp?.payload?.actions.map((item, index) => {
          return {
            id: index,
            action: capitalizeFirstLetter(item.platform || item.version ? `${item.type} (${Platform[item.platform || item.version]?.title})` : item.type),
            idAction: item.id || '-',
            date: item.date,
            status: item.status,
            ip: item.ip || 'BackupLABS',
            name: item.name || '-',
            isProPlanAction: item.isProPlanAction,
            platform: item.platform,
            type: item.type,
          }
        })
      }
      setIsLoading(false)
      setTotal(resp?.payload?.totalItemsNumber || 0)
      setActions(arr)
    } catch (err) {
      setIsLoading(false)
      const error = err as IError;
      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.message || error.error as string),
        imageUrl: errorBigIcon,
      });
      console.log(err);
    } finally {
      setTimeout(() => setLoadingData(false), 1000)
    }
  }

  useEffect(() => {
    getDashData()
  }, [])

  const PaginationFooter = () => {
    const listLength = total
    const itemFrom = (pageSize * page) - pageSize + 1
    const itemTo = (pageSize * page) <= listLength ? (pageSize * page) : listLength
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height='64px'
        px='16px'
      >
        <Typography>{`Showing ${itemFrom} to ${itemTo} of ${listLength}`}</Typography>
        {Math.ceil(listLength / pageSize) > 1 && (
          <Pagination
            count={Math.ceil(listLength / pageSize)}
            page={page}
            onChange={(e, v) => {
              setIsLoading(true)
              // setActions([])
              setPage(v)
              getDashData(v - 1)
            }}
          />
        )}
      </Stack>

    )
  };

  return (
    <div className="user-actions-container">
      {!actions?.length && loadingData && (
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div>
      )}

      {!!actions?.length &&
          <DataGrid
              sx={{
                '.MuiDataGrid-overlay': {
                  zIndex: isLoading ? 99 : 0,
                  height: isLoading ? '100%!important' : '3000px',
                  maxHeight: 'calc(100vh - 278px)'
                }
              }}
              loading={isLoading}
              rowCount={total}
              paginationMode="server"

              autoHeight
              headerHeight={48}
              rowHeight={52}
              pageSize={pageSize}
              page={page - 1}
              onPageSizeChange={(e) => setPageSize(e)}
              className={classes.root}
              rows={actions}
              getRowClassName={(params) => !!params.row.platform && !params.row.isProPlanAction ? 'pointer' : ''}
              onRowClick={(params) => {
                if (!!params.row.platform && !params.row.isProPlanAction) {
                  updateSubscriptionPlanModal({
                    translate,
                    platformName: params.row.platform,
                    navigate
                  });
                }
              }}
              columns={[
                {
                  field: 'action',
                  headerName: translate('views.platform_pages.data_grid.action'),
                  minWidth: 200,
                  flex: 1,
                  sortable: false,
                  editable: false,
                  headerClassName: 'backup-table--header',
                },
                {
                  field: 'name',
                  headerName: translate('views.platform_pages.data_grid.name'),
                  minWidth: 100,
                  flex: 1,
                  sortable: false,
                  editable: false,
                  headerClassName: 'backup-table--header',
                  cellClassName: (params) =>
                    !!params.row.platform && !params.row.isProPlanAction ?
                      'backup-table--type-cell visible-icon' : 'backup-table--type-cell'
                  ,
                  renderCell: (params: GridCellParams) => (
                    !!params.row.platform && !params.row.isProPlanAction ? '': params.value
                  ),
                },
                {
                  field: 'status',
                  headerName: translate('views.platform_pages.data_grid.status'),
                  minWidth: 140,
                  flex: 1,
                  sortable: false,
                  editable: false,
                  headerClassName: 'backup-table--header',
                  cellClassName: (params) =>
                    !!params.row.platform && !params.row.isProPlanAction ?
                      'backup-table--type-cell blur' : 'backup-table--type-cell'
                  ,
                  renderCell: (params: GridCellParams) => (
                    <div>
                      <Chip
                          className={params.value === 'success' ? 'success' : 'error'}
                          label={params.value}
                      />
                      {params.row.type === 'restore' && params.value === 'failed' && < CustomTooltip
                          title={`Unable to restore. Check your ${params.row.platform} account to investigate any limitations.`}>
                        <HelpCircle className="info-icon"/>
                      </CustomTooltip>}
                    </div>
                  ),
                },
                {
                  field: 'idAction',
                  headerName: 'Event ID',
                  minWidth: 200,
                  flex: 1,
                  sortable: false,
                  editable: false,
                  headerClassName: 'backup-table--header',
                  cellClassName: (params) =>
                    !!params.row.platform && !params.row.isProPlanAction ?
                      'blur' : ''
                },
                {
                  field: 'date',
                  headerName: translate('views.platform_pages.data_grid.date'),
                  minWidth: 180,
                  flex: 1,
                  sortable: false,
                  editable: false,
                  headerClassName: 'backup-table--header',
                  cellClassName: (params) =>
                    !!params.row.platform && !params.row.isProPlanAction ?
                      'blur' : ''
                  ,
                  valueFormatter: ({value}) => new Date(value as string).toLocaleString(),
                },
                {
                  field: 'ip',
                  headerName: translate('views.platform_pages.data_grid.ip'),
                  minWidth: 140,
                  flex: 1,
                  sortable: false,
                  editable: false,
                  cellClassName: (params) =>
                    !!params.row.platform && !params.row.isProPlanAction ?
                      'blur' : ''
                  ,
                  headerClassName: 'backup-table--header',
                }
              ]}
              disableColumnSelector
              disableSelectionOnClick
              disableColumnMenu
              components={{
                Footer: PaginationFooter
              }}
          />
      }
    </div>
  );
};

export default UserActions;
