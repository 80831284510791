import { useEffect, useState } from 'react';
import { EWindowMaxWidth } from '../models/consts';
import { IWindowSize } from '../models/inner-models';

function getWindowDimensions(): { isDesktop: boolean; isTablet: boolean; isSmallScreenPortrait: boolean; isMobileOnly: boolean; isSmallScreen: boolean; width: number; isMobile: boolean; height: number } {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    isMobileOnly: width <= EWindowMaxWidth.MOBILE,
    isMobile: width < EWindowMaxWidth.TABLET,
    isTablet: width > EWindowMaxWidth.MOBILE && width < EWindowMaxWidth.TABLET,
    isDesktop: width >= EWindowMaxWidth.TABLET,
    isSmallScreenPortrait: width < 900,
    isSmallScreen: width < 1181
  };
}

export default function useWindowDimensions(): IWindowSize {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
