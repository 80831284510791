import {IError} from "../models/inner-models";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "./popUpTextDecorator";
import {errorBigIcon} from "../assets/icons/icons";

const fetchItemsErrorHandler = (error: IError) => {
  console.log(error)

  Swal.fire({
    title: 'Error',
    text: decoratePopUpMessage(error.error as string) + '. Please refresh the page or contact us.',
    imageUrl: errorBigIcon,
    confirmButtonText: 'Refresh',
    cancelButtonText: 'Cancel',
    denyButtonText: 'Contact us',
    showDenyButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
  })
    .then((res) => {
      if (res.isConfirmed) {
        window.location.reload()
      } else if (res.isDenied) {
        window.open('/contact', '_self');
      }
    });
}

export default fetchItemsErrorHandler
