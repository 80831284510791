import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LOCALE_ENGLISH } from '../constants';
import en from './locales/en';

const i18nextConfig = i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
    },
    lng: LOCALE_ENGLISH,
    fallbackLng: LOCALE_ENGLISH,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18nextConfig;
