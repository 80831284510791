import {Checkbox, FormControlLabel, InputAdornment} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, { FunctionComponent, useEffect, useState } from 'react';
import {Controller, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  CheckboxInputChecked,
  CheckboxInputEmpty,
  errorBigIcon,
  gLogo,
  iconNotifySuccess
} from '../../../assets/icons/icons';
import ValidationErrorBlock from '../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import IsVisibleButton from '../../../components/Buttons/IsVisibleButton/IsVisibleButton';
import { AuthWrapper as Wrapper, Button, FormControl, TextField } from '../../../components/StyledComponents';
import {
  EMAIL_REGEX,
  MAX_INPUT_VALUE_LENGTH,
  MAX_INPUT_VALUE_EMAIL_LENGTH,
  MIN_INPUT_VALUE_LENGTH,
  PASSWORD_REGEX, EActivePlatformsNames
} from '../../../models/consts';
import {IMarketingData, IPasswordsVisiblity, ISignUp} from '../../../models/inner-models';
import { ISignUpApi } from '../../../models/models';
import {postDataToMarketing, registerCognitoUser} from '../../../store/actions';
import { useAppDispatch } from '../../../store/hooks';
import { decoratePopUpMessage } from '../../../utils/popUpTextDecorator';
import { Helmet } from 'react-helmet';
import '../style.scss';
import logo from "../../../assets/icons/backuplabs-logo.png";
import trello from "../../../assets/icons/trello-logo-white.svg";
import github from "../../../assets/icons/github-logo-white.svg";
import gitlab from "../../../assets/icons/gitkab-full.svg";
import jira from "../../../assets/icons/Jira_white.svg";
import notion from "../../../assets/icons/notion-logo.svg";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import {iniFrame} from "../../../serviceWorker";
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
const env = process.env.STAGE_TYPE;
const ALLOWED_ACTIVE_CAMPAIGN_ENVS = ['prod']

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      margin: 0,
      boxShadow: 'none',
      width: '100%'
    },
    wrapperBottom: {
      margin: 0,
      padding: '10px 0 0',
      boxShadow: 'none',
      textAlign: 'center',
      '& .agreement': {
        textAlign: 'center'
      }
    },
    inputBlock: {
      width: '100%',
      marginTop: '4px',
    },
    textField: {
      width: '100%',
      height: '42px',
      '& input': {
        height: '42px',
        boxSizing: 'border-box'
      }
    },
    passField: {
      width: '100%',
      height: '42px',
      '& input': {
        height: '42px',
        boxSizing: 'border-box'
      },
      '& > div': {
        paddingRight: '32px'
      }
    },
    fullWidthButton: {
      width: '100%',
      marginTop: '5px',
      padding: '15px 25px',
      fontSize: '14px',
      fontWeight: '600',
    },
  }),
);

const SignUp: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { register, formState: { errors }, handleSubmit, control } = useForm<ISignUp>({
    mode: 'onChange',
  });
  const [notValidError, setNotValidError] = useState<boolean>(true);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isGoogleAuth, setIsGoogleAuth] = useState<boolean>(false);
  const mainPass = useWatch({ control, name: 'password' });

  const [visible, setPasswordVisibility] = useState<IPasswordsVisiblity>({
    password: false
  });

  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  }
  zxcvbnOptions.setOptions(options)

  const handleClickShowPassword = (field: keyof IPasswordsVisiblity) => () => {
    setPasswordVisibility({ ...visible, [field]: !visible[field] });
  };

  useEffect(() => {
    if (PASSWORD_REGEX.test(mainPass) && !(!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
      (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0))) {
      setNotValidError(false);
    } else {
      setNotValidError(true);
    }
  }, [mainPass]);

  const onSubmit: SubmitHandler<ISignUp> = ({ firstName, lastName, email, password,appsProtect }) => {
    const appsToProtect = EActivePlatformsNames.filter((_, index) => appsProtect[index]);

    if (!notValidError) {
      setIsSubmit(true)
      const signUpFormData: ISignUpApi = {
        email,
        password,
        name: firstName,
        family_name: lastName,
      };
      const marketingData:IMarketingData = {
        email,
        appsToProtect: appsToProtect
      }

      dispatch(registerCognitoUser(signUpFormData))
        .unwrap()
        .then(() => {
          if(env && ALLOWED_ACTIVE_CAMPAIGN_ENVS.includes(env)) {
            dataLayer.push({
              'event': 'sign_up',
              'signUpProcess': 'success',
              'appsToProtect': appsToProtect.join('/')
            })
          }
          return Swal.fire({
            title: translate('notifications.titles.success'),
            text: iniFrame() ? 'Registration successful. Please login to BackupLABS to start protecting your data.' : 'Please check your email now for the confirmation link.',
            imageUrl: iconNotifySuccess,
            confirmButtonText: translate('notifications.choices.continue'),
            customClass: {
              image: 'success-sign-up-icon'
            },
            didOpen: (toast) => {
              if(env && ALLOWED_ACTIVE_CAMPAIGN_ENVS.includes(env)) {
                const children = toast.children
                let notes: Element | null;
                for (const child of children) {
                  if (child.className === 'swal2-actions') {
                    notes = child
                    break;
                  }
                }
                if (notes) {
                  for (const child of notes.children) {
                    if (child.className.includes('swal2-confirm')) {
                      child.id = 'signup_confirm_modal_btn'
                      break;
                    }
                  }
                }
              }
            }
          });
        })
        .then(() => {
          setIsSubmit(false)

          if(env && ALLOWED_ACTIVE_CAMPAIGN_ENVS.includes(env)){
            dispatch(postDataToMarketing(marketingData))
          }

          navigate('/auth/login');
        })
        .catch((err) => {
          setIsSubmit(false)
          console.log(err);
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(err.error),
            imageUrl: errorBigIcon,
            confirmButtonText: translate('notifications.choices.close'),
          });
        });
    }
  };
  const googleAuth = async()=> {
    if (!isGoogleAuth) {
      setIsGoogleAuth(true)
      Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
    }
  }

  return (
    <>
      <Helmet>
        <title>BackupLABS Account Creation Page</title>
        <meta name="title" content="BackupLABS Account Creation Page" />
        <meta name="description" content="Create a free account on the BackupLABS platform to start protecting your critical SaaS app data." />
      </Helmet>
      <div className='auth-signup'>
        <div className='auth-signup-left'>
          <div className="brand-block" onClick={() => {
            navigate('/');
          }}>
            <img className="logo" src={logo} loading="lazy"/>
          </div>
          <Wrapper className={classes.wrapper}>
            <div className="auth-title">
              {translate('forms.sign_up.title')}
            </div>
            <div className="links-block">
              {translate('forms.sign_up.subTitle')}
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <FormControl className="form-row" fullWidth>
                  <div className="label-block">{translate('forms.common.first_name')}</div>
                  <div className={classes.inputBlock}>
                    <TextField
                      className={classes.textField}
                      required
                      placeholder={translate('forms.common.first_name')}
                      id="first-name"
                      {...register('firstName', {
                        required: translate('forms.common.required') as string,
                        minLength: {
                          value: MIN_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.min_length'),
                        },
                        maxLength: {
                          value: MAX_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.max_length50'),
                        },
                      })}
                      error={!!errors.firstName}
                    />
                    {errors.firstName &&
                      <span className={'error'}>
                    {errors.firstName.message}
                  </span>
                    }
                  </div>
                </FormControl>
                <FormControl className="form-row" fullWidth>
                  <div className="label-block">{translate('forms.common.last_name')}</div>
                  <div className={classes.inputBlock}>
                    <TextField
                      className={classes.textField}
                      required
                      placeholder={translate('forms.common.last_name')}
                      id="last-name"
                      {...register('lastName', {
                        required: translate('forms.common.required') as string,
                        minLength: {
                          value: MIN_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.min_length'),
                        },
                        maxLength: {
                          value: MAX_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.max_length50'),
                        },
                      })}
                      error={!!errors.lastName}
                    />
                    {errors.lastName &&
                      <span className={'error'}>
                    {errors.lastName.message}
                  </span>
                    }
                  </div>
                </FormControl>
                <FormControl className="form-row" fullWidth>
                  <div className="label-block">{translate('forms.common.emailA')}</div>
                  <div className={classes.inputBlock}>
                    <TextField
                      className={classes.textField}
                      required
                      placeholder={translate('forms.common.emailA')}
                      id="email"
                      type="email"
                      inputProps={{ maxLength: MAX_INPUT_VALUE_EMAIL_LENGTH }}
                      {...register('email', {
                        required: translate('forms.common.required') as string,
                        pattern: {
                          value: EMAIL_REGEX,
                          message: translate('forms.common.invalid_email'),
                        },
                      })}
                      error={!!errors.email}
                    />
                    {errors.email &&
                        <span className={'error'}>
                          {errors.email.message}
                        </span>
                    }
                  </div>
                </FormControl>
              <FormControl className="form-row" fullWidth>
                <div className="label-block">{translate('forms.common.apps_to_protect')}</div>
                <div className="apps-container">
                {EActivePlatformsNames.map((platform,index)=>{
                  return(
                    <FormControlLabel
                      key={platform}
                      control={
                        <Controller
                          name={`appsProtect[${index}]`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              color="primary"
                              icon={<CheckboxInputEmpty />}
                              checkedIcon={<CheckboxInputChecked />}
                              {...field}
                            />
                          )}
                        />
                      }
                      label={platform}
                    />
                  )
                })}
                </div>
                {errors.appsProtect &&
                    <span className={'error'}>
                          {errors.appsProtect.message }
                        </span>
                }
              </FormControl>

              <FormControl className="form-row" fullWidth>
                  <div className="label-block">{translate('forms.common.password')}</div>
                  <div className={classes.inputBlock}>
                    <TextField
                      className={classes.passField}
                      required
                      placeholder={translate('forms.common.password')}
                      id="password"
                      type={visible.password ? 'text' : 'password'}
                      {...register('password', {
                        required: translate('forms.common.required') as string,
                        // pattern: {
                        //   value: PASSWORD_REGEX,
                        //   message: translate('forms.common.invalid_password'),
                        // },
                        maxLength: {
                          value: MAX_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.max_length50'),
                        },
                      })}
                      error={!!errors.password}
                      inputProps={{ maxLength: MAX_INPUT_VALUE_LENGTH }}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IsVisibleButton
                              isPrimary
                              onClick={handleClickShowPassword('password')}
                              isNowVisible={visible.password}
                            />
                          </InputAdornment>,
                      }}
                    />
                    {errors.password &&
                    <ValidationErrorBlock errorMessage={errors.password.message as string} />
                    }
                    {(!!mainPass?.length) && (
                      <ul className={mainPass?.length === 0 ? 'auth-password-help validation' : 'auth-password-help'}>
                        <li className={mainPass?.length > 9 ? 'success' : 'error'}>
                          10 characters minimum
                        </li>
                        <li className={
                          !!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                          (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0) ? 'error' : 'success'
                        }>
                          No dictionary words, repetitions and commonly used passwords
                        </li>
                        <li className={!/\s/.test(mainPass) ? 'success' : 'error'}>
                          No spaces
                        </li>
                        <li className={/^(.*[0-9].*)$/.test(mainPass) ? 'success' : 'error'}>
                          One number
                        </li>
                        <li className={/^(?=.*[a-z])(?=.*[A-Z]).*$/.test(mainPass) ? 'success' : 'error'}>
                          At least one uppercase and one lowercase
                        </li>
                        <li className={/^(?=.*[@$!%*^"])[a-zA-Z0-9@$!%*^"]+$/.test(mainPass) ? 'success' : 'error'}>
                          One special character. Use only: $ ! % * ^ &quot; @
                        </li>
                        {(!PASSWORD_REGEX.test(mainPass) || (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                          (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0))) && (
                          <li className={'error'}>
                            {translate('forms.common.invalid_password')}
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                </FormControl>
              <div className="action-row">
                <Button className="primary-button w-100" type="submit" disabled={isSubmit || notValidError || isGoogleAuth}>
                  {translate('common.default_pages.get_started')}
                </Button>

                <div className='btn-split'>or</div>

                <div className={`google-btn ${isGoogleAuth && 'disabled'}`}
                     onClick={googleAuth}
                >
                  <div className="google-icon-wrapper">
                    <img className="google-icon-svg"
                         src={gLogo} loading="lazy"/>
                  </div>
                  <p className="btn-text"><b>Sign up with Google</b></p>
                </div>

                <div className="links-block center mt">
                  {translate('forms.sign_up.already_have_acc')}
                  <Link to="/auth/login" className="link ml-4">
                    {translate('common.default_pages.sign_in')}
                  </Link>
                </div>
              </div>

            </form>
          </Wrapper>
          <Wrapper className={classes.wrapperBottom}>
            <div className="agreement">
              {translate('forms.sign_up.agree_with_privacy')} <br/>
              <a href="https://www.backuplabs.io/terms-of-use" className="link" target="_blank" rel="noreferrer">
                {translate('common.default_pages.terms_of_use')}
              </a>,
              <a href="https://www.backuplabs.io/static-files/sla.pdf" className="link" target="_blank" rel="noreferrer">
                {translate('common.default_pages.sla')}
              </a>{', & '}
              <a href="https://www.backuplabs.io/privacy" className="link" target="_blank" rel="noreferrer">
                {translate('common.default_pages.privacy_policy')}
              </a>
            </div>
          </Wrapper>
        </div>
        <div className='auth-signup-right'>
          <div className='signup-info'>
            <div className='signup-info-title'>
              {translate('forms.sign_up.rightSide.title')}
            </div>
            <div className='signup-info-description'>
              {translate('forms.sign_up.rightSide.description')}
            </div>
            <div className='signup-info-sub'>
              {translate('forms.sign_up.rightSide.subTitle')}
            </div>
            <div className='signup-info-platform'>
              <div className='signup-platform-logo'>
                <img src={trello} loading="lazy"/>
              </div>
              <div className='signup-platform-logo'>
                <img src={github} loading="lazy"/>
              </div>
              <div className='signup-platform-logo'>
                <img src={gitlab} loading="lazy"/>
              </div>
              <div className='signup-platform-logo'>
                <img src={jira} loading="lazy"/>
              </div>
              <div className='signup-platform-logo'>
                <img src={notion} loading="lazy"/>
              </div>
            </div>
            <div className='signup-info-coming'>
              {translate('forms.sign_up.rightSide.comingSoon')}
            </div>
            <div className='signup-info-platform'>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default SignUp;
