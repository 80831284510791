import { Box, CircularProgress } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { loadingUser } from '../../../store/selectors';
import { Divider, Typography } from '../../StyledComponents';
import '../style.scss';


interface IPlatformUserPageBlockProps {
  isLoadingPlatformUsers: boolean;
  platformName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ChildComponent: FunctionComponent<any>
}

const PlatformUserPageBlock: FunctionComponent<IPlatformUserPageBlockProps> = ({
  isLoadingPlatformUsers,
  platformName,
  ChildComponent
}: IPlatformUserPageBlockProps) => {
  const userLoading = useAppSelector(loadingUser);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  return (
    <div id="platform-content">
      <div className="header-block">
        <ArrowLeft className="arrow-icon" onClick={() => navigate('/platform/dashboard/' + platformName)} />
        <Typography variant="h3" gutterBottom display="inline">
          {translate('forms.common.user_config')}
        </Typography>
      </div>
      <Divider my={6} className="divider" />

      <Box sx={{ width: '100%' }}>
        {isLoadingPlatformUsers || userLoading ?
          <div className="spinner-wrapper">
            <CircularProgress />
          </div>
          : <ChildComponent />
        }
      </Box>
    </div>
  );
};

export default (PlatformUserPageBlock);
