import React from 'react';
import { IClassName } from '../../../constants';

const DatabaseIcoInvert: React.FC<IClassName> = ({ className }) => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M0 10.5V13.5C0 15.1569 3.13401 16.5 7 16.5C10.866 16.5 14 15.1569 14 13.5V10.5C14 12.1569 10.866 13.5 7 13.5C3.13401 13.5 0 12.1569 0 10.5Z" fill="#6B7280"/>
      <path d="M0 5.5V8.5C0 10.1569 3.13401 11.5 7 11.5C10.866 11.5 14 10.1569 14 8.5V5.5C14 7.15685 10.866 8.5 7 8.5C3.13401 8.5 0 7.15685 0 5.5Z" fill="#6B7280"/>
      <path d="M14 3.5C14 5.15685 10.866 6.5 7 6.5C3.13401 6.5 0 5.15685 0 3.5C0 1.84315 3.13401 0.5 7 0.5C10.866 0.5 14 1.84315 14 3.5Z" fill="#6B7280"/>
    </svg>
  );
};

export default DatabaseIcoInvert;
