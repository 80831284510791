import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CircularProgress,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStripe} from '@stripe/react-stripe-js';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {getRawSubscriptions, getUserSelector, loadingUser} from '../../../store/selectors';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import './style.scss';
import styled from 'styled-components';
import {AlertCircle, AlertTriangle, Check, ChevronDown, ChevronUp, MinusCircle, PlusCircle, X} from "react-feather";
import Platform from "../../../models/platforms-data";
import {Button} from "../../../components/StyledComponents";
import {PlatformsState} from "../../../store/reducers/platform";
import {useNavigate} from "react-router-dom";
import {IUser} from "../../../models/models";
import {
  getStripeDefaultPaymentMethod,
  getStripePrices,
  getTaxInfo,
  getUser, removeUsersPaymentTax,
  setBillingAddressAndSubscribe
} from "../../../store/actions";
import BillingForm from "../../../components/BillingForm/BillingForm";
import CardForm from "./CardForm/CardForm";
import {
  IBillingAddressPayload,
  IBillingAddressSubscribePayload,
  ICouponDiscount,
  IError,
  IPayment
} from "../../../models/inner-models";
import {CustomTooltip} from "../../../styles/components/CustomTooltip";
import Swal from "sweetalert2";
import {errorBigIcon, iconNotifySuccess} from "../../../assets/icons/icons";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import RadioButton from "../../../components/Buttons/RadioButton";
import Collapse from '@mui/material/Collapse';

const Card = styled(MuiCard)`
  width: 100%;
  box-shadow: none;

  &.main-checkout-block {
    padding-bottom: 80px;
  }

  & .action-buttons {
    position: absolute;
    bottom: 24px;
    right: 24px;
    width: auto !important;
    padding-top: 0 !important;
  }
`;

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding: 24px 24px 0;
    box-sizing: border-box;
  }
`;

type StepType = {
  step: number;
  stepCur?: number;
  title: string;
  titleDub?: string;
  desc: string;
}

type ProdType = {
  plan: number;
  platformName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  planObj: Record<string, any>;
  changePlan: (number) => void;
  onDelete: () => void;
  activeSub?: boolean;
  overload?: boolean;
}

const stepsList: StepType[] = [
  {
    step: 1,
    title: 'Backup capacity',
    titleDub: 'Choose backup capacity',
    desc: 'Build the backup plan that’s right for you and your apps.'
  },
  {
    step: 2,
    title: 'Company details',
    titleDub: 'Company details',
    desc: 'We’ll need some information to get you going.'
  },
  {
    step: 3,
    title: 'Payment details',
    titleDub: 'Payment details',
    desc: 'Make sure everything is right, and start backing up.'
  }
];

const Steps = ({step, stepCur, title, desc}: StepType) => {
  return (
    <div
      className={'checkout-step' + (step === stepCur ? ' active-step' : '') + (stepCur && step < stepCur ? ' done-step' : '')}>
      <div className={'checkout-step-icon' + (stepCur && step < stepCur ? ' done-step' : '')}>
        {stepCur && step >= stepCur ?
          '0' + step :
          <Check/>
        }
      </div>
      <div className='checkout-step-text'>
        <div className='checkout-step-title'>
          {title}
        </div>
        <div className='checkout-step-desc'>
          {desc}
        </div>
      </div>
    </div>
  )
}

const Prod = ({platformName, planObj, plan, onDelete, changePlan, activeSub, overload}: ProdType) => {
  let prevPlan = +plan;
  let nextPlan = +plan;

  if (planObj.unitCost || platformName === 'jira') {
    const maxCount = platformName === 'jira' ? 2001 : 201
    if (platformName === 'notion' || platformName === 'jira') {
      prevPlan = (plan === 1 ? 0 : plan - 1)
    } else {
      prevPlan = plan === 5 ? 0 : plan - 1;
    }
    nextPlan = plan === maxCount ? 2500 : plan + 1;
  } else {
    const curIndex = plan !== 1001 ? planObj.planCost.findIndex(item => item.to === plan) : 6
    prevPlan = curIndex === 0 ? 0 : planObj.planCost[curIndex - 1].to;
    nextPlan = curIndex === 6 ? 2500 : curIndex === 5 ? 1001 : planObj.planCost[curIndex + 1].to;
  }

  return (
    <div className='checkout-item-list-row'>
      <div className='checkout-item-list-cell logo-cell'>
        <img src={Platform[platformName]?.smallLogo} className="platform-logo" loading="lazy"/>
        {Platform[platformName]?.title}
      </div>
      <div className='checkout-item-list-cell'>
        <Button
          onClick={() => changePlan(prevPlan)}
          disabled={prevPlan === 0 || plan === 0 || platformName === 'jira'}
        >
          <MinusCircle className='checkout-item-plan-btn'/>
        </Button>
        {/*{(platformName === 'jira' || plan === 2001) && (*/}
          <span>
            {(plan === 201 && platformName !== 'jira') ? '200+' :
              (plan === 1001 && platformName !== 'jira') ? '1000+' :
                (plan === 2001) ? '2000+' :
                  plan
            }
          </span>
        {/*)}*/}
        {/*{(platformName !== 'jira' && plan < 2001) && (*/}
        {/*  <SearchInput*/}
        {/*    className="checkout-input-plan"*/}
        {/*    type="text"*/}
        {/*    value={plan}*/}
        {/*    onChange={(e) => {*/}
        {/*      const val = +e.target.value.replace(/\D/g, '')*/}
        {/*      changePlan(val > 2000 ? 2001 : val)*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <Button
          onClick={() => changePlan(nextPlan)}
          disabled={nextPlan === 2500 || platformName === 'jira'}
        >
          <PlusCircle className='checkout-item-plan-btn'/>
        </Button>

        {activeSub && (
          <CustomTooltip title='You already have this active subscription'>
            <AlertTriangle className='checkout-item-bought'/>
          </CustomTooltip>
        )}
        {overload && platformName !== 'jira' && (
          <CustomTooltip title={`You’re choosing a plan that supports fewer ${platformName === 'jira' ? 'users' : 'backups'} than you currently have`}>
            <AlertCircle className='checkout-item-overload'/>
          </CustomTooltip>
        )}
      </div>
      <X className="feather-icon-in-button icon-ml" onClick={onDelete}/>
    </div>
  )
}

const Checkout: FunctionComponent = () => {
  const allPlatform = ['github', 'trello', 'gitlab', 'jira', 'notion']
  const {t: translate} = useTranslation();
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userLoading = useAppSelector(loadingUser);
  const user: IUser | null = useAppSelector(getUserSelector);
  const [stepCur, setStepCur] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [isYear, setIsYear] = useState<boolean>(true);
  // const [showAdd, setShowAdd] = useState<boolean>(false);
  const [taxLoad, setTaxLoad] = useState<boolean>(false);
  const [contactList, setContactList] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>();
  const [couponCode, setCouponCode] = useState<string>('');
  const [couponDiscountObj, setCouponDiscountObj] = useState<ICouponDiscount>({
    amountOff: 0, currency: null, percentOff: 0, valid: null
  });
  const [couponLoad, setCouponLoad] = useState<boolean>(false);
  const [isSubscriptionUpdating, setIsSubscriptionUpdating] = useState<boolean>(false);

  const platform: PlatformsState | null = useAppSelector(getRawSubscriptions);
  const [activeSub, setActiveSub] = useState<Record<string, string>>({
    github: '',
    trello: '',
    gitlab: '',
    jira: '',
    notion: ''
  });
  const [errorSub, setErrorSub] = useState<Record<string, boolean>>({
    github: false,
    trello: false,
    gitlab: false,
    jira: false,
    notion: false
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stripeSub, setStripeSub] = useState<Record<string, any>>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedPlan, setSelectedPlan] = useState<Record<string, any>>({});
  const [addedApp, setAddedApp] = useState<Array<string>>([]);
  const [billingDet, setBillingDet] = useState<IBillingAddressPayload | null>();
  const [tax, setTax] = useState<number>(0);
  const [planType, setPlanType] = useState<string>('pro_plan');
  const [open, setOpen] = useState<boolean>(false);

  const maxSub = {
    github: 1000,
    trello: 200,
    gitlab: 1000,
    jira: 2000,
    notion: 200
  }

  useEffect(() => {
    if (!userLoading && user && !billingDet && user.company?.address) {
      const billData = {
        company_details: {
          name: user.company.name || '',
          city: user.company.address.city || '',
          country: user.company.address.country || '',
          line1: user.company.address.line1 || '',
          line2: user.company.address.line2 || '',
          state: user.company.address.state || '',
          postal_code: user.company.address.postal_code || ''
        }
      }
      if (!!user.company.tax_details.length) {
        if (user.company.tax_details.length > 1) {
          dispatch(removeUsersPaymentTax(user.company.tax_details[0].id as string))
        }
        const revTax = [...user.company.tax_details].reverse()
        billData['tax_details'] = {
          type: revTax[0].type,
          value: revTax[0].value
        }
      }

      setBillingDet({...billData})
    }
  }, [userLoading, user])

  useEffect(() => {
    if (stepCur === 3) {
      const payload = {
        country: billingDet?.company_details?.country || '',
        // state: billingDet?.company_details?.state || '',
        // postal_code: billingDet?.company_details?.postal_code || ''
      }
      setTaxLoad(true)
      dispatch(getTaxInfo(payload)).unwrap()
        .then(res => {
          setTax(+res.rate)
          setTaxLoad(false)
        })
        .catch(e => {
          console.log(e)
          setTax(0)
          setTaxLoad(false)
        })
    }
  }, [stepCur])

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        await Promise.all([dispatch(getStripePrices()).unwrap(), dispatch(getStripeDefaultPaymentMethod()).unwrap()])
          .then(res => {
            setStripeSub({...res[0]?.result})
          })
      } catch (e) {
        const error = e as IError;
        if (error.error !== 'No default payment method for this account') {
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.message || error.error as string),
            imageUrl: errorBigIcon,
          });
        }
        console.log(e);
      } finally {
        if (localStorage.getItem('checkout-platform') && Object.keys(activeSub).find(item => item === localStorage.getItem('checkout-platform'))) {
          if (localStorage.getItem('checkout-platform') === 'jira') {
            setIsYear(false)
          }
          setAddedApp([localStorage.getItem('checkout-platform') || ''])
          localStorage.removeItem('checkout-platform')
        }
        if (localStorage['checkoutContact']) {
          const resData = JSON.parse(localStorage['checkoutContact'])
          setIsYear(!!resData.isYear)
          setPlanType(resData.planType)
          setSelectedPlan(resData.selectedPlan)
          setContactList(resData.contactList)
          setAddedApp(resData.addedApp)
        }
        setLoadingData(false);
      }
    };

    fetchData();
  }, [])

  useEffect(() => {
    if (addedApp?.length > Object.keys(selectedPlan).length && !!Object.keys(stripeSub).length && platform) {
      const temp = {...selectedPlan}
      addedApp.forEach(item => {
        if (!temp[item]) {
          temp[item] = isYear ? stripeSub[item]?.[planType]?.year?.defaultPlan?.to : stripeSub[item]?.[planType]?.month?.defaultPlan?.to
          if (activeSub[item]) {
            if (!Object.keys(selectedPlan).length && item !== 'jira' && ((activeSub[item].split('-')[0] === 'year' && !isYear) || (activeSub[item].split('-')[0] !== 'year' && isYear))) {
              setIsYear(activeSub[item].split('-')[0] === 'year')
            }
            if (maxSub[item] < +activeSub[item].split('-')[1]) {
              temp[item] = maxSub[item] + 1
              setContactList(item)
            } else {
              temp[item] = +activeSub[item].split('-')[1]
            }
            if (activeSub[item].split('-')[2] === 'Essential') {
              setPlanType('essential')
            }

            setErrorSub(prevState => {
              return {
                ...prevState,
                [item]: true
              }
            })
          }
        }
      })
      setSelectedPlan(temp)
    }
  }, [addedApp, stripeSub, isYear, platform, activeSub, planType])

  useEffect(() => {
    if (!!Object.keys(selectedPlan).length && !!Object.keys(stripeSub).length) {
      let price = 0;
      Object.keys(selectedPlan).map(item => {
        const itemPlan = +selectedPlan[item];
        if (stripeSub[item]?.[planType]?.month.unitCost) {
          price = (itemPlan * stripeSub[item]?.[planType]?.[isYear ? 'year' : 'month'].unitCost)
        } else {
          if (item === 'jira') {
            price = itemPlan * stripeSub[item]?.[planType]?.[isYear ? 'year' : 'month'].planCost.find(item => item.to >= itemPlan && item.from <= itemPlan)?.cost || 0
          } else {
            price = stripeSub[item]?.[planType]?.[isYear ? 'year' : 'month'].planCost.find(item => item.to === itemPlan)?.cost || 0
          }
        }

        setErrorSub(prevState => {
          return {
            ...prevState,
            [item]: !!activeSub[item]?.length && activeSub[item] === (isYear ? `year-${selectedPlan[item]}` : `month-${selectedPlan[item]}`) && ((platform[item]?.subscription?.planName === 'Pro' && planType === 'pro_plan') || (platform[item]?.subscription?.planName === 'Essential' && planType === 'essential'))
          }
        })
      })
      setTotalPrice(price ? Math.round(price * 100) / 100 : 0)
    }
  }, [selectedPlan, isYear, planType])

  useEffect(() => {
    if (platform && !!Object.keys(stripeSub).length) {
      Object.keys(platform).forEach(item => {
        if (!!platform[item].subscription && platform[item].subscription.status === 'active' && item !== 'jira') {
          setActiveSub(prevState => {
            return {
              ...prevState,
              [item]: `${platform[item].subscription.isAnnual ? 'year' : 'month'}-${platform[item].subscription.planQuantity}-${platform[item].subscription.planName}`
            }
          })
        } else if (!!platform[item].trial?.addedItemsQuantity && (!platform[item].subscription || platform[item].subscription.status !== 'active') && item !== 'jira') {
          setActiveSub(prevState => {
            const itemAmount = item !== 'github' && item !== 'gitlab' ?
              platform[item].trial.addedItemsQuantity :
              stripeSub[item]?.essential?.year.planCost.find(i => i.to >= platform[item].trial.addedItemsQuantity)?.to
            return {
              ...prevState,
              [item]: `year-${itemAmount}-pro_plan`
            }
          })
        } else if (item === 'jira') {
          setActiveSub(prevState => {
            return {
              ...prevState,
              [item]: `month-${platform[item]?.trial?.addedItemsQuantity || 2}-${platform?.[item]?.subscription?.planName}`
            }
          })
        }
      })
    }
  }, [platform, stripeSub])

  const onDeleteItem = (itemName: string) => {
    setAddedApp(prevState => prevState.filter(el => el !== itemName))
    setErrorSub(prevState => {
      return {...prevState, [itemName]: false}
    })
    setSelectedPlan(prevState => {
      const temp = {}
      Object.keys(prevState).forEach(el => {
        if (el !== itemName) {
          temp[el] = prevState[el]
        }
      })
      if (!Object.keys(temp).length) {
        setTotalPrice(0)
      }
      return {...temp}
    })
    localStorage.removeItem('checkoutContact')
  }

  const buySubscription = async () => {
    try {
      let response;
      let stripeSecure;

      if (billingDet) {
        const itemsPay: IPayment[] = Object.keys(selectedPlan).map(item => {
          const itemPlan = +selectedPlan[item];
          const payItem = {
            priceId: '',
            quantity: itemPlan,
            paymentMethodType: 'card',
            platform: item
          }
          payItem.priceId = isYear ? stripeSub[item]?.[planType]?.year.stripeIds.priceId : stripeSub[item]?.[planType]?.month.stripeIds.priceId
          return payItem
        })
        const data: IBillingAddressSubscribePayload = {...billingDet, items: itemsPay}
        if (couponDiscountObj.valid && couponCode.length > 0) {
          data.promotion_code = couponCode
        }
        response = await dispatch(setBillingAddressAndSubscribe(data)).unwrap()

        let reqIndex = response.findIndex(i => i.paymentIntentStatus === 'requires_action' && i.clientSecret)
        let elseIndex = response.findIndex(i => i.paymentIntentStatus !== 'succeeded' && i.paymentIntentStatus !== 'requires_action' && i.clientSecret)

        if (reqIndex === -1) reqIndex = 0
        if (elseIndex === -1) elseIndex = 0

        if (stripe && response[reqIndex].paymentIntentStatus === 'requires_action' && response[reqIndex].clientSecret) {
          for (const item of response) {
            if (item.paymentIntentStatus === 'requires_action' && item.clientSecret) {
              stripeSecure = await stripe.confirmCardPayment(item.clientSecret);
              if (stripeSecure?.error) {
                throw new Error(stripeSecure.error.message || translate('notifications.payment.stripe_error'));
              }
            }
          }
        }

        if (response[elseIndex].clientSecret && response[elseIndex].paymentIntentStatus !== 'requires_action' && response[elseIndex].paymentIntentStatus !== 'succeeded') {
          await Swal.fire({
            title: 'Payment failed',
            text: 'Please review your payment details and funds.',
            imageUrl: errorBigIcon,
          });
          setIsSubscriptionUpdating(false);
          setClientSecret(response[elseIndex].clientSecret);
          return;
        }

        await new Promise<void>((resolve) => setTimeout(async function () {
          await dispatch(getUser()).unwrap();
          resolve();
        }, billingDet ? 5000 : 10000));

        setIsSubscriptionUpdating(false);

        if (stripeSecure?.error) {
          throw new Error(stripeSecure.error.message || translate('notifications.payment.stripe_error'));
        }

        await Swal.fire({
          title: 'Thank you!',
          text: addedApp[0] === 'jira' ? translate('notifications.payment.success_pay_jira') : translate('notifications.payment.success_pay'),
          imageUrl: iconNotifySuccess,
        });

        navigate(`/`);
      }
    } catch (err) {
      setIsSubscriptionUpdating(false);
      const error = err as IError;

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.message || error.error as string),
        imageUrl: errorBigIcon,
      });
    }
  }

  const addOneMonthToDate = () => {
    const currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();
    currentMonth += 1;
    if (currentMonth > 11) {
      currentMonth = 0;
      currentYear += 1;
    }
    const date = new Date(currentYear, currentMonth, currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
    return date.toLocaleDateString()
  }

  const addOneYearToDate = () => {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    currentYear += 1;
    const date = new Date(currentYear, currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
    return date.toLocaleDateString()
  }

  let totalPriceWithTaxWithoutDiscount = totalPrice * (1 - couponDiscountObj.percentOff / 100) - couponDiscountObj.amountOff;
  totalPriceWithTaxWithoutDiscount = Math.max(totalPriceWithTaxWithoutDiscount, 0);
  const discountAmountWithCoupon = totalPrice - totalPriceWithTaxWithoutDiscount;
  const taxAmount = totalPriceWithTaxWithoutDiscount * (tax / 100);
  totalPriceWithTaxWithoutDiscount += taxAmount;

  const backupData = () => {
    localStorage['checkoutContact'] = JSON.stringify({
      selectedPlan,
      planType,
      isYear,
      contactList,
      addedApp
    })
  }

  useEffect(() => {
    if (!!Object.keys(selectedPlan)?.length) {
      backupData()
    }
  }, [selectedPlan, planType, isYear, contactList, addedApp])

  return (
    <div id="checkout-page">
      {userLoading || !platform || loadingData ?
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div> :
        <div className="checkout-block">
          <Typography variant="h3" display="block" mb='32px' className='page-title-h3'>
            {translate('common.default_pages.checkout_title')}
          </Typography>

          <div className='checkout-actions'>
            {stepsList.map(item => {
              return (
                <Steps key={item.step} step={item.step} stepCur={stepCur} title={item.title} desc={item.desc}/>
              )
            })}
          </div>

          <Typography variant="h4" display="block" mb='24px'>
            {stepsList[stepCur - 1].titleDub}
          </Typography>
          {Object.values(errorSub).find(item => item) && (
            <div className='checkout-info checkout-info-alert'>
              <div className='checkout-info-ico'>
                <AlertTriangle/>
              </div>
              {Platform[Object.keys(errorSub).find(item => errorSub[item]) || '']?.title}: This subscription is already
              active.
            </div>
          )}
          {addedApp[0] === 'jira' && stepCur === 1 && (
            <div className='checkout-info checkout-info-alert'>
              <div className='checkout-info-ico'>
                <AlertTriangle/>
              </div>
              Jira: Users and pricing are automatically calculated and subject to vary as your account may fluctuate.
            </div>
          )}

          {addedApp[0] !== 'jira' && Object.keys(selectedPlan).find(item => platform[item]?.trial?.addedItemsQuantity > selectedPlan[item]) && (
            <div className='checkout-info checkout-info-error'>
              <div className='checkout-info-ico'>
                <AlertCircle/>
              </div>
              {Object.keys(selectedPlan).filter(item => platform[item]?.trial?.addedItemsQuantity > selectedPlan[item])
                .map(fItem => Platform[fItem]?.title).join(', ')}: You’re choosing a plan that supports fewer {addedApp[0] === 'jira' ? ' users ' : ' backups '}
              than you currently have.
            </div>
          )}
          {!!contactList && (
            <div className='checkout-info checkout-info-info'>
              <div className='checkout-info-ico'>
                <AlertTriangle/>
              </div>
              <div className='checkout-info-text-btn'>
                <div>Enterprise plan</div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    backupData()
                    navigate('/contact')
                  }}
                >
                  Contact us
                </Button>
              </div>
            </div>
          )}

          <div className='checkout-container'>
            {stepCur === 1 && (
              <Card className='main-checkout-block'>
                <CardContent>
                  <p className='checkout-card-title'>
                    Please select your capacity requirements for your apps:
                  </p>

                  <div className='checkout-item-list'>
                    <div className='checkout-item-list-row checkout-item-list-row--head'>
                      <div className='checkout-item-list-cell'>
                        App
                      </div>
                      <div className='checkout-item-list-cell'>
                        {addedApp[0] === 'jira' ?
                          'Number of users' :
                          'Number of items to protect'
                        }
                      </div>
                    </div>

                    {Object.keys(selectedPlan).map(item => {
                      return (
                        <Prod
                          key={item} platformName={item} plan={selectedPlan[item]}
                          planObj={isYear ? stripeSub[item]?.[planType]?.year : stripeSub[item]?.[planType]?.month}
                          activeSub={errorSub[item]}
                          overload={platform[item]?.trial?.addedItemsQuantity > selectedPlan[item]}
                          changePlan={(amount) => setSelectedPlan(prevState => {
                            setContactList((amount === 1001 || amount === 2001 || amount === 201) ? item : '')
                            setErrorSub(prevState => {
                              return {
                                ...prevState,
                                [item]: !!activeSub[item]?.length && activeSub[item] === (isYear ? `year-${amount}` : `month-${amount}`) && ((platform[item]?.subscription?.planName === 'Pro' && planType === 'pro_plan') || (platform[item]?.subscription?.planName === 'Essential' && planType === 'essential'))
                              }
                            })
                            return {...prevState, [item]: amount}
                          })}
                          onDelete={() => {
                            if (!!contactList) {
                              setContactList('')
                            }
                            onDeleteItem(item)
                          }}
                        />
                      )
                    })}
                    {!Object.keys(selectedPlan).length && (
                      <Select
                        value='more'
                        className='checkout-app-select'
                        MenuProps={{id: 'sub-platform-select'}}
                        disabled={Object.keys(selectedPlan).length >= 1}
                      >
                        <MenuItem value='more' disabled sx={{display: 'none'}}>
                          Select an app
                        </MenuItem>
                        {allPlatform.map(itemPl => {
                          if (addedApp.indexOf(itemPl) === -1) {
                            return (
                              <MenuItem
                                key={itemPl}
                                disabled={
                                  (platform[itemPl]?.trial?.trialStatus !== 'active' && itemPl === 'jira' && activeSub[itemPl]?.split('-')?.[1] > 2000) || ((itemPl === 'trello' || itemPl === 'notion') && activeSub[itemPl]?.split('-')?.[1] > 200) ||
                                  ((itemPl === 'github' || itemPl === 'gitlab') && activeSub[itemPl]?.split('-')?.[1] > 1000)
                                }
                                value='history-list'
                                onClick={() => setAddedApp(prevState => {
                                  if (itemPl === 'jira') {
                                    setIsYear(false)
                                  }
                                  return [...prevState, itemPl]
                                })}
                              >
                                <div className='subscriptions-logo-select-item'>
                                  <img src={Platform[itemPl]?.smallLogo} className="platformLogo" loading="lazy"/>
                                  {Platform[itemPl]?.title}
                                </div>
                                {/*<img src={Platform[itemPl]?.logo} className="add-platform-logo"/>*/}
                              </MenuItem>
                            )
                          }
                        })}
                      </Select>
                    )}
                  </div>
                  <div className="action-buttons">
                    <Button variant="contained" color="primary"
                            onClick={() => setStepCur(prevState => prevState + 1)}
                            disabled={
                              !addedApp.length || Object.values(errorSub).find(item => item) || !!contactList ||
                              !!Object.keys(selectedPlan).find(item => selectedPlan[item] === 0 || platform[item]?.trial?.addedItemsQuantity > selectedPlan[item])
                            }
                    >
                      Continue
                    </Button>
                  </div>
                </CardContent>
              </Card>
            )}
            {stepCur === 2 && (
              <Card className='main-checkout-block'>
                <CardContent>
                  <p className='checkout-card-title'>
                    {user?.company?.address ? 'Let us know if any of your details have changed:' : 'Please fill out the form below so we can get you up and running:'}
                  </p>
                  <BillingForm
                    setData={setBillingDet}
                    data={billingDet}
                    onClose={() => setStepCur(prevState => prevState - 1)}
                    next={() => setStepCur(prevState => prevState + 1)}
                  />
                </CardContent>
              </Card>
            )}
            {stepCur === 3 && (
              <Card className='main-checkout-block'>
                <CardContent>
                  <p className='checkout-card-title'>
                    Double-check your subscription and payment details:
                  </p>
                  <CardForm
                    disableBtn={!addedApp.length || Object.values(errorSub).find(item => item)}
                    buySub={buySubscription}
                    isUpdate={isSubscriptionUpdating}
                    isUpdateInfo={taxLoad || couponLoad}
                    setIsUpdate={setIsSubscriptionUpdating}
                    clientSecret={clientSecret}
                    onClose={() => setStepCur(prevState => prevState - 1)}
                    totalPrice={totalPriceWithTaxWithoutDiscount}
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    setCouponDiscountObj={setCouponDiscountObj}
                    setCouponLoad={setCouponLoad}
                    couponDiscountObj={couponDiscountObj}
                    appName={addedApp[0] || ''}
                  />
                </CardContent>
              </Card>
            )}
            <Card className='main-checkout-block checkout-bill'>
              <CardContent>
                {!contactList && (
                  <div className='checkout-bill-right'>

                    <p className='checkout-bill-title'>
                      <div>Your Subscription</div>
                    </p>
                    {Object.keys(selectedPlan).map(item => {
                      const itemPlan = +selectedPlan[item];

                      return (
                        <div key={item} className='checkout-bill-item'>
                          <div className='checkout-bill-item-logo'>
                            <img src={Platform[item]?.smallLogo} className="platform-logo" loading="lazy"/>
                            {Platform[item]?.title}
                          </div>
                          <div className='checkout-bill-item-plan'>
                            <div className='checkout-bill-item-plan-title'>
                              Subscription plan
                            </div>
                            <div className='checkout-bill-item-plan-name'>
                              {!!contactList ? 'Enterprise' : planType === 'pro_plan' ? 'Pro' : 'Essential'}
                            </div>
                          </div>
                          <div className='checkout-bill-item-plan'>
                            <div className='checkout-bill-item-plan-title'>
                              {item === 'jira' ?
                                'Max users' :
                                'Max items'
                              }
                            </div>
                            <div className='checkout-bill-item-plan-name'>
                              {itemPlan}
                            </div>
                          </div>
                          {item === 'jira' && (
                            <div className='checkout-bill-item-plan'>
                              <div className='checkout-bill-item-plan-title'>
                                Per user
                              </div>
                              <div className='checkout-bill-item-plan-name'>
                                {stripeSub[item]?.[planType]?.[isYear ? 'year' : 'month'].planCost.find(item => item.to >= itemPlan && item.from <= itemPlan)?.cost
                                  .toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                  }) || 0
                                }
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    })}
                    <div className='checkout-bill-total'>

                      <div className='checkout-bill-total-item sub-item-price'>
                        <div className='checkout-bill-total-item-cell'>
                          Original price
                        </div>
                        <div className='checkout-bill-total-item-cell'>
                          {(isYear ? (Math.round(totalPrice / 0.008) / 100) : totalPrice).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                          })} / {isYear ? 'annual' : 'month'}
                        </div>
                      </div>
                      {isYear && (
                        <div className='checkout-bill-total-item sub-item-price'>
                          <div className='checkout-bill-total-item-cell'>
                            Annual discount (20%)
                          </div>
                          <div className='checkout-bill-total-item-cell'>
                            -{(Math.round(((totalPrice / 0.8) - totalPrice) * 100) / 100).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                          })} / {isYear ? 'annual' : 'month'}
                          </div>
                        </div>
                      )}

                      <div className='checkout-bill-total-item sub-item-price'>
                        <div className='checkout-bill-total-item-cell'>
                          Coupon
                          savings {(couponDiscountObj.percentOff > 0 && couponCode.length > 0) && `(${couponDiscountObj.percentOff}%)`
                          || (couponDiscountObj.amountOff > 0 && couponCode.length > 0) && `($${couponDiscountObj.amountOff})`}
                        </div>
                        <div className='checkout-bill-total-item-cell'>
                          {couponLoad ?
                            <div className="small-spinner-wrapper">
                              <CircularProgress color="inherit"
                                                style={{width: '20px', height: '20px', marginTop: '6px'}}/>
                            </div>
                            : couponDiscountObj.percentOff > 0 ? `-${discountAmountWithCoupon.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD"
                            })} / ${isYear ? 'annual' : 'month'}` : couponDiscountObj.amountOff > 0 ? `-${couponDiscountObj.amountOff.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD"
                            })} / ${isYear ? 'annual' : 'month'}` : '—'
                          }
                        </div>
                      </div>
                      <div className='checkout-bill-total-item sub-item-price'>
                        <div className='checkout-bill-total-item-cell'>
                          Local taxes
                          {(!!tax || isYear) && ` (${!!tax ? `${tax}%` : ''}${(!!tax && isYear) ? ', ' : ''}${isYear ? 'after discounts' : ''})`}
                        </div>
                        <div className='checkout-bill-total-item-cell'>
                          {taxLoad ?
                            <div className="small-spinner-wrapper">
                              <CircularProgress color="inherit"
                                                style={{width: '20px', height: '20px', marginTop: '6px'}}/>
                            </div>
                            : !!tax ? `+${(taxAmount).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD"
                            })} / ${isYear ? 'annual' : 'month'}` : '—'
                          }
                        </div>
                      </div>
                      <div className='checkout-bill-total-item total-item-price'>
                        <div className='checkout-bill-total-item-cell'>
                          Total price {stepCur === 3 || tax ? <span> (after tax)</span> : <span> (before tax)</span>}
                        </div>
                        <div className='checkout-bill-total-item-cell'>
                          {(totalPriceWithTaxWithoutDiscount).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                          })} / {isYear ? 'annual' : 'month'}
                        </div>
                      </div>
                      {Object.keys(selectedPlan).length === 1 && (
                        <>
                          {!!addedApp?.length && platform[addedApp[0]]?.subscription?.status === 'active' && (
                            <div className='checkout-bill-total-item sub-item-price'>
                              <div className='checkout-bill-total-item-cell'>
                                Renew date: Current
                              </div>
                              <div className='checkout-bill-total-item-cell'>
                                {platform[addedApp[0]]?.subscription?.finishDate}
                              </div>
                            </div>
                          )}
                          <div className='checkout-bill-total-item sub-item-price'>
                            <div className='checkout-bill-total-item-cell'>
                              Renew date: After purchase
                            </div>
                            <div className='checkout-bill-total-item-cell'>
                              {isYear ? addOneYearToDate() : addOneMonthToDate()}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>

          {stepCur === 1 && (
            <div className='checkout-container checkout-plans'>
              <div className='checkout-plans-title'>
                Select a plan
              </div>
              <div className={open ? 'plan-checkout-group open-list' : 'plan-checkout-group'}>
                <Card
                  className={planType === 'essential' && !contactList ? 'plan-checkout-block plan-active' : 'plan-checkout-block'}
                  onClick={() => !isSubscriptionUpdating && setPlanType('essential')}>
                  <CardContent>
                    <div className='plan-checkout-block-title'>
                      {planType === 'essential' && !contactList && (
                        <Check className='plan-checkout-block-title-icon'/>
                      )}
                      Essential Plan
                    </div>
                    <div className='checkout-year'>
                      <CustomTooltip title={addedApp[0] === 'jira' && planType === 'essential' ? 'N/A' : ''} placement="bottom-start">
                        <div className='checkout-year-btn'>
                          <RadioButton
                            checked={isYear && planType === 'essential' && !contactList} name='year'
                            onClick={(e) => {
                              e.stopPropagation()
                              setIsYear(true)
                            }}
                            disabled={isSubscriptionUpdating || planType !== 'essential' || !!contactList || addedApp[0] === 'jira'}
                          />
                          <div className='checkout-year-btn-name'>
                            <div>
                              Annual billing
                            </div>
                            {addedApp[0] !== 'jira' && <span>(Save 20%)</span>}
                          </div>
                          {addedApp[0] !== 'jira' && !contactList && Object.keys(selectedPlan).map(item => {
                            let price: number;
                            const itemPlan = +selectedPlan[item];
                            if (stripeSub[item]?.essential?.year.unitCost) {
                              price = (itemPlan * stripeSub[item]?.essential?.year.unitCost)
                            } else {
                              if (item === 'jira') {
                                price = itemPlan * stripeSub[item]?.essential?.year.planCost.find(item => item.to >= itemPlan && item.from <= itemPlan)?.cost
                              } else {
                                price = stripeSub[item]?.essential?.year.planCost.find(item => item.to === itemPlan)?.cost
                              }
                            }
                            return (
                              <div key={item} className='checkout-year-btn-name-price'>
                                {(Math.round((price || 0) * 100) / 100).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                })} / annual
                              </div>
                            )
                          })}
                          {addedApp[0] === 'jira' && <div className='checkout-year-btn-name-price'>Unavailable</div>}

                        </div>
                      </CustomTooltip>
                      <div className='checkout-year-btn'>
                        <RadioButton
                          checked={!isYear && planType === 'essential' && !contactList} name='month'
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsYear(false)
                          }}
                          disabled={isSubscriptionUpdating || planType !== 'essential' || !!contactList}
                        />
                        <div className='checkout-year-btn-name'>
                          Monthly billing
                        </div>
                        {!contactList && Object.keys(selectedPlan).map(item => {
                          let price: number;
                          const itemPlan = +selectedPlan[item];
                          if (stripeSub[item]?.essential?.month.unitCost) {
                            price = (itemPlan * stripeSub[item]?.essential?.month.unitCost)
                          } else {
                            if (item === 'jira') {
                              price = itemPlan * stripeSub[item]?.essential?.month.planCost.find(item => item.to >= itemPlan && item.from <= itemPlan)?.cost
                            } else {
                              price = stripeSub[item]?.essential?.month.planCost.find(item => item.to === itemPlan)?.cost
                            }
                          }
                          return (
                            <div key={item} className='checkout-year-btn-name-price'>
                              {(Math.round((price || 0) * 100) / 100).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                              })} / month
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <Collapse in={open}>
                      <div className='checkout-plan-list'>
                        <ul>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            AES 256-bit encryption
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Two-Factor authentication
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            30 day data retention history <br/>
                            (For each backup)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Up to 1000 items<br/>
                            (Boards, Projects, Repos etc)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Automated daily backups<br/>
                            (Once every 24 hours)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Download backups locally
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Unlimited individual item restores
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Email notifications<br/>
                            (Account level and critical alerts)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            24 hour email & chat support
                          </li>
                        </ul>
                      </div>
                    </Collapse>
                  </CardContent>
                </Card>
                <Card
                  className={planType === 'pro_plan' && !contactList ? 'plan-checkout-block plan-active' : 'plan-checkout-block'}
                  onClick={() => !isSubscriptionUpdating && setPlanType('pro_plan')}>
                  <CardContent>
                    <div className='plan-checkout-block-title'>
                      {planType === 'pro_plan' && !contactList && (
                        <Check className='plan-checkout-block-title-icon'/>
                      )}
                      Pro Plan
                      <div className='plan-recommended-badge'>
                        Recommended
                      </div>
                    </div>
                    <div className='checkout-year'>
                      <CustomTooltip title={addedApp[0] === 'jira' && planType === 'pro_plan' ? 'N/A' : ''} placement="bottom-start">
                        <div className='checkout-year-btn' >
                          <RadioButton
                            checked={isYear && planType === 'pro_plan' && !contactList} name='year'
                            onClick={(e) => {
                              e.stopPropagation()
                              setIsYear(true)
                            }}
                            disabled={isSubscriptionUpdating || planType !== 'pro_plan' || !!contactList || addedApp[0] === 'jira'}
                          />
                          <div className='checkout-year-btn-name'>
                            <div>
                              Annual billing
                            </div>
                            {addedApp[0] !== 'jira' && <span>(Save 20%)</span>}
                          </div>

                          {addedApp[0] !== 'jira' && !contactList && Object.keys(selectedPlan).map(item => {
                            let price: number;
                            const itemPlan = +selectedPlan[item];
                            if (stripeSub[item]?.pro_plan?.year.unitCost) {
                              price = (itemPlan * stripeSub[item]?.pro_plan?.year.unitCost)
                            } else {
                              if (item === 'jira') {
                                price = itemPlan * stripeSub[item]?.pro_plan?.year.planCost.find(item => item.to >= itemPlan && item.from <= itemPlan)?.cost
                              } else {
                                price = stripeSub[item]?.pro_plan?.year.planCost.find(item => item.to === itemPlan)?.cost
                              }
                            }
                            return (
                              <div key={item} className='checkout-year-btn-name-price'>
                                {(Math.round((price || 0) * 100) / 100).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                })} / annual
                              </div>
                            )
                          })}
                          {addedApp[0] === 'jira' && <div className='checkout-year-btn-name-price'>Unavailable</div>}

                        </div>
                      </CustomTooltip>

                      <div className='checkout-year-btn'>
                        <RadioButton
                          checked={!isYear && planType === 'pro_plan' && !contactList} name='month'
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsYear(false)
                          }}
                          disabled={isSubscriptionUpdating || planType !== 'pro_plan' || !!contactList}
                        />
                        <div className='checkout-year-btn-name'>
                          Monthly billing
                        </div>
                        {!contactList && Object.keys(selectedPlan).map(item => {
                          let price: number;
                          const itemPlan = +selectedPlan[item];
                          if (stripeSub[item]?.pro_plan?.month.unitCost) {
                            price = (itemPlan * stripeSub[item]?.pro_plan?.month.unitCost)
                          } else {
                            if (item === 'jira') {
                              price = itemPlan * stripeSub[item]?.pro_plan?.month.planCost.find(item => item.to >= itemPlan && item.from <= itemPlan)?.cost
                            } else {
                              price = stripeSub[item]?.pro_plan?.month.planCost.find(item => item.to === itemPlan)?.cost
                            }
                          }
                          return (
                            <div key={item} className='checkout-year-btn-name-price'>
                              {(Math.round((price || 0) * 100) / 100).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                              })} / month
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <Collapse in={open}>
                      <div className='checkout-plan-list'>
                        <div className='checkout-plan-list-include'>
                          Includes everything in the Essential plan, plus:
                        </div>
                        <ul>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            90 day data retention history<br/>
                            (For each backup)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Automated daily backups<br/>
                            (Once every 12 hours)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Backup on demand
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            External storage option
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Unlimited multiple item restores
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Activate/deactivate backups
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            +Organisations<br/>
                            (Coming soon)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Telephone support
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Multiple users & admin control<br/>
                            (Coming soon)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Audit trail
                          </li>
                        </ul>
                      </div>
                    </Collapse>
                  </CardContent>
                </Card>
                <Card
                  className={planType === 'enterprise' || !!contactList ? 'plan-checkout-block plan-active' : 'plan-checkout-block'}>
                  <CardContent>
                    <div className='plan-checkout-block-title'>
                      {(planType === 'enterprise' || !!contactList) && (
                        <Check className='plan-checkout-block-title-icon'/>
                      )}
                      Enterprise Plan
                    </div>
                    <div className='checkout-plan-contact'>
                      <Button
                        variant="contained"
                        color={(planType === 'enterprise' || !!contactList) ? 'secondary' : "primary"}
                        onClick={() => {
                          backupData()
                          navigate('/contact')
                        }}
                      >
                        Contact us
                      </Button>
                    </div>
                    <Collapse in={open}>
                      <div className='checkout-plan-list'>
                        <div className='checkout-plan-list-include'>
                          Includes everything in the Pro plan, plus:
                        </div>
                        <ul>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Unlimited data retention history<br/>
                            (For each backup)
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Unlimited items
                          </li>
                          <li>
                            <Check className='plan-checkout-list-icon'/>
                            Customer managed encryption keys<br/>
                            (Coming soon)
                          </li>
                        </ul>
                      </div>
                    </Collapse>
                  </CardContent>
                </Card>
              </div>
              <div className='expand-btn'>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(!open)}
                >
                  Compare plans
                  {open ?
                    <ChevronUp/> :
                    <ChevronDown/>
                  }
                </Button>
              </div>
            </div>
          )}
        </div>
      }
      {/*<AddPlatform*/}
      {/*  isOpen={showAdd}*/}
      {/*  closeNotification={() => setShowAdd(false)}*/}
      {/*  list={addedApp}*/}
      {/*  add={(name) => setAddedApp(prevState => [...prevState, name])}*/}
      {/*/>*/}
    </div>
  );
};

export default withRedirectAfterLogout()(Checkout);
