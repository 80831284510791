import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../StyledComponents';
import './style.scss';
import {Plus} from "react-feather";

interface INoBackupsBlockProps {
  action: () => void
  actionText: string
  altAction?: () => void
  altActionText?: string
  platformItem: string
  isAdmin: boolean
  isSearch?: boolean
}

const NoItemsBlock: FunctionComponent<INoBackupsBlockProps> = ({
                                                                 action,
                                                                 actionText,
                                                                 altAction,
                                                                 altActionText,
                                                                 isAdmin,
                                                                 isSearch
                                                                 // platformItem
}: INoBackupsBlockProps) => {
  const { t: translate } = useTranslation();

  return (
    <div className="data-block-sub">
      <div className="subtitle">
        {isSearch ? translate('views.bulk_restore.no_search') : translate('views.platform_pages.no_backups')}
      </div>
      <Button variant="contained" color="primary" onClick={action} disabled={!isAdmin}>
        <Plus className="feather-icon-in-button"/> {actionText}
      </Button>
      {altAction && altActionText && (
        <Button variant="contained" color="primary" onClick={altAction} mt={4} disabled={!isAdmin}>
          <Plus className="feather-icon-in-button"/> {altActionText}
        </Button>
      )}
    </div>
  );
};

export default (NoItemsBlock);
