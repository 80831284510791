import React, { FunctionComponent, useEffect, useState } from 'react';
import PlatformUserPageBlock from '../../../../components/PlatformComponents/PlatformUserPageBlock';
import { EPlatformsNames } from '../../../../models/consts';
import { IPlatformUser, SubPlanInfo } from '../../../../models/models';
import { fetchGitlabUsers } from '../../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getGitlabUsersSelector,
  gitlabSubscription,
  gitlabTrialHoursLeft, gitlabTrialPlanQuantity, gitlabTrialQuantityLeft,
  gitlabTrialStatus,
  isGitlabUsersLoadingSelector,
  loadingUser,
} from '../../../../store/selectors';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import GitlabUserAddEdit from '../GitlabUserAddEditView/GitlabUserAddEdit';

const GitlabUserSettings: FunctionComponent = () => {
  const userLoading = useAppSelector(loadingUser);
  const isLoadingGitlabUsers = useAppSelector(isGitlabUsersLoadingSelector);
  const trialState = useAppSelector(gitlabTrialStatus);
  const trialDays = useAppSelector(gitlabTrialHoursLeft);
  const trialQuantity = useAppSelector(gitlabTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(gitlabTrialPlanQuantity);
  const subInfo = useAppSelector(gitlabSubscription);
  const users = useAppSelector(getGitlabUsersSelector);
  const dispatch = useAppDispatch();

  const platformName = EPlatformsNames.GITLAB;

  const [subPlan, setSubPlan] = useState<SubPlanInfo>();
  const [platformUsers, setPlatformUsers] = useState<Array<IPlatformUser>>([]);

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);

      setSubPlan(subPlan);
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingGitlabUsers) {
      setPlatformUsers(users || []);
    }
  }, [users, isLoadingGitlabUsers]);

  useEffect(() => {
    dispatch(fetchGitlabUsers()).unwrap().catch(err => console.log(err));
  }, [platformName]);

  return (
    <PlatformUserPageBlock
      platformName={platformName}
      isLoadingPlatformUsers={isLoadingGitlabUsers}
      ChildComponent={() => <GitlabUserAddEdit user={platformUsers[0]} subPlan={subPlan} />}
    />
  );
};

export default withRedirectAfterLogout()(GitlabUserSettings);
