import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EPlatformsNames, EStripeSubStatus, ETrialStatus } from '../../../models/consts';
import { EGitlabPlanNames } from '../../../models/paymentPlans/gitlab-payments-plan';
import { IPlatformSubscriptionRow } from '../../../views/UserPages/Subscription/Subscription';
import { PlatformsState } from '../../reducers/platform';

export const getGitlabReposSelector = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.items);
export const getGitlabTotal = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.totalItems);

export const getGitlabCanUserBackup = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.canUserBackup);

export const isGitlabRepoLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.itemsLoading);

export const isGitlabUsersLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.usersLoading);

export const getGitlabUsersSelector = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.users);
export const isGitlabConnect = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.isConnectionActive);

export const gitlabTrialStatus = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.trial?.trialStatus);

export const gitlabTrialHoursLeft = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => {
  return gitlab.trial?.trialHoursLeft || null;
});

export const gitlabTrialQuantityLeft = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => {
  return gitlab.trial?.availableItemsQuantity || null;
});

export const gitlabTrialPlanQuantity = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => {
  return gitlab.trial?.planQuantity || 0;
});

export const gitlabSubInfo = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { subscription } = gitlab;
  if (subscription) {
    return {
      id: 5,
      platformName: EPlatformsNames.GITLAB,
      plan: subscription?.planName || '',
      price: `$${subscription?.cost.toFixed(2)} / ${subscription?.isAnnual ? 'yr.' : 'mo.'}`,
      status: subscription?.status,
      quantity: subscription.quantity,
      planQuantity: subscription.planQuantity,
      addedItemsQuantity: subscription.addedItemsQuantity,
      isCancel: subscription.isCancel,
      isConnect: false
    };
  }
  return null;
});

export const gitlabTrialInfo = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { trial } = gitlab;
  if (trial) {
    return {
      id: 6,
      platformName: EPlatformsNames.GITLAB,
      plan: EGitlabPlanNames.TRIAL,
      price: `-`,
      status: trial.trialStatus,
      quantity: 0,
      planQuantity: 0,
      addedItemsQuantity: 0,
      isCancel: false,
      isConnect: false
    };
  }
  return null;
});

export const gitlabSubscription = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => gitlab.subscription);

export const gitlabTrialData = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => {
  return gitlab.trial
});

export const gitlabActualPlan = createSelector((state: DefaultRootState) => state.platform, ({ gitlab }: PlatformsState) => {
  const { subscription: gitlabSub, trial: gitlabTrial, isConnectionActive } = gitlab;

  const gitlabItem: IPlatformSubscriptionRow = {
    id: 1,
    platformName: EPlatformsNames.GITLAB,
    plan: '-',
    price: '-',
    quantity: 0,
    planQuantity: 0,
    addedItemsQuantity: 0,
    status: EStripeSubStatus.INACTIVE,
    isCancel: false,
    finishDateInMillisecond: null,
    isConnect: isConnectionActive
  };

  if (gitlabTrial && gitlabTrial.trialStatus === ETrialStatus.NOT_STARTED) {
    gitlabItem.plan = EGitlabPlanNames.TRIAL;
    gitlabItem.status = gitlabTrial.trialStatus;
    gitlabItem.quantity = gitlabTrial.availableItemsQuantity || 0;
    gitlabItem.addedItemsQuantity = gitlabTrial.addedItemsQuantity || 0;
    gitlabItem.planQuantity = gitlabTrial.planQuantity || 0;
    gitlabItem.finishDate = gitlabTrial.finishDate || null;
    gitlabItem.isConnect = isConnectionActive
  }

  if (gitlabTrial && gitlabTrial.trialStatus === ETrialStatus.STARTED) {
    gitlabItem.plan = EGitlabPlanNames.TRIAL;
    gitlabItem.status = gitlabTrial.trialStatus;
    gitlabItem.quantity = gitlabTrial.availableItemsQuantity || 0;
    gitlabItem.addedItemsQuantity = gitlabTrial.addedItemsQuantity || 0;
    gitlabItem.planQuantity = gitlabTrial.planQuantity || 0;
    gitlabItem.finishDate = gitlabTrial.finishDate || null;
    gitlabItem.isConnect = isConnectionActive
  }

  if (gitlabSub && (!gitlabTrial || gitlabTrial?.trialStatus !== ETrialStatus.STARTED || gitlabSub?.status === EStripeSubStatus.ACTIVE)) {
    gitlabItem.plan = gitlabSub.planName || '';
    gitlabItem.status = gitlabSub.status;
    gitlabItem.quantity = gitlabSub.quantity || 0;
    gitlabItem.finishDate = gitlabSub.finishDate || null;
    gitlabItem.planQuantity = gitlabSub.planQuantity || 0;
    gitlabItem.addedItemsQuantity = gitlabSub.addedItemsQuantity || 0;
    gitlabItem.price = `$${gitlabSub.cost.toFixed(2)} / ${gitlabSub.isAnnual ? 'yr.' : 'mo.'}`
    gitlabItem.isCancel = gitlabSub.isCancel;
    gitlabItem.finishDateInMillisecond = gitlabSub.finishDateInMillisecond;
    gitlabItem.isConnect = isConnectionActive
  }

  return gitlabItem
});
