import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/backuplabs-logo.png';
import { DRAWER_WIDTH } from '../../models/consts';
import { useAppSelector } from '../../store/hooks';
import {getUserSelector, isAuthorized} from '../../store/selectors';
import { VariantType } from '../../theme/variants';
import AuthorizedUserItem from './AuthorizedUserItem/AuthorizedUserItem';
import './style.scss';
import useWindowDimensions from "../../utils/windowDimensions";
import {IUser} from "../../models/models";
import {IconButton} from "../StyledComponents";
import { Menu as MenuIcon } from '@mui/icons-material';

interface IHeaderProps {
  menuToggle: () => void;
  secondLogo: boolean;
}

const useStyles = makeStyles((theme: VariantType) =>
  createStyles({
      drawer: {
        width: DRAWER_WIDTH,
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 40,
      },
      drawerMenu: {
        width: DRAWER_WIDTH,
      },
      header: {
        position: 'absolute',
        zIndex: 100,
        width: '100%',
        background: '#ffffff',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        color: theme.sidebar.header.color,
      },
      menuButton: {
        position: 'absolute',
      },
      svg: {
        width: '22px',
        height: '22px',
      },
    },
  ),
);


const Header: FunctionComponent<IHeaderProps> = ({ secondLogo, menuToggle }: IHeaderProps) => {
  const classes = useStyles();
  const isAuth = useAppSelector(isAuthorized);
  const navigate = useNavigate();
  const { isSmallScreen } = useWindowDimensions();
  const user: IUser | null = useAppSelector(getUserSelector);

  return (
    <div className={classes.header} style={{zIndex: isSmallScreen ? 1400 : 100 }}>
      <header className="main-header">
        {user?.hadEverAnySubscriptions && isSmallScreen && (
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            className={classes.menuButton}
            onClick={menuToggle}
          >
            <MenuIcon className={classes.svg}/>
          </IconButton>
        )}
        <div>
          {!secondLogo && (
            <div className="brand-block" onClick={() => {
              navigate('/app-connector');
            }}>
              <img className="logo" src={logo} loading="lazy"/>
            </div>
          )}
        </div>
        <nav className="menu">
          {isAuth && <AuthorizedUserItem />}
        </nav>
      </header>
    </div>
  );
};

export default Header;
