import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Button } from '../../../../../components/StyledComponents';
import {IPoppupBase} from "../../../../../models/inner-models";
import './style.scss'

const JiraHowTo: FunctionComponent<IPoppupBase> = ({isOpen, closeNotification}: IPoppupBase) => {

  const handleClose = () => {
    if (closeNotification) {
      closeNotification();
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="jira-modal-title"
      aria-describedby="jira-modal-info"
      classes={{
        paper: 'notification-modal jira-modal',
      }}
    >
      {/*<div className="close-btn" onClick={handleClose}>*/}
      {/*  <img src={closeModalIcon} />*/}
      {/*</div>*/}
      <DialogTitle id="jira-modal-title">
        How to restore Jira backups
      </DialogTitle>
      <DialogContent>
        <div id="jira-modal-info">
          <p>
            To create a new blank project in Jira you should:
          </p>
          <ol>
            <li>Go to  <a href='https://admin.atlassian.com/' rel="noreferrer" target='_blank'>https://admin.atlassian.com/</a></li>
            <li>Open Jira Software project</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction.png' loading="lazy"/>
            <li>Create a new project</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-1.png' loading="lazy"/>
            <li>Select Software Development and project template</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-2.png' loading="lazy"/>
            <li>Confirm template selection </li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-3.png' loading="lazy"/>
            <li>Select project type </li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-4.png' loading="lazy"/>
            <li>Fill in the project’s name and confirm the settings</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-5.png' loading="lazy"/>
          </ol>

          <p>
            Project configuration:
          </p>
          <ol>
            <li>Select ‘Manage workflow’</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-6.png' loading="lazy"/>
            <li>Add new statuses and click ‘Update workflow’</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-7.png' loading="lazy"/>
            <li>Re-arrange columns and save changes</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-15.png' loading="lazy"/>
          </ol>

          <p>
            Project Restore:
          </p>
          <ol>
            <li>Select the project for restore and click the ‘Restore’ button</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-8.png' loading="lazy"/>
            <li>Select a backup point</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-9.png' loading="lazy"/>
            <li>Select a project to restore to</li>
            <small>Note: The restore process doesn’t clean up the project. It creates new issues on the project</small><br/>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-10.png' loading="lazy"/>
            <li>Match project columns initial project with the project to restore</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-11.png' loading="lazy"/>
            <li>You can match all columns in the project</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-12.png' loading="lazy"/>
            <li>You can not match column by selection this option</li>
            <small>Note: If you don’t match columns, all issues will be restored in the To Do column</small><br/>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-13.png' loading="lazy"/>
            <li>Confirm Restore process</li>
            <img src='https://bl-public-media.s3.amazonaws.com/jira/jira-instruction-14.png' loading="lazy"/>
          </ol>
        </div>
      </DialogContent>

      <div className="action-buttons">
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="contained" color="primary"
        >
          Got it
        </Button>
      </div>
    </Dialog>
  );
};

export default JiraHowTo;
