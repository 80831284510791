import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import {errorBigIcon, toastSuccess, warningBigIcon} from '../../../../assets/icons/icons';
import {Button} from '../../../../components/StyledComponents';
import {IError} from '../../../../models/inner-models';
import {deleteSubUser, getInvitedUsersList, getUser, setOrgName} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {userOrganizationName} from '../../../../store/selectors';
import {decoratePopUpMessage} from '../../../../utils/popUpTextDecorator';
import './style.scss';
import {Chip as MuiChip, CircularProgress, OutlinedInput} from "@mui/material";
import ValidationErrorBlock from "../../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock";
import InviteUserBlock from "./InviteUserBlock/InviteUserBlock";
import {CustomTooltip} from "../../../../styles/components/CustomTooltip";
import styled from "styled-components";

const Chip = styled(MuiChip)<{ color: string }>`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;

  span {
    height: 20px;
  }

  &.success {
    background-color: #D1FAE5;
    color: #065F46;
  }

  &.error {
    background-color: #FEE2E2;
    color: #991B1B;
  }

  &.process {
    background-color: #FEF3C7;
    color: #92400E;
  }
`;

const InviteUser: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const {t: translate} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSendName, setIsSendName] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);
  const [delUser, setDelUser] = useState<string>('');
  const [orgNameField, setOrgNameField] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [inviteForm, setInviteForm] = useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<Array<any>>([]);
  const [editUsers, setEditUsers] = useState<Record<any, any>>({});
  const orgName = useAppSelector(userOrganizationName)
  const targetRef = useRef<HTMLDivElement>(null);

  const scrollToElement = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll
    }
  };

  const getData = async () => {
    try {
      setLoading(true)
      const data = await dispatch(getInvitedUsersList()).unwrap()
      setInvitedUsers(data)
      console.log(data)
      setLoading(false)
    } catch (err) {
      const error = err as IError;
      setLoading(false)
      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  useEffect(() => {
    getData()
  }, []);

  useEffect(() => {
    if (orgName && orgName !== orgNameField) {
      setOrgNameField(orgName)
    }
  }, [orgName]);

  const saveOrgName = async () => {
    try {
      setIsSendName(true)
      await dispatch(setOrgName(orgNameField))
      await dispatch(getUser())
      setIsSendName(false)
    } catch (err) {
      const error = err as IError;
      setIsSendName(false)
      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  const deleteInvitedUser = async (deleteUser: string) => {
    try {
      setIsSend(true)
      const userChoice = await Swal.fire({
        title: translate('notifications.titles.warning'),
        text: 'Are you sure you want to delete this user?',
        imageUrl: warningBigIcon,
        confirmButtonText: translate('notifications.choices.confirm'),
        cancelButtonText: translate('notifications.choices.cancel'),
        showCancelButton: true,
      });

      if (userChoice.isConfirmed) {
        await dispatch(deleteSubUser(deleteUser))
        await dispatch(getUser())
        Swal.fire({
          title: translate('notifications.titles.success'),
          text: 'User has been successfully deleted',
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
          imageUrl: toastSuccess,
          timer: 3000,
        });
        const data = await dispatch(getInvitedUsersList()).unwrap()
        setInvitedUsers(data)
      }
      setIsSend(false)
    } catch (err) {
      const error = err as IError;
      setIsSend(false)
      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  return (
    <div className="user-info-block-container user-invite-manage-block">
      <div className="block-label">
        <div className="block-label-title">User Management</div>
        <div className="block-label-desc">Add your team members here and manage their permissions.</div>
      </div>

      <div className="user-invite-manage-org">
        <div className="label-block">
          Your organization name*
        </div>
        <div className='user-invite-input-block'>
          <div className="input-label">
            <OutlinedInput
              placeholder='Type your organisation name here'
              value={orgNameField}
              onChange={(e) => {
                if (!e.target.value.length || e.target.value.length > 100) {
                  setError(true)
                } else {
                  setError(false)
                }
                setOrgNameField(e.target.value)
              }}
              error={error}
            />
            {error &&
              <ValidationErrorBlock errorMessage={!orgNameField?.length ? 'This field is required' : 'Max length is 100'}/>
            }
          </div>
          <Button
            ref={targetRef}
            onClick={saveOrgName}
            variant="contained"
            color="primary"
            disabled={isSendName || error || !orgNameField || orgName === orgNameField || loading}
          >
            {isSendName &&
              <div className="small-spinner-wrapper">
                <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '6px'}}/>
              </div>
            }
            {!orgName ? translate('notifications.choices.save') : translate('common.buttons.edit')}
          </Button>
        </div>
      </div>
      <div className='user-invite-send-block'>
        <div className="block-label">
          <div className="block-label-title">Send Invitation</div>
          <div className="block-label-desc">You
            have {10 - invitedUsers.length} {10 - invitedUsers.length === 1 ? 'invitation' : 'invitations'} left.
          </div>
        </div>
        {!inviteForm && !Object.keys(editUsers).length && (
          <div className="action-row">
            <Button
              onClick={() => setInviteForm(true)}
              variant="contained"
              color="primary"
              disabled={loading || isSendName || isSend || (10 - invitedUsers.length === 0) || !orgName}
            >
              Invite new user
            </Button>
          </div>
        )}
        {(inviteForm || !!Object.keys(editUsers).length) && (
          <InviteUserBlock
            onClose={() => {
              if (!!Object.keys(editUsers).length) {
                setEditUsers({})
              }
              setInviteForm(false)
            }}
            disable={isSend || isSendName || loading}
            roles={editUsers?.roles}
            userEmail={editUsers}
            updList={getData}
          />
        )}
      </div>
      {!!invitedUsers?.length && (
        <div className='user-invite-manage-list'>
          <div className="label-title">
            You have already invited these users:
          </div>
          <div className='user-invite-manage-list-table'>
            {invitedUsers?.map(i => {
              return (
                <div className='user-invite-manage-list-item' key={i}>
                  <CustomTooltip title={i.email}>
                    <div className='user-invite-manage-list-item-email'>
                      {i.email}
                    </div>
                  </CustomTooltip>
                  <div className='user-invite-manage-list-item-email center'>
                    <Chip
                      className={i.name ? 'success' : 'process'}
                      label={i.name ? `Active` : 'Invited'}
                    />
                  </div>
                  <CustomTooltip title={i.name ? `${i.name} ${i.family_name}` : ''}>
                    <div className='user-invite-manage-list-item-email'>
                      {i.name ? `${i.name} ${i.family_name}` : '—'}
                    </div>
                  </CustomTooltip>
                  <div className='user-invite-manage-list-item-action'>
                    <Button
                      onClick={() => {
                        scrollToElement()
                        setEditUsers(i)
                      }}
                      variant="contained"
                      color="secondary"
                      mr={3}
                      disabled={isSend || loading}
                    >
                      Show/Edit
                    </Button>
                    <Button
                      onClick={() => {
                        setDelUser(i.sub)
                        deleteInvitedUser(i.sub)
                        if (!!Object.keys(editUsers).length) {
                          setEditUsers({})
                        }
                        setInviteForm(false)
                      }}
                      variant="outlined"
                      color="warning"
                      disabled={isSend || loading}
                    >
                      {isSend && delUser === i.sub &&
                        <div className="small-spinner-wrapper">
                          <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '6px'}}/>
                        </div>
                      }
                      Delete
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteUser;
