import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {errorBigIcon} from '../../../assets/icons/icons';
import { EPaymentRequiredType } from '../../../models/consts';
import { IPaymentRequiredPoppup } from '../../../models/inner-models';
import { Button } from '../../StyledComponents';
import './style.scss';

const PaymentRequiredPoppup: FunctionComponent<IPaymentRequiredPoppup> = ({
  isOpen,
  closeNotification,
  type,
  platformName,
}: IPaymentRequiredPoppup) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    closeNotification && closeNotification();
  }, [closeNotification]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description-pay"
      classes={{
        paper: 'notification-modal-pay',
      }}
    >
      {/*<div className="close-btn" onClick={handleClose}>*/}
      {/*  <img src={closeModalIcon} />*/}
      {/*</div>*/}
      <DialogContent>
        <img className='pay-modal-icon' src={errorBigIcon} loading="lazy"/>
        <div id="alert-dialog-description-pay">
          <DialogTitle id="alert-dialog-title">
            {
              type === EPaymentRequiredType.TRIAL
                ? translate('notifications.subscription.subscribe_buy_trial')
                : translate('notifications.subscription.subscribe_buy')
            }
          </DialogTitle>
        </div>
        <div className='alert-dialog-description-text'>
          {type === EPaymentRequiredType.TRIAL ?
            translate('notifications.subscription.subscribe_buy_text_trial') :
            translate('notifications.subscription.subscribe_buy_text')
          }
        </div>
        <div className="action-buttons">
          <Button onClick={() => {
            handleClose();
            localStorage.setItem('checkout-platform', platformName.toLowerCase())
            navigate('/checkout');
          }
          } variant="contained" color="primary">
            {translate('notifications.choices.buy_subscription_now')}
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            {translate('notifications.choices.close')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentRequiredPoppup;
