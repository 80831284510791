enum EPlatform {
  fetchPlatformUsers = 'platform/get-platform-users',
  fetchPlatformPlaces = 'platform/get-platform-places',
  addPlatformUser = 'platform/add-platform-user',
  addPlatformToken = 'platform/add-platform-token',
  sendConfirmPlatformUser = 'platform/send-confirm-platform-user',
  confirmPlatformUser = 'platform/confirm-platform-user',
  editPlatformUser = 'platform/edit-platform-user',
  getAvailableItems = 'platform/get-available-items',
  getAvailableItemsSec = 'platform/get-available-items-sec',
  fetchUserItems = 'platform/get-user-items',
  fetchUserItemsSec = 'platform/get-user-items-sec',
  fetchUserWorkspace = 'platform/get-user-workspace',
  fetchRestoreItems = 'platform/get-restore-items',
  fetchAvailableTrelloOrganizations = 'platform/get-available-trello-organizations',
  fetchPlacesForGithubRestore = 'platform/get-available-github-places',
  addItem = 'platform/add-item',
  addItemGist = 'platform/add-item-gist',
  editItem = 'platform/edit-item',
  deleteItem = 'platform/delete-item',
  backupItem = 'platform/backup-item',
  cloudUrl = 'platform/cloud-url',
  cloudUrlRemove = 'platform/cloud-url-remove',
  cloudData = 'platform/cloud-data',
  backupAll = 'platform/backup-item-all',
  backupAllSec = 'platform/backup-item-all-gist',
  resetPlatform = 'platform/reset',
  updatePlatform = 'platform/update',
  dailyStatus = 'daily-status',
  billingAddress = 'billing-address',
  storeLoc = 'store-location',
  billingAddressAndItems = 'billing-address-items',
  checkUserTrial = 'user/get-trial-data',
  startTrial = 'user/start-trial',
  pingPaymentStatus = 'user/ping-payment-status',
  cancelSubscription = 'user/stripe-cancel-subscription',
  createStripeSubscription = 'user/stripe-create-subscription',
  getAllSubscriptions = 'user/get-all-subscriptions',
  getStatisticsData = 'user/get-statistics-data',
  getItemsBackups = 'user/get-items-backups',
  getInvoiceInfo = 'user/invoice-info',
  getTaxInfo = 'user/tax-info',
  getPrice = 'user/price',
  getCoupon = 'payments/stripe-validate-promotion-code',
  getUserActions = 'user/actions',
  getUserAmount = 'user/amount'
}

export default EPlatform;
