import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EPlatformsNames, EStripeSubStatus, ETrialStatus } from '../../../models/consts';
import { EGitlabPlanNames } from '../../../models/paymentPlans/gitlab-payments-plan';
import { ETrelloPlanNames } from '../../../models/paymentPlans/trello-payments-plan';
import { IPlatformSubscriptionRow } from '../../../views/UserPages/Subscription/Subscription';
import { PlatformsState } from '../../reducers/platform';

export const getGithubReposSelector = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.items);
export const getGithubTotal = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.totalItems);

export const getGithubCanUserBackup = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.canUserBackup);

export const getGithubAutoAddSelector = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.autoAdd);

export const getGithubAppInstallSelector = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.hasUserAnyActiveApp);

export const getGithubTokenValidSelector = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.isTokenValid);

export const isGithubRepoLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.itemsLoading);

export const isGithubUsersLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.usersLoading);

export const getGithubUsersSelector = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.users);

export const githubTrialStatus = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.trial?.trialStatus);
export const isGithubConnect = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.isConnectionActive);

export const githubTrialHoursLeft = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => {
  return github.trial?.trialHoursLeft || null;
});

export const githubTrialQuantityLeft = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => {
  return github.trial?.availableItemsQuantity || 0;
});

export const githubTrialPlanQuantity = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => {
  return github.trial?.planQuantity || 0;
});

export const githubSubInfo = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { subscription } = github;
  if (subscription) {
    return {
      id: 1,
      platformName: EPlatformsNames.GITHUB,
      plan: subscription?.planName || '',
      price: `$${subscription?.cost.toFixed(2)} / ${subscription?.isAnnual ? 'yr.' : 'mo.'}`,
      status: subscription?.status,
      quantity: subscription.quantity,
      planQuantity: subscription.planQuantity,
      addedItemsQuantity: subscription.addedItemsQuantity,
      isCancel: subscription.isCancel,
      isConnect: false
    };
  }
  return null;
});

export const githubTrialInfo = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { trial } = github;
  if (trial) {
    return {
      id: 2,
      platformName: EPlatformsNames.GITHUB,
      plan: ETrelloPlanNames.TRIAL,
      price: `-`,
      status: trial.trialStatus,
      quantity: 0,
      planQuantity: 0,
      addedItemsQuantity: 0,
      isCancel: false,
      isConnect: false
    };
  }
  return null;
});

export const githubSubscription = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => github.subscription);

export const githubTrialData = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => {
  return github.trial
});

export const githubActualPlan = createSelector((state: DefaultRootState) => state.platform, ({ github }: PlatformsState) => {
  const { subscription: githubSub, trial: githubTrial, isConnectionActive } = github;

  const githubItem: IPlatformSubscriptionRow = {
    id: 2,
    platformName: EPlatformsNames.GITHUB,
    plan: '-',
    price: '-',
    quantity: 0,
    planQuantity: 0,
    addedItemsQuantity: 0,
    finishDate: null,
    finishDateInMillisecond: null,
    status: EStripeSubStatus.INACTIVE,
    isCancel: false,
    isConnect: isConnectionActive
  };
  if (githubTrial && githubTrial.trialStatus === ETrialStatus.NOT_STARTED) {
    githubItem.plan = EGitlabPlanNames.TRIAL;
    githubItem.status = githubTrial.trialStatus;
    githubItem.quantity = githubTrial.availableItemsQuantity || 0;
    githubItem.addedItemsQuantity = githubTrial.addedItemsQuantity || 0;
    githubItem.planQuantity = githubTrial.planQuantity || 0;
    githubItem.finishDate = githubTrial.finishDate || null;
    githubItem.isConnect = isConnectionActive
  }

  if (githubTrial && githubTrial.trialStatus === ETrialStatus.STARTED) {
    githubItem.plan = EGitlabPlanNames.TRIAL;
    githubItem.status = githubTrial.trialStatus;
    githubItem.quantity = githubTrial.availableItemsQuantity || 0;
    githubItem.addedItemsQuantity = githubTrial.addedItemsQuantity || 0;
    githubItem.planQuantity = githubTrial.planQuantity || 0;
    githubItem.finishDate = githubTrial.finishDate || null;
    githubItem.isConnect = isConnectionActive
  }

  if (githubSub && (!githubTrial || githubTrial?.trialStatus !== ETrialStatus.STARTED || githubSub?.status === EStripeSubStatus.ACTIVE)) {
    githubItem.plan = githubSub.planName || '';
    githubItem.status = githubSub.status;
    githubItem.finishDate = githubSub.finishDate || null;
    githubItem.quantity = githubSub.quantity || 0;
    githubItem.addedItemsQuantity = githubSub.addedItemsQuantity || 0;
    githubItem.planQuantity = githubSub.planQuantity || 0;
    githubItem.price = `$${githubSub.cost.toFixed(2)} / ${githubSub.isAnnual ? 'yr.' : 'mo.'}`;
    githubItem.isCancel = githubSub.isCancel;
    githubItem.isConnect = isConnectionActive;
    githubItem.finishDateInMillisecond = githubSub.finishDateInMillisecond;
  }

  return githubItem;
});
