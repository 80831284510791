import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import { UserState } from '../reducers/user';

export const getUserSelector = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user);

export const getIsTestUser = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user?.is_test);
export const isAuthorized = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => !!user);
export const underWork = createSelector((state: DefaultRootState) => state.user, ({ underWork }: UserState) => underWork);
export const isHasProPlan = createSelector((state: DefaultRootState) => state.user, ({ isHasProPlan }: UserState) => isHasProPlan);
export const loadingUser = createSelector((state: DefaultRootState) => state.user, ({ loading }: UserState) => loading);
export const loadedUser = createSelector((state: DefaultRootState) => state.user, ({ loading }: UserState) => !loading);
export const stripePaymentMethod = createSelector((state: DefaultRootState) => state.user, ({ stripePayment }: UserState) => stripePayment || undefined);
export const paymentHistorySelector = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user?.paymentHistory);
export const connectedExtStorage = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user?.connectedExtStorage);
export const userOrganizationName = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user?.organization_name);
export const userRoles = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user?.roles);
export const isSubUser = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user?.isSubUser);
export const roleByApp = createSelector((state: DefaultRootState) => state.user, ({ user }: UserState) => user?.roleByApp);
