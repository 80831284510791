import {Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, InputAdornment} from '@mui/material';
import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import {
  CheckboxInputChecked,
  CheckboxInputEmpty,
  closeModalIcon,
  errorBigIcon,
  searchIcon,
  toastSuccess,
} from '../../../../../assets/icons/icons';
import {Button} from '../../../../../components/StyledComponents';
import {IError, IGithubAddOrg} from '../../../../../models/inner-models';
import {ISetterUserWithItems} from '../../../../../models/models';
import {fetchGithubUsers, getGithubPlaces, setGithubPlaces,} from '../../../../../store/actions';
import {useAppDispatch} from '../../../../../store/hooks';
import {SearchInput} from '../../../../../styles/components/MainInputElements';
import {decoratePopUpMessage} from '../../../../../utils/popUpTextDecorator';
import './style.scss';

const GithubAddOrg: FunctionComponent<IGithubAddOrg> = ({
                                                             isOpen,
                                                             closeNotification,
                                                             user,
                                                          openOrgInfo
                                                           }: IGithubAddOrg) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { reset } = useForm<ISetterUserWithItems>({
    mode: 'onChange',
  });

  const [availableOrgs, setAvailableOrgs] = useState<Array<Record<string, string>>>([]);
  const [checkedOrgs, setCheckedOrgs] = useState<Array<number>>([]);
  const [filteredOrgs, setFilteredOrgs] = useState<Array<Record<string, string | number>>>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [availableOrgsLoading, setAvailableOrgsLoading] = useState<boolean>(true);
  const [addingOrgsProcessing, setAddingOrgsProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (user?.githubapp) {
      setAvailableOrgsLoading(true);
      setSearch('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (!user) {
      return;
    }

    // setAutoAdd(user?.auto_add as boolean);
  }, [user]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const searchStr = searchTerm.trim().toLowerCase();
    if (searchStr === '') {
      setFilteredOrgs(availableOrgs || []);
    } else {
      availableOrgs && setFilteredOrgs(availableOrgs.filter((repo) => repo?.name.toLowerCase().includes(searchStr) || repo?.type.toLowerCase().includes(searchStr)));
    }
  }, [searchTerm]);

  useEffect(() => {
    // if (!user || !user.githubapp) {
    //   return;
    // }

    async function getAvailableOrg() {
      try {
        // dispatch(getAllSubscriptions()).unwrap();
        const repos = await dispatch(getGithubPlaces()).unwrap();

        if (!repos || !repos.notAddedPlaces?.length) {
          setAvailableOrgs([]);
          setAvailableOrgsLoading(false);
          return;
        }

        setAvailableOrgs(repos.notAddedPlaces || []);
        setFilteredOrgs(repos.notAddedPlaces || []);

        setAvailableOrgsLoading(false);
      } catch (err) {
        console.log(err);
        setAvailableOrgsLoading(false);
      }
    }

    if (isOpen) {
      getAvailableOrg();
    }
  }, [isOpen]);


  const handleChange = (orgId) => {
    if (checkedOrgs.includes(orgId)) {
      setCheckedOrgs(checkedOrgs.filter((ell) => ell !== orgId));
    } else {
      setCheckedOrgs([...checkedOrgs, orgId]);
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    setCheckedOrgs(checked ? filteredOrgs.map(item => Number(item.id)) : []);
  };

  const onSubmit = async () => {
    const adaptedData = {
      placesIds: checkedOrgs.map(item => {
        return {id: Number(item)}
      })
    };

    setAddingOrgsProcessing(true);
    try {
      await dispatch(setGithubPlaces(adaptedData)).unwrap();
      dispatch(fetchGithubUsers()).unwrap();
      setAddingOrgsProcessing(false);
      handleClose();

      Swal.fire({
        title: translate('notifications.titles.success'),
        text: translate('notifications.github_repos.successfully_added_org'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError
      handleClose();
      setAddingOrgsProcessing(false);
      console.log(err);
      dispatch(fetchGithubUsers()).unwrap()

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const handleClose = useCallback(() => {
    setCheckedOrgs([]);
    reset();
    closeNotification && closeNotification();
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="github-repo-edit-title"
        aria-describedby="github-repo-edit-description"
        classes={{
          paper: 'add-edit-github-modal',
        }}
      >
        <DialogTitle id="github-repo-edit-title">
          {translate('forms.github_add_edit_repo.add_new_org')}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        <DialogContent>
          <div id="github-repo-edit-description">
              <div className="username-block">
                <div className='github-add-select-all'>
                  <div className='title-line'>
                    Organization
                  </div>
                  <div className='desc-line'>
                    {/*{user?.username &&*/}
                      <>
                        A list of all your organisations. If you don’t see your organisation in the list, please remove the restriction for third-party applications following the <a onClick={openOrgInfo} className='add-org-open-info'>instructions</a>
                        <br/>
                      </>
                  </div>
                </div>
                {!!availableOrgs.length && (
                  <div className="search-block">
                    <SearchInput
                      className="search-field"
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={onSearch}
                      endAdornment={
                        <InputAdornment position="end" className="search-icon">
                          <img src={searchIcon} loading="lazy"/>
                        </InputAdornment>
                      }
                    />
                  </div>
                )}
              </div>

              {availableOrgsLoading && user?.githubapp &&
                <div className="spinner-wrapper">
                  <CircularProgress />
                </div>
              }
              {!availableOrgsLoading &&
                <div className="scrollable-div">
                  {!!filteredOrgs.length && (
                    <div className="form-row head-row">
                      <Checkbox
                        className={'checkbox'}
                        checked={availableOrgs.length === checkedOrgs.length}
                        // disabled={(availableItemsLeft - checkedRepos.length) <= 0}
                        name="repoName"
                        onChange={(e) => {
                          handleSelectAllChange(e.target.checked);
                        }}
                        icon={<CheckboxInputEmpty/>}
                        checkedIcon={<CheckboxInputChecked />}
                      />
                      Name
                    </div>
                  )}
                  {!!filteredOrgs.length && !availableOrgsLoading ?
                    filteredOrgs.map(
                      (org, index) =>
                        <div className="form-row" key={`${org}-${index}`}>
                          <label key={org.name} className="input-label-checkbox">
                            <Checkbox
                              className={'checkbox'}
                              value={org.id}
                              checked={checkedOrgs.includes(org.id as number)}
                              name="repoName"
                              onChange={() => {
                                handleChange(org.id);
                              }}
                              icon={<CheckboxInputEmpty/>}
                              checkedIcon={<CheckboxInputChecked />}
                            />
                            <span className='item-org-type'>{org.type}</span>
                            {org.name}
                          </label>
                        </div>,
                    ) :
                    <div className="form-row with-overflow">
                      {user && !!searchTerm?.length ?
                        translate('notifications.github_repos.no_search_org') :
                        translate('notifications.github_repos.nothing_to_add_org')
                      }
                    </div>
                  }
                </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.cancel')}
            </Button>
            {!!availableOrgs.length && (
              <Button
                onClick={() => onSubmit()}
                variant="contained"
                color="primary"
                disabled={!checkedOrgs.length || addingOrgsProcessing}
              >
                {addingOrgsProcessing &&
                  <div className="small-spinner-wrapper">
                    <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                  </div>
                }
                {addingOrgsProcessing
                  ? translate('notifications.choices.processing')
                  : checkedOrgs.length > 1 ?
                    translate('forms.github_add_edit_repo.add_org_num', {num: checkedOrgs.length}) :
                    translate('forms.github_add_edit_repo.add_place')}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(GithubAddOrg);
