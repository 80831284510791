import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EPlatformsNames, EStripeSubStatus, ETrialStatus } from '../../../models/consts';
import { EGitlabPlanNames } from '../../../models/paymentPlans/gitlab-payments-plan';
import { ENotionPlanNames } from '../../../models/paymentPlans/notion-payments-plan';
import { IPlatformSubscriptionRow } from '../../../views/UserPages/Subscription/Subscription';
import { PlatformsState } from '../../reducers/platform';

export const getNotionWorkspaceSelector = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => notion.items);

export const getNotionCanUserBackup = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => notion.canUserBackup);

export const isNotionWorkspaceLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => notion.itemsLoading);

export const isNotionUsersLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => notion.usersLoading);

export const getNotionUsersSelector = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => {
  return notion.users
});
export const isNotionConnect = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => notion.isConnectionActive);

export const notionTrialStatus = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => notion.trial?.trialStatus);

export const getNotionTotal = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => notion.totalItems);

export const notionTrialHoursLeft = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => {
  return notion.trial?.trialHoursLeft || 0;
});

export const notionTrialQuantityLeft = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => {
  return notion.trial?.availableItemsQuantity || 0;
});

export const notionTrialPlanQuantity = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => {
  return notion.trial?.planQuantity || 0;
});

export const notionSubInfo = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { subscription } = notion;
  if (subscription) {
    return {
      id: 3,
      platformName: EPlatformsNames.NOTION,
      plan: subscription?.planName || '',
      price: `$${subscription?.isAnnual 
        ? (subscription.cost * 12).toFixed(2) + ' / yr.' : subscription.cost.toFixed(2) + ' / mo.'}`,
      status: subscription?.status,
      quantity: subscription.quantity,
      planQuantity: subscription.planQuantity,
      addedItemsQuantity: subscription.addedItemsQuantity,
      isCancel: subscription.isCancel,
      isConnect: false
    };
  }
  return null;
});

export const notionTrialInfo = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { trial } = notion;
  if (trial) {
    return {
      id: 4,
      platformName: EPlatformsNames.NOTION,
      plan: ENotionPlanNames.TRIAL,
      price: `-`,
      status: trial.trialStatus,
      quantity: 0,
      planQuantity: 0,
      addedItemsQuantity: 0,
      isCancel: false,
      isConnect: false
    };
  }
  return null;
});

export const notionSubscription = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => {
  return notion.subscription
});

export const notionTrialData = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => {
  return notion.trial
});

export const notionActualPlan = createSelector((state: DefaultRootState) => state.platform, ({ notion }: PlatformsState) => {
  const { subscription: notionSub, trial: notionTrial, isConnectionActive } = notion;

  const notionItem: IPlatformSubscriptionRow = {
    id: 4,
    platformName: EPlatformsNames.NOTION,
    plan: '-',
    price: '-',
    quantity: 0,
    planQuantity: 0,
    addedItemsQuantity: 0,
    status: EStripeSubStatus.INACTIVE,
    isCancel: false,
    finishDateInMillisecond: null,
    isConnect: isConnectionActive
  };

  if (notionTrial && notionTrial.trialStatus === ETrialStatus.NOT_STARTED) {
    notionItem.plan = EGitlabPlanNames.TRIAL;
    notionItem.status = notionTrial.trialStatus;
    notionItem.quantity = notionTrial.availableItemsQuantity || 0;
    notionItem.addedItemsQuantity = notionTrial.addedItemsQuantity || 0;
    notionItem.planQuantity = notionTrial.planQuantity || 0;
    notionItem.finishDate = notionTrial.finishDate || null;
    notionItem.isConnect = isConnectionActive
  }

  if (notionTrial && notionTrial.trialStatus === ETrialStatus.STARTED) {
    notionItem.plan = EGitlabPlanNames.TRIAL;
    notionItem.status = notionTrial.trialStatus;
    notionItem.quantity = notionTrial.availableItemsQuantity || 0;
    notionItem.addedItemsQuantity = notionTrial.addedItemsQuantity || 0;
    notionItem.planQuantity = notionTrial.planQuantity || 0;
    notionItem.finishDate = notionTrial.finishDate || null;
    notionItem.isConnect = isConnectionActive
  }

  if (notionSub && (!notionTrial || notionTrial?.trialStatus !== ETrialStatus.STARTED || notionSub?.status === EStripeSubStatus.ACTIVE)) {
    notionItem.plan = notionSub.planName || '';
    notionItem.status = notionSub.status;
    notionItem.finishDate = notionSub.finishDate || null;
    notionItem.quantity = notionSub.quantity || 0;
    notionItem.addedItemsQuantity = notionSub.addedItemsQuantity || 0;
    notionItem.planQuantity = notionSub.planQuantity || 0;
    notionItem.price = `$${notionSub.cost.toFixed(2)} / ${notionSub.isAnnual ? 'yr.' : 'mo.'}`
    notionItem.isCancel = notionSub.isCancel;
    notionItem.finishDateInMillisecond = notionSub.finishDateInMillisecond;
    notionItem.isConnect = isConnectionActive
  }

  return notionItem
});
