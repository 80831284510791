import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import '../style.scss';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { closeModalIcon } from '../../../../../assets/icons/icons';
import { Button } from '../../../../../components/StyledComponents';
import {IPoppupBase} from "../../../../../models/inner-models";

const GithubOrgAccInfoDialog: FunctionComponent<IPoppupBase> = ({isOpen, closeNotification}: IPoppupBase) => {
  const { t: translate } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(`/platform/dashboard/github`, { replace: true });

    searchParams.delete('isAppJustAdded');
    if (closeNotification) {
      closeNotification();
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="github-org-title"
      aria-describedby="github-org-info"
      classes={{
        paper: 'notification-modal github-org',
      }}
    >
      <div className="close-btn" onClick={handleClose}>
        <img src={closeModalIcon} loading="lazy"/>
      </div>
      <DialogTitle id="github-org-title">
        {translate('notifications.github_org_info.title')}
      </DialogTitle>
      <DialogContent>
        <div id="github-org-info">
          <ol>
            <li>{translate('notifications.github_org_info.step1')}</li>
            <img src='https://bl-public-media.s3.amazonaws.com/github/your-organizations.png' loading="lazy"/>
            <li>{translate('notifications.github_org_info.step2')}</li>
            <img src='https://bl-public-media.s3.amazonaws.com/github/settings-button.png' loading="lazy"/>
            <li>
              {translate('notifications.github_org_info.step3')}
              <strong>
                <svg version="1.1" width="16" height="16" viewBox="0 0 16 16" className="octicon octicon-apps"
                     aria-hidden="true">
                  <path
                    d="M1.5 3.25c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5A1.75 1.75 0 0 1 5.75 7.5h-2.5A1.75 1.75 0 0 1 1.5 5.75Zm7 0c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5a1.75 1.75 0 0 1-1.75 1.75h-2.5A1.75 1.75 0 0 1 8.5 5.75Zm-7 7c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5a1.75 1.75 0 0 1-1.75 1.75h-2.5a1.75 1.75 0 0 1-1.75-1.75Zm7 0c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5a1.75 1.75 0 0 1-1.75 1.75h-2.5a1.75 1.75 0 0 1-1.75-1.75ZM3.25 3a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h2.5A.25.25 0 0 0 6 5.75v-2.5A.25.25 0 0 0 5.75 3Zm7 0a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h2.5a.25.25 0 0 0 .25-.25v-2.5a.25.25 0 0 0-.25-.25Zm-7 7a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h2.5a.25.25 0 0 0 .25-.25v-2.5a.25.25 0 0 0-.25-.25Zm7 0a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h2.5a.25.25 0 0 0 .25-.25v-2.5a.25.25 0 0 0-.25-.25Z"
                  ></path>
                </svg>
                {translate('notifications.github_org_info.step31')}
              </strong>
              </li>
            <li>
              {translate('notifications.github_org_info.step4')}
              <strong>{translate('notifications.github_org_info.step41')}</strong>
            </li>
            {/*<img src='https://bl-public-media.s3.amazonaws.com/github/settings-third-party-remove-restrictions.png'/>*/}
            <li>
              {translate('notifications.github_org_info.step5')}
              <strong>{translate('notifications.github_org_info.step51')}</strong>
            </li>
            {/*<img src='https://bl-public-media.s3.amazonaws.com/github/settings-third-party-confirm-disable.png'/>*/}
          </ol>
        </div>
      </DialogContent>

        <div className="action-buttons">
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained" color="primary"
          >
            I understand
          </Button>
        </div>
    </Dialog>
  );
};

export default GithubOrgAccInfoDialog;
