import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { noBackups } from '../../../assets/images/other';
import './style.scss';


const NoBackupsBlock: FunctionComponent = () => {
  const { t: translate } = useTranslation();

  return (
    <div className="no-backup">
      <img src={noBackups} loading="lazy"/>
      {/*<div className="subtitle">{translate('views.platform_pages.no_backups')}</div>*/}
      <div className="subtitle">{translate('views.platform_pages.backups_soon')}</div>
    </div>
  );
};

export default (NoBackupsBlock);
