import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomTooltip} from "./CustomTooltip";
const env = process.env.NODE_ENV;
const MINUTES_IN_HOUR = 60
const SECONDS_IN_MINUTE = 60
const MILLISECONDS_IN_SECOND = 1000
const  BACKUP_ALL_ALLOWED_INTERVAL_HOURS = {
  default: 24,
  development:10/60, // 10 minutes
  production:24
}
let endTimeInMillisecondsCash: null | number = null

const CustomTimerTooltip: React.FunctionComponent<{ children: React.ReactNode, setUserCanBackup:()=>void }> = ({ children, setUserCanBackup})=> {
  const {t: translate} = useTranslation();
  const [hoursRemaining, setHoursRemaining] = useState<number | null>(null);
  const [minutesRemaining, setMinutesRemaining] = useState<number | null>(null);
  const intervalHours = BACKUP_ALL_ALLOWED_INTERVAL_HOURS[env] || BACKUP_ALL_ALLOWED_INTERVAL_HOURS.default


  useEffect(() => {
    function calculateTimeUntilNextDay() {
      const currentDateInMilliseconds = Date.now()
      const intervalInMilliseconds = intervalHours * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
      const currentIntervalStartInMilliseconds = Math.floor(currentDateInMilliseconds / intervalInMilliseconds) * intervalInMilliseconds;
      const currentIntervalEndInMilliseconds = currentIntervalStartInMilliseconds + intervalInMilliseconds;
      const hoursInUTC = (new Date(currentIntervalEndInMilliseconds - currentDateInMilliseconds)).getUTCHours()

      if(!endTimeInMillisecondsCash){
        endTimeInMillisecondsCash = currentIntervalEndInMilliseconds
      }

      if(hoursInUTC < 1){
        const endIntervalDate = new Date(currentIntervalEndInMilliseconds  - currentDateInMilliseconds)
        const endIntervalMinutes = endIntervalDate.getUTCMinutes() + 1

        setMinutesRemaining(endIntervalMinutes);

        if(currentIntervalStartInMilliseconds >= endTimeInMillisecondsCash){
          setUserCanBackup()
          endTimeInMillisecondsCash = null
        }

      } else{
        setHoursRemaining(hoursInUTC);
      }
    }
    calculateTimeUntilNextDay();

    const currentSeconds = (new Date()).getSeconds();
    const secondsUntilNextMinute = 60 - currentSeconds;
    const millisecondsUntilNextMinute = secondsUntilNextMinute * 1000;
    const intervalId = setInterval(calculateTimeUntilNextDay, millisecondsUntilNextMinute);
    return () => clearInterval(intervalId);
  }, []);

  return(
    <CustomTooltip title={`${translate('views.platform_pages.backups_disabled_per_day')} ${hoursRemaining&&hoursRemaining > 0 ? `${hoursRemaining} ${hoursRemaining === 1 ? 'hour' : 'hours'}`: `${minutesRemaining} ${minutesRemaining === 1 ? 'minute' : 'minutes'}` }`}>
      <div>
      {children}
      </div>
    </CustomTooltip>
  )
}
export default CustomTimerTooltip
