import {
  Checkbox,
  CircularProgress, debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment
} from '@mui/material';
import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  closeModalIcon,
  errorBigIcon,
  searchIcon,
  warningBigIcon, CheckboxInputEmpty, CheckboxInputChecked, iconNotifyWarningInfo, toastSuccess,
} from '../../../../../assets/icons/icons';
import PaymentRequiredPoppup from '../../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup';
import {Button} from '../../../../../components/StyledComponents';
import { EApiStatuses } from '../../../../../constants';
import { EPaymentRequiredType, EPlatformsNames } from '../../../../../models/consts';
import { IError, IGithubAddProps, IPaymentRequiredPoppup } from '../../../../../models/inner-models';
import { ISetterUserWithItems } from '../../../../../models/models';
import {
  addGithubItems,
  getAllSubscriptions,
  getAvailableItemsForGithubUser
} from '../../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../../store/hooks';
import { SearchInput } from '../../../../../styles/components/MainInputElements';
import { decoratePopUpMessage } from '../../../../../utils/popUpTextDecorator';
import './style.scss';
import {BadgesTrial} from "../../../../../components/Badges/Badges";
import {isSubUser} from "../../../../../store/selectors";
let abortController: AbortController | null

const GithubRepoAdd: FunctionComponent<IGithubAddProps> = ({
  isOpen,
  closeNotification,
  user,
  platformName,
  paymentType,
  availableItemsLeft,
  subPlan, updItems,
  isAppInstall
}: IGithubAddProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { handleSubmit, setValue, trigger, reset } = useForm<ISetterUserWithItems>({
    mode: 'onChange',
  });
  const isUserSub = useAppSelector(isSubUser);

  const [availableRepos, setAvailableRepos] = useState<Array<Record<string, string>>>([]);
  const [storeRepos, setStoreRepos] = useState<Array<Record<string, string>>>([]);
  const [checkedRepos, setCheckedRepos] = useState<Array<string>>([]);
  const [filteredRepos, setFilteredRepos] = useState<Array<Record<string, string>>>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [availableReposLoading, setAvailableReposLoading] = useState<boolean>(true);
  const [addingReposProcessing, setAddingReposProcessing] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  // const [autoAdd, setAutoAdd] = useState<boolean>(false);

  const [paymentPopup, setPaymentPopup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName || EPlatformsNames.GITHUB,
    type: paymentType || EPaymentRequiredType.NON,
  });
  const [scroll, setScroll] = useState<boolean>(false);
  const [netSearch, setNetSearch] = useState<boolean>(false);
  const step = (availableItemsLeft >= 20 && availableItemsLeft <= 100) ? availableItemsLeft : (availableItemsLeft > 100) ? 100 : 20;
  // const step = 10

  async function getAvailableRepos(name?:string) {
    try {
      const payload = localStorage['githubUser'] as number
      abortController = new AbortController();
      const { signal } = abortController;
      const {repos, lastPage} = await dispatch(getAvailableItemsForGithubUser({page: page, perPage: step, placeId: payload, signal, name})).unwrap();

      if(signal.aborted){
        return
      }

      if (!repos || !repos.length) {
        setAvailableRepos([]);
        // setStoreRepos([]);
        // setFilteredRepos([]);
        setAvailableReposLoading(false);
        setScroll(false);
        setNetSearch(false);
        return;
      }

      setAvailableRepos(repos);
      setPage(lastPage)
      setStoreRepos(prevState => prevState.concat(repos));
      setFilteredRepos(prevState => prevState.concat(repos));
      setAvailableReposLoading(false);
      setScroll(false);
      setNetSearch(false);
    } catch (err) {
      console.log(err);
      setAvailableReposLoading(false);
      setScroll(false);
      setNetSearch(false);
    }
  }

  const handleScroll = (e) => {
    if (!scroll && availableRepos.length >= step) {
      const element = e.target
      if (!element) return;

      const viewHeight = element.offsetHeight;
      const fullHeight = element.scrollHeight;
      const scrollingLength = element.scrollTop;
      if (fullHeight <= viewHeight + scrollingLength + 150) {
        setScroll(true);
        getAvailableRepos()
      }
    }
  }

  // useEffect(() => {
  //   if (!user) {
  //     return;
  //   }
  //
  //   setAutoAdd(user?.auto_add as boolean);
  // }, [user]);

  const debouncedSearch = useCallback(
    debounce((event) => {
      setAvailableReposLoading(true)
      setAvailableRepos([]);
      setStoreRepos([]);
      setFilteredRepos([]);
      getAvailableRepos(event)
    }, 1000),
    [],
  );

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value || ''
    setSearch(val);
    setNetSearch(true)
    debouncedSearch(val)
  };

  useEffect(() => {
    if (!user || !isAppInstall) {
      return;
    }

    if (isAppInstall) {
      setAvailableReposLoading(true);
      setSearch('');
    }

    if (isOpen) {
      dispatch(getAllSubscriptions()).unwrap();
      getAvailableRepos();
    } else if (!isOpen) {
      setAvailableRepos([]);
      setStoreRepos([]);
      setFilteredRepos([]);
    }
  }, [isOpen]);

  const openPayModal = () => {
    Swal.fire({
      title: translate('notifications.subscription.buy_plan_title'),
      text: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.subscription.buy_plan') :
        translate('notifications.subscription.upgrade_plan'),
      imageUrl: iconNotifyWarningInfo,
      cancelButtonText: translate('notifications.choices.nevermind'),
      confirmButtonText: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.choices.buy_subscription') :
        translate('notifications.choices.upgradeSub'),
      showCancelButton: true,
    })
      .then((res) => {
        if (res.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        }
      })
  }

  const handleChange = (repoName) => {
    if (checkedRepos.includes(repoName)) {
      setCheckedRepos(checkedRepos.filter((ell) => ell !== repoName));
    } else if ((availableItemsLeft - checkedRepos.length) > -1 && availableItemsLeft > 0) {
      setCheckedRepos([...checkedRepos, repoName]);
    } else if (!isUserSub && ((availableItemsLeft - checkedRepos.length) <= -1 || availableItemsLeft === 0)){
      openPayModal()
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    if (availableItemsLeft === 0 || checkedRepos.length - 1 === availableItemsLeft){
      Swal.fire({
        title: translate('notifications.subscription.buy_plan_title'),
        text: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.subscription.buy_plan') :
          translate('notifications.subscription.upgrade_plan'),
        imageUrl: iconNotifyWarningInfo,
        cancelButtonText: translate('notifications.choices.nevermind'),
        confirmButtonText: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.choices.buy_subscription') :
          translate('notifications.choices.upgradeSub'),
        showCancelButton: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName || '')
            navigate('/checkout');
          }
        })
    } else {
      setCheckedRepos((prevState) => {
        if (checked && prevState.length <= availableItemsLeft) {
          const newArr = filteredRepos?.map(i => i.id).filter(id => !prevState.includes(id))?.slice(0, availableItemsLeft - prevState.length) || []
          return [...prevState, ...newArr]
        } else {
          return []
        }
      })
    }
  };

  useEffect(() => {
    setValue('items', checkedRepos);
    trigger('items');
  }, [checkedRepos]);

  const onSubmit: SubmitHandler<ISetterUserWithItems> = async () => {
    const adaptedData = {
      placeId: Number(localStorage['githubUser']),
      reposIds: checkedRepos.map(item => {
        return {
          id: item,
          node_id: storeRepos.find(i=>i.id === item)?.node_id || ''
        }
      })
    };
    if ((availableItemsLeft - checkedRepos.length) < 0){
      if (!isUserSub) {
        openPayModal()
      }
      return
    }
    setAddingReposProcessing(true);
    try {
      await dispatch(addGithubItems(adaptedData)).unwrap();
      await updItems();
      if ((availableItemsLeft - checkedRepos.length) === 0) {
        dispatch(getAllSubscriptions()).unwrap()
      }
      setAddingReposProcessing(false);
      handleClose();

      Swal.fire({
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
        title: translate('notifications.titles.success'),
        text: checkedRepos.length > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup')
      });
    } catch (err) {
      const error = err as IError

      handleClose();
      setAddingReposProcessing(false);
      console.log(err);

      if (error.status === EApiStatuses.WARNING) {
        const userChoice = await Swal.fire({
          title: translate('notifications.titles.warning'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: warningBigIcon,
          confirmButtonText: translate('subscriptions.edit_sub'),
        });

        if (userChoice.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        } else {
          await updItems();
        }

        return;
      }

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const openTrialModal = useCallback(() => {
    setPaymentPopup((prev) => ({
      ...prev,
      isOpen: true,
    }));
  }, [paymentType]);

  const handleClose = useCallback(() => {
    setCheckedRepos([]);
    setPage(0)
    reset();
    closeNotification && closeNotification();
    abortController && abortController.abort();
    abortController = null
  }, []);

  const closeTrialPopup = useCallback(() => {
    setPaymentPopup({ ...paymentPopup, isOpen: false });
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="github-repo-edit-title"
        aria-describedby="github-repo-edit-description"
        classes={{
          paper: 'add-edit-github-modal',
        }}
      >
        <DialogTitle id="github-repo-edit-title">
          {translate('forms.github_add_edit_repo.add_new_repo')}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        {/*<div className="repos-left-block">*/}
        {/*  {availableItemsLeft - checkedRepos.length > 0*/}
        {/*    ? translate('forms.github_add_edit_repo.repos_left', { left: availableItemsLeft - checkedRepos.length })*/}
        {/*    : translate('forms.github_add_edit_repo.no_backups_left')*/}
        {/*  }*/}
        {/*</div>*/}
        <DialogContent>
          {(subPlan && paymentType === EPaymentRequiredType.NON && (availableItemsLeft - checkedRepos.length < 0 || availableItemsLeft === 0)) && (
            <BadgesTrial platformName={'github'} color='warning' isSub={subPlan.name !== "Trial"} isModal={true}/>
          )}
          <div id="github-repo-edit-description">
              <div className="username-block">
                <div className='github-add-select-all'>
                  <div className='title-line'>
                    {translate('views.bulk_restore.itemName.github.many')}
                  </div>
                  <div className='desc-line'>
                    {user?.username &&
                      <>
                        {`A list of all the repositories for ${user.userType === 'Organization' ? 'organization' : 'user'} “${user?.username}”.`}<br/>
                      </>
                    }
                    {availableItemsLeft - checkedRepos.length > 0
                      ? translate('forms.github_add_edit_repo.repos_left', { left: availableItemsLeft - checkedRepos.length })
                      : translate('forms.github_add_edit_repo.no_backups_left')
                    }
                  </div>
                </div>
                {(!!storeRepos.length && !availableReposLoading || searchTerm || netSearch) && (
                  <div className="search-block">
                    <SearchInput
                      className="search-field"
                      type="text"
                      placeholder="Search repositories..."
                      value={searchTerm}
                      onChange={onSearch}
                      endAdornment={
                      <InputAdornment position="end" className="search-icon">
                        <img src={searchIcon} loading="lazy"/>
                      </InputAdornment>
                    }
                    />
                  </div>
                )}
              </div>

              {(availableReposLoading || netSearch && !searchTerm) && isAppInstall &&
              <div className="spinner-wrapper">
                <CircularProgress />
              </div>
              }
              {!availableReposLoading && (!netSearch || netSearch && !!searchTerm) &&
              <div className="scrollable-div" onScroll={handleScroll}>
                {!!filteredRepos.length && (
                  <div className="form-row head-row">
                    <Checkbox
                      className={'checkbox'}
                      checked={!searchTerm && (storeRepos.length === checkedRepos.length || (!!checkedRepos.length && checkedRepos.length >= availableItemsLeft))}
                      // disabled={(availableItemsLeft - checkedRepos.length) <= 0}
                      name="repoName"
                      onChange={(e) => {
                        handleSelectAllChange(e.target.checked);
                      }}
                      icon={<CheckboxInputEmpty/>}
                      checkedIcon={<CheckboxInputChecked />}
                    />
                    Name
                  </div>
                )}
                {!!filteredRepos.length && !availableReposLoading ?
                  filteredRepos.map(
                    (repoName, index) =>
                      <div className={(availableItemsLeft - checkedRepos.length) <= 0 && !checkedRepos.includes(repoName.id) ? 'form-row check-inactive' : 'form-row'} key={`${repoName.id}-${index}`}>
                        <label key={repoName.id} className="input-label-checkbox">
                          <Checkbox
                            className={'checkbox'}
                            value={repoName.id}
                            checked={checkedRepos.includes(repoName.id)}
                            name="repoName"
                            onChange={() => {
                              handleChange(repoName.id);
                            }}
                            icon={<CheckboxInputEmpty/>}
                            checkedIcon={<CheckboxInputChecked />}
                          />
                          {repoName.name.split('::')[0]}
                        </label>
                      </div>
                  ) :
                  <div className="form-row with-overflow">
                    {user && isAppInstall && !searchTerm?.length ?
                      (subPlan?.planQuantity === subPlan?.quantity ?
                        translate('notifications.trello_boards.nothing_to_add_empty') :
                        translate('notifications.trello_boards.nothing_to_add')) :
                      !!searchTerm?.length && isAppInstall ?
                        translate('notifications.github_repos.no_search') :
                        (<>
                          {translate('notifications.github_repos.github_app_required')}
                          {translate('forms.github_add_edit_user.install_github_app')}
                          <b>
                            <a className="link" href={process.env.REACT_APP_GITHUB_APP_INSTALL_URL} target='_blank' rel='noreferrer'>
                              {translate('forms.github_add_edit_user.here')}
                            </a>
                          </b>
                        </>)
                    }
                  </div>
                }
                {scroll && (
                  <div className="spinner-wrapper">
                    <CircularProgress />
                  </div>
                )}
              </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.cancel')}
            </Button>

            {!!storeRepos.length && (
              <Button
                onClick={
                  paymentType === EPaymentRequiredType.NON
                    ? handleSubmit(onSubmit)
                    : openTrialModal
                }
                variant="contained"
                color="primary"
                disabled={!checkedRepos.length || addingReposProcessing || ((availableItemsLeft - checkedRepos.length) < 0 && isUserSub)}
              >
                {addingReposProcessing &&
                <div className="small-spinner-wrapper">
                  <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                </div>
                }
                {addingReposProcessing
                  ? translate('notifications.choices.processing')
                  : checkedRepos.length > 1 ?
                    translate('forms.github_add_edit_repo.add_repo_num', {num: checkedRepos.length}) :
                    translate('forms.github_add_edit_repo.add_repo')}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <PaymentRequiredPoppup
        isOpen={paymentPopup.isOpen}
        closeNotification={closeTrialPopup}
        type={paymentPopup.type}
        platformName={paymentPopup.platformName}
      />
    </>
  );
};

export default memo(GithubRepoAdd);
