import { EStripeSubStatus } from '../../../models/consts';
import { IGithubUser, ITrialDataAPI } from '../../../models/models';
import { EGithubPlanNames } from '../../../models/paymentPlans/github-payments-plan';
import { EJiraPlanNames, jiraPlans } from '../../../models/paymentPlans/jira-payments-plan';
import { trialDaysFinishDate } from '../../../utils/trialDaysLeft';
import { jiraProjectAdapterFunc, jiraUserAdapterFunction } from '../../functions';

export const fetchJiraUserAction = (state, { payload }) => {
  if (!payload) {
    state.jira.usersLoading = false;
    state.jira.users = [];
    return;
  }

  state.jira.usersLoading = false;
  state.jira.users = jiraUserAdapterFunction(payload);
}

export const addJiraUserAction = (state, { payload }) => {
  if (payload && (payload.user_name || payload.full_name)) {
    state.jira.users = jiraUserAdapterFunction([payload] as Array<IGithubUser>);
  }
}

export const fetchItemsForJiraUserAction = (state, { payload }) => {
  if (payload) {
    state.jira.itemsLoading = false;
    state.jira.items = jiraProjectAdapterFunc(payload.projects || []);
    state.jira.canUserBackup = payload.canUserBackup
    state.jira.totalItems = payload.totalItemsNumber || 0
  }
}

export const addJiraItemsAction = (state, { payload }) => {
  state.jira.items.push(...jiraProjectAdapterFunc(payload.projects));
}

export const deleteJiraItemAction = (state, { meta }) => {
  if (state?.jira.items && meta.arg) {
    state.jira.items = [...state.jira.items]?.filter(x => x.id !== meta.arg);
  }
}

export const checkJiraTrialStatusAction = (state, { payload }) => {
  if (payload) {
    state.jira.isConnectionActive = !!payload.isConnectionActive

    const trialData = payload as ITrialDataAPI;
    state.jira.trial = {
      trialStatus: trialData.trialStatus,
      finishDate: trialDaysFinishDate(trialData.trialHoursLeft),
      trialHoursLeft: trialData.trialHoursLeft,
      availableItemsQuantity: trialData.availableUsersQuantity,
      addedItemsQuantity: trialData.domainUsersNumber,
      planQuantity: 2002,
      isInvoiceRequested: trialData.stripe_jira_subscription_bank_transfer_requested || false,
      isCancel: false
    };

    const {s3_region, cloud_storages, stripe_jira_next_invoice, subscription_type, subscription_period, stripe_jira_subscription_cancel_at_period_end, stripe_jira_users_quantity, stripe_jira_users_bought_quantity, stripe_jira_subscription_status, availableUsersQuantity, domainUsersNumber, stripe_jira_subscription_bank_transfer_requested, stripe_jira_subscription_current_period_end } = trialData;

    state.jira.storage = cloud_storages || []
    if (s3_region) {
      state.jira.regionS3 = s3_region
    }

    if (stripe_jira_subscription_status === 'active') {
      const planData = jiraPlans.find(({ name }) => name === EJiraPlanNames.ESSENTIAL);
      const isAnnual = subscription_period && subscription_period === 'year' || planData?.stripeIds.yearly === trialData.stripe_jira_price_id;

      const cost = stripe_jira_next_invoice ? stripe_jira_next_invoice / 100 : 0;

      state.jira.subscription = {
        status: stripe_jira_subscription_status.toLowerCase() as EStripeSubStatus,
        planName: subscription_type === 'pro_plan' ? EGithubPlanNames.PRO : EGithubPlanNames.ESSENTIAL,
        isAnnual,
        finishDate: stripe_jira_subscription_current_period_end ? new Date(Number(stripe_jira_subscription_current_period_end + '000')).toLocaleDateString() : null,
        finishDateInMillisecond: Number(stripe_jira_subscription_current_period_end +'000' ),
        cost: cost as number,
        quantity: (stripe_jira_users_bought_quantity || 0) - (stripe_jira_users_quantity || 0) || availableUsersQuantity ,
        addedItemsQuantity: domainUsersNumber,
        planQuantity: stripe_jira_users_bought_quantity || stripe_jira_users_quantity,
        isInvoiceRequested: stripe_jira_subscription_bank_transfer_requested || false,
        isCancel: stripe_jira_subscription_cancel_at_period_end || false
      };
    } else if (stripe_jira_subscription_status) {
      state.jira.subscription = {
        status: stripe_jira_subscription_status.toLowerCase() as EStripeSubStatus,
        // planName: EGitlabPlanNames.ESSENTIAL,
        // isAnnual:
        // finishDate: stripe_gitlab_subscription_current_period_end ? new Date(Number(stripe_gitlab_subscription_current_period_end + '000')).toLocaleDateString() : null,
        cost: 0,
        addedItemsQuantity: domainUsersNumber,
        // quantity: availableUsersQuantity ,
        // planQuantity: stripe_gitlab_projects_quantity,
        // isInvoiceRequested: stripe_gitlab_subscription_bank_transfer_requested || false,
        // isCancel: stripe_gitlab_subscription_cancel_at_period_end || false
      };
    }
  }
}
