import { EStripeSubStatus } from '../../../models/consts';
import { IGitlabUser, ITrialDataAPI } from '../../../models/models';
import { EGitlabPlanNames, gitlabPlans } from '../../../models/paymentPlans/gitlab-payments-plan';
import { trialDaysFinishDate } from '../../../utils/trialDaysLeft';
import { gitlabRepoAdapterFunc, gitlabUserAdapterFunction } from '../../functions';
import {EGithubPlanNames} from "../../../models/paymentPlans/github-payments-plan";

export const fetchGitlabUsersAction = (state, { payload }) => {
  if (payload) {
    state.gitlab.usersLoading = false;
    state.gitlab.users = gitlabUserAdapterFunction(payload);
  }
};

export const addGitlabUserAction = (state, { payload }) => {
  if (payload) {
    state.gitlab.users = gitlabUserAdapterFunction([payload] as Array<IGitlabUser>);
  }
};

export const fetchItemsForGitlabUserAction = (state, { payload }) => {
  if (payload) {
    state.gitlab.itemsLoading = false;
    state.gitlab.items = gitlabRepoAdapterFunc(payload.projects);
    state.gitlab.canUserBackup = payload.canUserBackup
    state.gitlab.totalItems = payload.totalItemsNumber
  }
};

export const addGitlabItemsAction = (state, { payload }) => {
  state.gitlab.items?.push(...gitlabRepoAdapterFunc(payload));
}

export const deleteGitlabItemAction = (state, { meta }) => {
  if (state?.gitlab.items && meta.arg) {
    state.gitlab.items = [...state.gitlab.items]?.filter(x => x.id !== meta.arg);
  }
}

export const checkGitlabTrialStatusAction = (state, { payload }) => {
  if (payload) {
    state.gitlab.isConnectionActive = !!payload.isConnectionActive

    const trialData = payload as ITrialDataAPI;
    state.gitlab.trial = {
      trialStatus: trialData.trialStatus,
      finishDate: trialDaysFinishDate(trialData.trialHoursLeft),
      trialHoursLeft: trialData.trialHoursLeft,
      availableItemsQuantity: trialData.availableItemsQuantity,
      addedItemsQuantity: trialData.addedItemsQuantity,
      planQuantity: trialData.trialDefaultQuantity,
      isInvoiceRequested: trialData.stripe_gitlab_subscription_bank_transfer_requested || false,
      isCancel: false
    };

    const { s3_region, cloud_storages, stripe_gitlab_next_invoice, subscription_type, subscription_period, stripe_gitlab_subscription_cancel_at_period_end, stripe_gitlab_projects_quantity, stripe_gitlab_subscription_status, availableItemsQuantity, addedItemsQuantity, stripe_gitlab_subscription_bank_transfer_requested, stripe_gitlab_subscription_current_period_end } = trialData;

    state.gitlab.storage = cloud_storages || []
    if (s3_region) {
      state.gitlab.regionS3 = s3_region
    }

    if (stripe_gitlab_subscription_status && stripe_gitlab_projects_quantity) {
      const planData = gitlabPlans.find(({ name }) => name === EGitlabPlanNames.ESSENTIAL);
      const isAnnual = subscription_period && subscription_period === 'year' || planData?.stripeIds.yearly === trialData.stripe_gitlab_price_id;

      const cost = stripe_gitlab_next_invoice ? stripe_gitlab_next_invoice / 100 : 0;

      state.gitlab.subscription = {
        status: stripe_gitlab_subscription_status.toLowerCase() as EStripeSubStatus,
        planName: subscription_type === 'pro_plan' ? EGithubPlanNames.PRO : EGithubPlanNames.ESSENTIAL,
        isAnnual,
        finishDate: stripe_gitlab_subscription_current_period_end ? new Date(Number(stripe_gitlab_subscription_current_period_end + '000')).toLocaleDateString() : null,
        finishDateInMillisecond: Number(stripe_gitlab_subscription_current_period_end +'000' ),
        cost,
        quantity: availableItemsQuantity,
        addedItemsQuantity: addedItemsQuantity,
        planQuantity: stripe_gitlab_projects_quantity,
        isInvoiceRequested: stripe_gitlab_subscription_bank_transfer_requested || false,
        isCancel: stripe_gitlab_subscription_cancel_at_period_end || false
      };
    } else if (stripe_gitlab_subscription_status) {
      state.gitlab.subscription = {
        status: stripe_gitlab_subscription_status.toLowerCase() as EStripeSubStatus,
        // planName: EGitlabPlanNames.ESSENTIAL,
        // isAnnual:
        // finishDate: stripe_gitlab_subscription_current_period_end ? new Date(Number(stripe_gitlab_subscription_current_period_end + '000')).toLocaleDateString() : null,
        cost: 0,
        addedItemsQuantity: addedItemsQuantity,
        // quantity: availableItemsQuantity,
        // planQuantity: stripe_gitlab_projects_quantity,
        // isInvoiceRequested: stripe_gitlab_subscription_bank_transfer_requested || false,
        // isCancel: stripe_gitlab_subscription_cancel_at_period_end || false
      };
    }
  }
}


