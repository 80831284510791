import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import {
  closeModalIcon,
  errorBigIcon,
  successBigIcon,
  warningBigIcon,
  warningEmailBigIcon,
} from '../../../assets/icons/icons';
import { ENotificationTypes } from '../../../models/consts';
import { INotificationProps } from '../../../models/inner-models';
import { Button } from '../../StyledComponents';
import './style.scss';

const Notification: FunctionComponent<INotificationProps> = ({type, isOpen, title, message, confirmButtonText, closeNotification}: INotificationProps) => {

  const handleClose = useCallback(() => {
    closeNotification && closeNotification();
  }, [closeNotification]);

  const handleConfirmedDialog = useCallback(() => {
    closeNotification && closeNotification(true);
  }, [closeNotification])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: 'notification-modal'
      }}
    >
      <div className="close-btn" onClick={handleClose}>
        <img src={closeModalIcon} loading="lazy"/>
      </div>
      <DialogContent>
        <div id="alert-dialog-description">
          { type && type !== ENotificationTypes.NONE &&
            <img
              loading="lazy"
              className='notification-icon'
              src={
                type === ENotificationTypes.ERROR ? errorBigIcon :
                type === ENotificationTypes.SUCCESS ? successBigIcon :
                type === ENotificationTypes.WARNING_EMAIL ? warningEmailBigIcon : warningBigIcon
              }
            />
          }
          { title &&
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          }
          {message}

        </div>
        <div className="action-buttons">
          <Button onClick={type !== ENotificationTypes.CONFIRM ? handleClose : handleConfirmedDialog}
            className="primary-button"
          >
            { type === ENotificationTypes.SUCCESS ? 'Continue' :
              type !== ENotificationTypes.CONFIRM ? 'Close' : (confirmButtonText || 'Confirm')
            }
          </Button>
          { type === ENotificationTypes.CONFIRM &&
            <Button onClick={handleClose} className="close-link">
              Cancel
            </Button>
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default Notification;
