import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';
import AppLogout from "./utils/appLogout";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppLogout>
        <App />
      </AppLogout>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
//reset
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
