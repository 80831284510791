import { createAsyncThunk } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EApiStatuses } from '../../../constants';
import {IError, IPagePayload} from '../../../models/inner-models';
import {IDeletePayload, ISetterUserWithBoards} from '../../../models/models';
import { pingTrialStatusWrapper } from '../../../utils/pingWrapper';
import { EPlatform, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';
import {AxiosResponse} from "axios";

const fetchTrelloUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlatformUsers, EPlatformNames.trello),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('trello/get-trello-user');

      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        undefined : resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const addTrelloUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addPlatformUser, EPlatformNames.trello),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('trello/login-trello');
      return (!resp?.data) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp?.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const editTrelloUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.editPlatformUser, EPlatformNames.trello),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('trello/change-trello-user');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp?.data?.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getAvailableItemsForTrelloUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getAvailableItems, EPlatformNames.trello),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`trello/get-available-trello-boards?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`, { signal:payload.signal });
      return resp?.data?.result?.boards || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchItemsForTrelloUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserItems, EPlatformNames.trello),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`trello/get-user-trello-boards?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`, {});
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchOrganizationsForTrelloRestore = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchAvailableTrelloOrganizations, EPlatformNames.trello),
  async (payload, thunkAPI) => {
    try {
      const resp:AxiosResponse<{status:string,result:{organizations:{id:string,name:string}[]}}> = await instance.get(`trello/get-available-trello-organizations`, {});
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const addTrelloItems = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addItem, EPlatformNames.trello),
  async (boardsWithUser: ISetterUserWithBoards, thunkAPI) => {
    try {
      const boards = await instance.post('trello/add-boards', boardsWithUser);
      return (!boards?.data || boards.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: boards.data.status, error: boards.data.message }) :
        boards.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const deleteTrelloItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.deleteItem, EPlatformNames.trello),
  async (payload: IDeletePayload, thunkAPI) => {
    try {
      const resp = await instance.delete('trello/delete-boards', {
        data: payload,
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const backupTrelloItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupItem, EPlatformNames.trello),
  async (id: string, thunkAPI) => {
    try {
      const resp = await instance.post('trello/backup-now', {
        boardId: id,
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

//todo find usage and delete
const checkTrelloTrialStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.checkUserTrial, EPlatformNames.trello),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('payments/get-trello-trial-status');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
      //return subResponseMockData[8]
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const pingTrelloPaymentStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.pingPaymentStatus, EPlatformNames.trello),
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as DefaultRootState;

      const resp = await pingTrialStatusWrapper(state.platform.trello.subscription?.status); //instance.post('payments/start-github-trial', {});
      return (!resp.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export {
  fetchItemsForTrelloUser,
  getAvailableItemsForTrelloUser,
  addTrelloItems,
  deleteTrelloItem,
  backupTrelloItem,
  fetchOrganizationsForTrelloRestore,

  fetchTrelloUser,
  addTrelloUser,
  editTrelloUser,

  checkTrelloTrialStatus,
  pingTrelloPaymentStatus,
};
