import {Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, InputAdornment} from '@mui/material';
import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CheckboxInputChecked, CheckboxInputEmpty, closeModalIcon, searchIcon,} from '../../../../assets/icons/icons';
import {Button} from '../../../../components/StyledComponents';
import {IGithubUser, IItem, ISetterUserWithItems} from '../../../../models/models';
import {SearchInput} from '../../../../styles/components/MainInputElements';
import './style.scss';
import {CustomTooltip} from "../../../../styles/components/CustomTooltip";
import {
  fetchGistForGithubUser,
  fetchItemsForGithubUser,
  fetchItemsForGitlabUser, fetchItemsForJiraUser,
  fetchItemsForTrelloUser,
  fetchSnippetsForGitlabUser,
} from "../../../../store/actions";
import {useAppDispatch} from "../../../../store/hooks";
import {humanReadableFileSize} from "../../../../utils/fileSizeConverter";

interface IAddRestoreProps {
  isOpen: boolean;
  closeNotification: () => void;
  addItems: (newItems: { id: string; name: string; }[]) => void;
  selectedItem: { id: string; name: string; }[];
  user: IGithubUser
  platformName: string;
}

const AddRestoreItem: FunctionComponent<IAddRestoreProps> = ({
                                                               isOpen,
                                                               closeNotification,
                                                               user,
                                                               addItems,
                                                               selectedItem,
                                                               platformName
                                                             }: IAddRestoreProps) => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const {handleSubmit, reset} = useForm<ISetterUserWithItems>({mode: 'onChange',});
  const [loadedItems, setLoadedItems] = useState<IItem[]>([]);
  const [storeItems, setStoreItems] = useState<IItem[]>([]);
  const [checkedRepos, setCheckedRepos] = useState<IItem[]>([]);
  const [filteredRepos, setFilteredRepos] = useState<IItem[]>([]);
  const [listRepos, setListRepos] = useState<IItem[]>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [availableReposLoading, setAvailableReposLoading] = useState<boolean>(true);
  const isSubPlatform = platformName === 'gitlab' ? localStorage['gitlabType'] === 'snippet' : platformName === 'github' ? Number(localStorage['githubUser']) === 0 : false
  const itemName = translate(`views.bulk_restore.itemName.${isSubPlatform ? platformName + '_sub' : platformName}.many`)
  const [scroll, setScroll] = useState<boolean>(false);
  const step = 20;

  async function getAvailableItems() {
    try {
      const page = !storeItems.length ? 0 : Math.trunc(storeItems.length / 20)
      let items:IItem[] = []

      switch (platformName) {
        case 'github':
          items = isSubPlatform ? (await dispatch(fetchGistForGithubUser({page: page, perPage: 20})).unwrap()).gists : (await dispatch(fetchItemsForGithubUser({page: page, perPage: 20, placeId: localStorage['githubUser'] as number})).unwrap()).repos
          break;
        case 'gitlab':
          items = await dispatch(isSubPlatform ? fetchSnippetsForGitlabUser({page: page, perPage: 20}) : fetchItemsForGitlabUser({page: page, perPage: 20})).unwrap().then(res => res?.projects)
          break;
        case 'trello':
          items = (await dispatch(fetchItemsForTrelloUser({page: page, perPage: 20})).unwrap()).boards
          break;
        case 'jira':
          items = (await dispatch(fetchItemsForJiraUser({page: page, perPage: 20})).unwrap()).projects
          break;
      }

      if (!items || !items.length) {
        setLoadedItems([]);
        setAvailableReposLoading(false);
        setScroll(false);
        return;
      }

      setLoadedItems(items);
      setStoreItems(prevState => prevState.concat(items));
      const selectedRows = items.filter((cv) => !selectedItem.find((e) => e.id === cv.id) && !!cv.size && cv.size !== '-' && !!cv.status && cv.status !== 'deleted' )
      if (!!selectedRows?.length) {
        setListRepos(prevState => prevState.concat(selectedRows))
        setFilteredRepos(prevState => prevState.concat(selectedRows));
      }
      setAvailableReposLoading(false);
      setScroll(false);
    } catch (err) {
      console.log(err);
      setAvailableReposLoading(false);
      setScroll(false);
    }
  }

  const handleScroll = (e) => {
    if (!scroll && loadedItems.length === step) {
      const element = e.target
      if (!element) return;

      const viewHeight = element.offsetHeight;
      const fullHeight = element.scrollHeight;
      const scrollingLength = element.scrollTop;
      if (fullHeight <= viewHeight + scrollingLength + 150) {
        setScroll(true);
        getAvailableItems()
      }
    }
  }

  useEffect(() => {
    getAvailableItems()
  }, []);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const searchStr = searchTerm.trim().toLowerCase();
    if (searchStr === '') {
      setFilteredRepos(listRepos || []);
    } else {
      listRepos && setFilteredRepos(listRepos.filter((item) => {
        const name = item.repo || item.project || item.board || item.name || '-'
        return name.toLowerCase().includes(searchStr)
      }));
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setSearch('');

    if (isOpen) {
      const selectedRows = storeItems.filter((cv) => !selectedItem.find((e) => e.id === cv.id) && !!cv.size && cv.size !== '-' && !!cv.status && cv.status !== 'deleted')
      if (!!selectedRows?.length) {
        setListRepos(selectedRows)
        setFilteredRepos(selectedRows)
      } else {
        setListRepos([])
        setFilteredRepos([])
      }
    }
  }, [isOpen]);

  const handleChange = (repoName, item) => {
    if (checkedRepos.includes(item)) {
      setCheckedRepos(checkedRepos.filter((ell) => {
        const name = ell.repo || ell.project || ell.board || ell.name || '-'
        return name !== repoName
      }));
    } else {
      setCheckedRepos([...checkedRepos, item]);
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    setCheckedRepos(checked ? filteredRepos.slice(0, 10 - selectedItem.length) : []);
  };

  const onSubmit: SubmitHandler<ISetterUserWithItems> = () => {
    const tempData = checkedRepos.map(item => {
      return ({id: item.id, name: item.repo || item.project || item.board || item.name || '-'})
    })

    addItems(tempData)
    handleClose();
  };

  const handleClose = useCallback(() => {
    setCheckedRepos([]);
    reset();
    closeNotification && closeNotification();
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="restore-item-edit-title"
        aria-describedby="restore-item-edit-description"
        classes={{
          paper: 'add-restore-item-modal',
        }}
      >
        <DialogTitle id="restore-item-edit-title">
          {translate('views.bulk_restore.modalTitle', {item: isSubPlatform ? (platformName === 'github' ? 'gists' : 'snippets') : translate(`views.bulk_restore.itemName.${platformName}.many`)})}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        <DialogContent>
          <div id="restore-item-edit-description">
            <div className="username-block">
              {!availableReposLoading && (!!storeItems.length && ((!searchTerm?.length && !!filteredRepos.length) || !!searchTerm?.length)) && (
                <>
                  <div className='github-add-select-all'>
                    <div className='desc-line'>
                      A list of all the {itemName} for the user &quot;{user.username}&quot;.
                      Please select your {itemName} to restore.
                    </div>
                  </div>

                  <div className="search-block">
                    <SearchInput
                      className="search-field"
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={onSearch}
                      endAdornment={
                        <InputAdornment position="end" className="search-icon">
                          <img src={searchIcon} loading="lazy"/>
                        </InputAdornment>
                      }
                    />
                  </div>
                </>
              )}
            </div>
            {!storeItems.length &&
              <div className="spinner-wrapper">
                <CircularProgress/>
              </div>
            }
            {!!storeItems.length &&
              <div className="scrollable-div" onScroll={handleScroll}>
                {!!filteredRepos.length && !!storeItems.length && (
                  <div className="form-row head-row">
                    <label className="input-label-checkbox">
                      <CustomTooltip
                        title={
                          (selectedItem.length + checkedRepos.length >= 10) ?
                            'You can restore up to 10 items at a time' : ''
                        }
                      >
                        <Checkbox
                          className={'checkbox'}
                          disabled={!filteredRepos.length || (selectedItem.length + checkedRepos.length >= 50)}
                          checked={filteredRepos.length === checkedRepos.length && !!filteredRepos.length}
                          name="repoName"
                          onChange={(e) => {
                            handleSelectAllChange(e.target.checked);
                          }}
                          icon={<CheckboxInputEmpty/>}
                          checkedIcon={<CheckboxInputChecked/>}
                        />
                      </CustomTooltip>
                      Name
                    </label>
                    <div className='restore-modal-item-size'>Size</div>
                  </div>
                )}
                {filteredRepos.length && !!storeItems.length ?
                  filteredRepos.map(
                    (item, index) => {
                      const name = item.repo || item.project || item.board || item.name || '-';

                      return (
                        <div className="form-row" key={`${name}-${index}`}>
                          <label key={name} className="input-label-checkbox">
                            <CustomTooltip
                              title={
                                (selectedItem.length + checkedRepos.length >= 10) && !checkedRepos.includes(item) ?
                                  'You can restore up to 10 items at a time' : ''
                              }
                            >
                              <Checkbox
                                className={'checkbox'}
                                value={name}
                                checked={checkedRepos.includes(item)}
                                name="repoName"
                                onChange={() => {
                                  handleChange(name, item);
                                }}
                                disabled={(selectedItem.length + checkedRepos.length >= 10) && !checkedRepos.includes(item)}
                                icon={<CheckboxInputEmpty/>}
                                checkedIcon={<CheckboxInputChecked/>}
                              />
                            </CustomTooltip>
                            {name.split('::')[0]} {item.description ? ` - ${item.description}` : ''}
                          </label>
                          <div className='restore-modal-item-size'>{humanReadableFileSize(item.size)}</div>
                        </div>)
                    }
                  ) :
                  <div className="form-row with-overflow">
                    {!searchTerm?.length ?
                      translate('views.bulk_restore.noToAdd') :
                      translate('views.bulk_restore.no_search')
                    }
                  </div>
                }
              </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.cancel')}
            </Button>

            {!!storeItems.length && (
              <Button
                onClick={
                  handleSubmit(onSubmit)
                }
                variant="contained"
                color="primary"
                disabled={!checkedRepos.length}
              >
                {checkedRepos.length <= 1 ?
                  `Add ${translate(`views.bulk_restore.itemName.${isSubPlatform ? platformName + '_sub' : platformName}.one`)}` :
                  `Add ${checkedRepos.length} ${translate(`views.bulk_restore.itemName.${isSubPlatform ? platformName + '_sub' : platformName}.many`)}`
                }
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(AddRestoreItem);
