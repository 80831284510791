export enum ETrelloPlanNames {
  ESSENTIAL = 'Essential',
  TRIAL = 'Trial',
  PRO = 'Pro'
}

export const trelloPlans = [
  {
    name: ETrelloPlanNames.ESSENTIAL,
    stripeIds: {
      product: process.env.REACT_APP_STRIPE_TRELLO_PRODUCT_KEY,
      monthly: process.env.REACT_APP_STRIPE_TRELLO_MONTHLY_ID,
      yearly: process.env.REACT_APP_STRIPE_TRELLO_YEARLY_ID,
    },
    defaultPlan: {
      from: 1,
      to: 5,
      cost: 5,
    },
    monthlyCost: [],
    annualyCost: [],
  },
];
