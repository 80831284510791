import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import PlatformActionsPageBlock from '../../../../components/PlatformComponents/PlatformActionsPage';
import {
  EPaymentRequiredType,
  EPlatformsNames,
  EStripeSubStatus,
  ETrialStatus,
  JiraProjectsTypeKey
} from '../../../../models/consts';
import {IError, IPaymentRequiredPoppup} from '../../../../models/inner-models';
import {downloadJiraZip, getJiraActions, getJiraProjectColumns, restoreJiraBackup} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {clearBackupsState} from '../../../../store/reducers/backups';
import {
  getJiraActionsSelector,
  getJiraActionsTotalSelector, getJiraCanRestore,
  isJiraActionsLoadingSelector, isSubUser,
  jiraSubscription,
  jiraTrialStatus, roleByApp,
} from '../../../../store/selectors';
import restore from '../../../../utils/functions/restore';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import {IBackupRow} from '../../types';
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import {errorBigIcon, iconNotifySuccess} from "../../../../assets/icons/icons";
import {IRestorePayload} from "../../../../models/models";
import JiraColumnMatch from "./JiraColumnMatch/JiraColumnMatch";
import Modal from "../../../../components/Dialogs/Modal/Modal";

interface IMatchData {
  restoredProjectColumn: {
    name: string;
    statusesIds?: string[]
  };
  backedUpProjectColumn: {
    transitionId: string;
    name: string;
  }
}

interface IRestoreData {
  versions: string
  newId: string
  id: string
  emails?: string[],
  matchedColumns?: IMatchData[]
}

const JiraActivityPage: FunctionComponent = () => {
  const platformName = EPlatformsNames.JIRA;

  const {t: translate} = useTranslation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const id = searchParams.get('id');
  const projectTypeKey = searchParams.get('projectTypeKey');
  const data = searchParams.get('name')?.split('::') || ''

  const actions = useAppSelector(getJiraActionsSelector);
  const actionsTotal = useAppSelector(getJiraActionsTotalSelector);
  const loading = useAppSelector(isJiraActionsLoadingSelector);
  const canRestore = useAppSelector(getJiraCanRestore);
  const trialState = useAppSelector(jiraTrialStatus);
  const subInfo = useAppSelector(jiraSubscription);
  const isUserSub = useAppSelector(isSubUser);
  const roleApp = useAppSelector(roleByApp);
  const isAdminRole = isUserSub && roleApp?.jira === 'admin' || !isUserSub

  const [rows, setRows] = useState<Array<IBackupRow> | null>(null);
  const [paymentRequiredState, setPaymentRequiredState] = useState<EPaymentRequiredType>(EPaymentRequiredType.NON);
  const [paymentPoppup, setPaymentPoppup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [restoreData, setRestoreData] = useState<IRestoreData>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [modalData, setModalData] = useState<Record<string, any>>([])
  const [matchData, setMatchData] = useState<Array<IMatchData>>([])
  const [restartFetch, setRestartFetch] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (actionsTotal && actionsTotal !== total) {
      setTotal(actionsTotal)
    }
  }, [actionsTotal])

  useEffect(() => {
    setPaymentRequiredState(
      subInfo?.status === EStripeSubStatus.ACTIVE || trialState === ETrialStatus.STARTED ?
        EPaymentRequiredType.NON :
        !subInfo ? EPaymentRequiredType.TRIAL : EPaymentRequiredType.SUB,
    );
  }, [trialState, subInfo]);

  const closeTrialPoppup = useCallback(() => {
    setPaymentPoppup({...paymentPoppup, isOpen: false});
  }, []);

  const fillMatchData = (obj: IMatchData) => {
    setMatchData(prevState => {
      const indexToUpd = prevState.findIndex(item => item.backedUpProjectColumn.name === obj.backedUpProjectColumn.name);
      if (indexToUpd !== -1) {
        prevState[indexToUpd].restoredProjectColumn.name = obj.restoredProjectColumn.name
      } else {
        prevState.push(obj)
      }
      return [...prevState]
    })
  }

  const restoreItem = async (versions: string, newId: string, id: string, emails: string[]) => {

    if (paymentRequiredState === EPaymentRequiredType.NON) {

      if (projectTypeKey === JiraProjectsTypeKey.software) {
        setShowModal(true)
        setRestoreData({
          versions, newId, id, emails
        })
        dispatch(getJiraProjectColumns({backedUpProjectId: id, restoredProjectId: newId, versions: versions}))
          .unwrap()
          .then(res => {
            setModalData(res)
          })
          .catch(err => {
            const error = err as IError;
            Swal.fire({
              title: translate('notifications.titles.error'),
              text: decoratePopUpMessage(error.message || error.error as string),
              imageUrl: errorBigIcon,
            });
            console.log(err);
          });
      } else {

        return restore(() => onConfirmRestore(versions, newId, id, emails,[]), translate, 'jira');
      }
    } else {
      if (!isUserSub) {
        setPaymentPoppup((prev) => ({
          ...prev,
          isOpen: true,
        }));
      }
    }
  };

  const swalRestore = () => {
    if (restoreData) {
      return restore(() => onConfirmRestore(restoreData.versions, restoreData.newId, restoreData.id, restoreData.emails, matchData), translate, 'jira');
    }
  }

  const onConfirmRestore = async (versionId, newId, boardId, emails, matchData) => {
    if (versionId && boardId) {
      const payload: IRestorePayload = {
        items: [{id: boardId, versions: versionId, restoredId: newId}]
      }
      if (!!emails?.length) {
        payload.emails = emails;
      }
      if (!!matchData?.length) {
        payload.items[0].matchedColumns = matchData;
      }
      return dispatch(restoreJiraBackup(payload)).unwrap();
    }
  };

  const downloadZipCallback = useCallback(({versions, key}) => {
    dispatch(downloadJiraZip({versions: versions, projectId: key}))
      .unwrap()
      .then((res) => {
        window.open(`${res.url}`, '_blank');
      })
      .catch((err) => {
        console.log(err);
        const error = err as IError;
        console.log(error);

        if (error.status === 'success') {
          setRestartFetch(prevState => prevState + 1)
          Swal.fire({
            title: translate('notifications.titles.success'),
            text: decoratePopUpMessage(`${error.message as string}. The backup archive preparation can take a few minutes. We will send you an email once it is ready and available to download.`),
            imageUrl: iconNotifySuccess,
          });
        } else {
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.message as string),
            imageUrl: errorBigIcon,
          });
        }
      });
  }, []);

  useEffect(() => {
    !loading && actions && setRows(actions?.map((props, index) => ({...props, id: index})));
  }, [actions, loading]);

  useEffect(() => {
    if (!id) {
      navigate('/')
      return;
    }

    dispatch(clearBackupsState());
    updList()
  }, [id]);

  const updList = async (newPage?: number) => {
    try {
      if (!id) {
        return;
      }
      setIsLoading(true)
      await dispatch(getJiraActions({itemId: id, perPage: 10, page: newPage || 0}))
        .unwrap()
        .catch((err) => {
          console.log(err);
          const error = err as IError;
          setIsLoading(false)

          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.error as string),
            imageUrl: errorBigIcon,
          })
            .then(() => {
              navigate('/platform/dashboard/trello')
            });
        });
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e);
    }
  }

  return (
    <>
      <PlatformActionsPageBlock
        platformName={EPlatformsNames.JIRA}
        rows={rows || []}
        downloadZipCallback={downloadZipCallback}
        restoreItemCallback={restoreItem}
        closeTrialPopupCallback={closeTrialPoppup}
        loading={loading}
        total={total}
        page={page}
        setPage={(p) => {
          setPage(p)
          updList(p)
        }}
        isLoading={isLoading}
        paymentPopup={paymentPoppup}
        paymentRequiredState={paymentRequiredState}
        canRestore={canRestore}
        isAdmin={isAdminRole}
      />
      <Modal
        isOpen={showModal}
        closeNotification={() => {
          setShowModal(false)
          setModalData({})
          setMatchData([])
        }}
        title={'Match fields'}
        child={
          <JiraColumnMatch
            close={() => {
              setShowModal(false)
              setModalData({})
            }}
            projName={{old: data[0], new: localStorage['jiraNewName']}}
            fillData={fillMatchData}
            platformName={platformName}
            date={modalData}
            restore={swalRestore}
            matchData={matchData}
          />
        }
      />
    </>
  );
};

export default withRedirectAfterLogout()(JiraActivityPage);
