import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, FormControl, TextField} from "../../../../../components/StyledComponents";
import {
  EMAIL_REGEX,
  ETrialStatus,
  MAX_INPUT_VALUE_EMAIL_LENGTH,
  ORGANIZATION_ROLES
} from "../../../../../models/consts";
import ValidationErrorBlock from "../../../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock";
import {EGithubPlanNames} from "../../../../../models/paymentPlans/github-payments-plan";
import {Checkbox, CircularProgress} from "@mui/material";
import {CheckboxInputChecked, CheckboxInputEmpty, errorBigIcon, toastSuccess} from "../../../../../assets/icons/icons";
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import {getRawSubscriptions} from "../../../../../store/selectors";
import {useForm} from "react-hook-form";
import {ICreateOrganizationUser, IError, ILogin} from "../../../../../models/inner-models";
import Platform from "../../../../../models/platforms-data";
import {PlatformsState} from "../../../../../store/reducers/platform";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../../utils/popUpTextDecorator";
import {updateSubscriptionPlanModal} from "../../../../../utils/functions/updateSubscriptionPlanModal";
import {useNavigate} from "react-router-dom";
import {editInvitedUser, getUser, inviteNewUser} from "../../../../../store/actions";
import './style.scss';

interface UserInviteProps {
  onClose: () => void;
  updList: () => void;
  userEmail?: any;
  roles?: string[];
  disable?: boolean;
}

const InviteUserBlock: FunctionComponent<UserInviteProps> = ({onClose, userEmail, roles, disable, updList}: UserInviteProps) => {
  const {register, formState: {errors}, watch, setValue} = useForm<ILogin>({
    mode: 'onChange',
  });
  const platform = Platform;
  const platformSub: PlatformsState | null = useAppSelector(getRawSubscriptions);
  const dispatch = useAppDispatch();
  const {t: translate} = useTranslation();
  const [isSend, setIsSend] = useState<boolean>(false);
  const navigate = useNavigate();
  const [curData, setCurData] = useState<Array<string>>([])
  const [newData, setNewData] = useState<Array<string>>([])

  const permissionList = [
    {title: 'Read only', tag: 'user'},
    {title: 'Read/Write', tag: 'admin'}
  ]
  const emailField = watch('email')

  useEffect(() => {
    if (!!Object.keys(userEmail).length) {
      setValue('email', userEmail.email)
    }
  }, [userEmail]);

  useEffect(() => {
    if (!!roles?.length) {
      setCurData([...roles])
      setNewData([...roles])
    }
  }, [roles]);

  const setCheck = (role: string, key: string) => {
    if (newData?.includes(role)) {
      setNewData(prevState => {
        prevState.splice(prevState.findIndex(item => item.includes(role)), 1)
        return [...prevState]
      })
    } else {
      const index = newData.findIndex(item => item.includes(key));
      if (index !== -1) {
        setNewData(prevState => {
          prevState[index] = role
          return [...prevState]
        })
      } else {
        setNewData(prevState => {
          prevState.push(role)
          return [...prevState]
        })
      }
    }
  }

  const isEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }

  const submit = async () => {
    try {
      setIsSend(true)
      console.log(userEmail)
      const dataSend: ICreateOrganizationUser = {
        email: emailField,
        roles: newData as ORGANIZATION_ROLES[]
      }
      await (!Object.keys(userEmail).length ? dispatch(inviteNewUser(dataSend)).unwrap() : dispatch(editInvitedUser({roles: newData as ORGANIZATION_ROLES[], targetUserSub: userEmail?.sub})).unwrap())
        .then((res) => {
          console.log(res)
        })
      updList();
      await dispatch(getUser())
      Swal.fire({
        title: translate('notifications.titles.success'),
        text: !!Object.keys(userEmail).length ? 'User was successfully updated' : 'New user was successfully invited',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
      setIsSend(false)
      onClose()
    } catch (err) {
      const error = err as IError;
      setIsSend(false)
      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  return (
    <div className='user-invite-manage-form'>
      <div>
        <FormControl className="form-row">
          <div className="label-block">{translate('forms.common.email')}</div>
          <div className='form-input-field'>
            <TextField
              required
              placeholder={translate('forms.common.email')}
              className='form-text-field'
              id="email"
              type="email"
              inputProps={{maxLength: MAX_INPUT_VALUE_EMAIL_LENGTH}}
              {...register('email', {
                required: translate('forms.common.required') as string,
                pattern: {
                  value: EMAIL_REGEX,
                  message: translate('forms.common.invalid_email'),
                },
              })}
              error={!!errors.email}
              disabled={isSend || disable || !!Object.keys(userEmail).length}
            />
            {errors.email &&
              <ValidationErrorBlock errorMessage={errors.email.message as string}/>
            }
          </div>
        </FormControl>
      </div>
      <div className='storage-block-platforms'>
        <div className="label-block">
          Select access rights:
        </div>
        <div className='storage-block-platforms-list'>
          <div className='storage-block-single-platform--head'>
            <div className='storage-block-single-platform-title'>App</div>
            <div className='storage-block-single-platform-storages'>
              Read only
            </div>
            <div className='storage-block-single-platform-storages'>
              Read/Write
            </div>
          </div>
          {Object.keys(platform).map(key => {
            const isProPlanSubscription = (platformSub?.[key]?.subscription?.planName && platformSub[key].subscription.planName === EGithubPlanNames.PRO || platformSub?.[key]?.trial?.trialStatus && platformSub[key].trial.trialStatus === ETrialStatus.STARTED)
            if (key !== 'asana') {
              return (
                <div
                  key={key}
                  className={isProPlanSubscription ? 'storage-block-single-platform' : 'storage-block-single-platform invite-inactive-row'}
                >
                  <div className='storage-block-single-platform-title'>
                    <img src={platform[key]?.smallLogo} className="platformLogo" loading="lazy"/>
                    {platform[key].title}
                  </div>
                  {permissionList.map(item => (
                    <div key={key + item.tag} className='storage-block-single-platform-storages'>
                      <Checkbox
                        disabled={!platformSub[key].isConnectionActive || isSend || disable}
                        color="primary"
                        onClick={() => {
                          if (!isProPlanSubscription) {
                            updateSubscriptionPlanModal({translate, platformName: key, navigate})
                          } else {
                            setCheck(`organization_${key}_${item.tag}`, key)
                          }
                        }}
                        checked={!!newData?.includes(`organization_${key}_${item.tag}`)}
                        icon={<CheckboxInputEmpty/>}
                        checkedIcon={<CheckboxInputChecked/>}
                      />
                    </div>
                  ))}
                </div>
              )
            }
          })}
        </div>
      </div>
      <div className="action-row">
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          mr={4}
          disabled={isSend || disable}
        >
          Cancel
        </Button>
        <Button
          onClick={submit}
          variant="contained"
          color="primary"
          disabled={isSend || isEqual(curData, newData) || disable || !!errors.email || !emailField?.length}
        >
          {isSend &&
            <div className="small-spinner-wrapper">
              <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '6px'}}/>
            </div>
          }
          {!!Object.keys(userEmail).length ? 'Edit' : 'Send Invitation'}
        </Button>
      </div>
    </div>
  )
}

export default InviteUserBlock;
