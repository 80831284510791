import { CircularProgress } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { attentionIcon, errorBigIcon, iconNotifySuccess, warningEmailBigIcon, iconNotifyAlert } from '../../../assets/icons/icons';
import ValidationErrorBlock from '../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import { AuthWrapper as Wrapper, Button, FormControl, TextField } from '../../../components/StyledComponents';
import { EMAIL_REGEX } from '../../../models/consts';
import { IError, IRecoveryPassword } from '../../../models/inner-models';
import { recoverPasswordCognito, resendUserRegistrationLink } from '../../../store/actions';
import { useAppDispatch } from '../../../store/hooks';
import { decoratePopUpMessage } from '../../../utils/popUpTextDecorator';
import RecoverPasswordConfirmation from '../RecoverPasswordConfirmation/RecoverPasswordConfirmation';
import '../style.scss';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      margin: 0
    },
    textField: {
      marginTop: '4px',
      width: '100%',
      height: '42px',
      '& input': {
        height: '42px',
        boxSizing: 'border-box',
        '&:disabled': {
          fontWeight: 'bold'
        }
      }
    },
    fullWidthButton: {
      width: '100%',
      marginTop: '16px',
      padding: '0',
      fontSize: '14px',
      fontWeight: '600',
      height: '44px',
      boxSizing: 'border-box',
      border: '1px solid #6D4CF0',
      color: '#6D4CF0'
    },
  }),
);

const ResetPassword: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { t: translate } = useTranslation();
    const [isCodeSent, setCodeSending] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const classes = useStyles();
    const navigate = useNavigate();

    const { register, formState: { errors, isValid }, handleSubmit, getValues } = useForm<IRecoveryPassword>({
      mode: 'onChange',
    });

    const onSubmit: SubmitHandler<IRecoveryPassword> = async (data: IRecoveryPassword) => {
      const onResendLink = async () => {
        try {
          await dispatch(resendUserRegistrationLink(data.email));

          await Swal.fire({
            title: translate('notifications.titles.success'),
            text: decoratePopUpMessage(translate('notifications.auth.link_resent')),
            imageUrl: iconNotifySuccess,
          });
        } catch (e) {
          const error = e as IError;

          return Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.error as string),
            imageUrl: errorBigIcon,
          });
        }
      };

      try {
        setProcessing(true);
        await dispatch(recoverPasswordCognito(data)).unwrap();
        Swal.fire({
          title: translate('notifications.titles.success'),
          text: 'A verification code has been sent to you email.',
          imageUrl: iconNotifySuccess,
          confirmButtonText: 'OK',
        });
        setCodeSending(true);
      } catch (err) {
        const error = err as IError;

        console.log(err);

        return Swal.fire({
          title: translate('notifications.titles.attention'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: iconNotifyAlert,
          confirmButtonText: translate('notifications.choices.close'),
        });
      } finally {
        setProcessing(false);
      }
    };

    return (
      <>
        <Helmet>
          <title>BackupLABS Reset Password</title>
          <meta name="title" content="BackupLABS Reset Password" />
          <meta name="description" content="Recovery your Password" />
        </Helmet>
        <Wrapper>
          <h2 className={classes.title}>Password Recovery</h2>
          <div className="notification-text">
            <img src={attentionIcon} loading="lazy"/>
            <div>
              {isCodeSent ? translate('forms.recover_password.enter_new') : translate('forms.recover_password.enter_email')}
            </div>
          </div>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="label-block">{translate('forms.common.email')}</div>
              <FormControl fullWidth={true}>
                <TextField
                  className={classes.textField}
                  placeholder={translate('forms.common.email')}
                  required
                  id="email"
                  type="email"
                  disabled={isCodeSent}
                  {...register('email', {
                    required: translate('forms.common.required') as string,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: translate('forms.common.invalid_email'),
                    },
                  })}
                  error={!!errors.email}
                />
                {errors.email &&
                  <ValidationErrorBlock errorMessage={errors.email.message as string} />
                }
              </FormControl>
            </div>
            {!isCodeSent &&
              <>
                <div className="action-row">
                  <Button className="primary-button w-100" type="submit" disabled={!isValid || isProcessing}>
                    {isProcessing &&
                      <div className="small-spinner-wrapper">
                        <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '14px' }} />
                      </div>
                    }
                    {isProcessing
                      ? translate('notifications.choices.processing')
                      : translate('forms.recover_password.title')}
                  </Button>
                </div>
                <div className="action-row">
                  <Button className={classes.fullWidthButton} onClick={() => navigate(-1)} variant="outlined"
                          color="primary">
                    {translate('notifications.choices.cancel')}
                  </Button>
                </div>
              </>
            }
          </form>
          {isCodeSent &&
            <RecoverPasswordConfirmation email={getValues('email')} />
          }
        </Wrapper>
      </>
    );
  }
;

export default ResetPassword;
