import {ActionsUpdatePayload, ActionUpdatePayload} from "../backups";
import {EWebsocketMethods} from "../../../constants";
import {humanReadableFileSize} from "../../../utils/fileSizeConverter";
import {EActionStatus} from "../../../models/consts";
import {createActionObject} from "../../functions";

export const socketUpdatePlatformItemsStates = (state, action) => {
  const payload = action.payload! as ActionUpdatePayload | ActionsUpdatePayload
  const {platform} = payload

  if (!payload || !state[`${platform}`].items) {
    return;
  }

  switch (payload.method) {
    case EWebsocketMethods.changeItemStatus: {
      const specificPayload = payload as ActionUpdatePayload;
      state[platform].items = state[`${platform}`].items.map(item => {
        if (item.id === specificPayload.id) {
          const isBackupAction = specificPayload.type === 'backup' && specificPayload.status === EActionStatus.SUCCESS && new Date(specificPayload.date).toLocaleString() !== item.lastBackup
          const sizeBytes = isBackupAction ? specificPayload.data_size && item.sizeBytes ? +item.sizeBytes + +specificPayload.data_size : specificPayload.data_size && !item.sizeBytes ? +specificPayload.data_size : item.sizeBytes : item.sizeBytes
          const size = humanReadableFileSize(sizeBytes)
          const lastBackup = isBackupAction ? new Date(specificPayload.date).toLocaleString() : item.lastBackup
          const status = specificPayload.status === EActionStatus.SUCCESS || specificPayload.status === EActionStatus.FAILED ? 'backup' : specificPayload.status
          return {
            ...item,
            actionProcess: specificPayload.actionProcess,
            status: status,
            size,
            lastBackup: lastBackup,
            sizeBytes
          }
        } else {
          return item
        }
      })
      break
    }
    case EWebsocketMethods.bulkChangeItemsStatuses: {
      const specificPayload = payload as ActionsUpdatePayload;
      state[platform].items = state[`${platform}`].items.map(item => {
          const action = specificPayload.actions.find(action => action.id === item.id)

          if (action) {
            const isBackupAction = action.type === 'backup' && action.status === EActionStatus.SUCCESS && new Date(action.date).toLocaleString() !== item.lastBackup
            const sizeBytes = isBackupAction ? action.data_size && item.sizeBytes ? +item.sizeBytes + +action.data_size : action.data_size && !item.sizeBytes ? +action.data_size : item.sizeBytes : item.sizeBytes
            const size = humanReadableFileSize(sizeBytes)
            const lastBackup = isBackupAction ? new Date(action.date).toLocaleString() : item.lastBackup
            const status = action.status === EActionStatus.SUCCESS || action.status === EActionStatus.FAILED ? 'backup' : action.status
            return {
              ...item,
              actionProcess: specificPayload.actionProcess,
              size: size,
              status: status,
              lastBackup: lastBackup,
              sizeBytes
            }
          } else {
            return item
          }
        }
      )
      break
    }
  }
}

export const socketUpdatePlatformActionsStates = (state, action) => {
  const payload = action.payload as ActionUpdatePayload | ActionsUpdatePayload | undefined;

  if (!payload || !state[payload.platform]?.repo) {
    return;
  }

  const {platform, method, page} = payload;
  const repoId = state[platform]?.repo;

  switch (method) {
    case EWebsocketMethods.changeItemStatus: {
      const specificPayload = payload as ActionUpdatePayload;

      if (specificPayload.id === repoId) {
        state[platform].canRestore = true

        if (page === 0) {
          const actionObj = createActionObject(specificPayload);

          if (state[platform]?.actions.some((action) => !!action.action_process)) {
            state[platform]?.actions.shift();
          }

          if (!state[platform]?.actions.some((action) => action._id === actionObj._id)) {
            state[platform]?.actions.unshift(actionObj);
          }
        }
      }

      break;
    }

    case EWebsocketMethods.bulkChangeItemsStatuses: {
      const specificPayload = payload as ActionsUpdatePayload;
      const action = specificPayload.actions.find((action) => action.id === repoId);

      if (action) {

        if (state[platform]?.actions.some((action) => !action.action_process)) {
          state[platform].totalActions = state[platform]?.totalActions + 1
          state[platform].canRestore = false
        }

        if (action.type === 'backup') {
          state[platform].canRestore = true
        }

        if (page === 0) {

          action.actionProcess = specificPayload.actionProcess
          const actionObj = createActionObject(action);


          if (state[platform]?.actions.some((action) => !!action.action_process)) {
            state[platform]?.actions.shift();
          }

          if (!state[platform]?.actions.some((action) => action._id === actionObj._id)) {
            state[platform]?.actions.unshift(actionObj);
          }

        }
      }
      break;
    }
  }
}
