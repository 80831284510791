import { combineReducers } from '@reduxjs/toolkit';

import userReducer, { UserState } from '../reducers/user';
import backupReducer, { BackupState } from './backups';
import platformReducer, { PlatformsState } from './platform';

const rootReducer = combineReducers({
  user: userReducer,
  platform: platformReducer,
  backups: backupReducer
});

export default rootReducer;

export type TRootState = {
  user: UserState;
  platform: PlatformsState;
  backups: BackupState;
};

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends TRootState {}
}
