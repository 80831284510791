import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {IUser} from "../models/models";
import { useAppSelector} from "../store/hooks";
import {getUserSelector} from "../store/selectors";
const env = process.env.STAGE_TYPE || 'dev'
interface WebSocketContextType {
  send: (data: string) => void;
  onMessage: (callback: (data) => void) => void;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must use in WebSocketProvider');
  }
  return context;
};

let webSocket:WebSocket|null = null

export const WebSocketProvider: React.FC = ({ children }) => {
  const [messageCallbacks, setMessageCallbacks] = useState<((data) => void)[]>([]);
  const user: IUser | null = useAppSelector(getUserSelector);
  useEffect(() => {
    if(user && user?.sub && !webSocket) {
      const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_ID}.execute-api.us-west-2.amazonaws.com/${env}?sub=${user.sub}`);
      socket.onopen
      webSocket = socket;
    }
  }, [user]);

  useEffect(() => {
    if(webSocket) {
      webSocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        messageCallbacks.forEach((callback) => callback(data));
      };
    }
  }, [messageCallbacks]);

  const send = (data: string) => {
    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      webSocket.send(data);
    } else {
      console.error('WebSocket is not connected.');
    }
  };

  const onMessage = useCallback((callback: (data) => void) => {
    setMessageCallbacks((prevCallbacks) => [...prevCallbacks, callback]);

    return () => {
      setMessageCallbacks((prevCallbacks) => prevCallbacks.filter((cb) => cb !== callback));
    };
  }, []);

  const contextValue: WebSocketContextType = {
    send,
    onMessage,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
