import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import {TRootState} from "../reducers";

export const getRawSubscriptions = createSelector((state: DefaultRootState) => state, ({platform}: TRootState) => platform);

export * from './platformSelectors/getGithubPlatformSelector';
export * from './platformSelectors/getTrelloPlatformSelector';
export * from './platformSelectors/getGitlabPlatformSelector';
export * from './platformSelectors/getJiraPlatformSelector'
export * from './platformSelectors/getNotionPlatformSelector'
