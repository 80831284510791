import { createAsyncThunk } from '@reduxjs/toolkit';
import { EApiStatuses } from '../../../constants';
import {IError, IPageActionsPayload} from '../../../models/inner-models';
import {IDownloadZip, IRestorePayload} from '../../../models/models';
import { EBackups, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';

const getTrelloActions = createAsyncThunk(
  addPlatformIdentifier(EBackups.transferRepoData, EPlatformNames.trello),
  async (payload: IPageActionsPayload, thunkAPI) => {//payload: IPageActionsPayload,
    try {
      const response = await instance.post(`user/get-actions?page=${payload.page}&perPage=${payload.perPage}`,
        {platform: EPlatformNames.trello, id: payload.itemId});
      return (!response?.data?.result || response.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: response?.data?.message || 'Error on data load. Try later' }) : response.data.result;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const restoreTrelloBackup = createAsyncThunk(
  addPlatformIdentifier(EBackups.restoreBackup, EPlatformNames.trello),
  async (boardRestore: IRestorePayload, thunkAPI) => {
    try {
      const resp = await instance.post('trello/restore-boards', boardRestore);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({error: resp?.data?.message || 'Error on data restore. Try later'}) : resp.data;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const downloadTrelloZip = createAsyncThunk(
  addPlatformIdentifier(EBackups.downloadZip, EPlatformNames.trello),
  async (repoRestore: IDownloadZip, thunkAPI) => {
    try {
      const resp = await instance.post('trello/trello-get-zip', repoRestore);
      return (!resp?.data?.result || resp.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({message: resp?.data?.message, status: resp?.data?.status}) : resp.data.result;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export { getTrelloActions, restoreTrelloBackup, downloadTrelloZip };
