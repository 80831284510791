import { createAsyncThunk } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EApiStatuses } from '../../../constants';
import {IError, IPagePayload} from '../../../models/inner-models';
import {IDeletePayload, ISetterUserWithProjects} from '../../../models/models';
import { pingTrialStatusWrapper } from '../../../utils/pingWrapper';
import { EPlatform, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';
//absent
const fetchJiraUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlatformUsers, EPlatformNames.jira),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('jira/get-jira-users');

      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        undefined : resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const addJiraUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addPlatformUser, EPlatformNames.jira),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('jira/add-jira-user');
      return (!resp?.data) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp?.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
//absent
const editJiraUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.editPlatformUser, EPlatformNames.jira),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('jira/change-jira-user');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp?.data?.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getAvailableItemsForJiraUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getAvailableItems, EPlatformNames.jira),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`jira/get-all-available-for-backup-jira-projects-list?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`, { signal:payload.signal });
      return resp?.data?.result?.available_projects || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
//absent
const fetchItemsForJiraUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserItems, EPlatformNames.jira),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`jira/get-user-jira-projects?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`, {});
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchItemsForJiraRestore = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchRestoreItems, EPlatformNames.jira),
  async (payload: { projectTypeKey?:string | null }, thunkAPI) => {
    try {
      const resp = await instance.get(`jira/get-all-available-user-jira-projects?${payload.projectTypeKey? `projectTypeKey=${payload.projectTypeKey}`:''}`, {});
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const addJiraItems = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addItem, EPlatformNames.jira),
  async (projectsWithUser: ISetterUserWithProjects, thunkAPI) => {
    try {
      const resp = await instance.post('jira/add-jira-projects', projectsWithUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: resp.data.status, error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
//absent
const deleteJiraItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.deleteItem, EPlatformNames.jira),
  async (payload: IDeletePayload, thunkAPI) => {
    try {
      const res = await instance.delete('jira/delete-projects', {
        data: payload,
      });
      return (!res?.data || res.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: res.data.message }) : res.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const backupJiraItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupItem, EPlatformNames.jira),
  async (id: string, thunkAPI) => {
    try {
      const res = await instance.post('jira/backup-now', {
        projectId: id,
      });
      return (!res?.data || res.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: res.data.message }) : res.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

//todo find usage and delete
const checkJiraTrialStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.checkUserTrial, EPlatformNames.jira),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('payments/get-jira-trial-status');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
      //return subResponseMockData[8]
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const pingJiraPaymentStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.pingPaymentStatus, EPlatformNames.jira),
  async (_, thunkAPI) => {
    try {
      const resp = thunkAPI.getState() as DefaultRootState;

      const data = await pingTrialStatusWrapper(resp.platform.jira.subscription?.status); //instance.post('payments/start-github-trial', {});
      return (!data.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export {
  fetchItemsForJiraUser,
  getAvailableItemsForJiraUser,
  fetchItemsForJiraRestore,
  addJiraItems,
  deleteJiraItem,
  backupJiraItem,

  fetchJiraUser,
  addJiraUser,
  editJiraUser,

  checkJiraTrialStatus,
  pingJiraPaymentStatus,
};
