import {createAction, createSlice} from '@reduxjs/toolkit';
import {ITrialData} from '../../models/inner-models';
import {IItem, IPlatformUser, ISubPlan} from '../../models/models';

import {
  addGithubGists,
  addGithubItems,
  addGithubUser,
  addGitlabItems,
  addGitlabSnippets,
  addGitlabUser,
  addJiraItems,
  addJiraUser,
  addTrelloItems,
  addTrelloUser,
  checkGithubTrialStatus,
  checkGitlabTrialStatus,
  checkJiraTrialStatus,
  checkTrelloTrialStatus,
  confirmGithubUser,
  deleteGithubItem,
  deleteGitlabItem,
  deleteJiraItem,
  deleteTrelloItem,
  fetchGistForGithubUser,
  fetchGithubUsers,
  fetchGitlabUsers,
  fetchItemsForGithubUser,
  fetchItemsForGitlabUser,
  fetchItemsForJiraUser,
  fetchItemsForTrelloUser,
  fetchJiraUser, fetchNotionUser, fetchSnippetsForGitlabUser,
  fetchTrelloUser, fetchUserWorkspacesNotion,
  getAllSubscriptions,
  pingGithubPaymentStatus,
  pingGitlabPaymentStatus,
  pingJiraPaymentStatus,
  pingTrelloPaymentStatus,
} from '../actions';
import {EPlatform} from '../constants';

import {
  addGithubGistAction,
  addGithubItemsAction,
  addGithubUserAction,
  checkGithubTrialStatusAction,
  confirmGithubUserAction,
  deleteGithubItemAction,
  fetchGithubUsersAction,
  fetchItemsForGithubUserAction,
} from './platformCases/githubCases';

import {
  addGitlabItemsAction,
  addGitlabUserAction,
  checkGitlabTrialStatusAction,
  deleteGitlabItemAction,
  fetchGitlabUsersAction,
  fetchItemsForGitlabUserAction,
} from './platformCases/gitlabCases';
import {
  addJiraItemsAction,
  addJiraUserAction,
  checkJiraTrialStatusAction,
  deleteJiraItemAction,
  fetchItemsForJiraUserAction,
  fetchJiraUserAction,
} from './platformCases/jiraCases';

import {
  addTrelloItemsAction,
  addTrelloUserAction,
  checkTrelloTrialStatusAction,
  deleteTrelloItemAction,
  fetchItemsForTrelloUserAction,
  fetchTrelloUserAction,
} from './platformCases/trelloCases';
import {
  checkNotionTrialStatusAction,
  fetchNotionUserAction,
  fetchWorkspaceForNotionUserAction
} from "./platformCases/notionCases";
import {socketUpdatePlatformItemsStates} from "./platformCases/socketCases";

interface PlatformState {
  itemsLoading: boolean;
  usersLoading: boolean;
  hasUserAnyActiveApp?: boolean;
  isTokenValid?: boolean;
  autoAdd?: boolean;
  items: Array<IItem> | null;
  users: Array<IPlatformUser> | null;
  trial: ITrialData | null;
  subscription?: ISubPlan | null;
  canUserBackup: boolean;
  isConnectionActive: boolean;
  storage: Array<string>;
  regionS3: string;
  totalItems: number;
}

export interface PlatformsState {
  github: PlatformState;
  trello: PlatformState;
  gitlab: PlatformState;
  jira: PlatformState;
  notion: PlatformState;
}

const initialPlatformState: PlatformState = {
  itemsLoading: true,
  usersLoading: true,
  hasUserAnyActiveApp: false,
  isTokenValid: false,
  autoAdd: false,
  items: null,
  users: null,
  trial: null,
  subscription: null,
  canUserBackup: false,
  isConnectionActive: false,
  totalItems: 0,
  storage: [],
  regionS3: 'us-west-2'
};

const initialState: PlatformsState = {
  github: initialPlatformState,
  trello: initialPlatformState,
  gitlab: initialPlatformState,
  jira: initialPlatformState,
  notion: initialPlatformState,
};

const clearPlatformState = createAction(EPlatform.resetPlatform);
const updatePlatformState = createAction(EPlatform.updatePlatform);


const { reducer } = createSlice({
  name: 'platform',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Github platform reducers
      .addCase(fetchGithubUsers.fulfilled, fetchGithubUsersAction)
      .addCase(addGithubUser.fulfilled, addGithubUserAction)
      .addCase(fetchItemsForGithubUser.fulfilled, fetchItemsForGithubUserAction)
      .addCase(fetchGistForGithubUser.fulfilled, fetchItemsForGithubUserAction)
      .addCase(addGithubItems.fulfilled, addGithubItemsAction)
      .addCase(addGithubGists.fulfilled, addGithubGistAction)
      .addCase(deleteGithubItem.fulfilled, deleteGithubItemAction)
      .addCase(checkGithubTrialStatus.fulfilled, checkGithubTrialStatusAction)
      .addCase(pingGithubPaymentStatus.fulfilled, checkGithubTrialStatusAction)
      .addCase(confirmGithubUser.fulfilled, confirmGithubUserAction)
      //Trello platform reducers
      .addCase(fetchTrelloUser.fulfilled, fetchTrelloUserAction)
      .addCase(addTrelloUser.fulfilled, addTrelloUserAction)
      .addCase(fetchItemsForTrelloUser.fulfilled, fetchItemsForTrelloUserAction)
      .addCase(addTrelloItems.fulfilled, addTrelloItemsAction)
      .addCase(deleteTrelloItem.fulfilled, deleteTrelloItemAction)
      .addCase(checkTrelloTrialStatus.fulfilled, checkTrelloTrialStatusAction)
      .addCase(pingTrelloPaymentStatus.fulfilled, checkTrelloTrialStatusAction)
      //Gitlab platforms reducers
      .addCase(fetchGitlabUsers.fulfilled, fetchGitlabUsersAction)
      .addCase(addGitlabUser.fulfilled, addGitlabUserAction)
      .addCase(fetchItemsForGitlabUser.fulfilled, fetchItemsForGitlabUserAction)
      .addCase(fetchSnippetsForGitlabUser.fulfilled, fetchItemsForGitlabUserAction)
      .addCase(addGitlabItems.fulfilled, addGitlabItemsAction)
      .addCase(addGitlabSnippets.fulfilled, addGitlabItemsAction)
      .addCase(deleteGitlabItem.fulfilled, deleteGitlabItemAction)
      .addCase(checkGitlabTrialStatus.fulfilled, checkGitlabTrialStatusAction)
      .addCase(pingGitlabPaymentStatus.fulfilled, checkGitlabTrialStatusAction)
      //Jira platform reducers
      .addCase(fetchJiraUser.fulfilled, fetchJiraUserAction)
      .addCase(addJiraUser.fulfilled, addJiraUserAction)
      .addCase(fetchItemsForJiraUser.fulfilled, fetchItemsForJiraUserAction)
      .addCase(addJiraItems.fulfilled, addJiraItemsAction)
      .addCase(deleteJiraItem.fulfilled, deleteJiraItemAction)
      .addCase(checkJiraTrialStatus.fulfilled, checkJiraTrialStatusAction)
      .addCase(pingJiraPaymentStatus.fulfilled, checkJiraTrialStatusAction)
      //Notion platform reducers
      .addCase(fetchNotionUser.fulfilled, fetchNotionUserAction)
      // .addCase(getNotionLoginUrl.fulfilled, addNotionUserAction)
      .addCase(fetchUserWorkspacesNotion.fulfilled, fetchWorkspaceForNotionUserAction)

      .addCase(updatePlatformState, socketUpdatePlatformItemsStates)

      .addCase(getAllSubscriptions.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        checkGithubTrialStatusAction(state, { payload: payload.github });
        checkGitlabTrialStatusAction(state, { payload: payload.gitlab });
        checkTrelloTrialStatusAction(state, { payload: payload.trello });
        checkJiraTrialStatusAction(state, { payload: payload.jira });
        checkNotionTrialStatusAction(state, { payload: payload.notion });
      })

      .addCase(clearPlatformState, () => initialState);
  },
});

export { clearPlatformState, updatePlatformState };
export default reducer;
