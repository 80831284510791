export enum EJiraPlanNames {
  ESSENTIAL = 'Essential',
  TRIAL = 'Trial',
  PRO = 'Pro'
}

export const jiraPlans = [
  {
    name: EJiraPlanNames.ESSENTIAL,
    stripeIds: {
      product: process.env.REACT_APP_STRIPE_JIRA_PRODUCT_KEY,
      monthly: process.env.REACT_APP_STRIPE_JIRA_MONTHLY_ID,
      yearly: process.env.REACT_APP_STRIPE_JIRA_YEARLY_ID,
    },
    defaultPlan: {
      from: 1,
      to: 1,
      cost: 2.50,
    },
    monthlyCost: [],
    annualyCost: [],
  },
];
