enum EAuth {
  login = 'auth/login',
  loginG = 'auth/loginG',
  user = 'auth/user',
  signup = 'auth/signup',
  logout = 'auth/logout',
  confirmReg = 'auth/confirm-reg',
  unsubscribe = 'auth/unsubscribe',
  resendConfirm = 'auth/resend-confirm',
  recoverPassword = 'auth/recover-password',
  confirmPassword = 'auth/confirm-password',
  changePassword = 'auth/change-password',
  editUser = 'auth/edit-user',
  checkConfirm = 'auth/check-confirm'
}

export default EAuth;
