import React, {FunctionComponent, useEffect, useState} from 'react';
import './style.scss';
import {getQr, mfaEnabled} from "../../../../cognito/cognitoTest";
import {Button} from "../../../../components/StyledComponents";
import TwoFAModal from "./TwoFAModal/TwoFAModal";
import Swal from "sweetalert2";
import {toastSuccess} from "../../../../assets/icons/icons";
import {useTranslation} from "react-i18next";

const TwoFactorAuthentication: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const [qrImage,setQrImage]= useState<string>('')
  const [isEnabled,setEnabled]= useState<boolean>(false)
  const [isOpenDisableInput,setIsOpenDisableInput]= useState<boolean>(false)

  useEffect(()=>{
    mfaEnabled().then(mfaEnabled => setEnabled(mfaEnabled))
  },[])

  const getQrCode = async ()=>{
    setIsOpenDisableInput(true)
    if (!qrImage) {
      const url = await getQr()
      setQrImage(url)
    }
  }

  return (
    <div className="block-label auth-block" >
      <div>
        <div className="block-label-title">Two-Factor Authentication</div>
        <div className="block-label-desc">Add an extra layer of security to your account</div>
      </div>

      <Button
        variant="outlined" color="primary"
        className='enable-2fa'
        mt={4}
        onClick={
          isEnabled ?
            ()=>setIsOpenDisableInput(!isOpenDisableInput) :
            getQrCode
        }
      >
        {isEnabled ? 'Disable 2FA' : 'Enable 2FA'}
      </Button>

      <TwoFAModal
        isOpen={isOpenDisableInput}
        closeNotification={()=> {
          setIsOpenDisableInput(false)
        }}
        showToast={()=>{
          Swal.fire({
            title: translate('notifications.titles.success'),
            text: !isEnabled ? 'Two-Factor Authentication Enabled' : 'Two-Factor Authentication Disabled',
            toast: true,
            position: 'top-end',
            timerProgressBar: true,
            showConfirmButton:false,
            showCloseButton: true,
            imageUrl: toastSuccess,
            timer: 3000,
          });
        }}
        isEnable={isEnabled}
        setIsEnable={setEnabled}
        imgSrc={qrImage}
        setImgSrc={setQrImage}
      />
    </div>

  );
};

export default TwoFactorAuthentication;
