import { CircularProgress } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkTokenExpiration } from '../cognito/cognitoTest';
import { ELocalStoreKeys } from '../models/consts';
import { getUser } from '../store/actions';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { clearUserState } from '../store/reducers/user';
import { isAuthorized, loadingUser } from '../store/selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withRedirectAfterLogout = (to?: string) => (Component: React.FunctionComponent<any>): FunctionComponent<any> => (props) => {
  const navigate = useNavigate();
  const isAuth = useAppSelector(isAuthorized);
  const userLoading = useAppSelector(loadingUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userLoading && !isAuth) {
      navigate(to || '/auth/login');
    }
  }, [isAuth, userLoading]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await checkTokenExpiration();
      } catch (e) {
        if (!localStorage.getItem(ELocalStoreKeys.TOKEN)) {
          await dispatch(clearUserState());
          await dispatch(getUser());
          navigate('/auth/login');
        }
      }
    };

    checkAuth();
  }, []);

  return (
    <>
      {
        userLoading ?
          <div className="spinner-wrapper">
            <CircularProgress />
          </div> :
          <Component {...props} />
      }
    </>
  );

};

export default withRedirectAfterLogout;
