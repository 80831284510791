import React, {FunctionComponent, useEffect, useState} from "react";
import './style.scss';
import {useTranslation} from "react-i18next";
import {AlertTriangle, Clock, Database, ExternalLink, RefreshCcw, X, Check} from "react-feather";
import {Button} from "../StyledComponents";
import Platform from "../../models/platforms-data";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {getUserProfileName} from "../../store/actions";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {isSubUser} from "../../store/selectors";

interface IBadgesProps {
  onClose?: () => void;
  openSetToken?: () => void;
  openHowModal?: () => void;
  progressItem?: string;
  color?: string;
  platformName: string;
  trialDays?: number;
  isComplete?: boolean;
  isSub?: boolean;
  isDash?: boolean;
  isModal?: boolean;
  more?: boolean;
  addingItemsData?: {
    addedItems: number;
    totalItems: number
  }
}

const BadgesBackup: FunctionComponent<IBadgesProps> = ({
                                                         progressItem,
                                                         platformName,
                                                         onClose,
                                                         isComplete
                                                       }: IBadgesProps) => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profiles, setProfiles] = useState<Record<string, any>>({});
  const isUserSub = useAppSelector(isSubUser);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resProf = await dispatch(getUserProfileName()).unwrap()
        if (resProf) {
          setProfiles(resProf)
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [])

  return (
    <div className='platform-items-list-status-badge'>
      <div className='status-badge-left'>
        <div className='status-badge-left-ico'>
          {isComplete ?
            <Check className="feather-icon-in-button"/> :
            <RefreshCcw className="feather-icon-in-button rotate-ico"/>
          }
        </div>
        {isComplete ? progressItem?.replace('in progress...', ' completed.') : progressItem}
      </div>
      <div className='status-badge-right'>
        {!isUserSub && (
          <Button variant="outlined" color="primary"
                  onClick={() => {
                    if (profiles[platformName]) {
                      switch (platformName) {
                        case 'github':
                          window.open(`https://github.com/${profiles.github[Object.keys(profiles.github)[0]]}`, '_blank')
                          break;
                        case 'gitlab':
                          window.open(`https://gitlab.com/dashboard/projects`, '_blank')
                          break;
                        case 'trello':
                          window.open(`https://trello.com/u/${profiles.trello}/boards`, '_blank')
                          break;
                        case 'jira':
                          window.open(`https://${profiles.jira}.atlassian.net/jira/your-work`, '_blank')
                          break;
                        case 'notion':
                        default:
                          window.open(Platform[platformName].url, '_blank')
                          break
                      }
                    } else {
                      window.open(Platform[platformName].url, '_blank')
                    }
                  }}
          >
            {translate('common.buttons.visit')} {Platform[platformName]?.title}
            <ExternalLink className="feather-icon-in-button icon-ml"/>
          </Button>
        )}
        {onClose &&
          <X className="status-badge-right-close" onClick={onClose}/>}
      </div>
    </div>
  )
}

const BadgesAddingItems: FunctionComponent<Required<Pick<IBadgesProps, 'addingItemsData' | 'platformName' | 'onClose'>>> = ({
                                                                                                                              addingItemsData,
                                                                                                                              platformName,
                                                                                                                              onClose,
                                                                                                                            }: Required<Pick<IBadgesProps, 'addingItemsData' | 'platformName' | 'onClose'>>) => {
  const {t: translate} = useTranslation();

  return (
    <div className='platform-items-list-status-badge'>
      <div className='status-badge-left'>
        <div className='status-badge-left-ico'>
          {addingItemsData.addedItems === addingItemsData.totalItems ?
            <Check className="feather-icon-in-button"/> :
            <RefreshCcw className="feather-icon-in-button rotate-ico"/>
          }
        </div>
        {`${addingItemsData.addedItems === addingItemsData.totalItems ? 'Added' : 'Adding'} ${translate(`views.bulk_restore.itemName.${platformName}.many`)}: ${addingItemsData.addedItems} / ${addingItemsData.totalItems} `}
      </div>
      <div className='status-badge-right'>
        {onClose &&
          <X className="status-badge-right-close" onClick={onClose}/>}
      </div>
    </div>
  )
}

const BadgesTrial: FunctionComponent<IBadgesProps> = ({
                                                        trialDays = 0,
                                                        platformName,
                                                        color,
                                                        isSub,
                                                        isModal,
                                                        isDash,
                                                        more
                                                      }: IBadgesProps) => {
  const {t: translate} = useTranslation();
  const navigate = useNavigate();
  const days = Number((Number(trialDays) / 24).toFixed(0) || 0)
  const isUserSub = useAppSelector(isSubUser);

  return (
    <div className={'badge-block badge-trial ' + color?.replace('_2', '')}
         style={{margin: isModal ? '0 0 24px' : isDash ? '0 0 16px' : '24px 0 0'}}>
      <div className='badge-left' style={{fontSize: isModal ? '14px' : '16px'}}>
        {!isModal && (
          <div className='badge-left-ico'>
            {!color && <Clock className="feather-icon-in-button"/>}
            {color === 'warning' && <Database className="feather-icon-in-button"/>}
            {color?.replace('_2', '') === 'error' && <AlertTriangle className="feather-icon-in-button"/>}
          </div>
        )}
        {!color && (
          days ?
            <>{Platform[platformName]?.title}: You have {days} {days > 1 ? 'days' : 'day'} remaining on your free
              trial</> :
            <>{Platform[platformName]?.title}: Your free trial ends today.</>
        )
        }
        {color === 'warning' &&
          (platformName === 'jira' ?
              <>Jira: We have detected the number of users on your account has {more ? ' increased' : ' decreased'}.
                Your subscription will be automatically calculated on renewal.</> :
              <>You have reached the backup limit on your {isSub ? 'subscription' : 'free trial'}.</>
          )
        }
        {color === 'error' && <> {isSub ? 'Your subscription has expired. Your backups have been paused during this grace period.' : 'Your free trial has expired. Your backups have been paused during this grace period.'} </>}
        {color === 'error_2' && <>Your {isSub ? 'subscription has expired' : 'free trial has expired'}. Your backups
          have been removed due to inactivity.</>}
      </div>
      {((color === 'warning' && platformName !== 'jira') || color !== 'warning') && (
        <div className='badge-right'>
          {!isUserSub && (
            <Button variant="contained" color="primary"
                    onClick={() => {
                      localStorage.setItem('checkout-platform', platformName)
                      navigate('/checkout');
                    }}
                    style={{minWidth: '120px'}}
            >
              {color === 'warning' ? translate('common.buttons.upgrade') : translate('common.buttons.subNow')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

const BadgesSubscription: FunctionComponent<IBadgesProps> = ({
                                                               platformName,
                                                               isModal, isDash
                                                             }: IBadgesProps) => {
  const {t: translate} = useTranslation();
  const navigate = useNavigate();
  const isUserSub = useAppSelector(isSubUser);

  return (
    <div className={'badge-block badge-trial '}
         style={{margin: isDash ? '0 0 16px' : '24px 0 0'}}>
      <div className='badge-left' style={{fontSize: isModal ? '14px' : '16px'}}>
        {!isModal && (
          <div className='badge-left-ico'>
            <Clock className="feather-icon-in-button"/>
          </div>
        )}
        {Platform[platformName]?.title}: Your subscription ends today.
      </div>
      <div className='badge-right'>
        {!isUserSub && (
          <Button variant="contained" color="primary"
                  onClick={() => {
                    localStorage.setItem('checkout-platform', platformName)
                    navigate('/checkout');
                  }}
                  style={{minWidth: '120px'}}
          >
            {translate('common.buttons.renew')}
          </Button>
        )}
      </div>
    </div>
  )
}


const BadgesAppWasDeleted: FunctionComponent<IBadgesProps> = () => {
  const {t: translate} = useTranslation();
  const isUserSub = useAppSelector(isSubUser);

  return (
    <div className={'badge-block badge-trial ' + 'error'}>
      <div className='badge-left'>
        <div className='badge-left-ico'>
          <AlertTriangle className="feather-icon-in-button"/>
        </div>
        <>GitHub: The app was deleted. All backups are paused until you resume.</>
      </div>
      <div className='badge-right'>
        {!isUserSub && (
          <Button
            variant="contained"
            color="primary"
            href={process.env.REACT_APP_GITHUB_APP_INSTALL_URL}
          >
            {translate('forms.github_add_edit_user.github_app')}
          </Button>
        )}
      </div>
    </div>
  )
}

const BadgesNotionUpd: FunctionComponent<IBadgesProps> = ({onClose}: IBadgesProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const isUserSub = useAppSelector(isSubUser);

  return (
    <>
      {isOpen && (!localStorage['hideNotionMsg'] || (localStorage['hideNotionMsg'] && moment().isSameOrAfter(localStorage['hideNotionMsg']))) && (
        <div className={'badge-block badge-trial badge-notion'}>
          <div className='badge-left'>
            <div className='badge-left-ico'>
              <AlertTriangle className="feather-icon-in-button"/>
            </div>
            <div className='badge-text'>
              Notion: Ensure your backups include any newly added pages and teamspaces.
            </div>
          </div>
          <div className='badge-right'>
            {!isUserSub && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (onClose) {
                    onClose()
                  } else {
                    localStorage['showUpdNotionPopup'] = 'true'
                    navigate('/platform/dashboard/notion')
                  }
                }}
                mr={4}
              >
                Tutorial
              </Button>
            )}
            <X className="status-badge-right-close"
               onClick={() => {
                 setIsOpen(false)
                 localStorage['hideNotionMsg'] = moment().add(1, 'd').toJSON()
               }}
            />
          </div>
        </div>
      )}
    </>
  )
}

const BadgesJiraError: FunctionComponent<IBadgesProps> = ({isModal}: IBadgesProps) => {

  return (
    <div className={'badge-block badge-trial warning ' + (isModal ? 'badge-jira-restore' : 'badge-notion')}>
      <div className='badge-left'>
        <div className='badge-left-ico'>
          <AlertTriangle className="feather-icon-in-button"/>
        </div>
        <div className='badge-text'>
          Restoring to the same project will result in duplicates. This action is irreversible
        </div>
      </div>
      <div className='badge-right'>
      </div>
    </div>
  )
}

export {
  BadgesBackup,
  BadgesTrial,
  BadgesAppWasDeleted,
  BadgesNotionUpd,
  BadgesJiraError,
  BadgesSubscription,
  BadgesAddingItems
};
