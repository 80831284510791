import { createAsyncThunk } from '@reduxjs/toolkit';
import { EApiStatuses } from '../../../constants';
import {IError, IPageActionsPayload} from '../../../models/inner-models';
import {IDownloadZip, IJiraMatchFetch, IRestorePayload} from '../../../models/models';
import { EBackups, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';

// under question
const getJiraActions = createAsyncThunk(
  addPlatformIdentifier(EBackups.transferRepoData, EPlatformNames.jira),
  async (payload: IPageActionsPayload, thunkAPI) => {
    try {
      const response = await instance.post(`user/get-actions?page=${payload.page}&perPage=${payload.perPage}`,
        {platform: EPlatformNames.jira, id: payload.itemId});
      return (!response?.data?.result || response.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: response?.data?.message || 'Error on data load. Try later' }) : response.data.result;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const getJiraProjectColumns = createAsyncThunk(
  addPlatformIdentifier(EBackups.matchRepoData, EPlatformNames.jira),
  async (payload: IJiraMatchFetch, thunkAPI) => {
    try {
      const response = await instance.post('jira/get-projects-columns', payload);
      return (!response?.data?.result || response.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: response?.data?.message || 'Error on data load. Try later' }) : response.data.result;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const restoreJiraBackup = createAsyncThunk(
  addPlatformIdentifier(EBackups.restoreBackup, EPlatformNames.jira),
  async (boardRestore: IRestorePayload, thunkAPI) => {
    try {
      const board = await instance.post('jira/restore-projects', boardRestore);
      return (!board?.data || board.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({error: board?.data?.message || 'Error on data restore. Try later'}) : board.data;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const downloadJiraZip = createAsyncThunk(
  addPlatformIdentifier(EBackups.downloadZip, EPlatformNames.jira),
  async (repoRestore: IDownloadZip, thunkAPI) => {
    try {
      const repo = await instance.post('jira/jira-get-zip', repoRestore);
      return (!repo?.data?.result || repo.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({message: repo?.data?.message, status: repo?.data?.status}) : repo.data.result;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const updateUserWorkspace = createAsyncThunk(
  addPlatformIdentifier(EBackups.updateUserWorkspace, EPlatformNames.jira),
  async (name: string, thunkAPI) => {
    try {
      const repo = await instance.put('jira/update-user-workspace', {name});
      return (!repo?.data?.result || repo.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({error: repo?.data?.message || 'Error on data restore. Try later'}) : repo.data.result;
    } catch (err) {
      const error = err as IError
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export { getJiraActions, restoreJiraBackup, downloadJiraZip, getJiraProjectColumns, updateUserWorkspace };
