import React, { FunctionComponent, useEffect, useState } from 'react';
import PlatformUserPageBlock from '../../../../components/PlatformComponents/PlatformUserPageBlock';
import { EPlatformsNames } from '../../../../models/consts';
import { IPlatformUser, SubPlanInfo } from '../../../../models/models';
import { fetchGithubUsers } from '../../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getGithubUsersSelector,
  githubSubscription,
  githubTrialHoursLeft, githubTrialPlanQuantity, githubTrialQuantityLeft,
  githubTrialStatus,
  isGithubUsersLoadingSelector,
  loadingUser,
} from '../../../../store/selectors';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import GithubUserAddEdit from '../GithubUserAddEditView/GithubUserAddEdit';

const GithubUserSettings: FunctionComponent = () => {
  const userLoading = useAppSelector(loadingUser);
  const isLoadingGithubUsers = useAppSelector(isGithubUsersLoadingSelector);
  const trialState = useAppSelector(githubTrialStatus);
  const trialDays = useAppSelector(githubTrialHoursLeft);
  const trialQuantity = useAppSelector(githubTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(githubTrialPlanQuantity);
  const subInfo = useAppSelector(githubSubscription);
  const users = useAppSelector(getGithubUsersSelector);
  const dispatch = useAppDispatch();

  const platformName = EPlatformsNames.GITHUB;

  const [subPlan, setSubPlan] = useState<SubPlanInfo>();
  const [platformUsers, setPlatformUsers] = useState<Array<IPlatformUser>>([]);

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);

      setSubPlan(subPlan);
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingGithubUsers) {
      setPlatformUsers(users || []);
    }
  }, [users, isLoadingGithubUsers]);

  useEffect(() => {
    dispatch(fetchGithubUsers()).unwrap().catch(err => console.log(err));
  }, [platformName]);

  return (
    <PlatformUserPageBlock
      platformName={platformName}
      isLoadingPlatformUsers={isLoadingGithubUsers}
      ChildComponent={() => <GithubUserAddEdit user={platformUsers[0]} subPlan={subPlan} />}
    />
  );
};

export default withRedirectAfterLogout()(GithubUserSettings);
