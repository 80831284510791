import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {EPlatformsNames, ETrialStatus} from '../../../models/consts';
import {useAppSelector} from '../../../store/hooks';
import {githubTrialStatus, gitlabTrialStatus, jiraTrialStatus, trelloTrialStatus} from '../../../store/selectors';
import {Button} from '../../StyledComponents';
import './style.scss';

interface INoSubBlockProps {
  platformName: string;
  isOwner: boolean;
}

const NoSubscriptionBlock: FunctionComponent<INoSubBlockProps> = ({platformName, isOwner}: INoSubBlockProps) => {
  const {t: translate} = useTranslation();
  let platformTrialStatus;
  const navigate = useNavigate();

  switch (platformName) {
    case EPlatformsNames.GITHUB:
      platformTrialStatus = useAppSelector(githubTrialStatus);
      break;
    case EPlatformsNames.TRELLO:
      platformTrialStatus = useAppSelector(trelloTrialStatus);
      break;
    case EPlatformsNames.GITLAB:
      platformTrialStatus = useAppSelector(gitlabTrialStatus);
      break;
    case EPlatformsNames.JIRA:
      platformTrialStatus = useAppSelector(jiraTrialStatus);
      break;
  }

  return (
    <div className="data-block-sub">
      {/*<img src={noSub} />*/}
      <div className="subtitle">
        {translate('subscriptions.no_subscription_trial')}
      </div>
      <div className="description">
        {
          platformTrialStatus === ETrialStatus.NOT_STARTED
            ? translate('subscriptions.try_for_free')
            : translate('subscriptions.purchase_sub_trial')
        }
      </div>
      <div className='action-button'>
        <Button variant="contained"
                color="primary"
                disabled={!isOwner}
                onClick={() => {
                  if (platformTrialStatus === ETrialStatus.NOT_STARTED) {
                    navigate('/app-connector')
                  } else {
                    localStorage.setItem('checkout-platform', platformName.toLowerCase())
                    navigate('/checkout');
                  }
                }}
        >
          {platformTrialStatus === ETrialStatus.NOT_STARTED
            ? translate('subscriptions.connect_app')
            : translate('subscriptions.buy_subscription')}
        </Button>
        {platformTrialStatus === ETrialStatus.NOT_STARTED && (
          <Button ml={2} variant="outlined" color="primary"
                  disabled={!isOwner}
                  onClick={() => {
                    localStorage.setItem('checkout-platform', platformName.toLowerCase())
                    navigate('/checkout');
                  }}
          >
            {translate('subscriptions.buy_subscription')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default (NoSubscriptionBlock);
