export default {
  common: {
    coming_soon: 'Coming soon',
    backuplabs: 'BackupLABS',
    no_results: 'No results found',
    default_pages: {
      dashboard_overview: 'Dashboard overview',
      app_connector: 'App Connectors',
      checkout: 'Checkout',
      checkout_title: 'Subscription',
      job_monitor: 'Job Monitor',
      reports: 'Reports',
      audit_trail: 'Audit Trail',
      new_app: 'New Backup',
      dashboard: 'Dashboard',
      activity: 'Recent Activity',
      home: 'Home',
      integrations: 'Your Protected Apps',
      about_us: 'About us',
      contact_us: 'Contact us',
      contact: 'Contact',
      support: 'Support',
      privacy_policy: 'Privacy Policy',
      terms_of_use: 'Terms of Use',
      payment_history: 'View Payment History',
      payment_history_title: 'Payment History',
      repositories: 'Repositories',
      subscriptions: 'My Subscriptions',
      profile: 'Profile',
      profile_info: 'Profile info',
      actions: 'Action history',
      settings: 'Settings',
      sign_in: 'Sign in',
      sign_up: 'Sign up',
      sign_out: 'Sign Out',
      get_started: 'Get Started',
      sla: ' Service Level Agreement'
    },
    buttons: {
      start_for_free: 'Start for free',
      get_to_settings: 'Get to settings',
      back: 'Back',
      edit: 'Edit',
      history: 'History',
      backups: 'Backups',
      backupNow: 'Backup Now',
      delete: 'Delete',
      addPlatform: ' New App',
      view: 'View',
      visit: 'Visit ',
      deactivate: 'Deactivate',
      activate: 'Activate',
      restore: 'Restore',
      selectAll: 'Select all',
      cancel: 'Cancel',
      upgrade: 'Upgrade now',
      renew: 'Renew now',
      subNow: 'Subscribe now'
    },
    platforms: {
      github: 'GitHub',
      gitlab: 'GitLab',
      trello: 'Trello',
      asana: 'Asana',
      wrike: 'Wrike',
      bitbucket: 'Bitbucket',
      airtable: 'Airtable',
      jira: 'Jira',
      notion: 'Notion',
    },
    items: {
      repos: 'Repos',
      boards: 'Boards',
      projects: 'Projects',
      workspace: 'Workspace'
    }
  },
  views: {
    contact_section: {
      protect: 'Protect your critical business data today',
      create_and_run: 'Create and run your first cloud backup in less than five minutes',
      free_trial: '14 day free trial',
      no_credit_cards: 'No credit card required',
      cancel_anytime: 'Cancel Anytime',
      money_back: '30 day money-back guarantee',
    },
    footer: {
      all_rights_reserved: ' BackupLABS - All rights reserved',
    },
    about_us: {
      about: 'About',
      who_we_are: {
        title: 'Who We Are?',
        paragraph_1: 'We’re data backup veterans. And we’ve been doing this since 2004, when we were amongst the first to develop and deliver powerful new solutions for what was then called ‘online backup’ for servers and desktops.',
        paragraph_2: 'Fast-forward over a decade and a half, and whilst the terminology might have changed, our position at the forefront of business data protection hasn’t.',
        paragraph_3: 'As businesses become more and more reliant on cloud application providers that accept no responsibility for data loss, our experts have led the field in delivering cloud-to-cloud backup for these apps, making us a safe pair of hands in an increasingly risky data universe.',
      },
      our_mission: {
        title: 'Our Mission',
        paragraph_1: 'We made it our mission to stop backup becoming a complex, error-prone process that sucks up time and resources. ',
        paragraph_2: 'Why? Because we understood that this is what makes it the last thing on your to-do list – and we know what that looks like when an incident comes knocking. ',
        paragraph_3: 'We also know one of the industry’s guiltiest open secrets: that despite the huge growth in critical business data moving to the cloud in 2020, cloud application providers do not routinely backup your data!',
        paragraph_4: 'And the threats to that data are coming thick and fast. There is also an increasing need to satisfy various legal, compliance and insurance regulations. Ransomware, extortionware, and insider action– both malicious and unintended – can separate you from your business-critical data, permanently. ',
        paragraph_5: 'But not on our watch. Backups need to be simple and reliable.',
      },
      why_us: {
        title: 'Why us?',
        paragraph_1: 'We put you ahead of the game.',
        paragraph_2: 'With our many years’ cloud experience, we turn backup for your cloud applications into a noiseless, always-on service that protects all the business data stored in your cloud applications – and makes it easily restorable when the need arises, in minutes.',
        paragraph_3: 'Set. Forget. Restore. We look after the data, so you can get on with your business.',
      },
      backup_experts: 'Backup experts since 2004',
      simple_and_rapid: 'Simple and rapid setup',
      automatic: 'Automatic, offsite storage',
      encrypted: 'Encrypted backups at rest',
      friendly: 'Friendly & experienced team',
      affordable: 'Affordable monthly costs',
    },
    contact_us: {
      love_to_hear: 'We`d love to hear from you',
      place: 'BackupLABS, Wilson House, Lorne Park Road, Bournemouth, Dorset, BH1 1JN, United Kingdom',
      phone: '0044-20-3397-3082',
      email: 'support@backuplabs.io',
    },
    privacy_policy: {
      introduction: {
        title: 'We want you to understand the types of information we collect as you use our services',
        paragraph_1: 'We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which ads you’ll find most useful, the people who matter most to you online, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.',
        paragraph_2: 'When you’re not signed in to a Google Account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. This helps us do things like maintain your language preferences across browsing sessions.',
        paragraph_3: 'When you’re signed in, we also collect information that we store with your Google Account, which we treat as personal information.',
      },
      collect_data: {
        title: 'Things you create or provide to us',
        paragraph_1: 'When you create a Google Account, you provide us with personal information that includes your name and a password. You can also choose to add a phone number or payment information to your account. Even if you aren’t signed in to a Google Account, you might choose to provide us with information — like an email address to receive updates about our services.',
        paragraph_2: 'We also collect the content you create, upload, or receive from others when using our services. This includes things like email you write and receive, photos and videos you save, docs and spreadsheets you create, and comments you make on YouTube videos.',
      },
      privacy_control: {
        title: 'Information we collect as you use our services',
        paragraph_1: 'We collect information about the apps, browsers, and devices you use to access Google services, which helps us provide features like automatic product updates and dimming your screen if your battery runs low.',
        paragraph_2: 'The information we collect includes unique identifiers, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including IP address, crash reports, system activity, and the date, time, and referrer URL of your request.',
        paragraph_3: 'We collect this information when a Google service on your device contacts our servers — for example, when you install an app from the Play Store or when a service checks for automatic updates. If you’re using an Android device with Google apps, your device periodically contacts Google servers to provide information about your device and connection to our services. This information includes things like your device type, carrier name, crash reports, and which apps you`ve installed.',
      },
      share_information: {
        title: 'We want you to understand the types of information we collect as you use our services',
        paragraph_1: 'We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which ads you’ll find most useful, the people who matter most to you online, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.',
        paragraph_2: 'When you’re not signed in to a Google Account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. This helps us do things like maintain your language preferences across browsing sessions.',
        paragraph_3: 'When you’re signed in, we also collect information that we store with your Google Account, which we treat as personal information.',
      },
    },
    terms_of_use: {
      introduction: {
        title: 'We want you to understand the types of information we collect as you use our services',
        paragraph_1: 'We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which ads you’ll find most useful, the people who matter most to you online, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.',
        paragraph_2: 'When you’re not signed in to a Google Account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. This helps us do things like maintain your language preferences across browsing sessions.',
        paragraph_3: 'When you’re signed in, we also collect information that we store with your Google Account, which we treat as personal information.',
      },
      collect_data: {
        title: 'Things you create or provide to us',
        paragraph_1: 'When you create a Google Account, you provide us with personal information that includes your name and a password. You can also choose to add a phone number or payment information to your account. Even if you aren’t signed in to a Google Account, you might choose to provide us with information — like an email address to receive updates about our services.',
        paragraph_2: 'We also collect the content you create, upload, or receive from others when using our services. This includes things like email you write and receive, photos and videos you save, docs and spreadsheets you create, and comments you make on YouTube videos.',
      },
      privacy_control: {
        title: 'Information we collect as you use our services',
        paragraph_1: 'We collect information about the apps, browsers, and devices you use to access Google services, which helps us provide features like automatic product updates and dimming your screen if your battery runs low.',
        paragraph_2: 'The information we collect includes unique identifiers, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including IP address, crash reports, system activity, and the date, time, and referrer URL of your request.',
        paragraph_3: 'We collect this information when a Google service on your device contacts our servers — for example, when you install an app from the Play Store or when a service checks for automatic updates. If you’re using an Android device with Google apps, your device periodically contacts Google servers to provide information about your device and connection to our services. This information includes things like your device type, carrier name, crash reports, and which apps you`ve installed.',
      },
      share_information: {
        title: 'We want you to understand the types of information we collect as you use our services',
        paragraph_1: 'We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which ads you’ll find most useful, the people who matter most to you online, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.',
        paragraph_2: 'When you’re not signed in to a Google Account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. This helps us do things like maintain your language preferences across browsing sessions.',
        paragraph_3: 'When you’re signed in, we also collect information that we store with your Google Account, which we treat as personal information.',
      },
    },
    github: {
      carousel: {
        title: 'Why people love BackupLABS',
      },
      faq: {
        title: 'Frequently asked questions',
      },
      general_platform_info: {
        title1: 'Get peace of mind',
        title2: 'Protect your GitHub data',
        description: 'Cloud app providers do not routinely backup your data. This is your responsibility. Backup and recover your data from common SaaS applications with ease.',
        automatic_backup: 'Automatic Backups',
        automatic_backup_description: 'Automatically protect your SaaS data with no complex scripts or manual downloads.',
        offsite_and_secure: 'Offsite & Secure',
        offsite_and_secure_description: 'Your data is backed up securely to the AWS infrastructure and remains encrypted at all times.',
        simple_recovery: 'Simple Recovery',
        simple_recovery_description: 'Recover your data within minutes back to your app, or download directly to your computer.',
      },
      payment_info: {
        title: 'Our plans',
        description: 'Cloud app providers do not routinely backup your data. This is your responsibility. Backup and recover your data from common SaaS applications with ease.',
        plans: {
          free: {
            title: 'Free',
            cost: '$19.99',
            application: '1 Application',
            storage: '50gb Storage',
            cancellation: 'Free Cancellation',
            support: 'Support',
          },
          pro: {
            title: 'Pro',
            cost: '$29.99',
            application: '1 Application',
            storage: '50gb Storage',
            cancellation: 'Free Cancellation',
            support: 'Support',
          },
          enterprise: {
            title: 'Enterprise',
            cost: '$49.99',
            application: '1 Application',
            storage: '50gb Storage',
            cancellation: 'Free Cancellation',
            support: 'Support',
          },
        },
      },
      platform_features: {
        sync_too_google_drive: 'Sync backups to Google Drive.',
        description_1: 'Daily sync of your Asana projects to Google Sheets. Easy access to the backup of your comments & files.',
        undo_mistakes: 'Undo mistakes with One-click Restore.',
        description_2: 'It`s easy to accidentally delete the wrong task. Recover and restore deleted tasks with one click.',
        easy_to_use: 'Easy to use.',
        description_3: 'Automated backup, once every 24 hours, stored with military grade encryption. Captures all of your GitHub data, including files &#38; comments. Setup only takes 1 minute',

      },
    },
    home: {
      welcome_message: 'Welcome back, {{username}}, we`ve missed you!👋🏼',
      carousel: {},
      feature_section: {
        main: 'Main ',
        features: ' Features',
        automatic_backup: 'Automatic Backups',
        automatic_backup_description: 'Automatically protect your SaaS data with no complex scripts or manual downloads.',
        offsite_and_secure: 'Offsite & Secure',
        offsite_and_secure_description: 'Your data is backed up securely to the AWS infrastructure and remains encrypted at all times.',
        simple_recovery: 'Simple Recovery',
        simple_recovery_description: 'Recover your data within minutes back to your app, or download directly to your computer.',
      },
      how_work_section: {
        title: 'How does BackupLABS Work?',
        select_your_platform: {
          title: 'Select your cloud app platform',
          subtitle: 'Automatically protect your SaaS data with no complex scripts or manual downloads.',
          description_1: 'Screen record with optional webcam as a GIF or HD video.',
          description_2: 'Capture the entire screen or take a partial screen recording.',
          description_3: 'Add your webcam to make it more personal.',
        },
        authenticate_and_protect: {
          title: 'Authenticate and connect',
          subtitle: 'Automatically protect your SaaS data with no complex scripts or manual downloads.',
          description_1: 'Screen record with optional webcam as a GIF or HD video.',
          description_2: 'Capture the entire screen or take a partial screen recording.',
          description_3: 'Add your webcam to make it more personal.',
        },
        select_and_schedule: {
          title: 'Select data & schedule backup',
          subtitle: 'Automatically protect your SaaS data with no complex scripts or manual downloads.',
          description_1: 'Screen record with optional webcam as a GIF or HD video.',
          description_2: 'Capture the entire screen or take a partial screen recording.',
          description_3: 'Add your webcam to make it more personal.',
        },
      },
      integration_section: {
        title: 'App Integrations',
      },
      protect_section: {
        title_1: 'Why ',
        title_2: 'backup & protect',
        title_3: ' your data in a SaaS/cloud app?',
        human_error: 'Human error',
        description_1: 'Users make mistakes and accidental deletion is more common than you may think.',
        nefarious_users: 'Nefarious users',
        description_2: 'You only need on one disgruntled employee to wreck havoc on a cloud app.',
        platform_issues: 'Platform issues',
        description_3: 'No cloud app can guarantee 100% uptime and occasionally user data has even deleted by accident.',
        ransomware_and_viruses: 'Ransomware &#38; viruses',
        description_4: 'Unfortunately this risk is becoming more common and is now seen on cloud apps, not just desktop computers.',
        legal_requirements: 'Legal requirements',
        description_5: 'Certain industries and sectors are legally required to ensure `all their data is not in one single basket`',
        third_party_apps: 'Third party apps',
        description_6: '3rd party apps and csv import errors have the potential to remove data on a cloud platform.',
      },
      start_section: {
        protect: 'Protect',
        most_critical: ' your most critical asset - ',
        your_data: 'your data',
      },
    },
    dashboard: {
      addApp: 'Add new app',
      trialBlock: {
        p1: 'Upgrade to Essential Plan for automatic continuous daily backups',
        btn: 'Upgrade plan'
      }
    },
    platform_pages: {
      activity: 'Activity',
      protection: 'Protection',
      settings: 'Settings',
      configuration: 'Configuration',
      subscription: 'Subscription',
      no_backups: 'Start your backups here',
      configure_to_see: 'Configure integration',
      backup_all: 'Backup all now',
      backupNow: 'Backup now',
      backups_soon: 'Backups will be available soon',
      backups_disabled: 'Backups are disabled for this item. Upgrade subscription to store more!',
      backups_disabled_del: 'Backups are disabled for this item. This was deleted in {{platform}}.',
      backups_disabled_per_day: 'You can use Backup Now option once per day. This option will be available in',
      data_grid: {
        date: 'Date',
        dateTime: 'Date & Time',
        type: 'Type',
        date_long: 'Date',
        size: 'Size',
        sizeBackup: 'Backup Size',
        size_long: 'Size',
        status: 'Status',
        status_long: 'Status',
        restore: 'Restore',
        restoreDot: 'Restore...',
        download: 'Download .ZIP',
        save: 'Save .ZIP',
        viewScope: 'View backup scope',
        action: 'Action',
        ip: 'IP',
        download_button:'This download will expire in:',
        name:'Name',
      },
      actions_list: {
        backto_github: 'Back to All Repositories',
        backto_gitlab: 'Back to All Projects',
        backto_trello: 'Back to All Boards',
        backto_jira: 'Back to All Projects',
        backto_notion: 'Back to Workspaces',
        backto_sub: 'Back to All Subscriptions',
      }
    },
    auth:{
      sign_up:{
        get_started: 'Lets get started'
      }
    },
    new_app: {
      btnConnect: 'Connect',
      btnEditConnect: 'Edit Connection',
      list: {
        github: 'GitHub is an Internet hosting service for software development and version control using Git.',
        trello: 'Trello is a web-based, kanban-style, list-making application and is developed by Atlassian.',
        gitlab: 'GitLab is a DevOps software package which can develop, secure, and operate software.',
        jira: 'Jira is a proprietary issue tracking product developed by Atlassian that allows bug tracking and agile board management.',
        notion: 'Notion is the connected workspace where better, faster work happens. A new tool that blends your everyday work apps into one.',
        asana: 'Asana is a web and mobile "work management" platform designed to help teams organize, track, and manage work.',
        airtable: 'Airtable is a spreadsheet-database hybrid, with the features of a database but applied to a spreadsheet.',
        bitbucket: 'BitBucket is a Git-based source code repository hosting service owned by Atlassian.',
        wrike: 'Wrike is a project management application service provider based in San Jose, California.',
        clickup: 'Clickup is a web-based, kanban-style, list-making application and is developed by Atlassian.'
      }
    },
    bulk_restore: {
      title: 'Restore backups',
      subTitle: 'Please select which backups you’d like to restore to:',
      subTitleTwo: 'Please confirm that all these actions are correct:',
      actionText: 'A new {{type}} will be created, using the backup point “{{date}}” with the name “{{name}}”.',
      actionTextJira: 'The {{type}} will be restored to “{{name}}”, using the backup point “{{date}}”. All issues will be mixed, in case, if you restore the data to any existing project.',
      modalTitle: 'Add {{item}} for restore',
      noToAdd: 'All of your items have been added for restore.',
      no_search: 'There aren’t any items that match your search',
      itemName: {
        github: {
          one: 'repository',
          many: 'repositories'
        },
        github_sub: {
          one: 'gist',
          many: 'gists'
        },
        gitlab: {
          one: 'project',
          many: 'projects'
        },
        gitlab_sub: {
          one: 'snippet',
          many: 'snippets'
        },
        trello: {
          one: 'board',
          many: 'boards'
        },
        jira: {
          one: 'project',
          many: 'projects'
        },
        notion: {
          one: 'workspace',
          many: 'workspaces'
        }
      }
    }
  },
  notifications: {
    choices: {
      yes: 'Yes',
      no: 'No',
      cancel: 'Cancel',
      subscribe: 'Subscribe',
      updating: 'Updating... ',
      update: 'Update',
      processing: 'Processing... ',
      buy_subscription: 'Buy Subscription ',
      buy_subscription_now: 'Subscribe now',
      upd_buy_subscription: 'Update card and Buy Subscription ',
      close: 'Close',
      continue: 'Continue',
      save: 'Save',
      saveCard: 'Save card',
      resend_verification: 'Resend Verification',
      download_backups: 'Download backups',
      send: 'Send code',
      resend: 'Resend code',
      confirm: 'Confirm',
      confirm_user: 'Confirm user',
      enter_code: 'Enter code',
      upgrade: 'Upgrade',
      upgradeSub: 'Upgrade Subscription',
      purchase: 'Purchase',
      invoice: 'Create invoice',
      openInvoice: 'Open invoice',
      downloadInvoice: 'Download invoice',
      nevermind:'Nevermind',
      deactivateInst:'Deactivate instead',
      tutorial: "Tutorial"
    },
    titles: {
      success: 'Success',
      error: 'Error',
      warning: 'Warning',
      attention: 'Attention',
      verification_error: 'Verification Error',
    },
    change_card: {
      change_card_title: 'Payment method'
    },
    payment: {
      invoice_form: 'Confirm Order',
      card_number: 'Card number',
      expire: 'Expiry',
      cvc: 'CVC',
      confirm_update: 'Do you want to update it?',
      confirm_upgrade: 'Would you like to upgrade?',
      confirm_downgrade: 'Would you like to downgrade?',
      dialog_header: 'Your subscription plan will change to',
      success_payment: 'Your subscription was successfully paid',
      success_card_change: 'Your payment method was successfully changed',
      incorrect_card: 'Your card details are not correct. Please try again',
      success_changed_sub: 'The request for payment has been sent. The new subscription plan will be applied after successful payment',
      success_invoice_sub: 'The request for invoice has been sent. The new subscription plan will be applied after successful payment',
      success_pay: 'Payment successful',
      success_pay_jira: 'Subscription successfully amended',
      stripe_error: 'Stripe payment error',
      buy_to_continue: 'Buy subscription to continue using the services',
      coupon_code: 'Coupon Code',
      invalid_coupon_code: 'Your coupon is invalid',
      applied_coupon_code: 'Your coupon is applied'
    },
    trial: {
      trial_ended: 'Your Trial Has Ended',
      trial_started: 'Your trial period started',
    },
    auth: {
      link_resent: 'Verification link was sent successfully',
      mfa_code_invalid: 'Your code is invalid',
      verification_successful:'Verification successful! Please login'
    },
    subscription: {
      subscribe_confirm: 'Confirm subscription',
      subscribe_buy: 'Buy subscription',
      update_subscribe_plan_title: 'Upgrade Subscription Plan',
      update_subscribe_plan: 'This feature is only available with a Pro Plan subscription',
      subscribe_buy_trial: 'Your free trial is over',
      subscribe_buy_text: 'Please renew your subscription plan to continue your backups',
      subscribe_buy_text_trial: 'Please buy a subscription plan to continue your backups',
      subscription_ended: 'Your Subscription Has Ended',
      cancel_confirm: 'Are you sure you want to cancel your subscription?',
      cancel_confirm_all: 'Are you sure you want to cancel ALL your subscriptions?',
      canceled_successfully: 'Your subscription has successfully been canceled. It will expire the day before it is set to renew.',
      canceled_all_successfully: 'All your payment subscriptions were successfully stopped',
      upgrade_plan: 'You have reached the backup limit on your current subscription. Please upgrade to a larger plan to backup more items',
      buy_plan: 'You have reached the backup limit on your free trial. Please subscribe to a plan to backup more items',
      upgrade_plan_jira: 'You have reached the user limit on your current subscription. Please upgrade to a larger plan to backup more items',
      buy_plan_jira: 'You have reached the user limit on your free trial. Please subscribe to a plan to backup more items',
      buy_plan_title: 'Subscription upgrade required'
    },
    delete_item: {
      title: 'Are you absolutely sure?',
      p11: 'This action ',
      p12: 'cannot be undone',
      p13: '. This will permanently delete any backups that exist for this {{itemType}}.',
      p2: 'This will not change your billing plan. If you want to downgrade, you can do so in your Billing Settings.',
      p21: 'You are about to ',
      p22: 'permanently delete',
      p23: ' the following backups:',
      p24: ' the following backup:',
      p31: 'Please type “',
      p32: '” below to confirm:',
      btn: 'Permanently Delete Backup',
      btnBulk: 'Permanently Delete {{num}} Backups'
    },
    all: {
      activeStatus: 'Your backup has been activated',
      inactiveStatus: 'Your backup has been deactivated',
      activeStatus_many: 'Your backups have been activated',
      inactiveStatus_many: 'Your backups have been deactivated',
      process: {
        restoring_process: 'Restoring',
        backing_up_process: 'Backing up'
      },
      actionBadge: {
        one: {
          restoring_process: '{{app}} Restore: “{{item}}” in progress...',
          backing_up_process: '{{app}} Backup: “{{item}}” in progress...'
        },
        many: {
          restore: '{{app}} Restore: Multiple items in progress...',
          backup: '{{app}} Backup: Multiple items in progress...'
        },
        multi: 'Multiple actions in progress...'
      }
    },
    github_user: {
      error: 'Error during github connection: {{err}}',
      change: 'You want to change your GitHub user. All your backups will be deleted. We are strongly recommend to download them first. Do you want to continue?',
      successfully_added: 'New GitHub user successfully added',
      successfully_changed: 'GitHub user successfully edited',
      failed_changed: 'Please install the GitHub app first before your email will be connected to the system',
      user_not_confirmed: 'Your connected GitHub user is not confirmed yet',
      confirm_ownership: 'Confirm Github email',
      confirm_success: 'Your GitHub user is confirmed now!',
      confirm_user: 'Your records do not match between your BackupLABS and GitHub accounts. Please confirm to proceed'
    },
    github_repos: {
      successfully_added: 'Successfully added GitHub repositories',
      successfully_added_token: 'Successfully saved GitHub personal access token',
      successfully_added_gist: 'Successfully added GitHub gists',
      successfully_added_org: 'Successfully added GitHub organization',
      delete_confirm: 'Do you want to delete {{itemName}} with all backups? This operation is undoable',
      successfully_deleted: 'Your backup has been deleted',
      successfully_deleted_gist: 'Your backup has been deleted',
      successfully_deleted_many: 'Your backups have been deleted',
      nothing_to_add: 'All of your items are protected.',
      nothing_to_add_empty: 'Nothing to add for this account. It is empty.',
      nothing_to_add_org: 'Nothing to add. All of your organizations are protected.',
      nothing_to_add_gist: 'All of your items are protected.',
      no_search: 'There aren’t any repositories that match your search',
      no_search_gist: 'There aren’t any gists that match your search',
      no_search_org: 'There aren’t any organizations that match your search',
      github_app_required: 'Install the app to start your backups ',
      start_backup: 'Backup starts now',
      multi_start_backup: 'Your backups are now in process',
      single_start_backup: 'Your backup is now in process'
    },
    trello_user: {
      error: 'Error during trello connection: {{err}}',
      change: 'You want to change your Trello user. All your backups will be deleted. We are strongly recommend to download them first. Do you want to continue?',
    },
    notion_user: {
      error: 'Error during notion connection: {{err}}',
      change: 'You want to change or update your Notion user. All your backups will be overwritten with selected pages and DBs. Do you want to continue?',
    },
    gitlab_user: {
      error: 'Error during gitlab connection: {{err}}',
      change: 'You want to change your GitLab user. All your backups will be deleted. We are strongly recommend to download them first. Do you want to continue?',
    },
    jira_user: {
      error: 'Error during jira connection: {{err}}',
      change: 'You want to change your Jira user. All your backups will be deleted. We are strongly recommend to download them first. Do you want to continue?',
    },
    error_during_connection: 'Error during connection: {{err}}',
    gitlab_repos: {
      successfully_added: 'Successfully added GitLab projects',
      successfully_added_snippet: 'Successfully added GitLab snippets',
      delete_confirm: 'Do you want to delete {{itemName}} with all backups? This operation is undoable',
      successfully_deleted: 'Your backup has been deleted',
      successfully_deleted_snippets: 'Your backup has been deleted',
      nothing_to_add: 'Nothing to add for this account. All of your items are currently secured.',
      nothing_to_add_empty: 'Nothing to add for this account. It is empty.',
      no_search: 'There aren’t any projects that match your search',
      no_search_snippets: 'There aren’t any snippets that match your search',
    },
    trello_boards: {
      successfully_added: 'Successfully added Trello boards',
      delete_confirm: 'Do you want to delete {{itemName}} with all backups? This operation is undoable',
      successfully_deleted: 'Your backup has been deleted',
      successfully_deleted_many: 'Your backups have been deleted',
      nothing_to_add: 'All of your items are protected.',
      nothing_to_add_empty: 'Nothing to add for this account. It is empty.',
      no_search: 'There aren’t any boards that match your search',
    },
    jira_boards: {
      confirm_restore: 'You should create a clean project that the BackupLABS system will use to restore the data. All issues will be mixed, in case, if you restore the data to any existing project.',
      successfully_added: 'Successfully added Jira projects',
      delete_confirm: 'Do you want to delete {{itemName}} with all backups? This operation is undoable',
      successfully_deleted: 'Your backup has been deleted',
      successfully_deleted_many: 'Your backups have been deleted',
      nothing_to_add: 'All of your items are protected.',
      nothing_to_add_empty: 'Nothing to add for this account. It is empty.',
      no_search: 'There aren’t any projects that match your search',
    },
    github_org_info: {
      tooltip: 'If you don’t see your organisation repositories it can be because of GitHub Organisation settings. Click here to see instructions how to change default Github settings',
      title: 'Disabling OAuth App access restrictions for your organization',
      step1: 'In the top right corner of GitHub.com, click your profile photo, then click "Your organizations".',
      step2: 'Next to the organization, click "Settings".',
      step3: 'In the "Third-party Access" section of the sidebar, click',
      step31: 'OAuth application policy.',
      step4: 'Under "Third-party application access policy" click ',
      step41: 'Remove restrictions',
      step5: 'After you review the information about to remove all third-party application restrictions, click ',
      step51: 'Yes, remove application restrictions'
    },
    notion_tutorial: {
      tooltip: 'Click here to see instructions how to update Notion access',
      title: 'To update access for pages that need to be backed up in your Notion workspace, please follow the next steps:',
      step1: 'Get to workspace',
      step2: 'Click Settings and members',
      step3: 'Click My connections',
      step4: 'Click three dots -> Access selected pages ',
      step5: 'The popup with all pages and databases',
      step6: 'Confirm selection with ‘Update access’ button'
    },
    notion_workspaces:{
      successfully_deleted: 'Your backup has been deleted',
      successfully_deleted_many: 'Your backups have been deleted',
    }
  },
  appConnectors: {
    title: 'App connectors',
    subTitle: 'Select your app to get started',
    button: 'Get Started',
    config: 'Backups',
    coming: 'Coming soon'
  },
  twoFactorAuthentication:{
    title_enable: 'Enable Two-Factor Authentication'
  },
  subscriptions: {
    emailUnsubscribe: 'I want to receive emails about activities with my profile', //Subscribed for our mailing list
    hours_left: ' Hours Left',
    days_left: ' Days Left',
    repos_used: 'Backing up: {{used}}/{{max}}',
    subscription: 'Subscription',
    status: 'Status',
    stopped_subscription: 'Subscription (Stopped)',
    no_subscription: 'You don`t have a subscription yet',
    no_subscription_trial: 'Your free trial is over.',
    purchase_sub: 'Purchase a subscription to add a backup',
    purchase_sub_trial: 'Please buy a subscription to resume your backups',
    try_for_free: 'Try for free',
    start_trial: 'Start Free trial',
    connect_app: 'Connect app',
    connect_now: 'Install app',
    buy_subscription: 'Buy subscription',
    download_invoice: 'Download invoice',
    empty_subscription: 'No subscription',
    cancel_subscription: 'Cancel subscription',
    cancel_already: 'Your subscription has already been cancelled. The subscription will be active until the end of the paid period.',
    edit_sub: 'Edit Subscription',
    edit_card: 'Change card',
    how_many_repo: 'How many repositories do you want to back up?',
    how_many_proj: 'How many projects do you want to back up?',
    billed_monthly: 'Billed monthly',
    billed_annually: 'Billed annually',
    unlimited: '1000+',
    enterprise_trello: '200+',
    discount: 'Save 20%!',
    per_month: '/ per month',
    per_year: '/ per year',
    statuses: {
      active: 'active',
      initialized:'initialising',
      inactive: 'inactive',
      unpaid: 'unpaid',
      disabled: 'disabled'
    },
    github_info_list: {
      first_line: 'Back up repository and metadata such as issues, milestones and more',
      second_line: 'Restore to GitHub or download files',
      third_line: 'Option to download backups locally',
      forth_line: 'Backup snapshots of the last 30 days',
    },
    trello_info_list: {
      first_line: 'Back up boards, cards and attachments such as comments, pictures and more',
      second_line: 'Restore to Trello or download backups',
      third_line: 'Option to download backups locally',
      forth_line: 'Backup snapshots of the last 30 days',
    },
    gitlab_info_list: {
      first_line: 'Back up projects and metadata such as issues, milestones and more',
      second_line: 'Restore to GitLab or download files',
      third_line: 'Option to download backups locally',
      forth_line: 'Backup snapshots of the last 30 days',
    },
    jira_info_list: {
      first_line: 'Back up projects, cards and attachments such as comments, pictures and more',
      second_line: 'Restore to Jira or download backups',
      third_line: 'Option to download backups locally',
      forth_line: 'Backup snapshots of the last 30 days',
    },
    subscriptions_list: {
      subs_overview: 'Subscriptions',
      cancel_all: 'Cancel All Subscriptions',
      price: 'Price',
      card: 'Card',
      app: 'Application',
      status: 'Status',
      subscription: 'Subscription',
      quantity: 'Backup Limit',
      planName: 'Plan'
    },
    payment_history:{
      time: 'Date',
      amount: 'Amount',
      currency: 'Currency',
      status: 'Status',
      card: 'Card',
      invoice: 'Invoice',
      view_invoice: 'View',
      download_invoice: 'Receipt'
    },
    trello: {
      how_many_boards: 'How many boards do you want to back up?',
    }
  },
  forms: {
    common: {
      user_config: 'User Configuration',
      save_info: 'Save Info',
      submit: 'Submit',
      first_name: 'First name',
      apps_to_protect : 'Apps to protect ',
      last_name: 'Last name',
      email: 'Email',
      emailA: 'Email address',
      password: 'Password',
      confirm_password: 'Confirm password',
      required: 'This field is required',
      min_length: 'Min length is 2',
      max_length: 'Max length is 100',
      max_length50: 'Max length is 50',
      max_length1000: 'Max length is 1000',
      invalid_email: 'Invalid email',
      invalid_password: 'Invalid password',
      payment_method: 'Default payment method',
      payment_method_pref: 'Preferred Method',
      payment_method_pref_desc: 'This is the default method that we will use to bill your subscriptions.',
      payment_method_pref_desc_2: 'This is the payment method that we will use to bill your subscriptions.',
      subscriptionMail: 'Email Notifications',
      passwords_dont_match: 'Passwords don`t match',
      password_wrong_format: '*Password must contain at least 8 characters and include at least one lowercase letter, one uppercase letter, number, and special symbol.',
    },
    sign_in: {
      forgot_password: 'Forgot your password?',
      dont_have_acc: 'Or',
      trial: 'start your 14-day free trial',
      agree_with_privacy: 'By clicking SIGN IN, you agree with BackupLABS ',
    },
    sign_up: {
      title: 'Start your 14-day free trial',
      subTitle: 'No credit card required.',
      agree_with_privacy: 'By clicking GET STARTED, you agree with BackupLABS ',
      already_have_acc: 'Already have an account? ',
      verification: {
        something_went_wrong: 'Oops, something went wrong',
        login_page: 'BackupLABS',
        successfully_register_1: 'Registration successful',
        successfully_register_2: ' Please login to ',
        successfully_register_3: ' to start protecting your data.',
      },
      subscribe: {
        unsubscribe: 'You have successfully unsubscribed from BackupLABS emails ',
        change: 'Your email subscription status was successfully changed'
      },
      rightSide: {
        title: 'Get backup sorted in minutes',
        description: 'Get backup and recovery off your to-do list today! Focus on your core business, whilst we back up and secure your cloud app data – automatically and reliably.',
        subTitle: 'Protected SaaS apps',
        comingSoon: 'Plus many more coming soon...'
      }
    },
    recover_password: {
      title: 'Confirm',
      verification_code: 'Verification Code',
      enter_new: 'Enter your verification code and new password',
      new_pass: 'New Password',
      enter_email: 'Enter your email'
    },
    setup_user: {
      desc: 'Fill in your password and name'
    },
    contact_us: {
      fields: {
        company: 'Company',
        your_question: 'Your question',
      },
    },
    restoreModal: {
      github:{
        p1: 'This restore will create a new repository to prevent overriding.',
      } ,
      github_sub:{
        p1: 'This restore will create a new gist to prevent overriding.',
      } ,
      gitlab: {
        p1: 'This restore will create a new project to prevent overriding.'
      },
      gitlab_sub: {
        p1: 'This restore will create a new snippet to prevent overriding.',
      },
      trello: {
        p1: 'This restore will create a new board to prevent overriding.',
      },
      jira: {
        p1: 'This will restore the issues from this project to a different project of your choice to prevent overriding.',
      },
      name: '{{item}} name',
      verId: 'Backup point',
      newName: 'New {{item}} name',
      restoreTo: 'Select a project to restore to...',
      restoreToWorkspace: 'Select a workspace to restore to...',
      restoreToPlace: 'Select a places to restore to...',
      sendEmail: 'Send notification to...',
      sendSub: 'Separate multiple email addresses with commas'
    },
    github_add_edit_repo: {
      configure_account: 'Connect account',
      see_you_platform_activity: 'Your {{platform}} Protection',
      your_items: '{{platform}} {{itemName}}',
      edit_platform_user_settings: 'Edit {{platform}} User Settings',
      account_name: 'Account Name',
      repo_name: 'Repository Name',
      secure_token: 'Secure token',
      prev_token: 'Previous token: {{token}}',
      get_token: 'Get Secure Token',
      go_and_generate: 'Go and generate a limited access GitHub Token ',
      generate_token: 'Generate Token here',
      delete_repo: 'Delete repository',
      open_setting: 'Edit Settings',
      add_repo: 'Add repository',
      add_repo_num: 'Add {{num}} repositories',
      add_proj: 'Add projects',
      add_gist: 'Add gist',
      add_place: 'Add organization',
      add_gist_num: 'Add {{num}} gists',
      add_org_num: 'Add {{num}} organizations',
      add_new_repo: 'Add repositories for backup',
      add_new_gist: 'Add gists for backup',
      add_new_org: 'Add Organization',
      edit_repo: 'Edit repository',
      repos_left: 'You have {{left}} backups left. Choose a subscription plan to backup more!',
      no_backups_left: 'You don’t have any backups left. Upgrade to backup more!',
      select_all: "Select all repositories",
      select_all_gist: "Select all gists"
    },
    github_add_edit_user: {
      add_new_account: 'Authorise on GitHub',
      edit_account: 'Edit Account',
      change_account: 'Change account',
      account_name: 'GitHub Username or Email',
      auto_add: 'Auto-add new repositories',
      auto_add_desc: 'BackupLABS adds newly created repositories only',
      auto_add_desc_sub: '(*This applies after you select this option)',
      install_github_app: 'Install GitHub app',
      here: ' here',
      github_app: 'Install GitHub app',
      github_token_add: 'Set personal token',
      github_app_required: 'GitHub app installation is a required step. To continue - ',
      organization_tooltip: '. (if you are trying to connect Organization account, please, install app for both organization and it`s owner`s accounts)'
    },
    notion_workspace_scope: {
      title: 'Backup scope',
      sub_data: 'Backup date:'
    },
    trello_add_edit_repo: {
      board_name: 'Board Name',
      delete_board: 'Delete board',
      add_board: 'Add board',
      add_board_num: 'Add {{num}} boards',
      add_new_boards: 'Add boards for backup',
      edit_board: 'Edit board',
      boards_left: 'You have {{left}} backups left. Choose a subscription plan to backup more!',
      no_backups_left: 'You don’t have any backups left. Upgrade to backup more!',
      account_name: 'Account Name',
      select_all: "Select all boards"
    },
    trello_add_edit_user: {
      change_account: 'Change account',
      add_new_account: 'Authorize on Trello',
      edit_account: 'Edit Account',
      account_name: 'Trello account name',
      here: ' here',
    },
    notion_add_edit_user: {
      change_account: 'Change account',
      add_new_account: 'Authorize on Notion',
      edit_account: 'Edit Account',
      edit_upd_account: 'Change/Edit Account',
      account_name: 'Notion account name',
      here: ' here',
      add_workspace: 'Add workspaces'
    },
    gitlab_add_edit_project: {
      see_you_platform_activity: 'Your {{platform}} protection',
      edit_platform_repos: 'Edit {{platform}} configuration',
      edit_platform_user_settings: 'Edit {{platform}} user settings',
      account_name: 'Account Name',
      project_name: 'Project Name',
      snip_name: 'Snippet Name',
      secure_token: 'Secure token',
      prev_token: 'Previous token: {{token}}',
      get_token: 'Get Secure Token',
      go_and_generate: 'Go and generate a limited access GitHub Token ',
      generate_token: 'Generate Token here',
      delete_project: 'Delete project',
      delete_snip: 'Delete snippet',
      add_project: 'Add project',
      add_snip: 'Add snippet',
      add_project_num: 'Add {{num}} projects',
      add_snip_num: 'Add {{num}} snippets',
      add_new_project: 'Add projects for backup',
      add_new_snip: 'Add snippets for backup',
      edit_project: 'Edit project',
      edit_snip: 'Edit snipet',
      repos_left: 'You have {{left}} backups left. Choose a subscription plan to backup more!',
      no_backups_left: 'You don’t have any backups left. Upgrade to backup more!',
      select_all: "Select all projects"
    },
    gitlab_add_edit_user: {
      add_new_account: 'Authorize on GitLab',
      edit_account: 'Edit Account',
      change_account: 'Change account',
      account_name: 'GitLab account name',
      auto_add: 'Auto-add new repositories',
      auto_add_desc: 'BackupLABS will monitor your GitHub account, and automatically backup everything it finds.',
      install_gitlab_app: 'Install GitLab app',
      here: ' here',
      gitlab_app: 'GitLab app',
      gitlab_app_required: 'GitLab app installation is a required step. To continue - install '
    },
    jira_add_edit_repo: {
      board_name: 'Project name',
      delete_board: 'Delete board',
      account_name: 'Account Name',
      add_board: 'Add project',
      add_board_num: 'Add {{num}} projects',
      add_new_boards: 'Add projects for backup',
      edit_board: 'Edit project',
      boards_left: 'You have {{left}} backups left. Choose a subscription plan to backup more!',
      no_backups_left: 'You don’t have any backups left. Upgrade to backup more!',
      select_all: "Select all projects"
    },
    jira_add_edit_user: {
      change_account: 'Change account',
      add_new_account: 'Authorize on Jira',
      edit_account: 'Edit Account',
      account_name: 'Jira account name',
      here: ' here',
    },
    change_password: {
      current_password: 'Current Password',
      change_password: 'Change Password',
    },
    payment_history: {
      title: 'Payment history for {{platformName}}'
    }
  },
}
