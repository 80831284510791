import React from 'react';
import { IClassName } from '../../../constants';

const AuditPLogo: React.FC<IClassName> = ({ className }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M19 11.5H5M19 11.5C20.1046 11.5 21 12.3954 21 13.5V19.5C21 20.6046 20.1046 21.5 19 21.5H5C3.89543 21.5 3 20.6046 3 19.5V13.5C3 12.3954 3.89543 11.5 5 11.5M19 11.5V9.5C19 8.39543 18.1046 7.5 17 7.5M5 11.5V9.5C5 8.39543 5.89543 7.5 7 7.5M7 7.5V5.5C7 4.39543 7.89543 3.5 9 3.5H15C16.1046 3.5 17 4.39543 17 5.5V7.5M7 7.5H17" stroke="#B7A5FF" />
    </svg>


  );
};

export default AuditPLogo;
