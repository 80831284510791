import {Checkbox, CircularProgress, debounce, Dialog, DialogContent, DialogTitle, InputAdornment} from '@mui/material';
import React, { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  closeModalIcon,
  errorBigIcon,
  searchIcon,
  warningBigIcon, CheckboxInputEmpty, CheckboxInputChecked, iconNotifyWarningInfo, toastSuccess,
} from '../../../../../assets/icons/icons';
import PaymentRequiredPoppup from '../../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup';
import { Button } from '../../../../../components/StyledComponents';
import { EApiStatuses } from '../../../../../constants';
import { EPaymentRequiredType, EPlatformsNames } from '../../../../../models/consts';
import { IError, IGithubAddProps, IPaymentRequiredPoppup } from '../../../../../models/inner-models';
import { ISetterUserWithItems } from '../../../../../models/models';
import {
  addTrelloItems,
  getAllSubscriptions,
  getAvailableItemsForTrelloUser
} from '../../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../../store/hooks';
import { SearchInput } from '../../../../../styles/components/MainInputElements';
import { decoratePopUpMessage } from '../../../../../utils/popUpTextDecorator';
import './style.scss';
import {BadgesTrial} from "../../../../../components/Badges/Badges";
import {isSubUser} from "../../../../../store/selectors";
let abortController: AbortController | null

const TrelloBoardAdd: FunctionComponent<IGithubAddProps> = ({
  isOpen,
  closeNotification,
  user,
  platformName,
  paymentType,
  availableItemsLeft,
  subPlan,
  updItems
}: IGithubAddProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { handleSubmit, setValue, trigger, reset } = useForm<ISetterUserWithItems>({
    mode: 'onChange',
  });
  const isUserSub = useAppSelector(isSubUser);

  const [availableBoards, setAvailableBoards] = useState<Array<{ id: string, name: string }>>([]);
  const [storeItems, setStoreItems] = useState<Array<{ id: string, name: string }>>([]);
  const [checkedBoards, setCheckedBoards] = useState<Array<string>>([]);
  const [filteredBoards, setFilteredBoards] = useState<Array<{ id: string, name: string }>>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [availableBoardsLoading, setAvailableBoardsLoading] = useState<boolean>(true);
  const [addingBoardsProcessing, setAddingBoardsProcessing] = useState<boolean>(false);
  const [paymentPopup, setPaymentPopup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName || EPlatformsNames.TRELLO,
    type: paymentType || EPaymentRequiredType.NON,
  });
  const [scroll, setScroll] = useState<boolean>(false);
  const [netSearch, setNetSearch] = useState<boolean>(false);
  const step = (availableItemsLeft >= 20 && availableItemsLeft <= 100) ? availableItemsLeft : (availableItemsLeft > 100) ? 100 : 20;

  async function getAvailableBoards(name?:string) {
    try {
      const page = !storeItems.length ? 0 : Math.trunc(storeItems.length / step)
      abortController = new AbortController();
      const { signal } = abortController;
      const boards = await dispatch(getAvailableItemsForTrelloUser({page: page, perPage: step,name, signal})).unwrap();

      if(signal.aborted){
        return
      }

      if (!boards || !boards.length) {
        setAvailableBoards([]);
        // setStoreRepos([]);
        // setFilteredBoards([]);
        setAvailableBoardsLoading(false);
        setScroll(false);
        setNetSearch(false);
        return;
      }

      setAvailableBoards(boards);
      setStoreItems(prevState => prevState.concat(boards));
      setFilteredBoards(prevState => prevState.concat(boards));
      setAvailableBoardsLoading(false);
      setScroll(false);
      setNetSearch(false);
    } catch (err) {
      console.log(err);
      setAvailableBoardsLoading(false);
      setScroll(false);
      setNetSearch(false);
    }
  }

  const handleScroll = (e) => {
    if (!scroll && availableBoards.length === step) {
      const element = e.target
      if (!element) return;

      const viewHeight = element.offsetHeight;
      const fullHeight = element.scrollHeight;
      const scrollingLength = element.scrollTop;
      if (fullHeight <= viewHeight + scrollingLength + 150) {
        setScroll(true);
        getAvailableBoards()
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((event) => {
      setAvailableBoardsLoading(true)
      setAvailableBoards([]);
      setStoreItems([]);
      setFilteredBoards([]);
      getAvailableBoards(event)
    }, 1000),
    [],
  );

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value || ''
    setSearch(val);
    setNetSearch(true)
    debouncedSearch(val)
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setAvailableBoardsLoading(true);
    setSearch('');
    if (isOpen) {
      dispatch(getAllSubscriptions()).unwrap();
      getAvailableBoards();
    } else if (!isOpen) {
      setAvailableBoards([]);
      setStoreItems([]);
      setFilteredBoards([]);
    }
  }, [isOpen]);

  const openPayModal = () => {
    Swal.fire({
      title: translate('notifications.subscription.buy_plan_title'),
      text: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.subscription.buy_plan') :
        translate('notifications.subscription.upgrade_plan'),
      imageUrl: iconNotifyWarningInfo,
      cancelButtonText: translate('notifications.choices.nevermind'),
      confirmButtonText: subPlan && subPlan.name === 'Trial' ?
        translate('notifications.choices.buy_subscription') :
        translate('notifications.choices.upgradeSub'),
      showCancelButton: true,
    })
      .then((res) => {
        if (res.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        }
      })
  }

  const handleChange = (boardId) => {
    if (checkedBoards.includes(boardId)) {
      setCheckedBoards(checkedBoards.filter((ell) => ell !== boardId));
    } else if ((availableItemsLeft - checkedBoards.length) > -1 && availableItemsLeft > 0) {
      setCheckedBoards([...checkedBoards, boardId]);
    } else if (!isUserSub && ((availableItemsLeft - checkedBoards.length) <= -1 || availableItemsLeft === 0)){
      openPayModal()
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    if (availableItemsLeft === 0 || checkedBoards.length - 1 === availableItemsLeft){
      Swal.fire({
        title: translate('notifications.subscription.buy_plan_title'),
        text: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.subscription.buy_plan') :
          translate('notifications.subscription.upgrade_plan'),
        imageUrl: iconNotifyWarningInfo,
        cancelButtonText: translate('notifications.choices.nevermind'),
        confirmButtonText: subPlan && subPlan.name === 'Trial' ?
          translate('notifications.choices.buy_subscription') :
          translate('notifications.choices.upgradeSub'),
        showCancelButton: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName || '')
            navigate('/checkout');
          }
        })
    } else {
      setCheckedBoards((prevState) => {
        if (checked && prevState.length <= availableItemsLeft) {
          const newArr = filteredBoards?.map(i => i.id).filter(id => !prevState.includes(id))?.slice(0, availableItemsLeft - prevState.length) || []
          return [...prevState, ...newArr]
        } else {
          return []
        }
      })
    }
  };

  useEffect(() => {
    setValue('items', checkedBoards);
    trigger('items');
  }, [checkedBoards]);

  const onSubmit: SubmitHandler<ISetterUserWithItems> = async (data) => {
    const adaptedData = {
      user: user?.username,
      boards: storeItems.filter(board => data.items.includes(board.id)),
    };
    if ((availableItemsLeft - checkedBoards.length) <= -1){
      if (!isUserSub) {
        openPayModal()
      }
      return
    }

    setAddingBoardsProcessing(true);
    try {
      await dispatch(addTrelloItems(adaptedData)).unwrap();
      await updItems();
      if ((availableItemsLeft - checkedBoards.length) === 0) {
        dispatch(getAllSubscriptions()).unwrap()
      }
      setAddingBoardsProcessing(false);
      handleClose();

      Swal.fire({
        title: translate('notifications.titles.success'),
        text: checkedBoards.length > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError;

      handleClose();
      setAddingBoardsProcessing(false);
      console.log(err);

      if (error.status === EApiStatuses.WARNING) {
        const userChoice = await Swal.fire({
          title: translate('notifications.titles.warning'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: warningBigIcon,
          confirmButtonText: translate('subscriptions.edit_sub'),
        });

        if (userChoice.isConfirmed) {
          localStorage.setItem('checkout-platform', platformName || '')
          navigate('/checkout');
        } else {
          await updItems();
        }

        return;
      }

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const openTrialModal = useCallback(() => {
    setPaymentPopup((prev) => ({
      ...prev,
      isOpen: true,
    }));
  }, [paymentType]);

  const handleClose = useCallback(() => {
    setCheckedBoards([]);
    reset();
    closeNotification && closeNotification();
    abortController && abortController.abort();
    abortController = null
  }, []);

  const closeTrialPopup = useCallback(() => {
    setPaymentPopup({ ...paymentPopup, isOpen: false });
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="trello-repo-edit-title"
        aria-describedby="trello-repo-edit-description"
        classes={{
          paper: 'add-edit-trello-modal',
        }}
      >
        <DialogTitle id="trello-repo-edit-title">
          {translate('forms.trello_add_edit_repo.add_new_boards')}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>
        <DialogContent>
          {(subPlan && paymentType === EPaymentRequiredType.NON && (availableItemsLeft - checkedBoards.length < 0 || availableItemsLeft === 0)) && (
            <BadgesTrial platformName={'trello'} color='warning' isSub={subPlan.name !== "Trial"} isModal={true}/>
          )}
          <div id="trello-repo-edit-description">
              <div className="username-block">
                <div className='github-add-select-all'>
                  <div className='title-line'>
                    {translate('views.bulk_restore.itemName.trello.many')}
                  </div>
                  <div className='desc-line'>
                    {user?.username &&
                      <>
                        {`A list of all the boards for user “${user?.username}”.`}<br/>
                      </>
                    }
                    {availableItemsLeft - checkedBoards.length > 0
                      ? translate('forms.trello_add_edit_repo.boards_left', { left: availableItemsLeft - checkedBoards.length })
                      : translate('forms.trello_add_edit_repo.no_backups_left')
                    }
                  </div>
                </div>
                {(!!storeItems.length && !availableBoardsLoading || !!searchTerm || netSearch) && (
                  <div className="search-block">
                    <SearchInput
                      className="search-field"
                      type="text"
                      placeholder="Search boards..."
                      value={searchTerm}
                      onChange={onSearch}
                      endAdornment={
                        <InputAdornment position="end" className="search-icon">
                          <img src={searchIcon} loading="lazy"/>
                        </InputAdornment>
                      }
                    />
                  </div>
                )}
              </div>

              {(availableBoardsLoading || netSearch && !searchTerm) &&
              <div className="spinner-wrapper">
                <CircularProgress />
              </div>
              }
              {!availableBoardsLoading && (!netSearch || netSearch && !!searchTerm) &&
              <div className="scrollable-div" onScroll={handleScroll}>
                {!!filteredBoards.length && (
                  <div className="form-row head-row">
                    <Checkbox
                      className={'checkbox'}
                      checked={!searchTerm && (storeItems.length === checkedBoards.length || (!!checkedBoards.length && checkedBoards.length >= availableItemsLeft))}
                      // disabled={(availableItemsLeft - checkedBoards.length) <= 0 && !checkedBoards.length }
                      name="repoName"
                      onChange={(e) => {
                        handleSelectAllChange(e.target.checked);
                      }}
                      icon={<CheckboxInputEmpty/>}
                      checkedIcon={<CheckboxInputChecked />}
                    />
                    Name
                  </div>
                )}
                {!!filteredBoards.length && !availableBoardsLoading ?
                  filteredBoards.map(
                    (board, index) =>
                      <div className={(availableItemsLeft - checkedBoards.length) <= 0 && !checkedBoards.includes(board.id) ? 'form-row check-inactive' : 'form-row'} key={`${board.name}-${index}`}>
                        <label key={board.name} className="input-label-checkbox">
                          <Checkbox
                            className={'checkbox'}
                            value={board.id}
                            checked={checkedBoards.includes(board.id)}
                            name="repoName"
                            onChange={() => {
                              handleChange(board.id);
                            }}
                            icon={<CheckboxInputEmpty/>}
                            checkedIcon={<CheckboxInputChecked />}
                          />
                          {board.name.split('::')[0]}
                        </label>
                      </div>
                  ) :
                  <div className="form-row with-overflow">
                    {!!searchTerm?.length ?
                      translate('notifications.trello_boards.no_search') :
                      subPlan?.planQuantity === subPlan?.quantity ?
                        translate('notifications.trello_boards.nothing_to_add_empty') :
                        translate('notifications.trello_boards.nothing_to_add')
                    }
                  </div>
                }
                {scroll && (
                  <div className="spinner-wrapper">
                    <CircularProgress />
                  </div>
                )}
              </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.cancel')}
            </Button>
            {!!storeItems.length && (
              <Button
                onClick={
                  paymentType === EPaymentRequiredType.NON
                    ? handleSubmit(onSubmit)
                    : openTrialModal
                }
                variant="contained"
                color="primary"
                disabled={!checkedBoards.length || addingBoardsProcessing || ((availableItemsLeft - checkedBoards.length) < 0 && isUserSub)}
              >
                {addingBoardsProcessing &&
                <div className="small-spinner-wrapper">
                  <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                </div>
                }
                {addingBoardsProcessing
                  ? translate('notifications.choices.processing')
                  : checkedBoards.length > 1 ?
                    translate('forms.trello_add_edit_repo.add_board_num', {num: checkedBoards.length}) :
                    translate('forms.trello_add_edit_repo.add_board')}
              </Button>
              )}
          </div>
        </DialogContent>
      </Dialog>
      <PaymentRequiredPoppup
        isOpen={paymentPopup.isOpen}
        closeNotification={closeTrialPopup}
        type={paymentPopup.type}
        platformName={paymentPopup.platformName}
      />
    </>
  );
};

export default memo(TrelloBoardAdd);
