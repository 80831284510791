enum EUser {
  loginUser = 'user/login',
  loginUserG = 'user/loginG',
  getCurrentUser = 'user/get',
  stopLoading = 'user/stop',
  testLoading = 'user/test-load',
  proUser = 'user/pro',
  logout = 'user/logout',
  editUser = 'user/edit',
  updateAvatar = 'user/update-avatar',
  updateUser = 'user/update-user',
  profileUser = 'user/profile-user',
  unsubscribeProfile = 'user/unsubscribe-profile',
  putUserEmail = 'user/email-preferences',
  getUserEmail = 'user/email-preferences-gat',
  getStripeDefaultPayment = 'user/get-stripe-default-payment',
  setStripeDefaultPayment = 'user/set-stripe-default-payment',
  cancelAllSubs = 'user/cancel-all',
  cancelOneSub = 'user/cancel-one',
  getPaymentHistory = 'user/get-paymentHistory',
  resetUser = 'user/reset',
  actionUser = 'user/user-action',
  delTaxUser = 'user/del-tax',
  invite = 'user/invite',
  inviteUpd = 'user/invite-upd',
  setOrgName = 'user/set-org-name',
  userList = 'user/users-list',
  deleteUser = 'user/delete-user',
  sendActiveCampaignContact = 'send-active-campaign-contact'
}

export default EUser
