import {Chip as MuiChip, CircularProgress, MenuItem, Select, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {DataGrid, GridCellParams} from '@mui/x-data-grid';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  errorBigIcon,
  iconNotifySuccess,
  iconNotifyWarningDelete,
  masterCard,
  pen,
  visa
} from '../../../assets/icons/icons';
import {noSub} from '../../../assets/images/other';
import CardFormDialog from '../../../components/Dialogs/CardFormDialog/CardFormDialog';
import {Button} from '../../../components/StyledComponents';
import {
  ECardTypes,
  EPlatformPrices,
  EPlatformsNames, EProPlatformPrices,
  errorSubscriptionStatuses,
  EStripeSubStatus,
  inactiveSubscriptionStatuses,
  unpaidSubscriptionStatuses,
} from '../../../models/consts';
import {IError} from '../../../models/inner-models';
import {IHistoryItem, IPlatformSubscription} from '../../../models/models';
import Platform from '../../../models/platforms-data';
import {
  cancelOneSubscription,
  getAllSubscriptions,
  getStripeDefaultPaymentMethod, getStripePrices,
  getUsersPaymentsHistory,
} from '../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
  githubActualPlan,
  gitlabActualPlan,
  jiraActualPlan,
  loadingUser,
  notionActualPlan,
  paymentHistorySelector,
  stripePaymentMethod,
  trelloActualPlan
} from '../../../store/selectors';
import {decoratePopUpMessage} from '../../../utils/popUpTextDecorator';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import './style.scss';
import styled from 'styled-components';

export interface IPlatformSubscriptionRow extends IPlatformSubscription {
  id: number;
}

const Chip = styled(MuiChip)`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;

  &.unpaid {
    background-color: #FEF3C7;
    color: #92400E;
  }

  &.active {
    background-color: #D1FAE5;
    color: #065F46;
  }

  &.error {
    background-color: #FEE2E2;
    color: #991B1B;
  }
  
  &.inactive {
    background-color: #F3F4F6;
    color: #1F2937;
  }

  span {
    height: 20px;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    spinner: {
      color: '#684EE7',
    },
    featherIcon: {
      width: '15px',
      marginRight: '5px',
    },
    addIco: {
      width: '20px'
    }
  }),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const platformPlansLocal:Record<string, any> = {
  github: {cost: 12, amount: 10},
  gitlab: {cost: 12, amount: 10},
  trello: {cost: 5, amount: 5},
  jira: {cost: 2.50, amount: 1},
  notion: {cost: 10, amount: 1},
}

const Subscription: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const githubSubItem = useAppSelector(githubActualPlan);
  const trelloSubItem = useAppSelector(trelloActualPlan);
  const gitlabSubItem = useAppSelector(gitlabActualPlan);
  const jiraSubItem = useAppSelector(jiraActualPlan);
  const notionSubItem = useAppSelector(notionActualPlan);

  const userLoading = useAppSelector(loadingUser);
  const paymentHistoryItems = useAppSelector(paymentHistorySelector);
  const defaultPaymentMethod = useAppSelector(stripePaymentMethod);

  const [rows, setRows] = useState<Array<IPlatformSubscriptionRow>>();
  const [cardModalOpen, setCardModalOpen] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const openCardModal = () => {
    setCardModalOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        await dispatch(getStripePrices()).unwrap().then(res => {
          // console.log({...res.result})
        //   setStripeSub({...res.result})
        })
        dispatch(getUsersPaymentsHistory()).unwrap();
        await Promise.all([dispatch(getAllSubscriptions()).unwrap(), dispatch(getStripeDefaultPaymentMethod()).unwrap()])
        // await dispatch(getAllSubscriptions()).unwrap();
        // await dispatch(getUsersPaymentsHistory()).unwrap();
        // await dispatch(getStripeDefaultPaymentMethod()).unwrap();
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (userLoading) {
      return;
    }

    setRows([githubSubItem, gitlabSubItem, trelloSubItem, jiraSubItem, notionSubItem]);
  }, [githubSubItem, trelloSubItem, gitlabSubItem, jiraSubItem, notionSubItem, userLoading]);

  // const cancelSubs = useCallback(async () => {
  //   const onConfirmStopSub = async () => {
  //     try {
  //       await dispatch(cancelAllUsersSubscriptions());
  //
  //       await Swal.fire({
  //         title: translate('notifications.titles.success'),
  //         text: translate('notifications.subscription.canceled_all_successfully'),
  //         imageUrl: iconNotifySuccess,
  //       });
  //       location.reload();
  //
  //     } catch (err) {
  //       const error = err as IError;
  //
  //       Swal.fire({
  //         title: translate('notifications.titles.error'),
  //         text: decoratePopUpMessage(error.error as string),
  //         imageUrl: errorBigIcon,
  //         confirmButtonText: translate('notifications.choices.close'),
  //       });
  //     }
  //   };
  //
  //   return Swal.fire({
  //     title: translate('notifications.titles.warning'),
  //     text: translate('notifications.subscription.cancel_confirm_all'),
  //     imageUrl: iconNotifyWarningDelete,
  //     confirmButtonText: translate('notifications.choices.continue'),
  //     cancelButtonText: translate('notifications.choices.cancel'),
  //     showCancelButton: true,
  //     showLoaderOnConfirm: true,
  //     preConfirm: () => {
  //       return onConfirmStopSub();
  //     },
  //     allowOutsideClick: () => !Swal.isLoading(),
  //   });
  // }, []);

  const openPaymentHistory = (platform: string, paymentList: IHistoryItem[] | undefined) => {
    if (!paymentList) {
      return;
    }
    navigate(`/payment-history?platform=${platform}`)
  };

  const cancelOneSub = (platform: string) => {
    const onConfirmStopSub = async () => {
      try {
        dispatch(cancelOneSubscription(platform)).unwrap();

        await Swal.fire({
          title: translate('notifications.titles.success'),
          text: translate('notifications.subscription.canceled_successfully'),
          imageUrl: iconNotifySuccess,
        });
        location.reload();

      } catch (err) {
        const error = err as IError;

        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
        });
      }
    };

    return Swal.fire({
      title: translate('notifications.titles.warning'),
      text: translate('notifications.subscription.cancel_confirm'),
      imageUrl: iconNotifyWarningDelete,
      confirmButtonText: translate('notifications.choices.continue'),
      cancelButtonText: translate('notifications.choices.cancel'),
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor:'#6D4CF0',
      preConfirm: () => {
        return onConfirmStopSub();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  // const daysLeft = (finishDate) => {
  //   const [day, month, year] = finishDate.split('/');
  //   const finish = new Date(`${year}-${month}-${day}`);
  //   const today = new Date();
  //   const diff = finish.getTime() - today.getTime();
  //
  //   return Math.ceil(diff / (1000 * 60 * 60 * 24));
  // }

  const columns = [
    {
      field: 'platformName',
      headerName: translate('subscriptions.subscriptions_list.app'),
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      renderCell: (params: GridCellParams) => (
        <div className='subscriptions-logo'>
          <img src={Platform[params.value as EPlatformsNames]?.smallLogo} className="platformLogo" loading="lazy"/>
          {Platform[params.value as EPlatformsNames]?.title}
        </div>
      ),
    },
    {
      field: 'plan',
      headerName: translate('subscriptions.subscriptions_list.planName'),
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      cellClassName: 'plan',
      renderCell: (params: GridCellParams)=>{
        return !params.value || params.value === '-' ? 'N/A' : params.value === 'Trial' ? 'Free Trial' : params.value
      }
    },
    {
      field: 'planQuantity',
      headerName: translate('subscriptions.subscriptions_list.quantity'),
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      cellClassName: 'plan-quantity',
      renderCell: (params: GridCellParams)=>{
        return params.value === 0 ?
          params.row.status === 'active' && params.row.platformName === 'jira' ? '0 users' : // todo remove when all user will renew license
          `${platformPlansLocal[params.row.platformName]?.amount}+ ${params.row.platformName === 'jira' ? ' user' : translate(`views.bulk_restore.itemName.${params.row.platformName}.many`)} ${params.row.platformName === 'github' ?  '& Gists' : params.row.platformName === 'gitlab' ?  '& Snippets' : ''}` :
          params.value === 1 ?
            (params.row.platformName === 'jira' ? '1 user' : '1 Workspace') :
            params.value === 2002 ?
              'Unlimited users' :
              `${params.row.status === 'not started' ? 
                `${params.value}+` : 
                params.value} ${params.row.platformName === 'jira' ? 'users' : translate(`views.bulk_restore.itemName.${params.row.platformName}.many`)} ${params.row.platformName === 'github' ?  '& Gists' : params.row.platformName === 'gitlab' ?  '& Snippets' : ''}`
      }
    },
    {
      field: 'price',
      headerName: translate('subscriptions.subscriptions_list.price'),
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      cellClassName: 'price-cell',
      renderCell: (params: GridCellParams) => {
        return (
          <div className="price">
            {params.row.plan === 'Trial' ?
              params.row.status === 'not started' ?
                `Plans start from ${(Platform[params.row.platformName].plans[0].defaultPlan.cost || 5).toLocaleString("en-US", {style: "currency", currency: "USD"})} ${params.row.platformName === 'jira' ? 'per user' : ''}` :
                'Free'
              : (params.value.split(' / ')[0] === '$0.00' || params.value === '-') ?
                `Plans start from ${(Platform[params.row.platformName].plans[0].defaultPlan.cost || 5).toLocaleString("en-US", {style: "currency", currency: "USD"})} ${params.row.platformName === 'jira' ? 'per user' : ''}` :
                params.value.split(' / ')[0]
            }
            {(params.value !== '-' && !!params.row.planQuantity) && (
              <div className='price-sub'>
                {params.row.isCancel ?
                  'Canceled' :
                  params.value.split(' / ')[1] === 'yr.' ? 'Billed annually' : 'Billed monthly'
                }
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: 'status',
      headerName: translate('subscriptions.subscriptions_list.status'),
      flex: 1,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      cellClassName: 'subscription-table--cell',
      renderCell: (params: GridCellParams) => {
        let subscriptionStatusClass = 'active';
        let subscriptionStatus = translate('subscriptions.statuses.active');
        // let days = 0;

        if (params.row.isConnect && (unpaidSubscriptionStatuses.includes(params.value as EStripeSubStatus) || params.row.plan === 'Trial' && params.row.status === 'active')) {
          subscriptionStatusClass = 'unpaid';
          subscriptionStatus = params.row.plan === 'Trial' ? 'Trial period' : params.value.replaceAll('_', ' ');
          if (params.row.plan === 'Trial') {
            // days = daysLeft(params.row.finishDate)
          }
        } else if (!params.row.isConnect || params.row.plan === 'Trial' && params.row.status === 'not started' || errorSubscriptionStatuses.includes(params.value as EStripeSubStatus)) {
          subscriptionStatusClass = 'error';
          subscriptionStatus = params.value.replaceAll('_', ' ');
          if (!params.row.isConnect || params.row.status === 'not started' && params.row.plan === 'Trial') {
            subscriptionStatus = 'Not connected'
            // days = 14
          }
        } else if (inactiveSubscriptionStatuses.includes(params.value as EStripeSubStatus)) {
          subscriptionStatusClass = 'inactive';
          subscriptionStatus = params.value.replaceAll('_', ' ');
        }

        return (
          <div className='status'>
            <Chip label={subscriptionStatus} className={subscriptionStatusClass}/>
            <br/>
            {params.row.finishDate && params.row.status === 'active' && params.row.isConnect && (//&& params.row.plan !== 'Trial'
              <span className='active-until'>Until: {params.row.finishDate}</span>
            )}
            {/*{params.row.platformName !== 'notion' && params.row.finishDate && params.row.status === 'active' && params.row.plan === 'Trial' && (*/}
            {/*  <span className='active-until'>{days} {days > 1 ? 'days' : 'day'} remaining </span>*/}
            {/*)}*/}
          </div>
        );
      },
    },
    {
      field: '',
      flex: 1,
      minWidth: 300,
      editable: false,
      sortable: false,
      headerClassName: 'subscription-table--header',
      renderCell: (params: GridCellParams) => {
        const paymentList = paymentHistoryItems?.filter((item) => item.product_id === EPlatformPrices[params.row.platformName.toLowerCase()] || item.product_id === EProPlatformPrices[params.row.platformName.toLowerCase()])

        return (
          <div className="last-column">
            <div className="action-buttons subscriptions-buttons">
                <Button
                  mr={4} color="primary"
                  variant={params.row.plan !== 'Trial' ? params.row.status === 'active' && params.row.isConnect
                    ? "outlined" : "contained" : "contained"}
                  onClick={() => {
                    if ((params.row.plan === 'Trial' && params.row.status === 'not started') || !params.row.isConnect) {
                      navigate('/app-connector');
                    } else {
                      if (localStorage['checkoutContact']) {
                        localStorage.removeItem('checkoutContact')
                      }
                      localStorage.setItem('checkout-platform', params.row.platformName)
                      navigate('/checkout');
                    }
                  }}
                  disabled={
                    params.row.plan !== 'Trial' && params.row.isConnect && params.row.status === 'active' &&
                    ((params.row.platformName === 'jira' && params.row.planQuantity > 2000) || ((params.row.platformName === 'trello' || params.row.platformName === 'notion') && params.row.planQuantity > 200) ||
                      ((params.row.platformName === 'github' || params.row.platformName === 'gitlab') && params.row.planQuantity > 1000))
                  }
                >
                  {params.row.plan !== 'Trial' && params.row.status === 'active' && params.row.isConnect && (
                    <img src={pen} className={classes.featherIcon} loading="lazy"/>
                  )}
                  {params.row.plan !== 'Trial' && params.row.isConnect ? params.row.status === 'active'
                    ? translate('subscriptions.edit_sub')
                    : translate('subscriptions.buy_subscription') :
                   !params.row.isConnect && params.row.status !== 'not started'?
                      translate('subscriptions.connect_now') :
                     params.row.status === 'not started' ?
                       translate('subscriptions.start_trial') :
                       translate('subscriptions.buy_subscription')
                  }
                </Button>

                <Select
                  value='more'
                  className='subscriptions-select'
                  MenuProps={{id: 'sub-more-select'}}
                >
                  <MenuItem value='more' disabled sx={{display: 'none'}}>
                    More
                  </MenuItem>
                  <MenuItem
                    value='history-list'
                    onClick={() => openPaymentHistory(params.row.platformName, paymentList)}
                    disabled={!paymentHistoryItems || !paymentList || !paymentList?.length}
                  >
                    {translate('common.default_pages.payment_history')}
                  </MenuItem>
                  <MenuItem
                    value='cancel-sub'
                    sx={{color: '#EF4444'}}
                    onClick={() => cancelOneSub(params.row.platformName)}
                    disabled={params.row.status !== 'active' || params.row.plan === 'Trial' || params.row.isCancel}
                  >
                    {translate('subscriptions.cancel_subscription')}
                  </MenuItem>
                </Select>
            </div>
          </div>
        )
      },
    }
  ];

  return (
    <div id="subscription-page">
      {userLoading || loadingData ?
        <div className="spinner-wrapper">
          <CircularProgress />
        </div> :
        <div className="subscriptions-block">
          <Typography variant="h3" display="block" mb='32px' className='page-title-h3'>
            {translate('subscriptions.subscriptions_list.subs_overview')}

            <Button mr={2} variant="contained" color="primary"
                    onClick={() => navigate('/app-connector')}
            >
              <Plus className={classes.addIco} />
              {translate('common.buttons.addPlatform')}
            </Button>
          </Typography>
          {rows && rows.length ?
            <>
              <div className="action-line">
                {
                  defaultPaymentMethod && <div className="payment-method">
                    Payment method:
                    <div className="card-element">
                      <img className="card-icon"
                           src={defaultPaymentMethod?.cardType === ECardTypes.MASTERCARD ? masterCard : visa} loading="lazy"/>
                      {'**** ' + defaultPaymentMethod?.lastDigits}
                      <img src={pen} className="edit-icon" onClick={openCardModal} loading="lazy"/>
                    </div>
                  </div>
                }
                {/*<Button mr={2} variant="contained" color="primary" onClick={cancelSubs}*/}
                {/*        disabled={!isActiveSubscriptions(rows)}*/}
                {/*>*/}
                {/*  <XCircle className={classes.featherIcon} />*/}
                {/*  {translate('subscriptions.subscriptions_list.cancel_all')}*/}
                {/*</Button>*/}
              </div>
                <DataGrid
                  disableColumnMenu
                  disableColumnFilter
                  rows={rows}
                  disableSelectionOnClick
                  rowHeight={87}
                  hideFooter
                  autoHeight
                  columns={columns}
                  disableColumnSelector
                />
            </>
            :
            <div className="no-subs-placeholder">
              <img src={noSub} loading="lazy"/>
              <div className="subtitle">{translate('subscriptions.no_subscription')}</div>
              <div className="description">{translate('subscriptions.purchase_sub')}</div>
            </div>
          }
        </div>
      }
      <CardFormDialog
        isOpen={cardModalOpen}
        closeNotification={() => setCardModalOpen(false)}
      />
    </div>
  );
};

export default withRedirectAfterLogout()(Subscription);
