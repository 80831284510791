import { GitHub, Gitlab, Trello } from 'react-feather';
import Jira from '../assets/icons/CustomSvg/Jira';
import {asana, github, gitlab, jira, trello, notion, githubSm, gitlabSm, jiraSm, trelloSm, notionSm, asanaSm} from '../assets/images/platforms';
import { TPlatforms } from './inner-models';
import { githubPlans } from './paymentPlans/github-payments-plan';
import { gitlabPlans } from './paymentPlans/gitlab-payments-plan';
import { jiraPlans } from './paymentPlans/jira-payments-plan';
import { trelloPlans } from './paymentPlans/trello-payments-plan';
import { notionPlans } from './paymentPlans/notion-payments-plan';
import Notion from "../assets/icons/CustomSvg/Notion";

const PlatformsInfo: TPlatforms = {
  github: {
    title: 'GitHub',
    url: 'https://github.com/',
    logo: github,
    smallLogo: githubSm,
    miniatureLogo: GitHub,
    plans: githubPlans,
  },
  trello: {
    title: 'Trello',
    url: 'https://trello.com/',
    logo: trello,
    smallLogo: trelloSm,
    miniatureLogo: Trello,
    plans: trelloPlans,
  },
  gitlab: {
    title: 'GitLab',
    url: 'https://gitlab.com/',
    logo: gitlab,
    smallLogo: gitlabSm,
    plans: gitlabPlans,
    miniatureLogo: Gitlab,
  },
  jira: {
    title: 'Jira',
    url: 'https://www.atlassian.com/software/jira',
    logo: jira,
    smallLogo: jiraSm,
    plans: jiraPlans,
    miniatureLogo: Jira,
  },
  notion: {
    title: 'Notion',
    url: 'https://notion.so/',
    logo: notion,
    smallLogo: notionSm,
    plans: notionPlans,
    miniatureLogo: Notion,
  },
  asana: {
    title: 'Asana',
    logo: asana,
    smallLogo: asanaSm,
    plans: [],
  },
  // airtable: {
  //   title: 'Airtable',
  //   logo: airtable,
  //   smallLogo: airtableSm,
  //   plans: [],
  // },
  // bitbucket: {
  //   title: 'Bitbucket',
  //   logo: bitbucket,
  //   smallLogo: bitbucketSm,
  //   plans: [],
  // },
  // wrike: {
  //   title: 'Wrike',
  //   logo: wrike,
  //   smallLogo: wrikeSm,
  //   plans: [],
  // },
  // clickup: {
  //   title: 'ClickUp',
  //   logo: clickup,
  //   smallLogo: clickupSm,
  //   plans: [],
  // },
};

export default PlatformsInfo;
