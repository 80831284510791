import Swal from 'sweetalert2';
import {errorBigIcon, iconNotifyWarningInfo, toastSuccess} from '../../assets/icons/icons';
import { IError } from '../../models/inner-models';
import { decoratePopUpMessage } from '../popUpTextDecorator';

const restore = async (callback, translate, platform?) => {
  const onConfirm = async () => {
    try {
      await callback();
      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: 'Your restore is now in process',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError

      console.log(error);
      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  // return Swal.fire({
  //   title: translate('notifications.titles.warning'),
  //   text: platform && platform === 'jira' ?
  //     'Do you want to restore? All backed up data will be added to the selected project' :
  //     'Do you want to restore? It will be created as a NEW item',
  //   imageUrl: iconNotifyWarningInfo,
  //   confirmButtonText: translate('notifications.choices.continue'),
  //   cancelButtonText: translate('notifications.choices.cancel'),
  //   showCancelButton: true,
  //   showLoaderOnConfirm: true,
  //   preConfirm: () => {
      return onConfirm();
    // },
    // allowOutsideClick: () => !Swal.isLoading(),
  // });
};

export default restore;
