import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { SidebarItemsType } from '../../types/sidebar';
import { Items, PerfectScrollbar, Scrollbar } from '../StyledComponents';
import SidebarNavSection from './SidebarNavSection';
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {DRAWER_WIDTH} from "../../models/consts";
import {useNavigate} from "react-router-dom";
import logo from "../../assets/icons/backuplabs-logo-white.png";

type SidebarNavProps = {
  bigMenu?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
};

const useStyles = makeStyles(() =>
  createStyles({
      logoBlock: {
        width: DRAWER_WIDTH,
        height: '64px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
    brandBlock: {
      cursor: 'pointer',
      '& .logo': {
        width: '116px',
        height: 'auto',
        margin: '8px 10px -2px 16px'
      }
    },
      menuButton: {
        position: 'absolute',
      },
      svg: {
        width: '22px',
        height: '22px',
      },
    },
  ),
);

const SidebarNav: React.FC<SidebarNavProps> = ({ bigMenu = true, items, onClose }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType;

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <ScrollbarComponent>
      <div className={classes.logoBlock}>
        <div className={classes.brandBlock} onClick={() => {
          navigate('/');
        }}>
          <img className="logo" src={logo} loading="lazy"/>
        </div>
      </div>
      <List disablePadding>
        <Items>
          {items &&
          items.map((item) => (
            <SidebarNavSection
              bigMenu={bigMenu}
              component="div"
              key={item.title}
              pages={item.pages}
              title={item.title}
              onClose={onClose}
            />
          ))}
        </Items>
      </List>
      {/*<div className="help-circle" onClick={() => {*/}
      {/*  window.location.replace('mailto:support@backuplabs.io');*/}
      {/*}}>*/}
      {/*  <HelpCircle className="icon" />*/}
      {/*  {!isMobileOnly && bigMenu && translate('common.default_pages.support')}*/}
      {/*</div>*/}
    </ScrollbarComponent>
  );
};

export default SidebarNav;
