import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components/StyledComponents';
import {IPoppupBase} from "../../../../../models/inner-models";
import './styyle.scss'

const NotionTutorial: FunctionComponent<IPoppupBase> = ({isOpen, closeNotification}: IPoppupBase) => {
  const { t: translate } = useTranslation();

  const handleClose = () => {
    if (localStorage['showUpdNotionPopup']) {
      localStorage.removeItem('showUpdNotionPopup')
    }
    if (closeNotification) {
      closeNotification();
    }
  }
  const instructionData = [
    {
      title: 'Introduction',
      text: 'Every time you create a new Teamspace or page, you need to manually include them to ensure your Notion backups are complete. This cannot be automated due to API limitations.',
    },
    {
      title: 'Step 1',
      text: 'Inside Notion, select <strong><em>Settings & members</em></strong> in the top left of the page.',
      urlImg: 'https://bl-public-media.s3.amazonaws.com/notion/BL-Notion-Tutorial-01.jpg',
    },
    {
      title: 'Step 2',
      text: 'Then select <strong><em>My connections</em></strong>.',
      urlImg: 'https://bl-public-media.s3.amazonaws.com/notion/BL-Notion-Tutorial-02.jpg',
    },
    {
      title: 'Step 3',
      text: 'On the right side of the menu, select the <strong><em>3 dots.</em></strong>',
      urlImg: 'https://bl-public-media.s3.amazonaws.com/notion/BL-Notion-Tutorial-03.jpg',
    },
    {
      title: 'Step 4',
      text: 'Select <strong><em>Access selected pages</em></strong> and then tick all the Teamspaces and pages you want included in future backups. Review and then select <strong><em>Update access.</em></strong> ',
      urlImg: 'https://bl-public-media.s3.amazonaws.com/notion/BL-Notion-Tutorial-04.jpg',
    },
    {
      title: 'IMPORTANT',
      text: 'If any of those items are not selected, they will not be included in either Backup now or daily automated backup operations.',
    },
    {
      title: 'Step 5',
      text: 'Inside BackupLABS, on your Notion backup page, select <strong><em>Backup now</em></strong> to ensure everything is included and up to date.',
      urlImg: 'https://bl-public-media.s3.amazonaws.com/notion/BL-Notion-Tutorial-05.jpg',
    },
    {
      title: 'NOTE',
      text: 'This step is not necessary. Backups will also be complete every 24 hours in the automated operation.',
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="notion-modal-title"
      aria-describedby="notion-modal-info"
      classes={{
        paper: 'notification-modal notion-modal',
      }}
    >
      {/*<div className="close-btn" onClick={handleClose}>*/}
      {/*  <img src={closeModalIcon} />*/}
      {/*</div>*/}
      <DialogTitle id="notion-modal-title">
        {translate('notifications.notion_tutorial.title')}
      </DialogTitle>
      <DialogContent>
        <div id="notion-modal-info">
          {instructionData.map((step, index) => (
            <p key={index}>
              <div id="notion-modal-info-step">{step.title}</div>
              <div dangerouslySetInnerHTML={{ __html: step.text }} />
              {step.urlImg && <img src={step.urlImg} alt={`Step ${index + 1}`} loading="lazy"/>}
            </p>
          ))}
        </div>
      </DialogContent>

      <div className="action-buttons">
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="contained" color="primary"
        >
          I understand
        </Button>
      </div>
    </Dialog>
  );
};

export default NotionTutorial;
