import {CardContent, CircularProgress} from '@mui/material';
import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import {errorBigIcon, iconNotifySuccess, iconNotifyWarningInfo} from '../../../../assets/icons/icons';
import NoSubscriptionBlock from '../../../../components/Blocks/NoSubscriptionBlock/NoSubscriptionBlock';
import ValidationErrorBlock from '../../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import {Button, Card, FormControl, TextField} from '../../../../components/StyledComponents';
import {EPlatformsNames, ESubscriptionPlans, ETrialStatus} from '../../../../models/consts';
import {IError, IGithubUserAddEditProps} from '../../../../models/inner-models';
import {IGithubUser} from '../../../../models/models';
import {
  addGithubUser,
  editGithubUser,
  fetchGithubUsers,
  changeGithubAutoAdd,
  sendConfirmGithubUser as sendConfirmGithubUserAction,
  confirmGithubUser as confirmGithubUserAction, getAllSubscriptions, getUser
} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {getGithubAutoAddSelector, githubTrialStatus} from '../../../../store/selectors';
import {CustomToggle} from '../../../../styles/components/CustomToggle';
import {isGithubAppInstalled, isGithubConfirmed} from '../../../../utils/functions/isGithubAppInstalled';
import {decoratePopUpMessage} from '../../../../utils/popUpTextDecorator';
import './style.scss';
import {ExternalLink} from "react-feather";
import Platform from "../../../../models/platforms-data";

const GithubUserAddEdit: FunctionComponent<IGithubUserAddEditProps> = ({
                                                                         user,
                                                                         subPlan,
                                                                         onClose,
                                                                         page
  }: IGithubUserAddEditProps) => {
    const dispatch = useAppDispatch();
    const {t: translate} = useTranslation();
    const trialState = useAppSelector(githubTrialStatus);
    const autoAddSelector = useAppSelector(getGithubAutoAddSelector);
    const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);
    const [autoAdd, setAutoAdd] = useState<boolean>(false);
    const installBtn = useRef<HTMLButtonElement>(null);
    const [codeSent, setCodeSent] = useState<boolean>(false);

    const {register, formState: {errors, isValid, isDirty}, getValues, handleSubmit, reset} = useForm({
      mode: 'onChange',
    });

    useEffect(()=>{
      setAutoAdd(!!autoAddSelector)
    },[autoAddSelector])

    const sendConfirmationCode = useCallback(async () => {
      try {
        const message = await dispatch(sendConfirmGithubUserAction()).unwrap();
        setCodeSent(true);

        await Swal.fire({
          imageUrl: iconNotifySuccess,
          title: translate('notifications.titles.success'),
          text: decoratePopUpMessage(message),
          cancelButtonText: translate('notifications.choices.close'),
          showCancelButton: true,
          confirmButtonText: translate('notifications.choices.enter_code'),
          preConfirm: () => {
            return confirmUser();
          },
        });
      } catch (e) {
        const error = e as IError;
        setCodeSent(false);

        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          showConfirmButton: true,
          confirmButtonText: error.code === "err.app.missing" ?
            translate('forms.github_add_edit_user.github_app') :
            error.code === "err.app.delay" ?
              translate('notifications.choices.enter_code') :
              translate('common.default_pages.contact_us'),
          showCancelButton: true,
          cancelButtonText: translate('notifications.choices.close'),
          imageUrl: errorBigIcon,
        })
          .then(res => {
            if (res.value) {
              switch (error?.code) {
                case "err.app.missing":
                  window.open(process.env.REACT_APP_GITHUB_APP_INSTALL_URL, '_blank')
                  break;
                case "err.app.delay":
                  return confirmUser();
                default:
                  window.location.pathname = '/contact'
                  break
              }
            }
          });
      }
    }, []);

    const onConfirmUser = async (code: string) => {
      try {
        const message = await dispatch(confirmGithubUserAction(code)).unwrap();
        await dispatch(fetchGithubUsers()).unwrap().catch(err => console.log(err));
        dispatch(getUser()).unwrap()

        await Swal.fire({
          title: translate('notifications.titles.success'),
          text: decoratePopUpMessage(message as string),
          confirmButtonText: translate('notifications.choices.close'),
          imageUrl: iconNotifySuccess,
        });
        // window.location.reload()
      } catch (e) {
        const error = e as IError;

        await Swal.fire({
          imageUrl: errorBigIcon,
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          confirmButtonText: translate('notifications.choices.close'),
        });
      }
    };

    const sendCodeDialog = useCallback(async () => {
      if (codeSent) {
        return confirmUser();
      }

      return Swal.fire({
        title: translate('notifications.github_user.confirm_ownership'),
        text: translate('notifications.github_user.confirm_user'),
        showCancelButton: true,
        cancelButtonText: translate('notifications.choices.cancel'),
        denyButtonText: translate('notifications.choices.enter_code'),
        confirmButtonText: translate('notifications.choices.send'),
        imageUrl: iconNotifyWarningInfo,
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return sendConfirmationCode();
        },
        preDeny: () => {
          setCodeSent(true);
          confirmUser()
        },
      });
    }, [codeSent]);

    const confirmUser = async () => {
      await Swal.fire({
        text: 'Enter confirmation code',
        input: 'text',
        imageUrl: iconNotifyWarningInfo,
        inputAttributes: {
          autocapitalize: 'off',
        },
        confirmButtonText: translate('notifications.choices.confirm'),
        cancelButtonText: translate('notifications.choices.close'),
        denyButtonText: translate('notifications.choices.resend'),
        showCancelButton: true,
        showDenyButton: true,
        showLoaderOnConfirm: true,
        showLoaderOnDeny: true,
        customClass: {
          container: 'confirm-input-modal'
        },
        preConfirm: (code) => {
          return onConfirmUser(code);
        },
        preDeny: () => {
          return sendConfirmationCode();
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    };

    useEffect(() => {
      if (!user) {
        return;
      }
      reset({username: user.username?.replaceAll(' ', '') as string});
    }, [user]);

    useEffect(() => {
      if (installBtn.current) {
        installBtn.current.setAttribute('target', '_blank')
      }
    }, [installBtn])

    const onSubmit: SubmitHandler<IGithubUser> = async (data) => {
      const adaptedData = {
        username: data?.username?.replaceAll(' ', '') || '',
        auto_add: autoAdd,
      };
      try {
        setIsUpdatingUser(true);
        if (user && user.username) {

          const confirmationChoice = await Swal.fire({
            title: translate('notifications.titles.warning'),
            text: translate('notifications.github_user.change'),
            confirmButtonText: translate('notifications.choices.continue'),
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: translate('notifications.choices.cancel'),
            showCancelButton: true,
            imageUrl: iconNotifyWarningInfo,
          });

          if (!confirmationChoice.isConfirmed) {
            return;
          }
        }

        const request = await dispatch(!user?.username ? addGithubUser(adaptedData) : editGithubUser(adaptedData)).unwrap();
        await dispatch(fetchGithubUsers()).unwrap();

        if (onClose && !!subPlan) {
          //todo fix pagination
          // await dispatch(fetchItemsForGithubUser({page: page || 0, perPage: 10, placeId: localStorage['githubUser'] as number})).unwrap()
          // await dispatch(fetchItemsForGithubUser({page: page || 0, perPage: 10})).unwrap()
        }

        const userChoice = await Swal.fire({
          title: translate('notifications.titles.success'),
          text: translate('notifications.github_user.failed_changed'),
          imageUrl: iconNotifyWarningInfo,
          cancelButtonText: translate('notifications.choices.nevermind'),
          showCancelButton: true,
          confirmButtonText: (!user?.username || adaptedData.username !== user.username) && !request.is_app_installed
            ? translate('forms.github_add_edit_user.install_github_app')
            : translate('notifications.choices.close'),
        });

        if (userChoice.isConfirmed && process.env.REACT_APP_GITHUB_APP_INSTALL_URL && !request.is_app_installed) {
          window.open(process.env.REACT_APP_GITHUB_APP_INSTALL_URL, '_self');
        }

        if (user && adaptedData.username === user.username) {
          return;
        }

      } catch (err) {
        const error = err as IError;

        console.log(err);

        if (onClose && !!subPlan) {
          onClose()
        }

        if (error.code === 404) {
          const userFailed = await Swal.fire({
            title: translate('notifications.titles.warning'),
            text: translate('notifications.github_user.failed_changed'),
            imageUrl: iconNotifyWarningInfo,
            cancelButtonText: translate('notifications.choices.cancel'),
            showCancelButton: true,
            confirmButtonText: translate('forms.github_add_edit_user.install_github_app')
          });
          if (userFailed.isConfirmed && process.env.REACT_APP_GITHUB_APP_INSTALL_URL) {
            window.open(process.env.REACT_APP_GITHUB_APP_INSTALL_URL, '_self');
          }
        } else if (error.code !== 404) {
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.error as string),
            imageUrl: errorBigIcon,
          });
        }
      } finally {
        setIsUpdatingUser(false);
      }
    };

    return (
      <>
        {subPlan?.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED ?
          <NoSubscriptionBlock platformName={EPlatformsNames.GITHUB}/>
          :
          <Card mb={6}>
            <CardContent>
              <div className='newapp-step-two-log'>
                <p className='newapp-step-two-title'>
                  Connecting to {Platform.github.title}
                </p>
                <div className='newapp-step-two-app'>
                  <div className='platformLogoContainer'>
                    <img className="platformLogo" src={Platform.github.smallLogo} loading="lazy"/>
                  </div>
                  <p className='newapp-step-two-app-title'>
                    {Platform.github.title}
                  </p>
                </div>
              </div>
              <div id="github-repo-user-description">
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row github-autoadd-block">
                    <FormControl fullWidth>
                      <div className='github-autoadd-block-title'>{translate('forms.github_add_edit_user.auto_add')}</div>
                      <div className='github-autoadd-block-desc'>
                        {translate('forms.github_add_edit_user.auto_add_desc')}
                      </div>
                      <div className='github-autoadd-block-desc'>
                        {translate('forms.github_add_edit_user.auto_add_desc_sub')}
                      </div>
                    </FormControl>
                    <CustomToggle
                      checked={autoAdd}
                      onClick={() => {
                        if (!!user?.username?.length && getValues('username') !== '') {
                          const adaptedData = {
                            // username: user?.username,
                            auto_add: !autoAdd
                          };
                          dispatch(changeGithubAutoAdd(adaptedData))
                        }
                        setAutoAdd(!autoAdd);
                      }}
                    />
                  </div>

                  <p className='newapp-step-two-desc'>
                    Please authorize BackupLABS to access your {Platform.github.title} cloud data:
                  </p>
                  <div className="form-row github-user-change-block">
                    <FormControl className='input-name'>
                      <TextField
                        required
                        {...register('username', {
                          required: translate('forms.common.required') as string,
                          minLength: {
                            value: 2,
                            message: translate('forms.common.min_length'),
                          },
                        })}
                        id="user-name"
                        placeholder={translate('forms.github_add_edit_user.account_name')}
                        // label={translate('forms.github_add_edit_user.account_name')}
                        error={!!errors.username}
                        InputLabelProps={{shrink: true}}
                      />
                      {errors.username && !isUpdatingUser &&
                        <ValidationErrorBlock errorMessage={errors.username.message as string}/>
                      }
                    </FormControl>
                  </div>

                  {user && !isGithubAppInstalled(user) &&
                    <div className="form-row">
                      <p className="github-app-tooltip">
                        {translate('forms.github_add_edit_user.github_app_required')}
                        <b>
                          <a className="link" href={process.env.REACT_APP_GITHUB_APP_INSTALL_URL} target="_blank"
                             rel="noreferrer">
                            {translate('forms.github_add_edit_user.github_app')}
                          </a>
                        </b>
                        {translate('forms.github_add_edit_user.organization_tooltip')}
                      </p>
                    </div>
                  }
                  <div className="action-buttons">
                    {onClose && !subPlan && (
                      <Button
                        variant="outlined" color="primary"
                        className='newapp-back'
                        onClick={onClose}
                      >
                        Back
                      </Button>
                    )}
                    {user?.username && !isGithubAppInstalled(user) && (
                      <Button
                        ref={installBtn}
                        mr={4}
                        variant="contained"
                        color="primary"
                        href={process.env.REACT_APP_GITHUB_APP_INSTALL_URL}
                      >
                        {translate('forms.github_add_edit_user.github_app')}
                        <ExternalLink className="feather-icon-in-button icon-ml"/>
                      </Button>
                    )}
                    {user?.username && !isGithubConfirmed(user) && isGithubAppInstalled(user) && (
                      <Button
                        mr={4}
                        variant="contained"
                        color="primary"
                        onClick={sendCodeDialog}
                      >
                        {translate('notifications.choices.confirm_user')}
                        {/*<ExternalLink className="feather-icon-in-button icon-ml"/>*/}
                      </Button>
                    )}
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isUpdatingUser || !isDirty}
                    >
                      {isUpdatingUser &&
                        <div className="small-spinner-wrapper">
                          <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                        </div>                      }
                      {isUpdatingUser
                        ? translate('notifications.choices.processing')
                        : !user?.username
                          ? translate('forms.github_add_edit_user.add_new_account')
                          : translate('forms.github_add_edit_user.change_account')
                      }
                      <ExternalLink className="feather-icon-in-button icon-ml"/>
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        }
      </>
    );
  }
;

export default GithubUserAddEdit;
