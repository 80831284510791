import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {confirmPasswordRecover, updateUserData} from "../../../store/actions";
import {useAppDispatch} from "../../../store/hooks";
import {IRecoverPasswordConfirm} from "../../../models/models";
import Swal from "sweetalert2";
import {attentionIcon, errorBigIcon, toastSuccess} from "../../../assets/icons/icons";
import {IError, IPasswordsVisiblity, IRecoverPasswordForm} from "../../../models/inner-models";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthWrapper as Wrapper, Button, FormControl, TextField} from "../../../components/StyledComponents";
import ValidationErrorBlock from "../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock";
import {ELocalStoreKeys, EMAIL_REGEX, MAX_INPUT_VALUE_LENGTH, PASSWORD_REGEX} from "../../../models/consts";
import {CircularProgress, InputAdornment} from "@mui/material";
import IsVisibleButton from "../../../components/Buttons/IsVisibleButton/IsVisibleButton";
import {zxcvbn, zxcvbnOptions} from "@zxcvbn-ts/core";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {useForm, useWatch} from "react-hook-form";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import {Helmet} from 'react-helmet';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      margin: 0
    },
    textField: {
      marginTop: '4px',
      width: '100%',
      height: '42px',
      '& input': {
        height: '42px',
        boxSizing: 'border-box'
      },
      '& .Mui-disabled': {
        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.6)'
      }
    },
    fullWidthButton: {
      width: '100%',
      marginTop: '16px',
      padding: '0',
      fontSize: '14px',
      fontWeight: '600',
      height: '44px',
      boxSizing: 'border-box',
      border: '1px solid #6D4CF0'
    },
    resend: {
      cursor: 'pointer',
      fontSize: '14px',
      textDecoration: 'underline',
      textAlign: 'right'
    }
  }),
);

const SetupPassword = () => {
  const {t: translate} = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const {register, formState: {errors, isValid}, watch, setValue, control} = useForm({
    mode: 'onChange',
  });
  const [visible, setPasswordVisiblity] = useState<IPasswordsVisiblity>({
    password: false
  });
  const [notMatchError, setNotMatchError] = useState<boolean>(true);
  const emailUrl = searchParams.get('email') || ''
  const codeUrl = searchParams.get('code') || ''
  const mainPass = watch('password');
  const firstName = watch('firstName');
  const lastName = watch('lastName');

  useEffect(() => {
    setValue('email', emailUrl)
  }, [emailUrl]);

  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  }
  zxcvbnOptions.setOptions(options)

  useEffect(() => {
    if (PASSWORD_REGEX.test(mainPass) && !(!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
      (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0))) {
      setNotMatchError(false);
    } else {
      setNotMatchError(true);
    }
  }, [mainPass]);

  const handleClickShowPassword = (field: keyof IPasswordsVisiblity) => () => {
    setPasswordVisiblity({...visible, [field]: !visible[field]});
  };

  const handlePasswordSetup = async () => {
    const recoverPassword: IRecoverPasswordConfirm = {
      email: emailUrl,
      confirmation_code: codeUrl,
      new_password: mainPass,
    };
    const data = {
      lastName: lastName,
      firstName: firstName,
    }
    setProcessing(true)
    try {
      console.log(recoverPassword)
      console.log(data)

      const response = await Auth.signIn(emailUrl, codeUrl)
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {

        const resp = await Auth.completeNewPassword(
          await Auth.signIn(emailUrl, codeUrl),
          mainPass,
          {
            name: firstName,
            family_name: lastName
          }
        );
        const newIdToken = resp.AuthenticationResult?.IdToken;

        localStorage.setItem(ELocalStoreKeys.TOKEN, newIdToken);
        await dispatch(updateUserData(data))
        await Auth.signOut()
        localStorage.clear()

      } else {
        // Handle successful login
        console.log('Login successful or link invalid:', response);
      }
      await Swal.fire({
        title: 'Welcome aboard!',
        text: 'Your account setup is complete, and you\'re all set to go. Dive in and explore everything we have to offer!',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });

      navigate('/auth/login', { replace: true });
      setProcessing(false)
    } catch (err) {
      const error = err as IError;
      setProcessing(false)
      console.log(error);
      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>BackupLABS Reset Password</title>
        <meta name="title" content="BackupLABS Setup user"/>
        <meta name="description" content="Setup new user"/>
      </Helmet>
      <Wrapper id='invite-user-setup'>
        <h2 className={classes.title}>Setup New User</h2>
        <div className="notification-text">
          <img src={attentionIcon} loading="lazy"/>
          <div>
            {translate('forms.setup_user.desc')}
          </div>
        </div>
        <div id="confirmed-recover-password">
          <div>
            <div className="form-row">
              <div className="label-block">{translate('forms.common.email')}</div>
              <FormControl fullWidth={true}>
                <TextField
                  className={classes.textField}
                  placeholder={translate('forms.common.email')}
                  required
                  id="email"
                  type="email"
                  disabled
                  {...register('email', {
                    required: translate('forms.common.required') as string,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: translate('forms.common.invalid_email'),
                    },
                  })}
                  error={!!errors.email}
                />
                {errors.email &&
                  <ValidationErrorBlock errorMessage={errors.email.message as string}/>
                }
              </FormControl>
            </div>
            <div className="form-row">
              <div className="label-block">{translate('forms.common.first_name')}</div>
              <FormControl fullWidth={true}>
                <TextField
                  className={classes.textField}
                  placeholder={translate('forms.common.first_name')}
                  required
                  id="first-name"
                  {...register('firstName', {
                    required: translate('forms.common.required') as string,
                    maxLength: {
                      value: MAX_INPUT_VALUE_LENGTH,
                      message: translate('forms.common.max_length50'),
                    },
                  })}
                  error={!!errors.firstName}
                />
                {errors.firstName &&
                  <ValidationErrorBlock errorMessage={errors.firstName.message as string}/>
                }
              </FormControl>
            </div>
            <div className="form-row">
              <div className="label-block">{translate('forms.common.last_name')}</div>
              <FormControl fullWidth={true}>
                <TextField
                  className={classes.textField}
                  placeholder={translate('forms.common.last_name')}
                  required
                  id="last-name"
                  {...register('lastName', {
                    required: translate('forms.common.required') as string,
                    maxLength: {
                      value: MAX_INPUT_VALUE_LENGTH,
                      message: translate('forms.common.max_length50'),
                    },
                  })}
                  error={!!errors.lastName}
                />
                {errors.lastName &&
                  <ValidationErrorBlock errorMessage={errors.lastName.message as string}/>
                }
              </FormControl>
            </div>
            <div className="form-row">
              <div className="label-block">{translate('forms.recover_password.new_pass')}</div>
              <FormControl fullWidth={true}>
                <TextField
                  className={classes.textField}
                  placeholder={translate('forms.common.password')}
                  required
                  id="password"
                  type={visible.password ? 'text' : 'password'}
                  {...register('password', {
                    required: translate('forms.common.required') as string,
                    maxLength: {
                      value: MAX_INPUT_VALUE_LENGTH,
                      message: translate('forms.common.max_length50'),
                    },
                  })}
                  error={!!errors.password}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IsVisibleButton
                          isPrimary
                          onClick={handleClickShowPassword('password')}
                          isNowVisible={visible.password}
                        />
                      </InputAdornment>,
                  }}
                />
                {errors.password &&
                  <ValidationErrorBlock errorMessage={errors.password.message as string}/>
                }
                {(!!mainPass?.length) && (
                  <ul className={mainPass?.length === 0 ? 'auth-password-help validation' : 'auth-password-help'}>
                    <li className={mainPass?.length > 9 ? 'success' : 'error'}>
                      10 characters minimum
                    </li>
                    <li className={
                      !!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                      (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0) ? 'error' : 'success'
                    }>
                      No dictionary words, repetitions and commonly used passwords
                    </li>
                    <li className={/^(.*[0-9].*)$/.test(mainPass) ? 'success' : 'error'}>
                      One number
                    </li>
                    <li className={/^(?=.*[a-z])(?=.*[A-Z]).*$/.test(mainPass) ? 'success' : 'error'}>
                      At least one uppercase and one lowercase
                    </li>
                    <li className={/^(?=.*[@$!%*^"])[a-zA-Z0-9@$!%*^"]+$/.test(mainPass) ? 'success' : 'error'}>
                      One special character. Use only: $ ! % * ^ &quot; @
                    </li>
                    {(!PASSWORD_REGEX.test(mainPass) || (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                      (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0))) && (
                      <li className={'error'}>
                        {translate('forms.common.invalid_password')}
                      </li>
                    )}
                  </ul>
                )}
              </FormControl>
            </div>

            <div className="action-row">
              <Button className="primary-button w-100"
                      onClick={handlePasswordSetup}
                      disabled={!isValid || notMatchError || isProcessing}>
                {isProcessing &&
                  <div className="small-spinner-wrapper">
                    <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '14px'}}/>
                  </div>
                }
                {isProcessing
                  ? translate('notifications.choices.processing')
                  : translate('notifications.choices.save')
                }
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default SetupPassword;
