import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EPlatformsNames, EStripeSubStatus, ETrialStatus } from '../../../models/consts';
import { EGitlabPlanNames } from '../../../models/paymentPlans/gitlab-payments-plan';
import { ETrelloPlanNames } from '../../../models/paymentPlans/trello-payments-plan';
import { IPlatformSubscriptionRow } from '../../../views/UserPages/Subscription/Subscription';
import { PlatformsState } from '../../reducers/platform';

export const getTrelloBoardsSelector = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.items);
export const getTrelloTotal = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.totalItems);

export const getTrelloCanUserBackup = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.canUserBackup);

export const isTrelloBoardsLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.itemsLoading);

export const isTrelloUsersLoadingSelector = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.usersLoading);

export const getTrelloUsersSelector = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.users);
export const isTrelloConnect = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.isConnectionActive);

export const trelloTrialStatus = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => trello.trial?.trialStatus);

export const trelloTrialHoursLeft = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => {
  return trello.trial?.trialHoursLeft || null;
});

export const trelloTrialQuantityLeft = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => {
  return trello.trial?.availableItemsQuantity || 0;
});

export const trelloTrialPlanQuantity = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => {
  return trello.trial?.planQuantity || 0;
});

export const trelloSubInfo = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { subscription } = trello;
  if (subscription) {
    return {
      id: 3,
      platformName: EPlatformsNames.TRELLO,
      plan: subscription?.planName || '',
      price: `$${subscription?.isAnnual 
        ? (subscription.cost * 12).toFixed(2) + ' / yr.' 
        : subscription.cost.toFixed(2) + ' / mo.'}`,
      status: subscription?.status,
      quantity: subscription.quantity,
      planQuantity: subscription.planQuantity,
      addedItemsQuantity: subscription.addedItemsQuantity,
      isCancel: subscription.isCancel,
      isConnect: false
    };
  }
  return null;
});

export const trelloTrialInfo = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState): IPlatformSubscriptionRow | null => {
  const { trial } = trello;
  if (trial) {
    return {
      id: 4,
      platformName: EPlatformsNames.TRELLO,
      plan: ETrelloPlanNames.TRIAL,
      price: `-`,
      status: trial.trialStatus,
      quantity: 0,
      planQuantity: 0,
      addedItemsQuantity: 0,
      isCancel: false,
      isConnect: false
    };
  }
  return null;
});

export const trelloSubscription = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => {
  return trello.subscription
});

export const trelloTrialData = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => {
  return trello.trial
});

export const trelloActualPlan = createSelector((state: DefaultRootState) => state.platform, ({ trello }: PlatformsState) => {
  const { subscription: trelloSub, trial: trelloTrial, isConnectionActive } = trello;

  const trelloItem: IPlatformSubscriptionRow = {
    id: 3,
    platformName: EPlatformsNames.TRELLO,
    plan: '-',
    price: '-',
    quantity: 0,
    planQuantity: 0,
    addedItemsQuantity: 0,
    status: EStripeSubStatus.INACTIVE,
    isCancel: false,
    isConnect: isConnectionActive,
    finishDateInMillisecond: null
  };

  if (trelloTrial && trelloTrial.trialStatus === ETrialStatus.NOT_STARTED) {
    trelloItem.plan = EGitlabPlanNames.TRIAL;
    trelloItem.status = trelloTrial.trialStatus;
    trelloItem.quantity = trelloTrial.availableItemsQuantity || 0;
    trelloItem.addedItemsQuantity = trelloTrial.addedItemsQuantity || 0;
    trelloItem.planQuantity = trelloTrial.planQuantity || 0;
    trelloItem.finishDate = trelloTrial.finishDate || null;
    trelloItem.isConnect = isConnectionActive
  }

  if (trelloTrial && trelloTrial.trialStatus === ETrialStatus.STARTED) {
    trelloItem.plan = EGitlabPlanNames.TRIAL;
    trelloItem.status = trelloTrial.trialStatus;
    trelloItem.quantity = trelloTrial.availableItemsQuantity || 0;
    trelloItem.addedItemsQuantity = trelloTrial.addedItemsQuantity || 0;
    trelloItem.planQuantity = trelloTrial.planQuantity || 0;
    trelloItem.finishDate = trelloTrial.finishDate || null;
    trelloItem.isConnect = isConnectionActive
  }

  if (trelloSub && (!trelloTrial || trelloTrial?.trialStatus !== ETrialStatus.STARTED || trelloSub?.status === EStripeSubStatus.ACTIVE)) {
    trelloItem.plan = trelloSub.planName || '';
    trelloItem.status = trelloSub.status;
    trelloItem.finishDate = trelloSub.finishDate || null;
    trelloItem.quantity = trelloSub.quantity || 0;
    trelloItem.addedItemsQuantity = trelloSub.addedItemsQuantity || 0;
    trelloItem.planQuantity = trelloSub.planQuantity || 0;
    trelloItem.price = `$${trelloSub.cost.toFixed(2)} / ${trelloSub.isAnnual ? 'yr.' : 'mo.'}`
    trelloItem.isCancel = trelloSub.isCancel;
    trelloItem.finishDateInMillisecond = trelloSub.finishDateInMillisecond;
    trelloItem.isConnect = isConnectionActive
  }

  return trelloItem
});
