import { Container } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { pageNotFound } from '../../assets/images/other';
import { Button } from '../../components/StyledComponents';
import './style.scss';

const PageNotFound: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div id="page-not-found">
      <Container>
        <div className="content">
          <h1>404</h1>
          <img src={pageNotFound} loading="lazy"/>
          <h3>Something Missing</h3>
          <Button className="empty-button" onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </Container>
    </div>
  );
};

export default PageNotFound;
