import { AxiosResponse } from 'axios';
import { PING_COUNT, PING_INTERVAL } from '../models/consts';
import instance from '../store/middlewares/api';

export const pingTrialStatusWrapper = async (currentStatus) => {
  let counter = 0;
  return new Promise<AxiosResponse>((resolve, reject) => {

    const intervalId = setInterval(async() => {
      counter++;
      const data = await instance.post('payments/get-github-trial-status', {});
      if (data?.data?.result?.stripe_github_subscription_status && data.data.result.stripe_github_subscription_status !== currentStatus) {

        clearInterval(intervalId);
        resolve(data);
      }
      if (counter >= PING_COUNT) {
        if (data?.data) {
          clearInterval(intervalId);
          resolve(data);
        } else {
          reject({ error: 'Ooops, something went wrong'})
        }
      }
    }, PING_INTERVAL);
  });
}
