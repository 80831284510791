import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import React, {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IPoppupBase} from '../../../models/inner-models';
import './style.scss'
import {Button} from "../../StyledComponents";
import ChangePaymentMethodForm from "../../../views/UserPages/UserSettings/ChangePaymentMethodForm/ChangePaymentMethodForm";
import {closeModalIcon} from "../../../assets/icons/icons";

const CardFormDialog: FunctionComponent<IPoppupBase> = ({
  isOpen,
  closeNotification,
}: IPoppupBase) => {
  const { t: translate } = useTranslation();
  const [isNew, setIsNew] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setIsNew(false)
    closeNotification && closeNotification();
  }, [closeNotification]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="payment-dialog-title"
      aria-describedby="payment-dialog-description"
      classes={{
        paper: 'payment-modal',
      }}
    >
      <div className="close-btn" onClick={handleClose}>
        <img src={closeModalIcon} loading="lazy"/>
      </div>

      <DialogTitle className="title">
        {translate('notifications.change_card.change_card_title')}
      </DialogTitle>
      <DialogContent>
        <ChangePaymentMethodForm isSubP={true}  isSubBtn={setIsNew} closeModal={handleClose}/>
        {!isNew && (
          <div className="action-buttons">
            <Button
              variant="outlined" color="primary"
              className='newapp-back'
              onClick={handleClose}
              mr={4}
            >
              Back
            </Button>
            {/*<Button*/}
            {/*  type="submit"*/}
            {/*  variant="contained"*/}
            {/*  color="primary"*/}
            {/*  onClick={handleClose}*/}
            {/*>*/}
            {/*  Accept*/}
            {/*</Button>*/}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CardFormDialog;
