import { EStripeSubStatus } from '../../../models/consts';
import { IGithubUser, ITrialDataAPI } from '../../../models/models';
import { EGithubPlanNames, githubPlans } from '../../../models/paymentPlans/github-payments-plan';
import { trialDaysFinishDate } from '../../../utils/trialDaysLeft';
import { githubRepoAdapterFunc, githubUserAdapterFunction } from '../../functions';

export const fetchGithubUsersAction = (state, { payload }) => {
  if (payload) {
    state.github.usersLoading = false;
    state.github.users = githubUserAdapterFunction(payload.result || []);

    if (state.github) {
      state.github.hasUserAnyActiveApp = !!payload.hasUserAnyActiveApp
      state.github.isTokenValid = !!payload.isTokenValid
      state.github.autoAdd = !!payload.auto_add
    }
  }
};

export const addGithubUserAction = (state, { payload }) => {
  if (payload) {
    state.github.users = githubUserAdapterFunction([payload] as Array<IGithubUser>);
    //
    // if (state.github) {
    //   state.github.hasUserAnyActiveApp = !!payload.hasUserAnyActiveApp
    // }
  }
};

export const confirmGithubUserAction = (state) => {
  if (!state.github.users || !state.github.users.length) {
    return;
  }

  const users = [...state.github.users];
  users[0].isConfirmed = true;
  state.github.users = users

};

export const fetchItemsForGithubUserAction = (state, { payload }) => {
  if (payload) {
    state.github.itemsLoading = false;
    state.github.items = githubRepoAdapterFunc(payload);
    state.github.canUserBackup = payload.canUserBackup
    state.github.totalItems = payload.totalItemsNumber || 0
  }
};

export const addGithubItemsAction = (state, { payload, meta }) => {
  state.github.items?.push(...githubRepoAdapterFunc({ user: meta.arg.user, repos: payload.repos_list, isRepo: true }));
};

export const addGithubGistAction = (state, { payload, meta }) => {
  state.github.items?.push(...githubRepoAdapterFunc({ user: meta.arg.user, repos: payload.gists_list, isRepo: false }));
};

export const deleteGithubItemAction = (state, { meta }) => {
  if (state?.github.items && meta.arg) {
    state.github.items = [...state.github.items]?.filter(x => x.id !== meta.arg);
  }
};

export const checkGithubTrialStatusAction = (state, { payload }) => {
  if (payload) {
    state.github.isConnectionActive = !!payload.isConnectionActive

    const trialData = payload as ITrialDataAPI;
    state.github.trial = {
      trialStatus: trialData.trialStatus,
      finishDate: trialDaysFinishDate(trialData.trialHoursLeft),
      trialHoursLeft: trialData.trialHoursLeft,
      availableItemsQuantity: trialData.availableItemsQuantity,
      addedItemsQuantity: trialData.addedItemsQuantity,
      planQuantity: trialData.trialDefaultQuantity,
      isInvoiceRequested: trialData.stripe_github_subscription_bank_transfer_requested || false,
      isCancel: false
    };

    const { s3_region, cloud_storages, stripe_github_next_invoice, subscription_type, subscription_period, stripe_github_subscription_cancel_at_period_end, stripe_github_repos_quantity, stripe_github_subscription_status, availableItemsQuantity, addedItemsQuantity, stripe_github_subscription_bank_transfer_requested, stripe_github_subscription_current_period_end } = trialData;

    state.github.storage = cloud_storages || []
    if (s3_region) {
      state.github.regionS3 = s3_region
    }

    if (stripe_github_subscription_status && stripe_github_repos_quantity) {
      const planData = githubPlans.find(({ name }) => name === EGithubPlanNames.ESSENTIAL);
      const isAnnual = subscription_period && subscription_period === 'year' || planData?.stripeIds.yearly === trialData.stripe_github_price_id;

      const cost = stripe_github_next_invoice ? stripe_github_next_invoice / 100 : 0;

      state.github.subscription = {
        status: stripe_github_subscription_status.toLowerCase() as EStripeSubStatus,
        planName: subscription_type === 'pro_plan' ? EGithubPlanNames.PRO : EGithubPlanNames.ESSENTIAL,
        isAnnual,
        finishDate: stripe_github_subscription_current_period_end ? new Date(Number(stripe_github_subscription_current_period_end + '000')).toLocaleDateString() : null,
        finishDateInMillisecond: Number(stripe_github_subscription_current_period_end +'000' ),
        cost,
        quantity: availableItemsQuantity,
        addedItemsQuantity: addedItemsQuantity,
        planQuantity: stripe_github_repos_quantity,
        isInvoiceRequested: stripe_github_subscription_bank_transfer_requested || false,
        isCancel: stripe_github_subscription_cancel_at_period_end || false
      };
    } else if (stripe_github_subscription_status) {
      state.github.subscription = {
        status: stripe_github_subscription_status.toLowerCase() as EStripeSubStatus,
        // planName: EGitlabPlanNames.ESSENTIAL,
        // isAnnual:
        // finishDate: stripe_gitlab_subscription_current_period_end ? new Date(Number(stripe_gitlab_subscription_current_period_end + '000')).toLocaleDateString() : null,
        cost: 0,
        addedItemsQuantity: addedItemsQuantity,
        // quantity: availableItemsQuantity,
        // planQuantity: stripe_gitlab_projects_quantity,
        // isInvoiceRequested: stripe_gitlab_subscription_bank_transfer_requested || false,
        // isCancel: stripe_gitlab_subscription_cancel_at_period_end || false
      };
    }
  }
};
