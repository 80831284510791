import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';

const RadioIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  '&:hover':{
    backgroundColor:'none'
},
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    border: '1px solid #5C3ED0'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const RadioCheckedIcon = styled(RadioIcon)({
  backgroundColor: '#6D4CF0',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: 'radial-gradient(#fff,#fff 20%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#5C3ED0',
    border: 'none'
  },
});

// Inspired by blueprintjs
export default function RadioButton(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
    />
  );
}
