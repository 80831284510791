import { createAsyncThunk } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { EApiStatuses } from '../../../constants';
import {IError, IPagePayload} from '../../../models/inner-models';
import {IDeletePayload, ISetterUserWithProjects, ISetterUserWithSnippets} from '../../../models/models';
import { pingTrialStatusWrapper } from '../../../utils/pingWrapper';
import { EPlatform, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';

const fetchGitlabUsers = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlatformUsers, EPlatformNames.gitlab),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('gitlab/get-gitlab-users');
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const addGitlabUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addPlatformUser, EPlatformNames.gitlab),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('gitlab/add-gitlab-user');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp?.data?.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getAvailableItemsForGitlabUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getAvailableItems, EPlatformNames.gitlab),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`gitlab/get-available-projects-for-gitlab-user?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`, { signal:payload.signal });
      return resp?.data?.result?.not_added_projects || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getAvailableSnippetsForGitlabUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getAvailableItemsSec, EPlatformNames.gitlab),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`gitlab/get-available-snippets-for-gitlab-user?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`, { signal:payload.signal });
      return resp?.data?.result?.not_added_snippets || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const editGitlabUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.editPlatformUser, EPlatformNames.gitlab),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('gitlab/change-gitlab-user');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp?.data?.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchItemsForGitlabUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserItems, EPlatformNames.gitlab),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`gitlab/get-user-gitlab-projects?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`);
      return {projects: resp?.data?.result || [], canUserBackup: resp?.data?.canUserBackup, totalItemsNumber: resp?.data?.totalItemsNumber || 0} || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchSnippetsForGitlabUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserItemsSec, EPlatformNames.gitlab),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`gitlab/get-user-gitlab-snippets?page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`);
      return {projects: resp?.data?.result || [], canUserBackup: resp?.data?.canUserBackup, totalItemsNumber: resp?.data?.totalItemsNumber || 0} || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const addGitlabItems = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addItem, EPlatformNames.gitlab),
  async (projectsWithUser: ISetterUserWithProjects, thunkAPI) => {
    try {
      const resp = await instance.post('gitlab/add-gitlab-projects', projectsWithUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: resp.data.status, error: resp.data.message }) :
        resp.data.result.projects_list;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const addGitlabSnippets = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addItemGist, EPlatformNames.gitlab),
  async (projectsWithUser: ISetterUserWithSnippets, thunkAPI) => {
    try {
      const resp = await instance.post('gitlab/add-gitlab-snippets', projectsWithUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: resp.data.status, error: resp.data.message }) :
        resp.data.result.snippets_list;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const deleteGitlabItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.deleteItem, EPlatformNames.gitlab),
  async (payload: IDeletePayload, thunkAPI) => {
    try {
      const resp = await instance.delete('gitlab/delete-projects', {
        data: payload,
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const deleteGitlabSnippet = createAsyncThunk(
  addPlatformIdentifier(EPlatform.deleteItem, EPlatformNames.gitlab),
  async (payload: IDeletePayload, thunkAPI) => {
    try {
      const resp = await instance.delete('gitlab/delete-snippets', {
        data: payload,
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const backupGitlabItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupItem, EPlatformNames.gitlab),
  async (idRepo: string, thunkAPI) => {
    try {
      const resp = await instance.post('gitlab/backup-now', {
        projectId: idRepo,
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const backupGitlabSnippets = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupItem, EPlatformNames.gitlab),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('gitlab/backup-all-added-snippets-now');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

//todo find usage and delete
const checkGitlabTrialStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.checkUserTrial, EPlatformNames.gitlab),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('payments/get-gitlab-trial-status');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const pingGitlabPaymentStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.pingPaymentStatus, EPlatformNames.gitlab),
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as DefaultRootState;

      const resp = await pingTrialStatusWrapper(state.platform.gitlab.subscription?.status); //instance.post('payments/start-github-trial', {});
      return (!resp.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export {
  fetchItemsForGitlabUser,
  fetchSnippetsForGitlabUser,
  getAvailableItemsForGitlabUser,
  getAvailableSnippetsForGitlabUser,

  addGitlabItems,
  addGitlabSnippets,
  deleteGitlabItem,
  deleteGitlabSnippet,
  backupGitlabItem,
  backupGitlabSnippets,

  fetchGitlabUsers,
  addGitlabUser,
  editGitlabUser,

  checkGitlabTrialStatus,
  pingGitlabPaymentStatus,
};
