import React, {FunctionComponent, useEffect, useState} from 'react';
import PlatformUserPageBlock from '../../../../components/PlatformComponents/PlatformUserPageBlock';
import {EPlatformsNames} from '../../../../models/consts';
import {IPlatformUser, SubPlanInfo} from '../../../../models/models';
import {fetchTrelloUser} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  getTrelloUsersSelector,
  isTrelloUsersLoadingSelector,
  loadingUser,
  trelloSubscription,
  trelloTrialHoursLeft,
  trelloTrialPlanQuantity,
  trelloTrialQuantityLeft,
  trelloTrialStatus,
} from '../../../../store/selectors';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import TrelloUserAddEdit from './TrelloUserAddEdit';

const TrelloUserSettings: FunctionComponent = () => {
  const userLoading = useAppSelector(loadingUser);
  const isLoadingTrelloUsers = useAppSelector(isTrelloUsersLoadingSelector);
  const trialState = useAppSelector(trelloTrialStatus);
  const trialDays = useAppSelector(trelloTrialHoursLeft);
  const trialQuantity = useAppSelector(trelloTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(trelloTrialPlanQuantity);
  const subInfo = useAppSelector(trelloSubscription);
  const users = useAppSelector(getTrelloUsersSelector);
  const dispatch = useAppDispatch();
  const platformName = EPlatformsNames.TRELLO;
  // const { t: translate } = useTranslation();
  // const [searchParams] = useSearchParams();

  const [subPlan, setSubPlan] = useState<SubPlanInfo>();
  const [platformUsers, setPlatformUsers] = useState<Array<IPlatformUser>>([]);

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);

      setSubPlan(subPlan);
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingTrelloUsers) {
      setPlatformUsers(users || []);
    }
  }, [users, isLoadingTrelloUsers]);

  useEffect(() => {
    dispatch(fetchTrelloUser()).unwrap().catch(err => console.log(err));
  }, [platformName]);

  // useEffect(() => {
  //   const errorMessage = searchParams.get('error_message');
  //
  //   if(!errorMessage) {
  //     return
  //   }
  //
  //   Swal.fire({
  //     imageUrl: errorBigIcon,
  //     title: translate('notifications.titles.error'),
  //     text: decoratePopUpMessage(translate('notifications.trello_user.error', {err: errorMessage})),
  //   });
  // }, []);

  return (
    <PlatformUserPageBlock
      platformName={platformName}
      isLoadingPlatformUsers={isLoadingTrelloUsers}
      ChildComponent={() => <TrelloUserAddEdit user={platformUsers[0]} subPlan={subPlan} />}
    />
  );
};

export default withRedirectAfterLogout()(TrelloUserSettings);
