import { createAsyncThunk } from '@reduxjs/toolkit';
import { EApiStatuses } from '../../../constants';
import { IError } from '../../../models/inner-models';
import { EPlatform, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';
import {IDeletePayload, IFetchUserWorkspacesNotion, ISubItemsForNotionUser} from "../../../models/models";

const getNotionLoginUrl = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addPlatformUser, EPlatformNames.notion),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('notion/auth-url');

      return (!resp?.data) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp?.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchNotionUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlatformUsers, EPlatformNames.notion),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('notion/get-notion-users');

      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS || !resp?.data?.result) ?
        undefined : resp.data.result.map((obj)=> {
          obj.ownerId = obj.id;
          delete obj.id;
          return obj
        });
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchUserWorkspacesNotion = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserWorkspace, EPlatformNames.notion),
  async (payload: IFetchUserWorkspacesNotion, thunkAPI) => {
    try {
      const resp = await instance.get(`notion/get-user-workspaces?ownerId=${payload.ownerId}&page=${payload.page}&perPage=${payload.perPage}${payload.name? `&name=${payload.name}`:''}`, {});
      return {workspace:resp?.data?.result, canUserBackup: resp?.data?.canUserBackup, totalItemsNumber:resp?.data?.totalItemsNumber};
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const fetchSubItemsForNotionUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserItems, EPlatformNames.notion),
  async (payload: ISubItemsForNotionUser, thunkAPI) => {
    try {
      const resp = await instance.get(`notion/get-workspace-backup-scope?workspaceVersionId=${payload.workspaceScopeId}&workspaceId=${payload.workspaceId}`, {
        //workspaceVersionId: pageVerId
      });
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const backupNotionItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupItem, EPlatformNames.notion),
  async (ownerId: string, thunkAPI) => {
    try {
      const resp = await instance.post(`notion/backup-all-items-now?ownerId=${ownerId}`);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const deleteNotionItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.deleteItem, EPlatformNames.notion),
  async (payload: IDeletePayload, thunkAPI) => {
    try {
      console.log('payload',payload)
      const resp = await instance.delete('notion/delete-workspaces',{
        data: payload
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export {
  getNotionLoginUrl,
  fetchNotionUser,
  fetchUserWorkspacesNotion,
  fetchSubItemsForNotionUser,
  backupNotionItem,
  deleteNotionItem
  //getAvailableItemsForTrelloUser,
  //addTrelloItems,
  //deleteTrelloItem,
};
