import React, {FunctionComponent, useEffect, useState} from 'react';
import PlatformUserPageBlock from '../../../../components/PlatformComponents/PlatformUserPageBlock';
import {EPlatformsNames} from '../../../../models/consts';
import {IPlatformUser, SubPlanInfo} from '../../../../models/models';
import {fetchJiraUser} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  getJiraUsersSelector,
  isJiraUsersLoadingSelector,
  jiraSubscription,
  jiraTrialHoursLeft,
  jiraTrialPlanQuantity,
  jiraTrialQuantityLeft,
  jiraTrialStatus,
  loadingUser,
} from '../../../../store/selectors';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import JiraUserAddEdit from './JiraUserAddEdit';

const JiraUserSettings: FunctionComponent = () => {
  const userLoading = useAppSelector(loadingUser);
  const isLoadingJiraUsers = useAppSelector(isJiraUsersLoadingSelector);
  const trialState = useAppSelector(jiraTrialStatus);
  const trialDays = useAppSelector(jiraTrialHoursLeft);
  const trialQuantity = useAppSelector(jiraTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(jiraTrialPlanQuantity);
  const subInfo = useAppSelector(jiraSubscription);
  const users = useAppSelector(getJiraUsersSelector);
  const dispatch = useAppDispatch();
  const platformName = EPlatformsNames.JIRA;
  // const { t: translate } = useTranslation();
  // const [searchParams] = useSearchParams();

  const [subPlan, setSubPlan] = useState<SubPlanInfo>();
  const [platformUsers, setPlatformUsers] = useState<Array<IPlatformUser>>([]);

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);

      setSubPlan(subPlan);
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingJiraUsers) {
      setPlatformUsers(users || []);
    }
  }, [users, isLoadingJiraUsers]);

  useEffect(() => {
    dispatch(fetchJiraUser()).unwrap().catch(err => console.log(err));
  }, [platformName]);

  // useEffect(() => {
  //   const errorMessage = searchParams.get('error_message');
  //
  //   if(!errorMessage) {
  //     return
  //   }
  //
  //   Swal.fire({
  //     imageUrl: errorBigIcon,
  //     title: translate('notifications.titles.error'),
  //     text: decoratePopUpMessage(translate('notifications.jira_user.error', {err: errorMessage})),
  //   });
  // }, []);

  return (
    <PlatformUserPageBlock
      platformName={platformName}
      isLoadingPlatformUsers={isLoadingJiraUsers}
      ChildComponent={() => <JiraUserAddEdit user={platformUsers[0]} subPlan={subPlan} />}
    />
  );
};

export default withRedirectAfterLogout()(JiraUserSettings);
