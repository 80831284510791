import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles({
  iconWrapper: {
    width: 24,
    height: 24,
    padding: 5
  },
  root: {
    fill: 'transparent',
  },
});

export const DownArrowIcon: FunctionComponent<SvgIconProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.iconWrapper}>
      <SvgIcon className={classes.root}>
        <path d="M2.66602 5.33329L7.99935 10.6666L13.3327 5.33329" stroke="#3949ab" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </SvgIcon>
    </div>
  );
}
