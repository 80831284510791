enum EPlatformNames {
  all= 'all',
  github = 'github',
  trello = 'trello',
  gitlab = 'gitlab',
  jira = 'jira',
  notion = 'notion'
}

export default EPlatformNames;
