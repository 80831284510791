import { InputAdornment } from '@mui/material';
import React, { Fragment, FunctionComponent } from 'react';
import { visibleIcon, visibleIconPrimary, visibleOffIcon, visibleOffIconPrimary } from '../../../assets/icons/icons';
import { IconButton } from '../../StyledComponents';

interface IIsVisibleButtonProps {
  isNowVisible?: boolean;
  onClick: () => void;
  isPrimary?: boolean;
}

const IsVisibleButton: FunctionComponent<IIsVisibleButtonProps> = ({ isNowVisible, isPrimary = false, onClick }: IIsVisibleButtonProps) => {
  const visibleEnabledIcon = isPrimary ? visibleIcon : visibleIconPrimary
  const visibleDisabledIcon = isPrimary ? visibleOffIcon : visibleOffIconPrimary
  return (
    <Fragment>
      <InputAdornment position="end" className="password-visiblity-icon">
        <IconButton
          aria-label="toggle password visibility"
          onClick={onClick}
          disableRipple={true}
          disableFocusRipple={true}
          tabIndex={-1}
          size="large">
          {isNowVisible ? <img src={visibleDisabledIcon} loading="lazy"/> : <img src={visibleEnabledIcon} loading="lazy"/>}
        </IconButton>
      </InputAdornment>
    </Fragment>
  );
};

export default IsVisibleButton;
