import {Box, CircularProgress, debounce, InputAdornment} from '@mui/material';
import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {errorBigIcon, iconNotifyWarningInfo, searchIcon, toastSuccess} from '../../../../assets/icons/icons';
import {Button, Typography} from '../../../../components/StyledComponents';
import {
  EPaymentRequiredType,
  EPlatformsNames,
  ESubscriptionPlans,
  ETrialStatus,
  SubscriptionMockData
} from '../../../../models/consts';
import {IError, IPaymentRequiredPoppup, IPoppupBase, PlatformProps} from '../../../../models/inner-models';
import Platform from '../../../../models/platforms-data';
import {
  backupAllByPlatform,
  deleteTrelloItem,
  fetchItemsForTrelloUser,
  fetchTrelloUser,
  getAllSubscriptions,
} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  getIsTestUser,
  getTrelloBoardsSelector,
  getTrelloCanUserBackup,
  getTrelloTotal,
  getTrelloUsersSelector, isSubUser,
  isTrelloBoardsLoadingSelector,
  isTrelloUsersLoadingSelector,
  loadingUser, roleByApp,
  trelloSubscription,
  trelloTrialHoursLeft,
  trelloTrialPlanQuantity,
  trelloTrialQuantityLeft,
  trelloTrialStatus,
} from '../../../../store/selectors';
import {SearchInput} from '../../../../styles/components/MainInputElements';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import fetchItemsErrorHandler from "../../../../utils/fetchItemsErrorHandler";
import NoSubscriptionBlock from "../../../../components/Blocks/NoSubscriptionBlock/NoSubscriptionBlock";
import NoBackupsBlock from "../../../../components/Blocks/NoItemsBlock/NoItemsBlock";
import PlatformItemsList from "../../../../components/PlatformComponents/PlatformItemsList/PlatformItemsList";
import TrelloBoardAdd from "./TrelloBoardAdd/TrelloBoardAdd";
import PaymentRequiredPoppup from "../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import {defaultTrialReposMax} from "../../../../constants";
import Modal from "../../../../components/Dialogs/Modal/Modal";
import TrelloUserAddEdit from '../TrelloUserAddEditView/TrelloUserAddEdit';
import DeleteItemDialog from "../../../../components/Dialogs/DeleteItemDialog/DeleteItemDialog";
import DatabaseIcoInvert from "../../../../assets/icons/CustomSvg/DatabaseIcoInvert";
import {BadgesBackup, BadgesSubscription, BadgesTrial} from "../../../../components/Badges/Badges";
import CustomTimerTooltip from "../../../../styles/components/CustomTimerTooltip";
import {CustomTooltip} from '../../../../styles/components/CustomTooltip';
import {updateSubscriptionPlanModal} from "../../../../utils/functions/updateSubscriptionPlanModal";
import ExternalStorage from "../../../UserPages/UserSettings/ExternalStorage/ExternalStorage";

const TrelloPlatformSettings: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const userLoading = useAppSelector(loadingUser);
  const boards = useAppSelector(getTrelloBoardsSelector);
  const total = useAppSelector(getTrelloTotal);
  const canUserBackup = useAppSelector(getTrelloCanUserBackup);
  const isTestUser = useAppSelector(getIsTestUser);
  const isLoadingBoards = useAppSelector(isTrelloBoardsLoadingSelector);
  const isLoadingTrelloUsers = useAppSelector(isTrelloUsersLoadingSelector);
  const trialState = useAppSelector(trelloTrialStatus);
  const trialDays = useAppSelector(trelloTrialHoursLeft);
  const trialQuantity = useAppSelector(trelloTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(trelloTrialPlanQuantity);
  const subInfo = useAppSelector(trelloSubscription);
  const users = useAppSelector(getTrelloUsersSelector);
  const isUserSub = useAppSelector(isSubUser);
  const roleApp = useAppSelector(roleByApp);
  const isAdminRole = isUserSub && roleApp?.trello === 'admin' || !isUserSub
  // disable btn sub not admin - isUserSub && roleApp?.trello === 'user'
  const dispatch = useAppDispatch();
  const platformName = EPlatformsNames.TRELLO;
  const platform = Platform[platformName];
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [platformInfo, setPlatformInfo] = useState<PlatformProps>(
    {
      platformName: platformName,
      subPlan: SubscriptionMockData[ESubscriptionPlans.NO_SUB],
      items: boards || [],
      users: users || [],
      filteredItems: boards || [],
    },
  );
  const [notification, setNotification] = useState<IPoppupBase>({
    isOpen: false,
  });
  const [paymentPoppup, setPaymentPoppup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName,
  });
  const [paymentState, setPaymentState] = useState<EPaymentRequiredType>(EPaymentRequiredType.NON);
  const [buyShowed, setBuyShowed] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showSettings2, setShowSettings2] = useState<boolean>(false);
  const [deleteDate, setDeleteData] = useState<{ id?: string, name?: string }>({});
  const [deleteBulkDate, setDeleteBulkData] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectList, setSelectList] = useState<Array<string>>([]);
  const [searchParams] = useSearchParams();
  const [progressItem, setProgressItem] = useState<string>('');
  const [progressEnd, setProgressEnd] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [intervalFunc, setIntervalFunc] = useState<any>();
  const [userCanBackup, setUserCanBackup] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clearState = (notPage?: boolean) => {
    setDeleteData({})
    setDeleteBulkData([])
    setSelectList([])
    setSelectAll(false)
    if (!notPage) {
      setPage(0)
    }
  }

  useEffect(() => {
    setUserCanBackup(canUserBackup)
  }, [canUserBackup])

  useEffect(() => {
    if (selectAll && boards) {
      setSelectList(prevState => {
        boards.forEach(item => {
          if (prevState.indexOf(item.id) === -1) {
            prevState.push(item.id)
          }
        })
        return prevState
      })
    }
  }, [boards])

  const restartBadge = () => {
    if (intervalFunc) {
      clearInterval(intervalFunc)
      setIntervalFunc(null)
    }
    setProgressEnd(false)
    setProgressItem('')
  }

  useEffect(() => {
    const isExt = searchParams.get('isCloudStorageConnected');
    const errorMessage = searchParams.get('error_message');

    if (!errorMessage && isExt) {
      navigate(window.location.pathname, { replace: true })
      setShowSettings2(true)
    }

    if (!errorMessage) {
      return
    }

    Swal.fire({
      imageUrl: errorBigIcon,
      title: translate('notifications.titles.error'),
      text: decoratePopUpMessage(translate('notifications.trello_user.error', {err: errorMessage})),
    });
  }, []);

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);

      setPlatformInfo((prev) => ({
        ...prev,
        subPlan,
      }));
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingBoards && !isLoadingTrelloUsers) {
      setPlatformInfo((prev) => ({
        ...prev,
        items: boards || [],
        users: users || [],
        filteredItems: boards || [],
      }));

      const filt = boards?.filter(itemB => !!itemB.actionProcess?.length || itemB.status === 'initialized')
      if (filt && filt.length > 1 && !progressItem.length) {
        const isRest = filt.filter(itemB => itemB.actionProcess === 'restoring_process')
        filt.length === isRest?.length ?
          setProgressItem(translate(`notifications.all.actionBadge.many.restore`, {app: 'Trello'})) :
          !!isRest?.length ?
            setProgressItem(translate(`notifications.all.actionBadge.multi`)) :
            setProgressItem(translate(`notifications.all.actionBadge.many.backup`, {app: 'Trello'}))
      } else if (filt?.length === 1 && !progressItem.length) {
        setProgressItem(translate(`notifications.all.actionBadge.one.${filt[0].actionProcess || 'backing_up_process'}`, {
          app: 'Trello',
          item: filt[0].board?.split('::')[0]
        }))
      } else if (!filt?.length && !!progressItem?.length) {
        if (intervalFunc) {
          clearInterval(intervalFunc)
        }
        setProgressEnd(true)
      }
    }
  }, [boards, users, isLoadingBoards, isLoadingTrelloUsers]);

  const fetchData = async (newPage?: number, name?: string) => {
    try {
      setIsLoading(true)
      restartBadge()
      if (!users) {
        dispatch(fetchTrelloUser()).unwrap().catch(err => console.log(err));
      }
      await dispatch(fetchItemsForTrelloUser({page: newPage || 0, perPage: 10, name})).unwrap()
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          const error = err as IError;
          fetchItemsErrorHandler(error)
        });
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e);
    }
  };

  useEffect(() => {
    if (platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) {
      navigate('/app-connector')
    }
    fetchData();
  }, []);

  const debouncedSearch = useCallback(
    debounce((event) => {
      setPage(0)
      fetchData(0, event.toLowerCase())
    }, 1000),
    [],
  );

  const onSearch = useCallback((event) => {
    const val = event.target.value || ''
    setSearchTerm(val);
    debouncedSearch(val)
  }, []);

  useEffect(() => {
    if ((platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours) ||
      (platformInfo.subPlan.type === ESubscriptionPlans.SUBSCRIPTION && !platformInfo.subPlan.isPaid)) {
      setPaymentState(
        platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours ?
          EPaymentRequiredType.TRIAL : platformInfo.subPlan.isPaid ?
            EPaymentRequiredType.NON : EPaymentRequiredType.SUB,
      );
    } else {
      setPaymentState(EPaymentRequiredType.NON)
    }
  }, [platformInfo]);

  useEffect(() => {
    if (isUserSub && !roleApp?.[platformName]) {
      navigate('/', {replace: true})
    }
  }, [isUserSub, roleApp]);

  useEffect(() => {
    if (!isUserSub && !isLoadingBoards && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && !buyShowed) {
      setBuyShowed(true)
      Swal.fire({
        title: !!subInfo ? translate('notifications.subscription.subscribe_buy') : translate('notifications.subscription.subscribe_buy_trial'),
        text: !!subInfo ? translate('notifications.subscription.subscribe_buy_text') : translate('notifications.subscription.subscribe_buy_text_trial'),
        cancelButtonText: translate('notifications.choices.close'),
        confirmButtonText: translate('notifications.choices.buy_subscription_now'),
        showCancelButton: true,
        imageUrl: errorBigIcon,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName)
            navigate('/checkout');
          }
        })
    }
  }, [isLoadingBoards, subInfo]);

  const addRepos = () => {
    if (paymentState !== EPaymentRequiredType.NON) {
      if (!isUserSub) {
        setPaymentPoppup((prev) => ({
          ...prev,
          isOpen: true,
        }));
      }
    } else {
      setNotification({
        isOpen: true,
      });
    }
  }
  const onConfirmDeleteBoard = async (id, isDelBulk?) => {
    try {
      // setIsLoading(true)
      await dispatch(deleteTrelloItem({ids: [...(isDelBulk ? id : [id])]})).unwrap();
      dispatch(fetchItemsForTrelloUser({page: page, perPage: 10})).unwrap()
      dispatch(getAllSubscriptions()).unwrap()

      restartBadge()
      clearState()

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: isDelBulk ? translate('notifications.trello_boards.successfully_deleted_many') : translate('notifications.trello_boards.successfully_deleted'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
      // setIsLoading(false)

    } catch (err) {
      const error = err as IError;
      // setIsLoading(false)

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const handleDeleteBoard = async (id, name) => {
    setDeleteData({id, name});
  };

  const handleDeleteBulkBoard = async (arr) => {
    setDeleteBulkData(arr);
  };

  const closeNotification = useCallback(() => {
    setNotification({...notification, isOpen: false});
  }, []);

  const closeTrialPoppup = useCallback(() => {
    setPaymentPoppup({...paymentPoppup, isOpen: false});
  }, []);

  const getRemainingItems = useCallback(() => {
    if (!platformInfo.subPlan) {
      return 0;
    }

    if (platformInfo.subPlan.quantity !== undefined && platformInfo.subPlan.quantity >= 0 && platformInfo.items) {
      const payedItemsLeft = platformInfo.subPlan.quantity;
      return payedItemsLeft > 0 ? payedItemsLeft : 0;
    }

    return defaultTrialReposMax;
  }, [platformInfo]);

  const backupAllNow = async () => {
    try {
      setIsLoading(true)
      await dispatch(backupAllByPlatform('trello')).unwrap();
      //await dispatch(fetchItemsForTrelloUser({page: page, perPage: 10})).unwrap()
      restartBadge()
      if (total === 1) {
        setProgressItem(translate(`notifications.all.actionBadge.one.backing_up_process`, {
          app: 'Trello',
          item: platformInfo.items?.[0]?.board?.split('::')[0]
        }))
      } else {
        setProgressItem(translate(`notifications.all.actionBadge.many.backup`, {app: 'Trello'}))
      }
      setIsLoading(false)
      if (!isTestUser) {
        setUserCanBackup(false)
      }

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: total > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });

    } catch (err) {
      const error = err as IError;
      setIsLoading(false)

      if (error.code === 'err.user.backup.limit') {
        setUserCanBackup(false)
        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
          imageUrl: iconNotifyWarningInfo,
          timer: 3000,
        });
      } else {
        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      }
    }
  }
  return (
    <div id="platform-content" className='list-view'>
      {!!progressItem?.length && (
        <BadgesBackup progressItem={progressItem} platformName={platformName} isComplete={progressEnd} onClose={() => {
          restartBadge()
        }}/>
      )}

      {trialState === 'active' && trialDays && (
        <BadgesTrial trialDays={trialDays} platformName={platformName}/>
      )}
      {!isLoadingBoards && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && (
        <BadgesTrial platformName={platformName}
                     color={!!boards?.filter(i => i.lastBackup !== '-').length ? 'error' : 'error_2'}
                     isSub={!!subInfo}/>
      )}
      {/*{!isLoadingBoards && ((trialState === 'active' && trialDays && !trialQuantity) || (subInfo && subInfo.status === 'active' && !subInfo.quantity)) && (*/}
      {/*  <BadgesTrial platformName={platformName} color='warning' isSub={subInfo ? subInfo.status === 'active' : false}/>*/}
      {/*)}*/}
      {subInfo && subInfo?.isCancel && subInfo?.finishDateInMillisecond && ((subInfo?.finishDateInMillisecond- Date.now())/(24*60*60*1000))<1 && (
        <BadgesSubscription platformName={platformName}/>
      )}

      <div className="header-block">
        <Typography variant="h3" gutterBottom display="inline">
          {translate('forms.github_add_edit_repo.your_items', {platform: platform?.title, itemName: ''})}
        </Typography>

        <div className="search-block">
          <div className="action-button">
            {/*{(trialState === 'active' || subInfo || !!platformInfo?.users?.length) && (*/}
            {/*  <Button variant="outlined" color="primary"*/}
            {/*          onClick={() => setShowSettings(true)}>*/}
            {/*    {translate('forms.github_add_edit_repo.open_setting')}*/}
            {/*  </Button>*/}
            {/*)}*/}

            {(trialState === 'active' || subInfo || !!platformInfo?.users?.length) && !isLoadingTrelloUsers && !!users?.length &&  (
              <CustomTooltip
                title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}
              >
                <Button variant="outlined" color="primary"
                        disabled={platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial' || paymentState !== EPaymentRequiredType.NON}
                        onClick={() => setShowSettings2(true)}>
                  External storage
                </Button>
              </CustomTooltip>
            )}

            {!!boards?.length && ((userCanBackup || isTestUser) ? (
              <CustomTooltip
                title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}>
                <Button
                  ml={2} variant="outlined"
                  color="primary"
                  onClick={() => (platformInfo.subPlan?.name === 'Pro' || platformInfo.subPlan?.name === 'Trial') ? backupAllNow() : updateSubscriptionPlanModal({
                    translate,
                    platformName,
                    navigate
                  })}
                  disabled={(platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
                    (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED) ||
                    paymentState !== EPaymentRequiredType.NON || !isAdminRole
                  }
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTooltip>
            ) : (
              <CustomTimerTooltip setUserCanBackup={() => setUserCanBackup(true)}>
                <Button
                  ml={2} variant="outlined"
                  color="primary" onClick={backupAllNow}
                  disabled={true}
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTimerTooltip>
            ))}
            {!!platformInfo.users?.length && (
              <Button
                ml={2} variant="contained" color="primary" onClick={addRepos}
                disabled={!isAdminRole || isUserSub && subInfo?.status !== 'active' && trialState !== 'active'}
              >
                <Plus className="feather-icon-in-button"/>
                {translate('forms.trello_add_edit_repo.add_board_num', {num: ''})}
              </Button>
            )}
          </div>
        </div>

      </div>

      <div className="header-block header-block-sub">
        <div className="user-name">
          {platformInfo && !!platformInfo.users?.length && (
            <Typography variant="h4" gutterBottom display="inline">
              @{platformInfo.users[0].username}
            </Typography>
          )}
        </div>
        <div className="search-block">
          {(!!boards?.length || searchTerm) && (
            <SearchInput
              className="search-field"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={onSearch}
              endAdornment={
                <InputAdornment position="end" className="search-icon">
                  <img src={searchIcon} loading="lazy"/>
                </InputAdornment>
              }
            />
          )}
        </div>
      </div>

      <Box sx={{width: '100%'}}>
        {isLoadingTrelloUsers || isLoadingBoards || userLoading || isLoading ?
          <div className="spinner-wrapper">
            <CircularProgress/>
          </div>
          :
          <div className="data-block">
            {(platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
            (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED && !boards?.length) ?
              <NoSubscriptionBlock platformName={platformName} isOwner={!isUserSub}/>
              : !users?.length || !users[0]?.username ?
                <NoBackupsBlock
                  isAdmin={!isUserSub}
                  action={() => navigate('/app-connector')}
                  platformItem={translate('views.bulk_restore.itemName.trello.one')}
                  actionText={translate('forms.github_add_edit_repo.configure_account')}
                />
                : !platformInfo.items?.length ?
                  <NoBackupsBlock
                    isSearch={!!searchTerm}
                    isAdmin={isAdminRole}
                    action={addRepos}
                    platformItem={translate('views.bulk_restore.itemName.trello.one')}
                    actionText={translate('forms.trello_add_edit_repo.add_board_num', {num: ''})}
                  />
                  :
                  <>
                    <div className="repo-wrapper">
                      <div className="repos-list">
                        <PlatformItemsList
                          items={platformInfo.filteredItems || []}
                          updItems={() => dispatch(fetchItemsForTrelloUser({page: page, perPage: 10})).unwrap()}
                          platformName={platformName}
                          onDelete={handleDeleteBoard}
                          onBulkDelete={handleDeleteBulkBoard}
                          // onBackup={(id) => dispatch(backupTrelloItem(id)).unwrap()}
                          setSelectAll={(isSelect) => {
                            if (!isSelect) {
                              setSelectList([])
                              setSelectAll(isSelect)
                            } else {
                              const listToSelect = platformInfo.filteredItems?.filter(item => !item.actionProcess && item.status !== "initialized").map(item => item.id) || []
                              setSelectList(listToSelect)
                              if (listToSelect.length === platformInfo.filteredItems?.length) {
                                setSelectAll(isSelect)
                              }
                            }
                          }}
                          selectAll={selectAll}
                          setSelectList={(name) => (
                            setSelectList(prevState => {
                              const indexToRemove = prevState.indexOf(name);
                              if (indexToRemove !== -1) {
                                prevState.splice(indexToRemove, 1);
                              } else {
                                prevState.push(name)
                              }
                              if (!!prevState?.length && !!platformInfo.filteredItems?.length &&
                                ((prevState.length === platformInfo.filteredItems.length && !selectAll) ||
                                  (prevState.length !== platformInfo.filteredItems.length && selectAll))) {
                                setSelectAll(prevState.length === platformInfo.filteredItems.length)
                              }
                              if (!prevState.length) {
                                setSelectAll(false)
                              }
                              return [...prevState]
                            })
                          )}
                          selectList={selectList}
                          subPlan={platformInfo.subPlan}
                          users={platformInfo.users}
                          trialState={trialState}
                          total={total}
                          page={page}
                          setPage={(p) => {
                            setPage(p)
                            clearState(true)
                            fetchData(p, searchTerm)
                          }}
                          isLoading={isLoading}
                          isAdmin={isAdminRole}
                        />
                      </div>
                    </div>
                  </>
            }
            <TrelloBoardAdd
              availableItemsLeft={getRemainingItems()}
              updItems={() => {
                dispatch(fetchItemsForTrelloUser({page: page, perPage: 10})).unwrap()
                restartBadge()
              }}
              isOpen={notification.isOpen}
              user={users ? users[0] : {}}
              closeNotification={closeNotification}
              platformName={platformName}
              paymentType={paymentState}
              subPlan={platformInfo.subPlan}
            />
            <PaymentRequiredPoppup
              isOpen={paymentPoppup.isOpen}
              closeNotification={closeTrialPoppup}
              type={paymentState}
              platformName={paymentPoppup.platformName}
            />
            <Modal
              isOpen={showSettings}
              closeNotification={() => setShowSettings(false)}
              title='Edit settings'
              child={<TrelloUserAddEdit
                user={users ? users[0] : {}}
                subPlan={platformInfo.subPlan}
              />}
            />
            <Modal
              isOpen={showSettings2}
              className='ext-storage-modal'
              closeNotification={() => setShowSettings2(false)}
              child={<ExternalStorage
                platformModal='trello'
                closeModal={() => setShowSettings2(false)}
              />}
            />
            <DeleteItemDialog
              isOpen={!!deleteDate?.name?.length || !!deleteBulkDate.length}
              closeNotification={() => {
                clearState(true)
              }}
              isBulk={!!deleteBulkDate.length}
              platformName={platformName}
              bulkData={deleteBulkDate}
              singleData={deleteDate}
              paymentState={paymentState}
              items={platformInfo.filteredItems || []}
              name={deleteDate?.name || ''}
              updItems={() => dispatch(fetchItemsForTrelloUser({page: page, perPage: 10})).unwrap()}
              onConfirm={() => {
                deleteBulkDate.length > 1 ?
                  onConfirmDeleteBoard(deleteBulkDate, true) :
                  onConfirmDeleteBoard(deleteBulkDate[0])
              }}
            />
          </div>
        }
      </Box>
    </div>
  );
};

export default withRedirectAfterLogout()(TrelloPlatformSettings);
