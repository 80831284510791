import React, { FunctionComponent } from 'react';
import { errorIcon } from '../../../assets/icons/icons';

interface IValidationErrorProps {
  errorMessage?: string
}

const ValidationErrorBlock: FunctionComponent<IValidationErrorProps> = ({errorMessage}: IValidationErrorProps) => (
  <div className="validation-error">
    <img src={errorIcon} loading="lazy"/>
    <p className="error-message">
      {errorMessage}
    </p>
  </div>
);

export default ValidationErrorBlock;
