import {Card as MuiCard, CardContent as MuiCardContent, CircularProgress, Typography} from '@mui/material';
import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../store/hooks';
import {
  isHasProPlan,
  loadingUser
} from '../../../store/selectors';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import './style.scss';
import styled from 'styled-components';
import UserActions from "../UserSettings/UserActions/UserActions";
import {useNavigate} from "react-router-dom";

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 0;
    box-sizing: border-box;
  }
`;

const AuditTrail: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const userLoading = useAppSelector(loadingUser);
  const hasProPlan = useAppSelector(isHasProPlan)
  const navigate = useNavigate();

  useEffect(() => {
    if (!userLoading && !hasProPlan) {
      navigate('/')
    }
  }, [userLoading]);

  return (
    <div id="audit-page">
      {userLoading ?
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div> :
        <div className="audit-block">
          <Typography variant="h3" display="block" mb='32px'>
            {translate('common.default_pages.audit_trail')}
          </Typography>
          <Card>
            <CardContent>
              <UserActions/>
            </CardContent>
          </Card>
        </div>
      }
    </div>
  );
};

export default withRedirectAfterLogout()(AuditTrail);
