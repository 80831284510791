import {createAsyncThunk} from '@reduxjs/toolkit';
import {DefaultRootState} from 'react-redux';
import {EApiStatuses} from '../../../constants';
import {
  IDeletePayload, IGithubRestorePlace,
  IGithubSetPlaces,
  IGithubUser,
  ISetterUserWithGists,
  ISetterUserWithRepos
} from '../../../models/models';
import {IError, IPagePayload} from '../../../models/inner-models';
import {pingTrialStatusWrapper} from '../../../utils/pingWrapper';
import {EPlatform, EPlatformNames} from '../../constants';
import {addPlatformIdentifier} from '../../functions';
import instance from '../../middlewares/api';
import {AxiosResponse} from "axios";

const fetchGithubUsers = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlatformUsers, EPlatformNames.github),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('github/get-github-users');
      return resp?.data || {};
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const getGithubPlaces = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlatformPlaces, EPlatformNames.github),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('github/get-available-user-places');
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const setGithubPlaces = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlatformPlaces, EPlatformNames.github),
  async (payload: IGithubSetPlaces, thunkAPI) => {
    try {
      const resp = await instance.post('github/add-places-for-user', payload);
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const addGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addPlatformUser, EPlatformNames.github),
  async (githubUser: IGithubUser, thunkAPI) => {
    try {
      const resp = await instance.post('github/add-github-user', githubUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message, code: resp.data.code}) :
        resp?.data?.result;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const addGithubToken = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addPlatformToken, EPlatformNames.github),
  async (token: string, thunkAPI) => {
    try {
      const resp = await instance.post('github/change-github-owner-token', {access_token: token});
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message, code: resp.data.code}) :
        resp?.data?.result;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const sendConfirmGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.sendConfirmPlatformUser, EPlatformNames.github),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.post('github/send-confirm-github-user', {});
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message, ...resp.data}) :
        resp?.data.message;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const confirmGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.confirmPlatformUser, EPlatformNames.github),
  async (code: string, thunkAPI) => {
    try {
      const resp = await instance.post('github/confirm-code', {code});
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message}) :
        resp?.data.message;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const getAvailableItemsForGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getAvailableItems, EPlatformNames.github),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`github/get-available-repos-for-github-user?page=${payload.page}&perPage=${payload.perPage}&placeId=${payload.placeId}${payload.name ? `&name=${payload.name}` : ''}`, {signal: payload.signal});
      return resp?.data?.result?.not_added_repos? { repos: resp?.data?.result?.not_added_repos, lastPage: resp?.data?.result?.page }: [];
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const getAvailableGistsForGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getAvailableItems, EPlatformNames.github),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`github/get-available-gists-for-github-user?page=${payload.page}&perPage=${payload.perPage}`, {signal: payload.signal});
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const editGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.editPlatformUser, EPlatformNames.github),
  async (githubUser: IGithubUser, thunkAPI) => {
    try {
      const resp = await instance.post('github/change-github-user', githubUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message, code: resp.data.code}) :
        resp?.data;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const changeGithubAutoAdd = createAsyncThunk(
  addPlatformIdentifier(EPlatform.editPlatformUser, EPlatformNames.github),
  async (githubUser: IGithubUser, thunkAPI) => {
    try {
      const resp = await instance.post('github/change-github-user-auto-add', githubUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message, code: resp.data.code}) :
        resp?.data?.result;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const fetchItemsForGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserItems, EPlatformNames.github),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`github/get-user-github-items?page=${payload.page}&perPage=${payload.perPage}&placeId=${payload.placeId}${payload.name ? `&name=${payload.name}` : ''}`);
      return resp?.data?.result || {};
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const fetchGistForGithubUser = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchUserItemsSec, EPlatformNames.github),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`github/get-user-github-gists?page=${payload.page}&perPage=${payload.perPage}${payload.name ? `&name=${payload.name}` : ''}`);
      return resp?.data?.result || {};
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const addGithubItems = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addItem, EPlatformNames.github),
  async (reposWithUser: ISetterUserWithRepos, thunkAPI) => {
    try {
      const resp = await instance.post('github/add-github-repos', reposWithUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({status: resp.data.status, error: resp.data.message}) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const addGithubGists = createAsyncThunk(
  addPlatformIdentifier(EPlatform.addItemGist, EPlatformNames.github),
  async (reposWithUser: ISetterUserWithGists, thunkAPI) => {
    try {
      const resp = await instance.post('github/add-github-gists', reposWithUser);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({status: resp.data.status, error: resp.data.message}) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const deleteGithubItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.deleteItem, EPlatformNames.github),
  async (payload: IDeletePayload, thunkAPI) => {
    try {
      const resp = await instance.delete('github/delete-repos', {
        data: payload
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message}) : resp.data;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const deleteGithubGist = createAsyncThunk(
  addPlatformIdentifier(EPlatform.deleteItem, EPlatformNames.github),
  async (payload: IDeletePayload, thunkAPI) => {
    try {
      const resp = await instance.delete('github/delete-gists', {
        data: payload,
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message}) : resp.data;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const backupGithubItem = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupItem, EPlatformNames.github),
  async (repoName: string, thunkAPI) => {
    try {
      const resp = await instance.post('github/backup-now', {
        repoName: repoName?.split('::')[0],
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message}) : resp.data;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const backupGithubGist = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupItem, EPlatformNames.github),
  async (gistId: string, thunkAPI) => {
    try {
      const resp = await instance.post('github/backup-now-gist', {
        id: gistId?.split('::')[0],
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message}) : resp.data;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const backupRepoAll = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupAll, EPlatformNames.all),
  async (placeId: number, thunkAPI) => {
    try {
      const resp = await instance.get(`github/backup-all-repos-now?placeId=${placeId}`, {});

      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({
          status: resp.data.status,
          error: resp.data.message,
          code: resp.data.code
        }) : resp.data;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const backupGistAll = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupAllSec, EPlatformNames.all),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get(`github/backup-all-gists-now`, {});

      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({
          status: resp.data.status,
          error: resp.data.message,
          code: resp.data.code
        }) : resp.data;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

//todo find usage and delete
const checkGithubTrialStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.checkUserTrial, EPlatformNames.github),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('payments/get-github-trial-status');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message}) :
        resp.data.result;
      // return subResponseMockData[7];
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const pingGithubPaymentStatus = createAsyncThunk(
  addPlatformIdentifier(EPlatform.pingPaymentStatus, EPlatformNames.github),
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as DefaultRootState;

      const resp = await pingTrialStatusWrapper(state.platform.github.subscription?.status); //instance.post('payments/start-github-trial', {});
      return (!resp.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({error: resp.data.message}) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);

const fetchPlacesForGithubRestore = createAsyncThunk(
  addPlatformIdentifier(EPlatform.fetchPlacesForGithubRestore, EPlatformNames.github),
  async (payload, thunkAPI) => {
    try {
      const resp: AxiosResponse<{
        status: string, result: {
          places: IGithubRestorePlace[]
        }
      }> = await instance.get(`github/get-available-github-places`, {});
      return resp?.data?.result || [];
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({error: error.message});
    }
  },
);


export {

  fetchItemsForGithubUser,
  getAvailableItemsForGithubUser,
  getAvailableGistsForGithubUser,
  getGithubPlaces,

  addGithubToken,
  setGithubPlaces,
  addGithubItems,
  addGithubGists,
  deleteGithubItem,
  deleteGithubGist,
  backupGithubItem,
  backupGithubGist,
  backupRepoAll,
  backupGistAll,

  fetchGithubUsers,
  fetchGistForGithubUser,
  addGithubUser,
  editGithubUser,
  changeGithubAutoAdd,
  sendConfirmGithubUser,
  confirmGithubUser,

  checkGithubTrialStatus,
  pingGithubPaymentStatus,
  fetchPlacesForGithubRestore
};
