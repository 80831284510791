import Swal from "sweetalert2";
import {warningBigIcon} from "../../assets/icons/icons";

export const updateSubscriptionPlanModal = ({translate,platformName,navigate}) => {
  Swal.fire({
    title: translate('notifications.subscription.update_subscribe_plan_title'),
    text: translate('notifications.subscription.update_subscribe_plan'),
    cancelButtonText: translate('notifications.choices.close'),
    confirmButtonText: translate('notifications.choices.upgrade'),
    showCancelButton: true,
    imageUrl: warningBigIcon,
  })
    .then((res) => {
      if (res.isConfirmed) {
        localStorage.setItem('checkout-platform', platformName)
        navigate('/checkout');
      }
    })
};
