import { createAsyncThunk } from '@reduxjs/toolkit';
import * as cognito from '../../cognito/cognitoTest';
import { EApiStatuses } from '../../constants';
import { IEditUser, IError, ILogin, IRecoveryPassword } from '../../models/inner-models';
import { IChangePassword, IRecoverPasswordConfirm, ISignUpApi, ISignupConfirm, IUser } from '../../models/models';
import EAuth from '../constants/auth';
import instance from '../middlewares/api';

const loginCognito = createAsyncThunk(
  EAuth.login,
  async (loginInfo: ILogin, thunkAPI) => {
    try {
      return await cognito.onLogin(loginInfo); // get id token from cognito(on Login)
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const loginGoogleCognito = createAsyncThunk(
  EAuth.loginG,
  async (token: string, thunkAPI) => {
    try {
      return await cognito.onGoogleLogin(token); // get id token from cognito(on Login)
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getCognitoUser = createAsyncThunk(
  EAuth.user,
  async (_, thunkAPI) => {
    try {
      return await cognito.getCurrentUser() as IUser;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const logoutCognito = createAsyncThunk(
  EAuth.logout,
  async (_, thunkAPI) => {
    try {
      const result: IUser | { error: string } = await cognito.onLogout();
      return result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const registerCognitoUser = createAsyncThunk(
  EAuth.signup,
  async (signupInfo: ISignUpApi, thunkAPI) => {
    try {
      return await cognito.onSignUp(signupInfo);
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const confirmUserRegistration = createAsyncThunk(
  EAuth.confirmReg,
  async (confirmInfo: ISignupConfirm, thunkAPI) => {
    try {
      const data = await instance.post('email-verification', confirmInfo);
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.result }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const unsubscribeMail = createAsyncThunk(
  EAuth.unsubscribe,
  async (unsubscribeInfo: ISignupConfirm, thunkAPI) => {
    try {
      const data = await instance.put('subscribe-mail-sending', unsubscribeInfo);
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.result }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const resendUserRegistrationLink = createAsyncThunk(
  EAuth.resendConfirm,
  async (email: string, thunkAPI) => {
    try {
      const data = await instance.post('resend-email-verification', { email });
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.result }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const recoverPasswordCognito = createAsyncThunk(
  EAuth.recoverPassword,
  async (recoverInfo: IRecoveryPassword, thunkAPI) => {
    try {
      return await cognito.onForgotPassword(recoverInfo);
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const confirmPasswordRecover = createAsyncThunk(
  EAuth.confirmPassword,
  async (confirmInfo: IRecoverPasswordConfirm, thunkAPI) => {
    try {
      return await cognito.onPasswordConfirm(confirmInfo);
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const changePassword = createAsyncThunk(
  EAuth.changePassword,
  async (changePasswordInfo: IChangePassword, thunkAPI) => {
    try {
      const data = await instance.post('user/change-user-password', {
        newPassword: changePasswordInfo.password,
        oldPassword: changePasswordInfo.oldPassword,
      });
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
      // return await cognito.onUpdatePassword(changePasswordInfo);
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
const updateUserInfo = createAsyncThunk(
  EAuth.editUser,
  async (editUserInfo: IEditUser, thunkAPI) => {
    try {
      return await cognito.editUser(editUserInfo);
    } catch (err) {
      const error = err as IError;

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export {
  loginCognito,
  loginGoogleCognito,
  getCognitoUser,
  registerCognitoUser,
  logoutCognito,
  confirmUserRegistration,
  unsubscribeMail,
  recoverPasswordCognito,
  confirmPasswordRecover,
  changePassword,
  updateUserInfo,
  resendUserRegistrationLink
};
