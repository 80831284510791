import { EGithubUserTypes } from '../../models/consts';
import { IGithubUser } from '../../models/models';

const isGithubAppInstalled = (user: IGithubUser) => {
  if (!user.githubapp) {
    return false;
  }

  return !(user.userType === EGithubUserTypes.ORGANIZATION && !user.githubAppOwner);
};

const isGithubConfirmed = (user: IGithubUser) => {
  if (!user.isConfirmed) {
    return false;
  }

  return !(user.userType === EGithubUserTypes.ORGANIZATION && !user.githubAppOwner);
};

export {
  isGithubAppInstalled,
  isGithubConfirmed
}
