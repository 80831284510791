export const LOCALE_ENGLISH = 'en';

export enum EApiStatuses {
  SUCCESS = 'success',
  FAILED = 'failed',
  WARNING = 'warning',
}

export enum EActionTypes {
  BACKUP = 'backup',
  RESTORE = 'restore',
}

export enum EWebsocketMethods {
  changeItemStatus = 'changeItemStatus',
  bulkChangeItemsStatuses = 'bulkChangeItemsStatuses',
  itemsAddedCountChange = 'itemsAddedCountChange',
  updateDashboardPlatformItemsAmount = 'updateDashboardPlatformItemsAmount'
}

export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
};

export enum EPaymentStatuses {
  SUCCESS = 'succeeded',
  CANCELED = 'canceled',
}

export const defaultTrialReposMax = 0;

export interface IClassName {
  className?: string
}
