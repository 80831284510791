import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DRAWER_WIDTH } from '../../models/consts';
import { VariantType } from '../../theme/variants';
import './style.scss';

const useStyles = makeStyles((theme: VariantType) =>
  createStyles({
      drawer: {
        width: DRAWER_WIDTH,
      },
      links: {
        width: 'fit-content',
        display: 'flex',
        textDecoration: 'underline'
      },
      link: {
        width: 'fit-content',
        margin: '0 8px',
        cursor: 'pointer',
      },
      rightsReserved: {
        opacity: '0.5'
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 40,
      },
      drawerMenu: {
        width: DRAWER_WIDTH,
      },
      footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '40px',
        color: theme.footer.background,
      },
      menuButton: {
        position: 'absolute',
      },
      svg: {
        width: '22px',
        height: '22px',
      },
    },
  ),
);


const Footer: FunctionComponent = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <div className={classes.footer}>
      <div className='rights-reserved'>
        © {new Date().getFullYear()} {translate('views.footer.all_rights_reserved')}
      </div>
      <div className={classes.links}>
        <div className={classes.link} onClick={() => {
          window.open('https://www.backuplabs.io/terms-of-use', '_blank');
        }}>
          {translate('common.default_pages.terms_of_use')}
        </div>
        <div className={classes.link} onClick={() => {
          window.open('https://www.backuplabs.io/privacy', '_blank');
        }}>
          {translate('common.default_pages.privacy_policy')}
        </div>
        <div className={classes.link} onClick={() => {
          window.open('https://www.backuplabs.io/static-files/sla.pdf', '_blank');
        }}>
          {translate('common.default_pages.sla')}
        </div>
      </div>
    </div>
  );
};

export default Footer;
